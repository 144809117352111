import React, {useEffect, useState} from 'react';
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import FormTextArea from "./FormTextArea";
import FormSelectBox from "./FormSelectBox";
import {createRandomString} from "../assets/functions/common";

import './../assets/css/custom.css';

import {THEME_COLOR9} from "../constants";

import {ReactComponent as InfoIcon} from "../assets/img/infoCircleBlack.svg";

const AddProductPackingPriceTab = (props) => {
  const [productUid, setProductUid] = useState('');
  const [packingMaterial, setPackingMaterial] = useState('');
  const [description, setDescription] = useState('');
  const [sku, setSku] = useState('');
  const [pickupLocation, setPickupLocation] = useState('');
  const [unit, setUnit] = useState('');
  const [unitLabel, setUnitLabel] = useState('');
  const [packageNetWeight, setPackageNetWeight] = useState('');
  const [packageGrossWeight, setPackageGrossWeight] = useState('');
  const [pricePerPackage, setPricePerPackage] = useState('');
  const [exwPricePerKg, setExwPricePerKg] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    setProductUid(props?.productUid);
  }, [props.productUid]);

  useEffect(() => {
    if (props.data != null) {
      setPackingMaterial(props.data.packingMaterial);
      setDescription(props.data.description);
      setSku(props.data.sku);
      setPickupLocation(props.data.pickupLocation);
      setPackageNetWeight(props.data.packageNetWeight);
      setPackageGrossWeight(props.data.packageGrossWeight);
      setPricePerPackage(props.data.pricePerPackage);
    } else {
      let sku_val = createRandomString(8);
      setSku(sku_val);
    }
  }, [props.data]);

  useEffect(() => {
    if (props?.data?.unit && (props?.data != null)) {
      setUnit(props?.data?.unit)
    } else {
      setUnit(props.list?.unitList[0]?.value)
    }
  }, [props?.list])

  useEffect(() => {
    if (unit != '') {
      let unit_list = [...props.list?.unitList];
      let index = unit_list.findIndex((x) => x.value == unit)
      if (index >= 0) {
        setUnitLabel(unit_list[index]?.label)
      }
    }
  }, [unit])

  useEffect(() => {
    let exw_price_per_kg = 0;
    if (pricePerPackage && packageNetWeight) {
      exw_price_per_kg = (pricePerPackage) / (packageNetWeight);
      setExwPricePerKg(exw_price_per_kg?.toFixed(2));
    }
  }, [pricePerPackage, packageNetWeight])

  const onContinueClick = () => {
    let errorText = {};
    if (packingMaterial == '') {
      errorText = {packingMaterial: 'Packing material is required'};
    }
    if (description == '') {
      errorText = {...errorText, description: 'Description No is required'};
    }
    if (sku == '') {
      errorText = {...errorText, sku: 'SKU is required'};
    }
    if (pickupLocation == '') {
      errorText = {...errorText, pickupLocation: 'Pickup Location is required'};
    }
    if (unit == '') {
      errorText = {...errorText, unit: 'Unit is required'};
    }
    if (packageNetWeight == '') {
      errorText = {...errorText, packageNetWeight: 'Package Net Weight is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(packageNetWeight)) {
      errorText = {...errorText, packageNetWeight: 'Invalid Package Net Weight'};
    }
    if (packageGrossWeight == '') {
      errorText = {...errorText, packageGrossWeight: 'Package Gross Weight is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(packageGrossWeight)) {
      errorText = {...errorText, packageGrossWeight: 'Invalid Package Gross Weight'};
    }
    if (pricePerPackage == '') {
      errorText = {...errorText, pricePerPackage: 'Price/Package is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(pricePerPackage)) {
      errorText = {...errorText, pricePerPackage: 'Invalid Price/Package'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      productUid: productUid,
      packingMaterial: packingMaterial,
      description: description,
      sku: sku,
      pickupLocation: pickupLocation,
      unit: unit,
      packageNetWeight: packageNetWeight,
      packageGrossWeight: packageGrossWeight,
      pricePerPackage: pricePerPackage,
    }

    if (props?.data != null) {
      props.onUpdateProductPackingPrice(data)
    } else {
      props.onAddProductPackingPrice(data)
    }
  };

  return (
    <Grid item container xs={11.6} md={11.6}
          display={'flex'}
          flexDirection={'column'}
    >

      <Grid item md={6} xs={12} mt={2}
            container>
        <FormTextInput
          page={'AddProductPackingPriceTab'}
          value={packingMaterial}
          setValue={setPackingMaterial}
          label={'Enter Packing Material'}
          error={error?.packingMaterial}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={6} xs={12} mt={3}
            container>
        <FormTextArea
          page={'AddProductPackingPriceTab'}
          value={description}
          setValue={setDescription}
          label={'Description'}
          error={error?.description}
          required={true}
        />
      </Grid>

      <Grid style={styles.borderBottomLine} mt={4}/>

      <Box mt={2}>
        <Typography style={styles.contentTitleText}>Your packing material is listed below.</Typography>
      </Box>

      <Box mt={2} mb={3} style={styles.contentView}
           display={'flex'} flexDirection={'row'} alignItems={'center'}
           p={2}>
        <InfoIcon height={13} width={13}/>
        <Typography style={styles.contentText} ml={0.2}>You may have different sizes for the same product, enter price
          of higher
          grade product in Price Column.
          Entered price will be applicable to all
          size variations.</Typography>
      </Box>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={styles.tableHeadRow}>
              <TableCell>
                <Typography style={styles.tableLabelText}>SKU</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Packing Material</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Pickup Location</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Unit</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Package Net Weight</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Package Gross Weight</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>EXW Price/Package</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>EXW Price/{unitLabel}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={styles.tableRow}>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  disabled={true}
                  value={sku}
                  setValue={setSku}
                  error={error?.sku}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  disabled={true}
                  value={packingMaterial}
                  setValue={setPackingMaterial}
                  error={error?.packingMaterial}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  value={pickupLocation}
                  setValue={setPickupLocation}
                  placeholder={'Enter Location'}
                  error={error?.pickupLocation}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormSelectBox
                  page={'AddProductDetailTab'}
                  value={unit}
                  setValue={setUnit}
                  data={props.list?.unitList ? props.list.unitList : []}
                  error={error?.unit}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  placeholder={unitLabel}
                  value={packageNetWeight}
                  setValue={setPackageNetWeight}
                  error={error?.packageNetWeight}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  placeholder={unitLabel}
                  value={packageGrossWeight}
                  setValue={setPackageGrossWeight}
                  error={error?.packageGrossWeight}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  value={pricePerPackage}
                  setValue={setPricePerPackage}
                  error={error?.pricePerPackage}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  value={exwPricePerKg}
                  setValue={setExwPricePerKg}
                  error={error?.exwPricePerKg}
                  required={true}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4} mb={4}>
        <FormButton
          btnType={'btn11'}
          title={'Continue'}
          onSubmit={onContinueClick}
        />
      </Grid>

    </Grid>
  );
}

export default AddProductPackingPriceTab;

const styles = {
  labelText: {
    color: '#000',
    fontSize: 15,
    fontFamily: 'InterMedium'
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  borderBottomLine: {
    border: '1px solid #ccc'
  },
  contentTitleText: {
    fontSize: 14,
    fontFamily: 'InterBold',
    color: '#000'
  },
  contentView: {
    backgroundColor: THEME_COLOR9,
    borderRadius: 4,
    boxShadow: '0px 2px 3px #aaa',
  },
  contentText: {
    fontSize: 13,
    color: '#000',
    fontFamily: 'InterItalic',
  },
  tableHeadRow: {
    backgroundColor: '#fff',
    border: '1px solid #000'
  },
  tableRow: {
    backgroundColor: '#fff',
    border: '1px solid #000'
  },
  tableLabelText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 12,
  }
};

