import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import AddProductVarietyDetail from "./AddProductVarietyDetail";
import FormSelectBox from "./FormSelectBox";
import FormMultiSelectBox from "./FormMultiSelectBox";
import AlertMsg from "./AlertMsg";

import './../assets/css/custom.css';

import {ReactComponent as CloseIcon} from "../assets/img/close.svg";
import {ReactComponent as EditIcon} from "../assets/img/pencil.svg";

import {THEME_COLOR9} from "../constants";

const AddProductDetailTab = (props) => {
  const [productUid, setProductUid] = useState('')
  const [productName, setProductName] = useState('')
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [subCategoryList, setSubCategoryList] = useState([])
  const [selectedVarietyData, setSelectedVarietyData] = useState(null)
  const [hsCode, setHsCode] = useState(null);
  const [error, setError] = useState(null);
  const [variety, setVariety] = useState('')
  const [sizeList, setSizeList] = useState([])
  const [description, setDescription] = useState('')
  const [selectedSizeData, setSelectedSizeData] = useState(null)

  useEffect(() => {
    if (props.data != null) {
      setProductUid(props.data.productUid);
      setProductName(props.data.product);
      setCategory(props.data.category);
      setSubCategory(props.data.subCategory);
      setVariety(props.data.variety);
      setSizeList(props.data.sizeList);
      setDescription(props.data.description);

      if(props?.list) {
        let hscode_list = [...props?.list?.hsCodeList]
        if (hscode_list?.length > 0) {
          let codeIndex = hscode_list.findIndex((x) => x.value === props.data?.hsCode);
          if (codeIndex >= 0) {
            setHsCode(hscode_list[codeIndex]);
          }
        }
      }
    }
  }, [props.data, props.list]);

  useEffect(() => {
    if (category != '') {
      let catIndex = props.list?.categoryList.findIndex((x) => x.value === category);
      if (catIndex >= 0) {
        setSubCategoryList(props.list.categoryList[catIndex].subCategories);
      }
    }
  }, [category])

  const onChangeCategory = (value) => {
    setCategory(value);
    setSubCategory('');
  };

  const onContinueClick = () => {
    let errorText = {};
    if (category == '') {
      errorText = {category: 'Category is required'};
    }
    if (subCategory == '') {
      errorText = {...errorText, subCategory: 'Sub Category is required'};
    }
    if (hsCode == null) {
      errorText = {...errorText, hsCode: 'Hs Code is required'};
    }
    if (productName == '') {
      errorText = {...errorText, productName: 'Product is required'};
    }

    if(variety == '') {
      errorText = {...errorText, variety: 'Variety is required'};
    }
    if(sizeList.length == 0) {
      errorText = {...errorText, sizeList: 'Size is required'};
    }
    if(description == '') {
      errorText = {...errorText, description: 'Description is required'};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      productUid: productUid,
      category: category,
      subCategory: subCategory,
      hsCode: hsCode.value,
      product: productName,
      variety: variety,
      sizeList: JSON.stringify(sizeList),
      description: description,
    }

    if (productUid) {
      props.onUpdateProductVariety(data)
    } else {
      props.onAddProductVariety(data)
    }
  };

  // const onAddVarietyClick = (data, type) => {
  //   setSelectedVarietyData(null);
  //   let list = [...varietyList];
  //   if (type == 'edit') {
  //     let index = list.findIndex((x) => x?.id == data?.id);
  //     if (index >= 0) {
  //       list[index] = data;
  //     }
  //     setVarietyList(list)
  //   } else {
  //     setVarietyList([...list, data])
  //   }
  // };
  //
  // const onEditVariety = (item) => {
  //   setSelectedVarietyData(item);
  // };
  //
  // const onDeleteVariety = (item) => {
  //   let list = [...varietyList];
  //   let index = list.findIndex((x) => x?.id == item?.id);
  //   if (index >= 0) {
  //     list.splice(index, 1);
  //   }
  //   setVarietyList(list)
  // };

  return (
    <>

      <Grid container item md={11.6} xs={11.6} style={styles.contentView}
            display={'flex'}
            alignItems={'flex-start'}
            p={2}>
        <Typography style={styles.contentText}>Select category, sub category and HS Code of your product. For example, if
          you are listing
          Fresh Apple, the category will be Fruits, sub category will be Apple Fresh and HS Code will be
          080810.</Typography>
      </Grid>

      <Grid container item md={11} xs={11}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            mt={3}
            style={styles.boxView}
            p={2}>

        <Grid item md={5.9} xs={12} mt={3}
              container>
          <FormSelectBox
            page={'AddProductDetailTab'}
            value={category}
            setValue={onChangeCategory}
            data={props.list?.categoryList ? props.list.categoryList : []}
            label={'Category'}
            placeholder={'Select Category'}
            error={error?.category}
            required={true}
          />
        </Grid>

        <Grid item md={5.9} xs={12} mt={3}
              container>
          <FormSelectBox
            page={'AddProductDetailTab'}
            value={subCategory}
            setValue={setSubCategory}
            data={subCategoryList}
            label={'Sub Category'}
            placeholder={'Select Sub Category'}
            error={error?.subCategory}
            required={true}
          />
        </Grid>

        <Grid item md={5.9} xs={12} mt={3}
              container>
          <FormMultiSelectBox
            page={'AddProductDetailTab'}
            multiple={false}
            value={hsCode}
            setValue={setHsCode}
            data={props.list?.hsCodeList ? props.list.hsCodeList : []}
            label={'HS Code'}
            placeholder={'Select Hs Code'}
            error={error?.hsCode}
            required={true}
          />
        </Grid>

        <Grid item md={5.9} xs={12} mt={3}
              container>
          <FormTextInput
            page={'AddProductDetailTab'}
            value={productName}
            setValue={setProductName}
            label={'Product Name'}
            error={error?.productName}
            required={true}
          />
        </Grid>
      </Grid>

      <Grid container item md={11.6} xs={11.6} style={styles.contentView}
            p={2} mt={4}>
        <Typography style={styles.contentText}>For the fields below,list the Variety of your product with their size
          variations and
          give a brief Description of the Variety in the given box.</Typography>
      </Grid>

      <Grid container item md={11} xs={11}>

        {/*{varietyList.map((item, index) => {*/}
        {/*  return (*/}
        {/*    <AddProductVarietyDetail*/}
        {/*      key={index}*/}
        {/*      item={item}*/}
        {/*      type={'edit'}*/}
        {/*      onAdd={onAddVarietyClick}*/}
        {/*    />*/}
        {/*  )*/}
        {/*})}*/}


        <AddProductVarietyDetail
          type={'add'}
          item={selectedVarietyData}
          variety={variety}
          setVariety={setVariety}
          sizeList={sizeList}
          setSizeList={setSizeList}
          description={description}
          setDescription={setDescription}
          selectedSizeData={selectedSizeData}
          setSelectedSizeData={setSelectedSizeData}
          error={error}
          setError={setError}
          // onAdd={onAddVarietyClick}
        />

        {/*<Grid item md={12} xs={12}*/}
        {/*      container*/}
        {/*      display={"flex"}*/}
        {/*      alignItems={"center"}*/}
        {/*      mt={3}*/}
        {/*      style={styles.cardDiv}>*/}
        {/*  <TableContainer>*/}
        {/*    <Table aria-label="simple table">*/}
        {/*      <TableHead>*/}
        {/*        <TableRow>*/}
        {/*          <TableCell>Variety</TableCell>*/}
        {/*          <TableCell>Description</TableCell>*/}
        {/*          <TableCell>Size</TableCell>*/}
        {/*          <TableCell>Action</TableCell>*/}
        {/*        </TableRow>*/}
        {/*      </TableHead>*/}
        {/*      <TableBody>*/}
        {/*        {varietyList?.length > 0 ?*/}
        {/*          varietyList.map((item, index) => (*/}
        {/*            <TableRow*/}
        {/*              key={index}>*/}
        {/*              <TableCell>{item?.variety}</TableCell>*/}
        {/*              <TableCell>{item?.description}</TableCell>*/}
        {/*              <TableCell>{item?.sizeList?.map((item) => item?.size).join(', ')}</TableCell>*/}
        {/*              <TableCell>*/}
        {/*                <Box display={'flex'} flexDirection={'row'}>*/}
        {/*                  <Box onClick={() => onEditVariety(item)}>*/}
        {/*                    <EditIcon height={15} width={15}/>*/}
        {/*                  </Box>*/}
        {/*                  <Box ml={2}*/}
        {/*                       onClick={() => onDeleteVariety(item)}>*/}
        {/*                    <CloseIcon height={15} width={15}/>*/}
        {/*                  </Box>*/}
        {/*                </Box>*/}
        {/*              </TableCell>*/}
        {/*            </TableRow>*/}
        {/*          )) : null}*/}
        {/*      </TableBody>*/}
        {/*    </Table>*/}
        {/*  </TableContainer>*/}
        {/*</Grid>*/}

      </Grid>

      {error?.varietyList ?
        <Grid container item md={11} xs={11} mt={1} ml={0.5} styles={styles.errorView}>
          <Typography sx={styles.errorText}>{error?.varietyList}</Typography>
        </Grid>
        : null}

      <Grid item md={11} xs={11} container
            display="flex" flexDirection="row" justifyContent="right" mt={3} mb={3}>
          <FormButton
            btnType={'btn11'}
            title={'Continue'}
            onSubmit={onContinueClick}
          />
      </Grid>

      <AlertMsg/>

    </>
  );
}

export default AddProductDetailTab;

const styles = {
  cardDiv: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFF',
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  contentView: {
    backgroundColor: THEME_COLOR9,
    borderRadius: 4,
    boxShadow: '0px 2px 3px #aaa',
  },
  contentText: {
    fontSize: 13,
    color: '#000',
    fontFamily: 'InterMedium'
  },
  boxView: {
    border: '1px solid #aaa'
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'InterMedium'
  }
};

