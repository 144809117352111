import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Grid, Typography, Box, Button, Card, CardContent, CardActions} from "@mui/material";

import Container from "../../components/Container";
import BasicAddProductTabs from "../../components/BasicAddProductTabs";

import {connect} from "react-redux";
import {
  addProductData,
  updateProductDetail,
  getProductDetail,
  updateApiStatus
} from "../../redux/actions/userDataActions";

import styles from './AddProduct.module.css'

const AddProduct = (props) => {
  const navigate = useNavigate();

  useEffect(() => {

    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let product_id = params.get('id');
    if (product_id) {
      let data = {
        product_uid: product_id
      };
      props.getProductDetail(JSON.stringify(data));
    } else {
      props.updateProductDetail(null);
    }
    props.addProductData();

  }, []);


  useEffect(() => {
    if(props.apiStatus == 'GET_PRODUCT_DETAIL_FAIL') {
      navigate('/product')
      props.updateApiStatus('')
    } else if(props.apiStatus == 'GET_PRODUCT_DETAIL_SUCCESS') {
      let windowUrl = window.location.search;
      let params = new URLSearchParams(windowUrl);
      let product_id = params.get('id');
      if (product_id) {
        if (props?.productDetail?.productStatus == 'pending') {
        } else {
          navigate('/product')
        }
      }
      props.updateApiStatus('')
    }
  },[props.apiStatus])

  return (
    <Container page={"product"}>
      <Typography className={styles.addProductText}>Add Product</Typography>

      <Grid container item md={12} xs={12} mt={4}>
        <BasicAddProductTabs
          list={props?.addProductDataList ? props.addProductDataList : null}
          productDetail={props?.productDetail}
        />
      </Grid>

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    addProductDataList: state.userData.addProductDataList,
    productDetail: state.userData.productDetail,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProductData: data => dispatch(addProductData(data)),
    updateProductDetail: data => dispatch(updateProductDetail(data)),
    getProductDetail: data => dispatch(getProductDetail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);

