import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";

import {connect} from "react-redux";
import {addSellerBankDetail, updateSellerBankDetail} from "../redux/actions/userDataActions";

import './../assets/css/custom.css';

const AddBankDetails = (props) => {
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountType, setAccountType] = useState('Current Account');
  const [bankName, setBankName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [reEnterAccountNo, setReEnterAccountNo] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [reEnterSwiftCode, setReEnterSwiftCode] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      setAccountHolderName(props.data.accountHolderName);
      setBankName(props.data.bankName);
      setAccountNo(props.data.accountNo);
      setReEnterAccountNo(props.data.accountNo);
      setSwiftCode(props.data.swiftCode);
      setReEnterSwiftCode(props.data.swiftCode);
    }
  }, [props.data]);

  const onSaveClick = () => {
    let errorText = {};
    if (accountHolderName == '') {
      errorText = {accountHolderName: 'Account Holder Name is required'};
    }
    if (bankName == '') {
      errorText = {...errorText, bankName: 'Bank Name is required'};
    }
    if (accountNo == '') {
      errorText = {...errorText, accountNo: 'Account Number is required'};
    }
    if (reEnterAccountNo == '') {
      errorText = {...errorText, reEnterAccountNo: 'Re-enter Account Number is required'};
    }
    if ((accountNo != '' && reEnterAccountNo != '') && (accountNo != reEnterAccountNo)) {
      errorText = {...errorText, reEnterAccountNo: 'Account Number not match'};
    }
    if (swiftCode == '') {
      errorText = {...errorText, swiftCode: 'IFSC is required'};
    }
    if (reEnterSwiftCode == '') {
      errorText = {...errorText, reEnterSwiftCode: 'Confirm IFSC is required'};
    }
    if ((swiftCode != '' && reEnterSwiftCode != '') && (swiftCode != reEnterSwiftCode)) {
      errorText = {...errorText, reEnterSwiftCode: 'IFSC not match'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      sellerId: props?.data?.sellerId,
      accountHolderName: accountHolderName,
      bankName: bankName,
      accountNo: accountNo,
      swiftCode: swiftCode,
    };

    if (props.data?.sellerId) {
      props.updateSellerBankDetail(JSON.stringify(data))
    } else {
      props.addSellerBankDetail(JSON.stringify(data))
    }

    // props.onSaveClick(data)
  };

  // const onPrevClick = () => {
  //   props.onPrevBankDetailClick()
  // };

  return (
    <Grid
      item container
      xs={12} md={12}
      alignItems={"flex-start"}
      justifyContent={"space-between"} mt={3}>


      <Grid item md={12} xs={12}
            display={'flex'}
            flexDirection={'column'}
            container>
        <Typography style={styles.titleText}>Bank Account Details</Typography>

        <Typography style={styles.descText} mt={2}>Provide your bank account details to receive the proceedings of your
          global trade. Please provide the details of the bank account which is linked to your Export
          license.</Typography>
      </Grid>


      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddBankDetails'}
          disabled={true}
          value={accountHolderName}
          setValue={setAccountHolderName}
          label={'Account Holder Name'}
          placeholder={'As on tax registration'}
          error={error?.accountHolderName}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddBankDetails'}
          disabled={true}
          value={accountType}
          setValue={setAccountType}
          label={'Account Type'}
          error={error?.accountType}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddBankDetails'}
          value={bankName}
          setValue={setBankName}
          label={'Name of the bank'}
          error={error?.bankName}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddBankDetails'}
          value={accountNo}
          setValue={setAccountNo}
          label={'Account Number'}
          error={error?.accountNo}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddBankDetails'}
          value={reEnterAccountNo}
          setValue={setReEnterAccountNo}
          label={'Re-enter Account Number'}
          error={error?.reEnterAccountNo}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddBankDetails'}
          value={swiftCode}
          setValue={setSwiftCode}
          label={'IFSC'}
          error={error?.swiftCode}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddBankDetails'}
          value={reEnterSwiftCode}
          setValue={setReEnterSwiftCode}
          label={'Re-enter IFSC'}
          error={error?.reEnterSwiftCode}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      {props?.type == 'edit' ? null :
        <Grid item md={12} xs={12} container
              display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box>
            <FormButton
              title={'Save'}
              onSubmit={onSaveClick}
            />
          </Box>
        </Grid>
      }

    </Grid>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    addSellerBankDetail: data => dispatch(addSellerBankDetail(data)),
    updateSellerBankDetail: data => dispatch(updateSellerBankDetail(data)),
  };
};

export default connect(null, mapDispatchToProps)(AddBankDetails);

const styles = {
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  descText: {
    color: '#000',
    fontSize: 13,
    fontFamily: 'InterMedium',
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
};

