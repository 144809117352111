import React from 'react';
import {Box, Typography} from "@mui/material";
import {v4 as uuidv4} from 'uuid';
import Dropzone from 'react-dropzone'

import {ReactComponent as ImgIcon} from "../assets/img/img.svg";
import {ReactComponent as CloseIcon} from "../assets/img/close.svg";

import {connect} from "react-redux";
import {updateAlertMsg} from "../redux/actions/userDataActions";

const MultiImageUpload = (props) => {

  const uploadFile = acceptedFiles => {
    let fileList = [];
    acceptedFiles.map(file => {
      let image = URL.createObjectURL(file);
      fileList.push({
        id: uuidv4(),
        image: image,
        file: file,
      })
    });
    props.onChange(fileList);
  };

  const uploadError = (err) => {
    if (err.length) {
      if (err[0]['errors'].length)
        if (err[0]['errors'][0]['code'] == 'too-many-files') {
          if (props.maxFiles == 1) {
            props.updateAlertMsg({open: true, message: 'Only 1 image is allowed to upload', msgType: 'error'});
          } else if (props.maxFiles == 5) {
            props.updateAlertMsg({open: true, message: 'Maximum 5 images are allowed to upload', msgType: 'error'});
          } else {
            props.updateAlertMsg({
              open: true,
              message: `Only ${props.maxFiles} images are allowed to upload at a time`,
              msgType: 'error'
            });
          }
        } else {
          props.updateAlertMsg({open: true, message: err[0]['errors'][0]['message'], msgType: 'error'});
        }
    }
  };

  const onRemoveImage = (x) => {
    props.onRemove(x);
  };

  const ImageUploadView = (props) => {
    return (
      <Box sx={[styles.uploadMainImgView, {cursor: 'pointer'}]}
           {...(props?.getRootProps())}>
        <input {...props?.getInputProps()} />
        <Box display={"flex"}
             alignItems={"center"}
             justifyContent={"center"}
             sx={styles.uploadImgView}>
          <ImgIcon height={50} width={50}/>
        </Box>
      </Box>
    )
  }

  return (
    <Box>

      <Dropzone
        maxFiles={props.maxFiles}
        onDropRejected={err => uploadError(err)}
        accept={{'image/*': ['.jpeg', '.jpg', '.png']}}
        onDrop={uploadFile}>
        {({getRootProps, getInputProps}) => (

          <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
            {props.image.length > 0 ?
              props.image.map((x) => {
                return (
                  <Box sx={[styles.uploadMainImgView]} mr={4} mb={3} key={x.image}>
                    <Box display={"flex"}
                         alignItems={"center"}
                         justifyContent={"center"}
                         sx={styles.uploadImgView}>
                      <img src={x.image} height="100" width="100"/>
                    </Box>

                    <Box display={"flex"}
                         alignItems={"center"}
                         justifyContent={"center"}
                         sx={[styles.pencilCloseIconView, styles.closeIconView]}
                         onClick={() => onRemoveImage(x)}>
                      <CloseIcon height={13} width={13}/>
                    </Box>
                  </Box>
                )
              })
              : null}

            {props?.imageLengthCheck ?
              props?.image?.length < props?.maxFiles ?
                <ImageUploadView
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                />
                : null
              :
              <ImageUploadView
                getRootProps={getRootProps}
                getInputProps={getInputProps}
              />}

          </Box>

        )}
      </Dropzone>

      {props?.error ?
        <Box mt={2} ml={0.5} styles={styles.errorView}>
          <Typography sx={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Box>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    updateAlertMsg: data => dispatch(updateAlertMsg(data)),
  };
};

export default connect(null, mapDispatchToProps)(MultiImageUpload);

const styles = {
  uploadMainImgView: {
    position: 'relative',
    width: 'fit-content',
  },
  uploadImgView: {
    overflow: 'hidden',
    border: '3px solid #FFF',
    borderRadius: 3,
    boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
    height: 100,
    width: 100,
  },
  pencilCloseIconView: {
    position: 'absolute',
    backgroundColor: '#FFF',
    cursor: 'pointer',
    border: '3px solid #FFF',
    borderRadius: 10,
    right: -10,
    boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
    height: 24,
    width: 24,
  },
  pencilIconView: {
    top: -10,
  },
  closeIconView: {
    bottom: -10,
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'InterMedium'
  },
};
