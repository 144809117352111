import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import moment from "moment";

import styles from './OrderPaymentDetailView.module.css'

const OrderPaymentDetailView = (props) => {

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Method of Payment</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Transaction ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Payment Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Payment Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data?.order_payment_list?.length > 0 ?
              props.data?.order_payment_list?.map((item, index) => (
                <TableRow key={index} className={styles.tableRow}>
                  <TableCell>-</TableCell>
                  <TableCell>{item?.transaction_id ? item.transaction_id : '-'}</TableCell>
                  <TableCell>
                    {item?.payment_date ? moment(item.payment_date).format('DD-MM-YYYY') : '-'}
                  </TableCell>
                  <TableCell>{item?.type ?  (item.type == 'half_paid' ? '50% Paid' : 'Paid') : '-'}</TableCell>
                </TableRow>
              )) : null}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default OrderPaymentDetailView;
