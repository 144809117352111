import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import AddRCMCDetailsView from "./AddRCMCDetailsView";

import './../assets/css/custom.css';

const AddRCMCDetails = (props) => {
  const [rcmcList, setRcmcList] = useState([]);

  useEffect(() => {
    if (props.data != null) {
      let data = props?.data?.rcmcDetails;
      if(props?.type == 'edit') {

      } else {
        if (data?.length > 0) {
          let list = [...data];
          data = [{
            rcmcUid: '',
            boardName: '',
            rcmcNo: '',
            rcmcRegistrationDocument: null,
            displayRcmcRegistrationDocument: '',
          }, ...list]
        } else {
          data = [{
            rcmcUid: '',
            boardName: '',
            rcmcNo: '',
            rcmcRegistrationDocument: null,
            displayRcmcRegistrationDocument: '',
          }]
        }
      }
      setRcmcList(data);
    }
  }, [props.data]);

  const onAddMore = (newData) => {
    let list = [...rcmcList]
    setRcmcList([...list, newData]);
  }


  return (
    <Grid item container xs={12} md={12}
          alignItems={"flex-start"}
          justifyContent={"space-between"} mt={3}>

      <Typography style={styles.titleText}>RCMC Details</Typography>

      {rcmcList.map((item, index) => {
        return (
          <AddRCMCDetailsView
            key={index}
            index={index}
            item={item}
            data={props?.data}
            type={props?.type ? props?.type : 'add'}
            onAddMore={onAddMore}
          />
        )
      })}

    </Grid>
  );
}

export default AddRCMCDetails;

const styles = {
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
};

