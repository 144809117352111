import React, {useEffect, useState} from 'react'
import {
  Typography,
  Grid,
  Box,
  Tabs,
  Tab, Snackbar
} from "@mui/material";
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import ProductTab from "../../components/ProductTab";
import AlertMsg from "../../components/AlertMsg";
import FormButton from "../../components/FormButton";
import FormTextInput from "../../components/FormTextInput";
import FormSelectBox from "../../components/FormSelectBox";

import {connect} from "react-redux";
import {getSellerCategoryList} from "../../redux/actions/userDataActions";

import '../../assets/css/custom.css';

import {THEME_COLOR7, THEME_COLOR8, USER_URL} from "../../constants";

import {ReactComponent as TickIcon} from "../../assets/img/tick_green.svg";

import styles from './Product.module.css'
import moment from "moment";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Product = (props) => {
  const [searchSku, setSearchSku] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [productMessageVal, setProductMessageVal] = useState(null);
  const [value, setValue] = useState(0);
  const [copyOpen, setCopyOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    props.getSellerCategoryList();
  }, []);

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let product_status = params.get('status');
    if (product_status) {
      if (product_status == 'active') {
        setValue(1)
      } else if (product_status == 'inactive') {
        setValue(2)
      } else if (product_status == 'removed') {
        setValue(3)
      }
    }
  }, [])

  useEffect(() => {
    if (props?.productMessage != null) {
      setProductMessageVal(props?.productMessage)
    }
  }, [props?.productMessage]);

  useEffect(() => {
    if (props?.sellerCategoryList?.length > 0) {
      setCategoryList(props?.sellerCategoryList)
    }
  }, [props?.sellerCategoryList])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAddProduct = () => {
    navigate('/add_product');
  };

  const onChangeCategory = (val) => {
    setCategory(val);
    setSubCategory('');
    let list = [...categoryList];
    let listIndex = list.findIndex((x) => x.value == val);
    if (listIndex >= 0) {
      setSubCategoryList(list[listIndex]['subCategories']);
    }
  };

  const onSearchBtnClick = () => {
    let filter = {...filterData}
    filter['searchSku'] = searchSku;
    setFilterData(filter);

  };

  const onRefreshBtnClick = () => {
    let filter = {...filterData}
    filter['category'] = category;
    filter['subCategory'] = subCategory;
    setFilterData(filter);
  };

  const onShareStore = () => {
    let url = USER_URL + 'seller/' + props?.sellerUid;
    setCopyOpen(true);
    navigator.clipboard.writeText(url)
  };

  return (
    <Container page={"product"}>

      <Grid item md={12} xs={12} px={2} pt={2}>
        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
          <Grid item md={6} xs={12}>
            <Typography className={styles.manageInventoryText} mb={2}>Manage Inventory</Typography>
          </Grid>
          <Grid item md={6} xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}>
            <FormButton
              btnType={'btn9'}
              title={'Share your store'}
              onSubmit={onShareStore}
            />
            <Box pl={2}>
              <FormButton
                btnType={'btn11'}
                title={'Add Product'}
                onSubmit={onAddProduct}
              />
            </Box>
          </Grid>
        </Grid>

        {productMessageVal != null ?
          productMessageVal?.type == 'product_added' ?
            <Grid item md={12} xs={12} className={styles.msgBorderView} mt={3} mb={6} py={2} px={3}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                <Box>
                  <TickIcon height={20} width={20}/>
                </Box>
                <Box pl={1}>
                  <Typography className={styles.congratulationMsgText}>Congratulations! Your product is now listed for
                    sale on Trebbs.com</Typography>
                  <Typography className={styles.skuMsgText} mt={1} mb={1}>SKU: {productMessageVal?.sku}</Typography>
                  <Typography className={styles.descMsgText}>You have successfully submitted your listing. It may take
                    15
                    minutes for your listing to propagate to all systems.</Typography>
                </Box>
              </Box>
            </Grid>
            : (productMessageVal?.type == 'product_price_updated' ?
            <Grid item md={12} xs={12} className={styles.msgPriceBorderView} mt={3} mb={6} py={2} px={3}>
              <Typography className={styles.priceUpdatedMsgText}>Product price updated</Typography>
              <Typography className={styles.skuMsgText} mt={1} mb={1}>SKU: {productMessageVal?.sku}</Typography>
              <Typography className={styles.descMsgText}>Product price has been updated
                on {moment().format('DD/MM/YYYY,HH:mm')} hours. it
                will reflect immediately across the system. orders made before the given time
                will follow the erstwhile price.</Typography>
            </Grid>
            : null)
          : null}

        <Tabs scrollButtons="on" variant="scrollable" value={value} onChange={handleChange}
              sx={{
                borderBottom: '1px solid #ddd',
                "& .MuiTab-root": {
                  color: THEME_COLOR7,
                  fontSize: 15,
                  fontFamily: 'InterMedium',
                  textTransform: 'capitalize',
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: THEME_COLOR8,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: THEME_COLOR8,
                  fontSize: 15,
                  fontFamily: 'InterMedium',
                  textTransform: 'capitalize'
                }
              }}
              aria-label="basic tabs example">
          <Tab label={'All Inventory'} {...a11yProps(0)} />
          <Tab label={'Active Inventory'} {...a11yProps(1)} />
          <Tab label={'Inactive Inventory'} {...a11yProps(2)} />
          <Tab label={'Removed Inventory'} {...a11yProps(3)} />
        </Tabs>
      </Grid>

      <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={2}>
        <Grid item md={7} xs={7}/>
        <Grid item md={1} xs={1}
              className={styles.skuBoxView}
              display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography className={styles.skuText}>SKU</Typography>
        </Grid>
        <Grid item md={3} xs={3} pl={1}>
          <FormTextInput
            page={'Product'}
            value={searchSku}
            setValue={setSearchSku}
            textInputViewStyle={style.textInputViewStyle}
          />
        </Grid>
        <Grid item md={1} xs={1} pl={1}>
          <FormButton
            btnType={'btn12'}
            width={'100%'}
            title={'Search'}
            onSubmit={onSearchBtnClick}
          />
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} mt={4}>
        <Grid item md={1.5} xs={1.5} className={styles.productCountView} display={'flex'} flexDirection={'row'}
              alignItems={'center'} justifyContent={'center'} px={1}>
          <Typography
            className={styles.productCountText}>{props?.productTotalCount ? props?.productTotalCount : 0}</Typography>
          <Typography className={styles.productTitleText} pl={1}>
            {props?.productTotalCount > 1 ? 'Products' : 'Product'}
          </Typography>
        </Grid>
        <Grid item md={3.5} xs={3.5}/>
        <Grid item md={3} xs={3}>
          <FormSelectBox
            page={'Product'}
            value={category}
            setValue={(val) => onChangeCategory(val)}
            placeholderColor={'#000'}
            placeholderColorOpacity={'1'}
            textInputViewStyle={style.textInputViewStyle}
            data={categoryList}
            placeholder={'Category'}
          />
        </Grid>
        <Grid item md={3} xs={3} pl={1}>
          <FormSelectBox
            page={'Product'}
            value={subCategory}
            setValue={setSubCategory}
            placeholderColor={'#000'}
            placeholderColorOpacity={'1'}
            textInputViewStyle={style.textInputViewStyle}
            data={subCategoryList}
            placeholder={'Sub Category'}
          />
        </Grid>
        <Grid item md={1} xs={1} pl={1}>
          <FormButton
            btnType={'btn13'}
            width={'100%'}
            title={'Refresh'}
            onSubmit={onRefreshBtnClick}
          />
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}>
        <TabPanel value={value} index={0}>
          <ProductTab
            tab={'all_inventory'}
            tabStatus={'all'}
            filterData={filterData}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ProductTab
            tab={'active_inventory'}
            tabStatus={'active'}
            filterData={filterData}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ProductTab
            tab={'inactive_inventory'}
            tabStatus={'inactive'}
            filterData={filterData}
          />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <ProductTab
            tab={'removed_inventory'}
            tabStatus={'removed'}
            filterData={filterData}
          />
        </TabPanel>
      </Grid>
      <AlertMsg/>

      <Snackbar
        message="Copied"
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={2000}
        onClose={() => setCopyOpen(false)}
        open={copyOpen}
        ContentProps={{
          sx: {
            width: "100%",
            "& .MuiSnackbarContent-message": {
              width: "inherit",
              textAlign: "center",
            }
          }
        }}
      />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    productTotalCount: state.userData.productTotalCount,
    sellerCategoryList: state.userData.sellerCategoryList,
    productMessage: state.userData.productMessage,
    sellerUid: state.userData.sellerUid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSellerCategoryList: data => dispatch(getSellerCategoryList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

const style = {
  textInputViewStyle: {
    marginTop: 0
  },
};
