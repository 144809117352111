import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import ProductTabList from "./ProductTabList";
import Page from "./Page";

import {connect} from "react-redux";
import {
  listProduct,
} from "../redux/actions/userDataActions";

import styles from "./ProductTab.module.css"

const ProductTab = (props) => {
  const [pageNum, setPageNum] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    getProductList(1)
  }, [props?.filterData])

  const getProductList = (page) => {
    let filter = props?.filterData;
    let data = {
      page: page,
      status: props?.tabStatus ? props.tabStatus : 'all',
    };
    if (filter != null) {
      data = {
        page: page,
        status: props?.tabStatus ? props.tabStatus : 'all',
        searchSku: filter?.searchSku ? filter?.searchSku : '',
        category: (filter?.category) ? filter?.category : '',
        subCategory: (filter?.category && filter?.subCategory) ? filter?.subCategory : ''
      };
    }

    props.listProduct(JSON.stringify(data));
    setPageNum(page)
  };

  const onApplyFilter = (page) => {
    getProductList(page);
  };

  if (props.productLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>
      <TableContainer
        className={'tableContainer'}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead
            className={'table'}>
            <TableRow className={styles.tableHeadRow}>
              <TableCell>
                <Typography className={styles.labelText}>Listing Status</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>SKU</Typography>
                <Typography className={styles.subLabelText} ml={1}>Product ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Product</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>HS Code</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Pickup Location</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Price/Kg</Typography>
                <Typography className={styles.subLabelText} ml={1}>Referral fee</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Packing</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>EXW Price/Package</Typography>
                <Typography className={styles.subLabelText} ml={1}>Referral fee/Package</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Date Created</Typography>
                <Typography className={styles.subLabelText} ml={1}>Status Changed Date</Typography>
              </TableCell>
              {props?.tabStatus != 'removed' ?
                <TableCell>
                  <Typography className={styles.labelText}>Action</Typography>
                </TableCell>
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productList?.length > 0 ?
              props.productList?.map((item, index) => {
                return (
                  <TableRow key={index} className={styles.tableRow}>
                    <ProductTabList
                      key={index}
                      index={index}
                      data={item}
                      tabStatus={props?.tabStatus}
                    />
                  </TableRow>
                )
              }) : null}
          </TableBody>
        </Table>
      </TableContainer>


      <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
        {props.productTotalPages > 0 ?
          <Page
            totalPages={props.productTotalPages}
            pageNum={pageNum}
            onSelectPage={onApplyFilter}/>
          : null}
      </Box>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    productTotalPages: state.userData.productTotalPages,
    productList: state.userData.productList,
    productLoading: state.userData.productLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listProduct: data => dispatch(listProduct(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTab);
