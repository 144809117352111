import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import ImageUpload from "./ImageUpload";

import {connect} from "react-redux";
import {
  addSellerExportRcmcDetail,
  updateSellerExportRcmcDetail,
  deleteSellerExportRcmcDetail,
  updateApiStatus,
} from "../redux/actions/userDataActions";

import './../assets/css/custom.css';

import AlertDialogBox from "./AlertDialogBox";

import {ReactComponent as ExclaimIcon} from "../assets/img/exclamation.svg";

import {FILE_SIZE, THEME_COLOR2} from "../constants";

const AddRCMCDetailsView = (props) => {
  const [boardName, setBoardName] = useState('');
  const [rcmcUid, setRcmcUid] = useState('');
  const [rcmcNo, setRcmcNo] = useState('');
  const [rcmcRegistrationDocument, setRcmcRegistrationDocument] = useState(null);
  const [displayRcmcRegistrationDocument, setDisplayRcmcRegistrationDocument] = useState('');
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.item) {
      let data = props?.item;
      setRcmcUid(data?.rcmcUid);
      setBoardName(data?.boardName);
      setRcmcNo(data?.rcmcNo);
      setRcmcRegistrationDocument(data?.rcmcRegistrationDocument);
      setDisplayRcmcRegistrationDocument(data?.displayRcmcRegistrationDocument);
    }
  }, [props.item]);

  useEffect(() => {
    if (props?.apiStatus == 'DELETE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS') {
      setConfirmModalVisible(false);
      props?.updateApiStatus('')
    }
  }, [props?.apiStatus])

  const onSaveClick = () => {
    let errorText = {};
    if (boardName == '') {
      errorText = {boardName: 'Board Name is required'};
    }
    if (rcmcNo == '') {
      errorText = {...errorText, rcmcNo: 'RCMC No is required'};
    }
    if (rcmcRegistrationDocument == null && displayRcmcRegistrationDocument == '') {
      errorText = {...errorText, rcmcRegistrationDocument: 'RCMC Registration Document is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    setError(null)

    let formData = new FormData();
    // formData.append('sellerId', props?.data?.sellerId);
    formData.append('rcmcUid', rcmcUid);
    formData.append('boardName', boardName);
    formData.append('rcmcNo', rcmcNo);

    if (rcmcUid) {
      if (rcmcRegistrationDocument != null) {
        formData.append('rcmcRegistrationDocument', rcmcRegistrationDocument);
      }
    } else {
      formData.append('rcmcRegistrationDocument', rcmcRegistrationDocument);
    }

    if (rcmcUid) {
      props.updateSellerExportRcmcDetail(formData)
    } else {
      props.addSellerExportRcmcDetail(formData)
    }
  };

  const onRemoveImage = (type) => {
    setRcmcRegistrationDocument(null);
    setDisplayRcmcRegistrationDocument('');
  };

  const onChangeImage = (fileList, type) => {
    if(fileList[0]?.file?.size < FILE_SIZE) {
      setRcmcRegistrationDocument(fileList[0].file);
      setDisplayRcmcRegistrationDocument(fileList[0].image);
    }
  };

  const onAddMore = () => {
    let errorText = {};
    if (boardName == '') {
      errorText = {boardName: 'Board Name is required'};
    }
    if (rcmcNo == '') {
      errorText = {...errorText, rcmcNo: 'RCMC No is required'};
    }
    if (rcmcRegistrationDocument == null && displayRcmcRegistrationDocument == '') {
      errorText = {...errorText, rcmcRegistrationDocument: 'RCMC Registration Document is required'};
    } else if (rcmcUid) {
      errorText = {...errorText, addMore: ''};
    } else {
      errorText = {...errorText, addMore: 'Please Save the detail'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      rcmcUid: '',
      boardName: boardName,
      rcmcNo: rcmcNo,
      rcmcRegistrationDocument: rcmcRegistrationDocument,
      displayRcmcRegistrationDocument: displayRcmcRegistrationDocument,
    }

    setRcmcUid('');
    setBoardName('');
    setRcmcNo('');
    setRcmcRegistrationDocument('');
    setDisplayRcmcRegistrationDocument(null);
    setError(null)

    props?.onAddMore(data)
  };

  const onDeleteClick = () => {
    setConfirmModalVisible(true)
  };

  const onConfirmDeleteClick = () => {
    if (rcmcUid) {
      let data = {
        rcmcUid: rcmcUid
      }
      props?.deleteSellerExportRcmcDetail(JSON.stringify(data))
    }
  };

  const onCancelDeleteClick = () => {
    setConfirmModalVisible(false)
  }

  const onViewClick = () => {
    window.open(displayRcmcRegistrationDocument, '_blank')
  }

  return (
    <>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddRCMCDetails'}
          value={boardName}
          setValue={setBoardName}
          label={'Enter Board Name'}
          error={error?.boardName}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddRCMCDetails'}
          value={rcmcNo}
          setValue={setRcmcNo}
          label={'RCMC No.'}
          error={error?.rcmcNo}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={12} xs={12}
            container>
        <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12}
              mt={{xs: 3}}
              container>
          <Typography sx={styles.labelText}>Upload RCMC Registration Document(PDF)</Typography>
          <Box pl={0.5}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} mt={2} container>
          <ImageUpload
            page={'AddRCMCDetails'}
            type={'rcmcRegDoc'}
            maxFiles={1}
            fileType={'pdf'}
            fileSize={true}
            image={displayRcmcRegistrationDocument}
            error={error?.rcmcRegistrationDocument}
            disabled={props?.type == 'edit'}
            closeIcon={props?.type == 'edit' ? false : true}
            onRemove={onRemoveImage}
            onChange={onChangeImage}/>
        </Grid>

        {props?.type == 'edit' ?
          <Box mt={2} sx={styles.viewBox} px={1} py={0.5}>
            <Typography
              onClick={() => onViewClick()}
              sx={styles.viewText}>View</Typography>
          </Box>
          : null}
      </Grid>

      {props?.type == 'edit' ? null :
      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent={props?.index == 0 ? "space-between" : "flex-end"} mt={4}>

        {props?.index == 0 ?
          <Box>
            <Typography
              onClick={() => onAddMore()}
              sx={styles.addText}>Add More</Typography>
            {error != null && error?.addMore ?
              <Box mt={1} ml={0.5} styles={styles.errorView}>
                <Typography sx={styles.errorText}>{error?.addMore}</Typography>
              </Box>
              : null}
          </Box>
          : null}
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {rcmcUid ?
            <Box>
              <FormButton
                btnType={'btn4'}
                title={'Delete'}
                onSubmit={onDeleteClick}
              />
            </Box>
            : null}
          <Box pl={1}>
            <FormButton
              title={'Save'}
              onSubmit={onSaveClick}
            />
          </Box>
        </Box>
      </Grid>}

      <AlertDialogBox
        confirmBox={confirmModalVisible}
        type={'delete'}
        icon={<ExclaimIcon height={70} width={70}/>}
        content={"Are you sure you would like to delete?"}
        firstBtn={"Yes, delete!"}
        secondBtn={"No, cancel"}
        onConfirmClick={onConfirmDeleteClick}
        onCancelClick={onCancelDeleteClick}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSellerExportRcmcDetail: data => dispatch(addSellerExportRcmcDetail(data)),
    updateSellerExportRcmcDetail: data => dispatch(updateSellerExportRcmcDetail(data)),
    deleteSellerExportRcmcDetail: data => dispatch(deleteSellerExportRcmcDetail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRCMCDetailsView);

const styles = {
  labelText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  addText: {
    color: THEME_COLOR2,
    fontSize: 12,
    fontFamily: 'InterMedium',
    cursor: 'pointer'
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'InterMedium',
  },
  viewBox: {
    border: '1px solid ' + THEME_COLOR2,
    width: 80
  },
  viewText: {
    color: THEME_COLOR2,
    fontFamily: 'InterMedium',
    fontSize: 14,
    cursor: 'pointer',
    textAlign: 'center'
  }
};

