import React, {useEffect, useRef} from 'react'
import {
  Grid, Typography, Box, Card, CardActions, CardContent, Checkbox, Divider,
} from '@mui/material';

import LandingHeader from "../../components/LandingHeader";

import styles from './About.module.css';

import LandingFooter from "../../components/LandingFooter";

const About = (props) => {
  const aboutRef = useRef(null);

  useEffect(() => {
    if (aboutRef?.current) {
      aboutRef?.current?.scrollIntoView({behavior: "smooth"});
    }
  }, []);

  return (
    <Grid container item md={12} xs={12} display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          ref={aboutRef}>

      <LandingHeader
        showHome={true}
      />

      <Grid container item md={8} xs={10} display={"flex"} mt={7} mb={7}
            alignItems={"center"}>

        <Grid item md={12} xs={12} mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography className={styles.mainTitleText}>About</Typography>
        </Grid>

        <Typography className={styles.contentText}>
          TREBBS TRADE SOLUTIONS PRIVATE LIMITED is an Indian company on a mission to revolutionize the global
          agri-trade landscape. We've developed <a className={styles.linkText} href="http://trebbs.com/">Trebbs.com</a>,
          a B2B online marketplace that connects agri-exporters and
          importers worldwide, fostering "Trade Beyond Borders."
        </Typography>

        <Typography className={styles.contentText} mt={4}>
          <a className={styles.linkText} href="http://trebbs.com/">Trebbs.com</a> was born out of a desire to simplify
          and streamline the agri-trade process. Our user-friendly
          platform empowers exporters and importers to connect directly, discover new opportunities, and trade a vast
          array of agricultural products with greater ease and efficiency.
        </Typography>

        <Typography className={styles.contentText} mt={4}>
          Through <a className={styles.linkText} href="http://trebbs.com/">Trebbs.com</a>, we strive to:
        </Typography>

        <Typography className={styles.contentText} mt={4}>
          Empower Agri-Businesses: <a className={styles.linkText} href="http://trebbs.com/">Trebbs.com</a> provides a
          platform for agri-businesses of all sizes to expand their
          reach and access new markets globally.
          Promote Transparency: Our platform fosters transparency in pricing and communication, ensuring a fair and
          secure trading environment.
          Streamline Operations: <a className={styles.linkText} href="http://trebbs.com/">Trebbs.com</a> simplifies the
          trade process, reducing paperwork and administrative
          burdens for both exporters and importers.
          We are passionate about building a future where agri-trade is accessible, efficient, and beneficial for all
          stakeholders. Join us on <a className={styles.linkText} href="http://trebbs.com/">Trebbs.com</a> and
          experience the future of global agri-trade!
        </Typography>
      </Grid>

      <LandingFooter/>

    </Grid>

  )
}

export default About
