import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import ImageUpload from "./ImageUpload";

import './../assets/css/custom.css';

import {connect} from "react-redux";
import {
  addSellerExportLicenseDetail,
  updateApiStatus,
  updateSellerExportLicenseDetail
} from "../redux/actions/userDataActions";

import {FILE_SIZE, THEME_COLOR2} from '../constants'

const AddExportLicenseDetails = (props) => {
  const [exporterName, setExporterName] = useState('');
  const [exportLicenseNo, setExportLicenseNo] = useState('');
  const [reEnterExportLicenseNo, setReEnterExportLicenseNo] = useState('');
  const [exportLicenseDocument, setExportLicenseDocument] = useState(null);
  const [displayExportLicenseDocument, setDisplayExportLicenseDocument] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      setExporterName(props.data?.exporterName);
      setExportLicenseNo(props.data?.exportLicenseNo);
      setReEnterExportLicenseNo(props.data?.exportLicenseNo);
      setDisplayExportLicenseDocument(props.data?.displayExportLicenseDocument);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.apiStatus == "ADD_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS"
      || props.apiStatus == "UPDATE_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS") {
      setExportLicenseDocument(null);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  const onSaveClick = () => {
    let errorText = {};
    if (exporterName == '' || exporterName == null) {
      errorText = {exporterName: 'Export Name is required'};
    }
    if (exportLicenseNo == '' || exportLicenseNo == null) {
      errorText = {...errorText, exportLicenseNo: 'Export License No is required'};
    }
    if (reEnterExportLicenseNo == '' || reEnterExportLicenseNo == null) {
      errorText = {...errorText, reEnterExportLicenseNo: 'Re-enter Export License No is required'};
    }
    if (exportLicenseNo != reEnterExportLicenseNo) {
      errorText = {...errorText, reEnterExportLicenseNo: 'Export License No not match'};
    }
    if (exportLicenseDocument == null && displayExportLicenseDocument == '') {
      errorText = {...errorText, exportLicenseDocument: 'Export licence Document is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('sellerId', props?.data?.sellerId);
    formData.append('exporterName', exporterName);
    formData.append('exportLicenseNo', exportLicenseNo);

    if (props.data?.sellerId) {
      if (exportLicenseDocument != null) {
        formData.append('exportLicenseDocument', exportLicenseDocument);
      }
    } else {
      formData.append('exportLicenseDocument', exportLicenseDocument);
    }

    if (props.data?.sellerId) {
      props.updateSellerExportLicenseDetail(formData)
    } else {
      props.addSellerExportLicenseDetail(formData)
    }
  };

  const onRemoveImage = (type) => {
    setExportLicenseDocument(null);
    setDisplayExportLicenseDocument('');
  };

  const onChangeImage = (fileList, type) => {
    if (fileList[0]?.file?.size < FILE_SIZE) {
      setExportLicenseDocument(fileList[0].file);
      setDisplayExportLicenseDocument(fileList[0].image);
    }
  };

  const onViewClick = () => {
    window.open(displayExportLicenseDocument, '_blank')
  }

  return (
    <Grid item container xs={12} md={12}
          alignItems={"flex-start"}
          justifyContent={"space-between"}>

      <Typography style={styles.titleText}>Export License Details</Typography>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddExportLicenseDetails'}
          value={exporterName}
          setValue={setExporterName}
          label={'Exporter Name as on Export License'}
          error={error?.exporterName}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddExportLicenseDetails'}
          value={exportLicenseNo}
          setValue={setExportLicenseNo}
          label={'Export License number'}
          error={error?.exportLicenseNo}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddExportLicenseDetails'}
          value={reEnterExportLicenseNo}
          setValue={setReEnterExportLicenseNo}
          label={'Re-enter Export License number'}
          error={error?.reEnterExportLicenseNo}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
          disabled={props?.type == 'edit'}
        />
      </Grid>

      <Grid item md={12} xs={12}
            container>
        <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12}
              mt={{xs: 3}}
              container>
          <Typography sx={styles.labelText}>Upload Export License Document(PDF)</Typography>
          <Box pl={0.5}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} mt={2} container>
          <ImageUpload
            page={'AddExportLicenseDetails'}
            type={'exportLicDoc'}
            maxFiles={1}
            fileType={'pdf'}
            fileSize={true}
            image={displayExportLicenseDocument}
            error={error?.exportLicenseDocument}
            disabled={props?.type == 'edit'}
            closeIcon={props?.type == 'edit' ? false : true}
            onRemove={onRemoveImage}
            onChange={onChangeImage}/>
        </Grid>

        {props?.type == 'edit' ?
          <Box mt={2} sx={styles.viewBox} px={1} py={0.5}>
            <Typography
              onClick={() => onViewClick()}
              sx={styles.viewText}>View</Typography>
          </Box>
          : null}
      </Grid>


      {props?.type == 'edit' ? null :
        <Grid item md={12} xs={12} container
              display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box>
            <FormButton
              title={'Save'}
              onSubmit={onSaveClick}
            />
          </Box>
        </Grid>}

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSellerExportLicenseDetail: data => dispatch(addSellerExportLicenseDetail(data)),
    updateSellerExportLicenseDetail: data => dispatch(updateSellerExportLicenseDetail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExportLicenseDetails);

const styles = {
  labelText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  viewBox: {
    border: '1px solid ' + THEME_COLOR2,
    width: 80
  },
  viewText: {
    color: THEME_COLOR2,
    fontFamily: 'InterMedium',
    fontSize: 14,
    cursor: 'pointer',
    textAlign: 'center'
  }
};

