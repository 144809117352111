import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel, Typography
} from '@mui/material';

import AlertMsg from "../../components/AlertMsg";

import LoginBgImg from "../../assets/img/login_bg_image.jpg"

import {ReactComponent as LogOutIcon} from "../../assets/img/logout.svg"

import {THEME_COLOR5, THEME_COLOR2} from '../../constants'

const Verification = (props) => {
  const navigate = useNavigate();


  return (
    <Grid container item md={12} xs={12} style={{position: 'relative'}}>
      <Box sx={styles.bgImgView}>
        <img alt="" src={LoginBgImg} style={styles.imgStyle}/>
      </Box>

      <Box
        onClick={() => navigate('/sign_out')}
        style={{position: 'absolute', top: 10, right: 10, zIndex: 10, cursor: 'pointer'}}>
        <LogOutIcon height={30} width={30}/>
      </Box>

      <Grid sx={styles.contentView}
            container item md={12} xs={12}
            mt={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
        <Grid container item md={12} xs={12}
              display={"flex"} alignItems={"center"} justifyContent={"center"}
        >
          <Card sx={[styles.cardView, {
            width: {md: '70%', sm: '90%', xs: '90%'},
            minHeight: {md: '30vw', sm: '100vw', xs: '100vw'}
          }]}>
            <CardContent style={styles.cardContentView}>
              <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"}
                    alignItems={'flex-start'}
                    justifyContent={"center"} mt={6} sx={styles.mainView}>

                <Grid container item md={5} xs={5}>
                  <Grid item md={11} xs={11} mb={2} sx={styles.boxView} pt={1} pb={1} px={2}>
                    <Typography sx={styles.titleText}>Account under verification !!!</Typography>
                  </Grid>

                  <Typography sx={styles.descText}>Dear Seller your account has been created and submitted for
                    verification. once the verification is completed and get approved you will be directed to the account.
                  </Typography>
                </Grid>

                <Box sx={styles.borderLineView} pr={2} pl={2}/>

                <Grid container item md={5} xs={5}>
                  <Typography sx={styles.descSubText}>If you have any query regarding the verification process
                    please <a style={styles.supportText}> contact
                      support team. </a></Typography>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AlertMsg/>

    </Grid>
  )
};

export default Verification;

const styles = {
  bgImgView: {
    position: 'fixed',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  contentView: {
    zIndex: 10,
    minHeight: window.innerHeight
  },
  cardView: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    marginBottom: 10
  },
  cardContentView: {
    display: 'flex',
    flexDirection: 'column',
  },
  boxView: {
    border: '1px solid #ccc'
  },
  titleText: {
    color: THEME_COLOR5,
    fontFamily: 'InterBold',
    fontSize: 20
  },
  descText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  borderLineView: {
    borderLeft: '1px solid #ccc',
    height: 500
  },
  descSubText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  supportText: {
    color: THEME_COLOR2,
    fontSize: 14,
    fontFamily: 'InterMedium',
    textDecoration: 'none'
  }
};
