import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AppBar, Toolbar, IconButton, Avatar, Box, Tooltip, Menu, MenuItem, Typography, Divider} from '@mui/material';

import AlertMsg from "./AlertMsg";

import {connect} from "react-redux";

import {THEME_COLOR9} from '../constants'
import {COUNTRIES} from '../constants/countries'

import {ReactComponent as MenuIcon} from "../assets/img/menu.svg";

const drawerWidth = 270;

const Navbar = (props) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const onOpen = () => {
    setOpen(true)
  };

  const onClose = () => {
    setOpen(false)
  };

  const settings = [
    {
      text: 'My Account',
      // path: '/update_account'
      path: '/my_account'
    },
    {
      text: 'Sign Out',
      path: '/sign_out'
    },
  ];

  return (
    <AppBar position="fixed"
            sx={{
              boxShadow: '0px 10px 30px 0px rgba(82, 63, 105, 0.05)',
              zIndex: 5,
            }}>
      <Toolbar sx={styles.mainNav}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography style={styles.trebbsSellerText}>Trebbs Seller</Typography>
          <Typography style={styles.userCountryText} pl={1}>
            {COUNTRIES.filter(
            (x) => x.code == props?.userCountry
            )?.[0]?.label}
          </Typography>
          <Typography style={styles.leftLineText} pl={1}>|</Typography>
          <Typography style={styles.userNameEntityText} pl={1} pr={3}>{props?.userNameEntity}</Typography>
        </Box>
        <Box>
          <IconButton onClick={onOpen}>
            <MenuIcon height={20} width={20}/>
          </IconButton>
          <Menu
            disableScrollLock={true}
            sx={{mt: '45px'}}
            id="menu-appbar"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={onClose}>

            <Box>
              <Box display={'flex'} flexDirection={'row'} mb={1}>
                <IconButton
                  size="medium">
                  <Avatar sx={{width: 50, height: 50}}></Avatar>
                </IconButton>
                <Box mt={1.5} pr={1}>
                  <Typography style={styles.contactNameEmailText}>{props.userContactName}</Typography>
                  <Typography style={styles.contactNameEmailText} mt={0.2}>{props.userEmail}</Typography>
                </Box>
              </Box>

              <Divider/>

              <Box mt={1}>
                {settings.map((item) => (
                  <MenuItem key={item.text} onClick={() => navigate(item.path)}>
                    <Typography textAlign="center" style={styles.settingsText}>{item.text}</Typography>
                  </MenuItem>
                ))}
              </Box>

            </Box>

          </Menu>
        </Box>
      </Toolbar>

      <AlertMsg/>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    userContactName: state.userData.userContactName,
    userEmail: state.userData.userEmail,
    userNameEntity: state.userData.userNameEntity,
    userCountry: state.userData.userCountry,
  };
};

export default connect(mapStateToProps, null)(Navbar);

const styles = {
  mainNav: {
    backgroundColor: THEME_COLOR9,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  contactNameEmailText: {
    color: '#666',
    fontFamily: 'InterBold',
    fontSize: 13,
  },
  settingsText: {
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  trebbsSellerText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  userCountryText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 11,
  },
  leftLineText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  userNameEntityText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 14,
    textTransform: 'capitalize'
  }
};
