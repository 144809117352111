import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import {v4 as uuidv4} from "uuid";

import {connect} from "react-redux";

import './../assets/css/custom.css';

const AddProductVarietySizeDetail = (props) => {
  const [size, setSize] = useState('')
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.item != null) {
      setSize(props?.item?.size)
    } else {
      setSize('')
    }
  }, [props.item]);

  const onAddClick = () => {
    let errorText = {};
    if (size == '') {
      errorText = {size: 'Size is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let id_val = uuidv4();
    if (props?.item != null) {
      id_val = props?.item?.id;
    }

    let data = {
      id: id_val,
      size: size,
    }

    if (props.type == 'add') {
      setSize('')
    }

    let type = 'add';
    if (props?.item != null) {
      type = 'edit';
    }
    props?.onAdd(data, type)

  };

  return (
    <Grid item container xs={12} md={12}>

      <Grid item md={12} xs={12}
            mt={1}
            container>
        <FormTextInput
          page={'AddProductVarietySizeDetail'}
          value={size}
          setValue={setSize}
          error={error?.size}
          required={true}
        />
      </Grid>


      {props?.type == 'add' ?
        <Grid item md={12} xs={12} container
              display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box>
            <FormButton
              title={props?.item != null ? 'Update' : 'Add'}
              btnType={'btn11'}
              onSubmit={onAddClick}
            />
          </Box>
        </Grid>
        : null}

    </Grid>
  );
}

export default AddProductVarietySizeDetail;
