import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormButton from "./FormButton";

import AddExportLicenseDetails from "./AddExportLicenseDetails";
import AddRCMCDetails from "./AddRCMCDetails";
import AddFSSAIDetails from "./AddFSSAIDetails";
import AddTaxDetails from "./AddTaxDetails";
import AddBankDetails from "./AddBankDetails";
import UploadDocuments from "./UploadDocuments";

import './../assets/css/custom.css';

const AddExporterDetails = (props) => {
  const [error, setError] = useState(null);

  const onContinueClick = () => {
    props.onContinue()
  };

  return (
    <Grid item container xs={12} md={12}
          alignItems={"flex-start"}
          justifyContent={"space-between"}>


      <AddExportLicenseDetails
        data={props?.data?.exportLicenseDetails ? props.data.exportLicenseDetails : null}
        type={props?.type ? props?.type : 'add'}
      />

      <AddRCMCDetails
        data={props?.data?.exportRcmcDetails ? JSON.parse(JSON.stringify(props.data.exportRcmcDetails)) : null}
        type={props?.type ? props?.type : 'add'}
      />

      <AddFSSAIDetails
        data={props?.data?.exportFssaiDetails ? props.data.exportFssaiDetails : null}
        type={props?.type ? props?.type : 'add'}
      />

      <AddTaxDetails
        data={props?.data?.exportTaxDetails ? props.data.exportTaxDetails : null}
        type={props?.type ? props?.type : 'add'}
      />

      <AddBankDetails
        data={props?.data?.bankDetails ? JSON.parse(JSON.stringify(props.data.bankDetails)) : null}
        type={props?.type ? props?.type : 'add'}
      />

      <UploadDocuments
        data={props?.data?.documentDetails ? props.data.documentDetails : null}
        type={props?.type ? props?.type : 'add'}
      />

      {props?.type == 'edit' ? null :
        <Grid item md={12} xs={12} container
              display="flex" flexDirection="row" justifyContent="center" mt={4}>
          <FormButton
            title={'Continue'}
            onSubmit={onContinueClick}
          />
        </Grid>
      }

    </Grid>
  );
}

export default AddExporterDetails;
