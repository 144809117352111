import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import userDataReducer from './userDataReducer';

const persistConfig = {
  timeout: 0,
  key: 'root',
  storage,
  blacklist:['userData']
};

const userDataPersistConfig = {
  key: 'userData',
  storage,
  blacklist: [
    'productMessage',
  ],
};

const rootReducer = combineReducers({
  userData: persistReducer(userDataPersistConfig, userDataReducer),
});

export default persistReducer(persistConfig, rootReducer);
