import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Grid, Box, Card, CardContent, Typography} from '@mui/material';

import LoginBgImg from "../../assets/img/login_bg_image.jpg"

import {THEME_COLOR2} from "../../constants";

import styles from './TermsAndConditions.module.css'

const seller_use_list = [
  {
    number: 1,
    title: `Seller Use of Trebbs.com`,
    data: [
      {
        number: `a`,
        content: `No commission will be charged for using Trebbs.com services.`,
      },
      {
        number: `b`,
        content: `Your eligibility to register as a seller and the creation of your account is described in the
Terms.`,
      },
      {
        number: `c`,
        content: `Trebbs.com has the right to change, upgrade, modify, limit or suspend any
functionalities or applications, at any time, temporarily or permanently, without prior
notice.`,
      },
      {
        number: `d`,
        content: `A seller account will have the following core features (which may be added to or
modified, or suspended for maintenance purposes, from time to time at the sole
discretion of Trebbs.com):`,
      },
    ],
    subdata: [
      {
        number: `i`,
        content: <span><span style={{fontFamily: 'InterBold', fontSize: 15}}>Company Profile:</span> allows each Seller to display and edit basic information about its
business, such as year and place of establishment, estimated annual sales, number of
          employees, and products and services offered, etc.</span>,
      },
      {
        number: `ii`,
        content: <span><span style={{fontFamily: 'InterBold', fontSize: 15}}>Products:</span> allows each Seller to display and edit descriptions, specifications and images.</span>,
      }
    ]
  }
];

const terms_conditions_list = [
  {
    number: 2,
    title: `Seller Responsibilities`,
    data: [
      {
        number: 2.1,
        content: `You represent and agree that:`,
        subdata: [
          {
            number: `a`,
            content: `you have full power and authority to accept this Agreement and to fulfill your
obligations under this Agreement;`
          },
          {
            number: `b`,
            content: `the information and documents submitted during the registration process are true,
accurate, current and complete, and you will maintain and update all information and
documents to keep them true, accurate, current and complete while your Trebbs.com
account is active;`
          },
          {
            number: `c`,
            content: `you consent to Trebbs.com including information about you or your business in our
database, and authorize Trebbs.com and our affiliates to share or use the information as
described in our Privacy Policy;`
          },
          {
            number: `d`,
            content: `you are solely responsible for obtaining all required licenses, permits and certifications
for the Content (as defined in the Terms) that you submit, post or display;`
          },
          {
            number: `e`,
            content: `Content that you submit, post or display for sale is not counterfeit or stolen and does
not otherwise violate any of copyright, patent, trademark, trade name, trade secrets or
any other rights of any third party;`
          },
          {
            number: `f`,
            content: `you have the right and authority to sell, trade, distribute or export the products listed
on Trebbs.com, and the listing of these products does not violate any third-party rights
(for example, exclusive agency or distribution agreements); and`
          },
          {
            number: `g`,
            content: `Neither you, your company, its directors and officers nor affiliates are the subject of any
trade restrictions, sanctions or other legal restrictions of any country, international
organization or jurisdiction.`
          },
        ]
      },
      {
        number: 2.2,
        content: `You further agree that the Content that you submit, post or display will:`,
        subdata: [
          {
            number: `a`,
            content: `be true, accurate, complete and legal;`
          },
          {
            number: `b`,
            content: `not contain information that is defamatory, libelous, threatening, harassing, obscene,
explicit, offensive, or otherwise objectionable;`
          },
          {
            number: `c`,
            content: `not contain information that is discriminatory or promotes discrimination;`
          },
          {
            number: `d`,
            content: `not violate the Product Listing Policy, the Terms, or any other additional agreements
with Trebbs.com;`
          },
          {
            number: `e`,
            content: `not violate any applicable laws and regulations or promote any activities which may
violate them; and`
          },
          {
            number: `f`,
            content: `not post any link directly or indirectly to other websites which include content that
violates the Terms`
          },
        ]
      },
      {
        number: 2.3,
        content: `Additionally, you agree that you will:`,
        subdata: [
          {
            number: `a`,
            content: `carry on activities on Trebbs.com in compliance with any applicable laws and
regulations;`
          },
          {
            number: `b`,
            content: `conduct your business transactions with other Trebbs.com users in good faith;`
          },
          {
            number: `c`,
            content: `carry on your activities according to this Agreement, and any other applicable
agreements;`
          },
          {
            number: `d`,
            content: `upload all requested order data for every transaction to the Trebbs.com platform;`
          },
          {
            number: `e`,
            content: `not use Trebbs.com to defraud any person or entity;`
          },
          {
            number: `f`,
            content: `not impersonate any person or entity, misrepresent yourself or your affiliation with any
person or entity;`
          },
          {
            number: `g`,
            content: `not manipulate feedback through multiple user accounts or third parties, by leaving
positive feedback for yourself, or unjustified negative feedback for another user;`
          },
          {
            number: `h`,
            content: `not copy, reproduce, download, republish, sell or distribute any information, text,
images, graphics, video clips, sound, directories, files, databases or listings, available on
or through Trebbs.com for any purpose;`
          },
          {
            number: `i`,
            content: `not engage in spamming, phishing or spreading viruses;`
          },
          {
            number: `j`,
            content: `not use Trebbs.com solely to collect information about other users;`
          },
          {
            number: `k`,
            content: `not use information provided to you during a transaction on Trebbs.com to solicit
additional sales offline; and`
          },
          {
            number: `l`,
            content: `not engage in any activities that would create liability for Trebbs.com.`
          },
        ]
      },
      {
        number: 2.4,
        content: `In its sole discretion, Trebbs.com has the right to remove a posting or listing or suspend or
terminate any account, if it learns of a violation or suspected violation of any of the points
under Clauses 2.1-2.3, or for any reason it considers appropriate. Trebbs.com will not be liable
for compensation or damages to the Seller.`,
      }
    ]
  },
  {
    number: 3,
    title: `Transactions Between Buyers and Sellers`,
    data: [
      {
        number: 3.1,
        content: `Through its website and related applications, Trebbs.com provides a platform for buyers
and sellers to interact with one another for the sale and purchase of products. Trebbs.com does
not represent either buyers or sellers in any transaction and is not a party to any agreement
between them.`,
      },
      {
        number: 3.2,
        content: `Buyers and sellers must agree on and create their own contractual terms and upload the
order details of each transaction to the Trebbs.com platform.`,
      },
      {
        number: 3.3,
        content: `Trebbs.com does not control and is not responsible for the quality, legality, safety, pricing or
availability of the products offered for sale on Trebbs.com, the ability of sellers to complete a
sale or the ability of buyers to complete a purchase.`,
      },
      {
        number: 3.4,
        content: `By accessing or using Trebbs.com, you accept the risks of conducting any transaction, as
well as any related activities, such as storage, shipment and delivery and return of products.`,
      },
      {
        number: 3.5,
        content: `Trebbs.com is not liable for unsatisfactory or delayed performance, losses, damages or
business interruptions because of products which are unavailable, damaged or not delivered.`,
      },
      {
        number: 3.6,
        content: `Trebbs.com may, but is not required, mediate or attempt to resolve any dispute or
disagreement between buyers and sellers, but will not be responsible for the resolution of such
dispute.`,
      },
      {
        number: 3.7,
        content: `Please Contact Us to report any issues or violations of these terms.`,
      },
    ]
  },
  {
    number: 4,
    title: `Invoicing and Taxes`,
    data: [
      {
        number: 4.1,
        content: `The primary invoicing relationship is between you and the Buyer; therefore, you will not
issue any invoices to Trebbs.com for the sale of products. You are solely responsible for issuing
and submitting an invoice to the Buyer directly, as agreed between you and the Buyer, but at
the latest at the time of delivery. `,
      },
      {
        number: 4.2,
        content: `For Trebbs.com to transfer your sales proceeds, you must upload a copy of each transaction
tax invoice and proof of delivery to the Seller Centre. Payment will be made per Clause 5.4.`,
      },
      {
        number: 4.3,
        content: `Trebbs.com will issue an invoice to you for any commissions or other fees to be paid by
you, with VAT included, as applicable.`,
      },
      {
        number: 4.4,
        content: `You will be responsible for collecting, reporting, and payment of all taxes. If Trebbs.com
collects taxes from a buyer on your behalf as part of a transaction payment, you remain liable
for reporting and payment to the proper authorities`,
      },
    ]
  },
  {
    number: 5,
    title: `Transaction Processing and Payment of Sales Proceeds`,
    desc: `NOTE: This clause applies to all purchases made through the Buy Now feature, in addition to
negotiated transactions when payment is made by the Buyer through credit card and bank
transfer`,
    data: [
      {
        number: 5.1,
        content: `You authorize Trebbs.com to act on your behalf solely for purposes of:`,
        subdata: [
          {
            number: `a`,
            content: `collecting and processing payments from the Buyer`
          },
          {
            number: `b`,
            content: `refunds and adjustments for your transactions,`
          },
          {
            number: `c`,
            content: `receiving and holding sales proceeds from the Buyer,`
          },
          {
            number: `d`,
            content: `remitting sales proceeds to your bank account, or`
          },
          {
            number: `e`,
            content: `withholding or offsetting sales proceeds from other sales and deducting amounts you
owe to Trebbs.com, or that are under dispute with the Buyer.`
          },
        ]
      },
      {
        number: 5.2,
        content: `Trebbs.com is only obligated to pay sales proceeds to you when the transaction payment is
made by the Buyer. Under no circumstances will Trebbs.com be liable for any claim of nonpayment by the Buyer.`,
      },
      {
        number: 5.3,
        content: `When Trebbs.com receives payment from the Buyer for an order (or, in the case of an
escrow payment, the Buyer instructs Trebbs.com to release the funds to you), you agree that
the Buyer has fulfilled its obligation to pay you and you will not pursue the Buyer directly for
payment of funds.`,
      },
      {
        number: 5.4,
        content: `Before remitting sales proceeds to you, Trebbs.com will deduct the following amounts:`,
        subdata: [
          {
            number: `a`,
            content: `refunds due to buyers for the return of products or order cancellations;`
          },
          {
            number: `b`,
            content: `any resolution of a dispute in favor of the Buyer; and`
          },
          {
            number: `c`,
            content: `any commission, logistics or other fees due to Trebbs.com, per this Agreement or any
other agreement with Trebbs.com.`
          },
        ]
      },
      {
        number: 5.5,
        content: `For orders that are delivered and invoices that are uploaded to the Seller Centre
Trebbs.com will pay the net amount due to you within 7 working days. Proceeds will be paid to
the bank account provided to Trebbs.com. If the bank account details that have been provided
to Trebbs.com are not correct, you will be paid per the above schedule, based on when the
correct information is provided to Trebbs.com.`,
      },
      {
        number: 5.6,
        content: `If a refund is made to a Buyer for the return of products, order cancellation or as resolution
in a dispute, Trebbs.com may:`,
        subdata: [
          {
            number: `a`,
            content: `offset any amounts that are payable by you to Trebbs.com from any sales proceeds that
may be owed to you; or`
          },
          {
            number: `b`,
            content: `invoice you for amounts due to Trebbs.com, which must be paid upon receipt; or`
          },
          {
            number: `c`,
            content: `obtain reimbursement from you through any other legal action.`
          },
        ]
      },
      {
        number: 5.7,
        content: `If Trebbs.com determines that your account has been used to engage in deceptive,
fraudulent, or illegal activity, or to repeatedly violate this Agreement, the Terms or other
policies, then Trebbs.com is not obligated to act on your behalf to procure payments from the
Buyer and may permanently withhold any payments to you.`,
      }
    ]
  },
  {
    number: 6,
    title: `Third-Party Sites or Services`,
    data: [
      {
        number: 6.1,
        content: `Trebbs.com may give you access to third-party sites or services through hyperlinks, API or
other means. You should review the third-party terms and conditions, as Trebbs.com has no
control over and does not monitor such sites. Trebbs.com will not be liable to you for the
content of these sites or your use of them.`,
      },
      {
        number: 6.2,
        content: `Trebbs.com may offer you third-party services, such as payment gateways, financing and
delivery services. These services may be subject to a separate agreement between you and the
service provider. Under no circumstances Trebbs.com will be liable to you for the use of such
services.`,
      },
      {
        number: 6.3,
        content: `Third-party services may be subject to separate fees. You will be informed of such fees prior
to the performance of the services.`,
      },
    ]
  },
  {
    number: 7,
    title: `Third-Party Sites or Services`,
    data: [
      {
        number: 7.1,
        content: `The services provided by Trebbs.com and the products displayed on Trebbs.com are
provided “as is”, “as available” and “with all faults”, and all warranties are excluded.`,
      },
      {
        number: 7.2,
        content: `You agree to defend Trebbs.com, our affiliates, directors, officers and employees against
any and all third-party losses, claims and liabilities (including legal costs) which may result from
(i) your violation of any applicable laws or this Agreement; (ii) use of Trebbs.com or its services
(or third-party services or sites offered through Trebbs.com); (iii) your products, including the
offer, sale, refund, cancellation, return, or adjustments; (iv) your taxes or the collection,
payment, or failure to collect or pay your taxes, or the failure to meet tax registration
obligations or duties; or (v) actual or alleged breach of any representations you have made. You
agree to fully compensate Trebbs.com for such losses.`,
      },
      {
        number: 7.3,
        content: `Trebbs.com has the right to take control of any legal matter subject to Clause 7.2.`,
      },
      {
        number: 7.4,
        content: `Trebbs.com will not be liable to pay any consequential, indirect, punitive or incidental
damages (including but not limited to damages for loss of profits or savings, business
interruption, loss of information), resulting from your use of Trebbs.com, or any third-party
services or sites offered through Trebbs.com.`,
      },
      {
        number: 7.5,
        content: `Regardless of the above clauses, the total liability of Trebbs.com, our employees, agents,
affiliates or representatives for any or all your claims during any calendar year is limited to USD
$100.`,
      },
      {
        number: 7.6,
        content: `The above clauses will apply whether Trebbs.com has been advised of, or should have been
aware of, the possibility of any losses.`,
      },
    ]
  },
  {
    number: 8,
    title: `Cooperation with Authorities`,
    data: [
      {
        number: 8.1,
        content: `Trebbs.com has the right to cooperate fully with governmental or regulatory authorities or
law enforcement in the investigation of any suspected criminal or civil wrongdoing. As
permitted by applicable laws and policies, Trebbs.com may disclose a seller's identity and
contact information regarding a seller's account, transactions or activities carried on
Trebbs.com will not be liable for damages due to such disclosure, and you agree not to bring
any claim against Trebbs.com for the disclosure.`,
      },
    ]
  },
  {
    number: 9,
    title: `Term and Termination`,
    data: [
      {
        number: 9.1,
        content: `The term of this Agreement will begin on the date your registration is verified and continues
until terminated by either Trebbs.com or you. Trebbs.com may terminate or suspend this
Agreement or your account for any reason at any time. You may terminate this Agreement or
any Trebbs.com service for any reason, at any time, by closing your account.`,
      },
    ]
  },
  {
    number: 10,
    title: `Force Majeure`,
    data: [
      {
        number: 10.1,
        content: `Neither you nor Trebbs.com will be liable for failing to fulfill any obligation under this
Agreement that result from circumstances beyond either party’s reasonable control, such as
natural disasters, pandemics or war.`,
      },
    ]
  },
  {
    number: 11,
    title: `Intellectual Property`,
    data: [
      {
        number: 11.1,
        content: `“Trebbs.com” and its related logos and words on Trebbs.com are registered trademarks of
Trebbs.com and are protected by international trademark and other intellectual property rights
and laws. Trebbs.com’s trademarks may not be used in connection with any service or products
other than Trebbs.com’s, without its written consent. All other trademarks not owned by
Trebbs.com that appear on Trebbs.com are the property of their respective owners, who may
or may not be affiliated with Trebbs.com.`,
      },
      {
        number: 11.2,
        content: `Trebbs.com is the sole owner or lawful licensee of all the rights and interests in
Trebbs.com and its content. Trebbs.com and its content contains trade secrets and other
intellectual property rights protected under worldwide copyright and other laws. All title,
ownership and intellectual property rights in Trebbs.com and its content belong to Trebbs.com.
All other rights are reserved.`,
      },
      {
        number: 11.3,
        content: ` If you believe that any content on Trebbs.com violates your intellectual property or other
third-party rights, please contact legal@Trebbs.com.`,
      },
    ]
  },
  {
    number: 12,
    title: `Notices`,
    data: [
      {
        number: 12.1,
        content: `All notices or demands to Trebbs.com must be sent by email to: legal@Trebbs.com`,
      },
      {
        number: 12.2,
        content: `All legal notices or demands on a seller will be delivered to the last known email address
provided by Trebbs.com, unless otherwise required by specific laws or regulations.`,
      },
    ]
  },
  {
    number: 13,
    title: `General Clauses`,
    data: [
      {
        number: 13.1,
        content: `Unless you have any additional agreements with Trebbs.com, this Agreement represents
the entire agreement between you and Trebbs.com as to your use of the website and any
related applications or services, overriding any prior written or verbal agreements that may
have been made.`,
      },
      {
        number: 13.2,
        content: `This Agreement does not create an agency, partnership, joint venture, employment or
franchise relationship between you and Trebbs.com.`,
      },
      {
        number: 13.3,
        content: `If any clause of this Agreement is considered invalid or unenforceable, such clause will be
deleted and the remaining clauses will remain valid and enforceable.`,
      },
      {
        number: 13.4,
        content: `If Trebbs.com does not enforce its rights or act against any breach by you under this
Agreement, it does not mean that Trebbs.com is waiving its rights to enforce actions against
you in the future.`,
      },
      {
        number: 13.5,
        content: `Trebbs.com has the right to assign this Agreement to any person or entity. You may not
assign this Agreement to any person or entity, without written consent from Trebbs.com.`,
      },
      {
        number: 13.6,
        content: ` This Agreement is governed by and construed according to the laws and regulations of the
Republic of India. Any dispute, difference, controversy or claim arising out of or in connection
with this Agreement, including any question regarding its existence, validity, interpretation,
performance, discharge and applicable remedies, will be subject to the exclusive jurisdiction of
Indian Courts. If you have any questions regarding this Agreement, please Contact Us.`,
      },
    ]
  },
];

const TermsAndConditions = (props) => {
  const navigate = useNavigate();

  return (
    <Grid container item md={12} xs={12}>
      <Box sx={style.bgImgView}>
        <img alt="" src={LoginBgImg} style={style.imgStyle}/>
      </Box>
      <Grid sx={style.contentView}
            container item md={12} xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
        <Grid container item md={12} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} mt={5} mb={5}>
          <Card sx={{
            width: {md: '90%', sm: '90%', xs: '90%'},
            minHeight: {md: '40vw', sm: '100vw', xs: '100vw'},
            padding: 5
          }}>
            <CardContent>

              <Grid item md={12} xs={12} mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography className={styles.mainTitleText}>SELLER AGREEMENT</Typography>
              </Grid>

              <Typography className={styles.contentText}>
                These are the terms and conditions of the Seller Agreement ("Agreement") between you and
                TREBBS TRADE SOLUTIONS PRIVATE LIMITED and its affiliates (“Trebbs.com”) regarding the use
                of the Trebbs.com website and any mobile applications to sell your products.
              </Typography>

              <Typography className={styles.titleText} mt={2}>
                By completing the registration process and clicking the “Register” button, you agree to be
                bound by this Agreement, the User Terms and Conditions (“Terms”), Product Listing Policy
                and Privacy Policy, which are incorporated by reference in this Agreement (“Agreement").
              </Typography>

              <Typography className={styles.contentText}>
                This Agreement will not take effect unless and until you have fully activated your seller account
                and have been verified by Trebbs.com.
              </Typography>

              <Typography className={styles.contentText} mt={2}>
                Trebbs.com may update this Agreement at any time. Any changes to this Agreement will be
                posted on this page. Once posted, the new Agreement will be effective immediately. By
                continuing to access Trebbs.com or use its services, you will be considered to have accepted the
                updated Agreement.
              </Typography>

              {seller_use_list.map((t, i) => {
                return (
                  <Grid item md={12} xs={12} key={i}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={3}>
                      <Typography className={styles.titleText}>{t?.number}.</Typography>
                      <Typography pl={1} className={styles.titleText}>{t?.title}</Typography>
                    </Box>
                    {t?.data?.length > 0 ?
                      t?.data?.map((t1, i1) => {
                        return (
                          <Grid item md={12} xs={12} key={i1} pl={3}>
                            <Box mt={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                              <Typography className={styles.contentText}>({t1?.number})</Typography>
                              <Typography pl={1} className={styles.contentText}>{t1?.content}</Typography>
                            </Box>
                          </Grid>
                        )
                      })
                      : null}
                    {t?.subdata?.length > 0 ?
                      t?.subdata?.map((t2, i2) => {
                        return (
                          <Grid item md={12} xs={12} key={i2} pl={3}>
                            <Box mt={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                              <Typography className={styles.contentText}>{t2?.number}.</Typography>
                              <Typography pl={1} className={styles.contentText}>{t2?.content}</Typography>
                            </Box>
                          </Grid>
                        )
                      })
                      : null}
                  </Grid>
                )
              })}

              {terms_conditions_list.map((item, index) => {
                return (
                  <Grid item md={12} xs={12} key={index}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={3}>
                      <Typography className={styles.titleText}>{item?.number}.</Typography>
                      <Typography pl={1} className={styles.titleText}>{item?.title}</Typography>
                    </Box>
                    {item?.desc ?
                      <Box mt={2}>
                        <Typography className={styles.titleText}>{item?.desc}</Typography>
                      </Box>
                      : null}
                    {item?.data?.length > 0 ?
                      item?.data?.map((item1, index1) => {
                        return (
                          <Grid item md={12} xs={12} key={index1}>
                            <Box mt={2} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                              <Typography className={styles.contentText}>{item1?.number}</Typography>
                              <Typography pl={1} className={styles.contentText}>{item1?.content}</Typography>
                            </Box>
                            {item1?.subdata?.length > 0 ?
                              item1?.subdata?.map((item2, index2) => {
                                return (
                                  <Grid item md={12} xs={12} key={index2} pl={3}>
                                    <Box mt={1} display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                                      <Typography className={styles.contentText}>({item2?.number})</Typography>
                                      <Typography pl={1} className={styles.contentText}>{item2?.content}</Typography>
                                    </Box>
                                  </Grid>
                                )
                              })
                              : null}
                          </Grid>
                        )
                      })
                      : null}
                  </Grid>
                )
              })}

            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </Grid>
  )
};

export default TermsAndConditions;

const style = {
  bgImgView: {
    position: 'fixed',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  contentView: {
    zIndex: 10,
    minHeight: window.innerHeight
  },
  titleText: {
    textAlign: 'center',
    fontFamily: 'InterBold',
    fontSize: 30
  },
  subTitleText: {
    textAlign: 'center',
    color: '#a1a5b7',
    fontFamily: 'InterBold',
    fontSize: 17
  },
};
