import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, CircularProgress} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormCountrySelectBox from "./FormCountrySelectBox";
import FormMultiSelectBox from "./FormMultiSelectBox";
import FormButton from "./FormButton";

import './../assets/css/custom.css';

import {connect} from "react-redux";
import {
  getStates,
  getCities,
  updateCities,
} from "../redux/actions/userDataActions";
import {COUNTRIES} from "../constants/countries";

const AddContactDetails = (props) => {
  // const [loading, setLoading] = useState(false);
  const [sellerUid, setSellerUid] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [designation, setDesignation] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState(null);
  const [country, setCountry] = useState(null);
  const [zipCode, setZipCode] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNoCode, setMobileNoCode] = useState(null);
  const [mobileNo, setMobileNo] = useState('');
  const [whatsAppNoCode, setWhatsAppNoCode] = useState(null);
  const [whatsAppNo, setWhatsAppNo] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {

    if (props.data != null && (props.statesList.length > 0)) {

      let state_val = null;
      let mobile_code_val = null;
      let whatsapp_code_val = null;

      if (props?.data?.sellerId == '') {
        if (country != null) {
          mobile_code_val = COUNTRIES.filter((x) => (x.code === country?.code));
          whatsapp_code_val = COUNTRIES.filter((x) => (x.code === country?.code));
        }
      } else {
        if (props.data?.mobileNoCode) {
          mobile_code_val = COUNTRIES.filter((x) => ((x?.phone === props.data?.mobileNoCode?.toString()) && (x.code === props.data?.mobileNoCountry)));
        }

        if (props.data?.whatsAppNoCode) {
          whatsapp_code_val = COUNTRIES.filter((x) => ((x?.phone === props.data?.whatsAppNoCode?.toString()) && (x.code === props.data?.whatsAppNoCountry)));
        }
      }

      if (props.data?.stateProvince) {
        state_val = props.statesList.filter((x) => (x.name === props.data?.stateProvince?.toString()));
      }
      let data1 = props?.data;

      setSellerUid(data1?.sellerId ? props?.sellerUid : '')
      setFirstName(data1?.firstName ? data1.firstName : '');
      setLastName(data1?.lastName ? data1.lastName : '');
      setDesignation(data1?.designation ? data1.designation : '');
      setBusinessAddress(data1?.businessAddress ? data1.businessAddress : '');
      setStateProvince(state_val != null ? state_val?.[0] : null);
      setZipCode(data1?.zipCode ? data1.zipCode : '');
      setEmail(data1?.email ? data1.email : '');
      setMobileNoCode(mobile_code_val != null ? mobile_code_val?.[0] : null);
      setMobileNo(data1?.mobileNo ? data1.mobileNo : '');
      setWhatsAppNoCode(whatsapp_code_val != null ? whatsapp_code_val?.[0] : null);
      setWhatsAppNo(data1?.whatsAppNo ? data1.whatsAppNo : '');
    }
  }, [props.data, props.statesList]);

  // useEffect(() => {
  //   if (props.statesList.length > 0) {
  //     if (props?.type == 'edit') {
  //     } else {
  //       if (props?.data?.sellerId) {
  //       } else {
  //         setLoading(false)
  //       }
  //     }
  //   }
  // }, [props.statesList])


  useEffect(() => {
    let country = props.data?.corporateCountry;
    if (country) {
      let country_val = null;
      let country_list = [...COUNTRIES]
      let country_index = country_list?.findIndex((x) => x.code == country);
      if (country_index >= 0) {
        setCountry(country_list[country_index]);
        country_val = country_list[country_index];
      }

      if (country_val != null) {
        let data = {
          country: country_val?.label
        };
        props.getStates(JSON.stringify(data));
      }
    }
  }, [props?.data?.corporateCountry])

  useEffect(() => {
    if (stateProvince != null) {

      let state_val = null
      if (props.data?.stateProvince) {
        state_val = props.statesList.filter((x) => (x.name === props.data?.stateProvince?.toString()));
      }

      if (state_val != null) {
        if (stateProvince?.state_code == state_val?.[0]?.state_code) {
          setCity(props?.data?.city ? props.data.city : '');
          // if (props?.type == 'edit' || props?.data?.sellerId) {
          //   setLoading(false)
          // }
        } else {
          setCity('');
        }
      } else {
        setCity('');
      }
      let data = {
        country: country?.label,
        state: stateProvince?.name
      };
      props.getCities(JSON.stringify(data));
    }
  }, [stateProvince]);

  const onContinueClick = () => {
    let errorText = {};
    if (firstName == '') {
      errorText = {firstName: 'First Name is required'};
    }
    if (lastName == '') {
      errorText = {...errorText, lastName: 'First Name is required'};
    }
    if (designation == '') {
      errorText = {...errorText, designation: 'Designation is required'};
    }
    if (businessAddress == '') {
      errorText = {...errorText, businessAddress: 'Business Address is required'};
    }
    if (stateProvince == null) {
      errorText = {...errorText, stateProvince: 'State/Province is required'};
    }
    if (city == '') {
      errorText = {...errorText, city: 'City is required'};
    }
    if (zipCode == '') {
      errorText = {...errorText, zipCode: 'Zip Code is required'};
    }
    if (mobileNo == '' || mobileNoCode == '') {
      errorText = {...errorText, mobileNo: 'Mobile No & Code is required'};
    } else if (!/^[0-9]{10}$/.test(mobileNo)) {
      errorText = {...errorText, mobileNo: 'Invalid Mobile No'};
    }
    if (whatsAppNo == '' || whatsAppNoCode == '') {
      errorText = {...errorText, whatsAppNo: 'WhatsApp No & Code is required'};
    } else if (!/^[0-9]{10}$/.test(whatsAppNo)) {
      errorText = {...errorText, whatsAppNo: 'Invalid WhatsApp No'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      sellerId: props.data?.sellerId ? props.data?.sellerId : '',
      firstName: firstName,
      lastName: lastName,
      designation: designation,
      businessAddress: businessAddress,
      city: city,
      stateProvince: stateProvince,
      zipCode: zipCode,
      email: email,
      mobileNo: mobileNo,
      mobileNoCode: mobileNoCode,
      whatsAppNo: whatsAppNo,
      whatsAppNoCode: whatsAppNoCode,
    };

    props.onAddContactDetailClick(data)
  };


  // if (loading) {
  //   return (
  //     <Box style={{minHeight: window.innerHeight - 140}}
  //          display={"flex"}
  //          alignItems={"center"}
  //          justifyContent={"center"}>
  //       <CircularProgress/>
  //     </Box>
  //   )
  // }

  return (
    <Grid item container xs={12} md={12}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
    >

      <Grid display={"flex"}
            flexDirection={"row"}
            item md={12} xs={12}
            mt={3}
            container>
        <Typography sx={styles.labelText}>Name of authorized Person</Typography>
        <Box pl={1}>
          <Typography sx={styles.requiredText}>*</Typography>
        </Box>
      </Grid>

      <Grid item md={5.8} xs={5.8}
            container>
        <FormTextInput
          page={'AddContactDetails'}
          value={firstName}
          setValue={setFirstName}
          placeholder={'First Name'}
          error={error?.firstName}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={5.8} xs={5.8}
            container>
        <FormTextInput
          page={'AddContactDetails'}
          value={lastName}
          setValue={setLastName}
          placeholder={'Last Name'}
          error={error?.lastName}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddContactDetails'}
          value={designation}
          setValue={setDesignation}
          label={'Designation'}
          error={error?.designation}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddContactDetails'}
          value={businessAddress}
          setValue={setBusinessAddress}
          label={'Business Address'}
          error={error?.businessAddress}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormCountrySelectBox
          disabled={true}
          page={'AddContactDetails'}
          type={"code"}
          value={country}
          setValue={setCountry}
          label={'Country'}
          placeholder={'Select a Country'}
          error={error?.country}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormMultiSelectBox
          type={'state'}
          page={'AddContactDetails'}
          multiple={false}
          loading={props?.stateLoading}
          value={stateProvince}
          setValue={setStateProvince}
          data={props?.statesList ? props?.statesList : []}
          label={'State/Province'}
          error={error?.stateProvince}
          textInputViewStyle={styles.textInputViewStyle}
          selectedItemViewStyle={styles.selectedItemViewStyle}
          required={true}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormMultiSelectBox
          type={'city'}
          page={'AddContactDetails'}
          multiple={false}
          loading={props?.cityLoading}
          value={city}
          setValue={setCity}
          data={props?.citiesList ? props?.citiesList : []}
          label={'City'}
          error={error?.city}
          textInputViewStyle={styles.textInputViewStyle}
          selectedItemViewStyle={styles.selectedItemViewStyle}
          required={true}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddContactDetails'}
          value={zipCode}
          setValue={setZipCode}
          label={'ZIP Code'}
          error={error?.zipCode}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={5.9} xs={12} mt={3}
            container>
        <FormTextInput
          disabled={true}
          page={'AddContactDetails'}
          value={email}
          setValue={setEmail}
          type={"email"}
          label={'E-mail'}
          error={error?.email}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid display={"flex"}
            flexDirection={"column"}
            item md={5.9} xs={12} mt={3}
            container>
        <Grid display={"flex"}
              flexDirection={"row"}
              item md={12} xs={12}
              container>
          <Typography sx={styles.labelText}>Mobile No</Typography>
          <Box pl={1}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} mt={0.5}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              container>
          <Grid item md={4} xs={12}
                container>
            <FormCountrySelectBox
              page={'AddContactDetails'}
              type={"dial_code"}
              value={mobileNoCode}
              setValue={setMobileNoCode}
              placeholder={'Code'}
              textInputViewStyle={styles.textInputViewStyle}
            />
          </Grid>
          <Grid item md={7.5} xs={12}
                container>
            <FormTextInput
              page={'AddContactDetails'}
              value={mobileNo}
              setValue={setMobileNo}
              error={error?.mobileNo}
              textInputViewStyle={styles.textInputViewStyle}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid display={"flex"}
            flexDirection={"column"}
            item md={5.9} xs={12} mt={3}
            container>
        <Grid display={"flex"}
              flexDirection={"row"}
              item md={12} xs={12}
              container>
          <Typography sx={styles.labelText}>WhatsApp No</Typography>
          <Box pl={1}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} mt={0.5}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              container>
          <Grid item md={4} xs={12}
                container>
            <FormCountrySelectBox
              page={'AddVendor'}
              type={"dial_code"}
              value={whatsAppNoCode}
              setValue={setWhatsAppNoCode}
              placeholder={'Code'}
              textInputViewStyle={styles.textInputViewStyle}
            />
          </Grid>
          <Grid item md={7.5} xs={12}
                container>
            <FormTextInput
              page={'AddVendor'}
              value={whatsAppNo}
              setValue={setWhatsAppNo}
              error={error?.whatsAppNo}
              textInputViewStyle={styles.textInputViewStyle}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4}>
        <Box>
          <FormButton
            title={props?.type == 'edit' ? 'Update' : 'Continue'}
            onSubmit={onContinueClick}
          />
        </Box>
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    statesList: state.userData.statesList,
    stateLoading: state.userData.stateLoading,
    citiesList: state.userData.citiesList,
    cityLoading: state.userData.cityLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStates: data => dispatch(getStates(data)),
    getCities: data => dispatch(getCities(data)),
    updateCities: data => dispatch(updateCities(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContactDetails);

const styles = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  labelText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
};

