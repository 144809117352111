import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

import {connect} from "react-redux";

import FormButton from "./FormButton";

import styles from "./LandingContent2.module.css";

import {THEME_COLOR6, THEME_COLOR11} from "../constants";

import {ReactComponent as LandingFreeIcon} from "../assets/img/landing_free.svg";
import {ReactComponent as LandingVerifiedIcon} from "../assets/img/landing_verified.svg";
import {ReactComponent as LandingPaymentIcon} from "../assets/img/landing_payment.svg";
import {ReactComponent as LandingLogisticsIcon} from "../assets/img/landing_logistics.svg";
import {ReactComponent as LandingDocumentationIcon} from "../assets/img/landing_documentation.svg";
import {ReactComponent as LandingTopLine} from "../assets/img/landing_top_line.svg";
import {ReactComponent as LandingBottomLine} from "../assets/img/landing_bottom_line.svg";
import {ReactComponent as LandingArrowIcon} from "../assets/img/landing_arrow.svg";
import {ReactComponent as AddUserIcon} from "../assets/img/add_user.svg";
import {ReactComponent as BoxIcon} from "../assets/img/box.svg";
import {ReactComponent as ShipIcon} from "../assets/img/ship.svg";
import {ReactComponent as LandingTickIcon} from "../assets/img/landing_tick.svg";
import {ReactComponent as LandingBusinessManIcon} from "../assets/img/landing_business_man.svg";
import {ReactComponent as CurveLineIcon} from "../assets/img/curve_line.svg";

const cutting_edge_features = [
  {
    id: 1,
    number: '01',
    image: <LandingFreeIcon height={'35%'} width={'35%'}/>,
    title: 'Zero commission',
    desc: 'We do not take any commission from sellers',
    color: '#315CCB'
  },
  {
    id: 2,
    number: '02',
    image: <LandingVerifiedIcon height={'35%'} width={'35%'}/>,
    title: 'Verified Buyers',
    desc: 'Only verified buyers are allowed to trade on trebbs.com',
    color: '#EE2525'
  },
  {
    id: 3,
    number: '03',
    image: <LandingPaymentIcon height={'35%'} width={'35%'}/>,
    title: 'Payment protection',
    desc: 'Receive payments before shipping',
    color: '#FF9933'
  },
  {
    id: 4,
    number: '04',
    image: <LandingLogisticsIcon height={'35%'} width={'35%'}/>,
    title: 'Inbuilt Logistics',
    desc: 'We will take care of your global shipping',
    color: '#128807'
  },
  {
    id: 5,
    number: '05',
    image: <LandingDocumentationIcon height={'35%'} width={'35%'}/>,
    title: 'E-documentation',
    desc: 'Paperless documentation to ease hurdles in clearance',
    color: '#09ADE1'
  }
]

const landing_step = [
  {
    id: 1,
    step: 'Step 1',
    icon: <AddUserIcon height={'55%'} width={'55%'}/>,
    title: 'Register your account',
    subTitle: 'You should have.....',
    data: [
      'A registered business entity',
      'Tax registration details',
      'Export license',
      'RCMC',
    ]
  },
  {
    id: 2,
    step: 'Step 2',
    icon: <BoxIcon height={'55%'} width={'55%'}/>,
    title: 'List your products',
    subTitle: 'You should need to.....',
    data: [
      'List your products',
      'Manage your inventory',
      'Ensure quality',
      'Confirm orders',
    ]
  },
  {
    id: 3,
    step: 'Step 3',
    icon: <ShipIcon height={'80%'} width={'80%'}/>,
    title: 'Ship your products',
    subTitle: 'You should have to.......',
    data: [
      'Prepare your orders',
      'Upload documents',
      'Receive payments',
      'Ship your products',
    ]
  }
]

const LandingContent2 = (props) => {
  const navigate = useNavigate();

  const onExportNowClick = () => {
    if (props?.tgsin) {
      navigate('/index')
    } else {
      navigate('/sign_up')
    }
  };

  return (
    <Grid item md={12} xs={12}>

      <Grid item md={12} xs={12} mt={10}>
        <Typography className={styles.landingTitleText}>Explore Trebbs Cutting-Edge Features.</Typography>
        <Typography className={styles.landingDescText} mt={3}>
          Trebbs.com was born out of a desire to simplify and streamline the agri-trade process. Our user-friendly
          platform empowers exporters and importers to connect directly, discover new opportunities, and trade a vast
          array of agricultural products with greater ease and efficiency.
        </Typography>
        <Grid item md={12} xs={12} display={'flex'} flexDirection={{md: 'row', xs: 'column'}}
              alignItems={{md: 'center', xs: 'unset'}}
              justifyContent={{md: 'space-around', xs: 'unset'}} mt={4} mb={4}
              sx={style.cardMainView}>
          {cutting_edge_features?.map((item, index) => {
            return (
              <Grid container item md={1.8} xs={12} key={index} mt={{md: 0, xs: 3}}
                    display={'flex'}
                    alignItems={index % 2 == 0 ? {md: 'flex-start', xs: 'unset'} : {md: 'flex-end', xs: 'unset'}}
                    justifyContent={{md: 'unset', xs: 'center'}}
                    className={styles.cardView}>
                <Box className={styles.cardBoxView}>
                  <Grid item md={12} xs={12}
                        display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                        sx={{
                          border: '1px solid ' + item?.color,
                        }}
                        className={styles.cardSubView}>

                    <Grid className={styles.landingTopLineView}
                          sx={{
                            path: {
                              fill: item?.color,
                            }
                          }}>
                      <LandingTopLine height={'100%'} width={'100%'}/>
                    </Grid>

                    <Box
                      className={styles.boxView}
                      sx={{
                        border: '1px solid ' + item?.color,
                        backgroundColor: item?.color
                      }}>
                      <Typography className={styles.numberText}>{item?.number}</Typography>
                    </Box>
                    <Box p={2}
                         display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                      {item?.image}
                      <Typography className={styles.featureTitleText} sx={{color: item?.color}}
                                  mt={1}>{item?.title}</Typography>
                      <Typography className={styles.featureDescText} sx={{color: item?.color}}
                                  mt={1}>{item?.desc}</Typography>
                    </Box>


                  </Grid>


                  <Grid className={styles.bottomLineArrowView}>
                    <Box
                      sx={{
                        path: {
                          fill: item?.color,
                        }
                      }}>
                      <LandingBottomLine/>
                    </Box>
                    <Box className={styles.landingArrowView}
                         sx={{
                           path: {
                             fill: item?.color,
                           }
                         }}>
                      <LandingArrowIcon/>
                    </Box>
                  </Grid>

                </Box>
              </Grid>
            )
          })}
        </Grid>
        <Typography className={styles.landingDescText}>
          <span style={style.landingTextColor}>Trebbs</span> brings innovation to your fingertips with a range of
          cutting-edge features designed to enhance your
          global trade experience. These features that set <span style={style.landingTextColor}>Trebbs</span> apart and
          make global trade a breeze.
        </Typography>
      </Grid>


      <Grid item md={12} xs={12} mt={10}>
        <Typography className={styles.landingTitleText}>You are just three steps away...</Typography>
        <Typography className={styles.landingDescText} mt={3}>
          Trebbs b2b marketplace helps you to take your business to global market. Whether you are a farmer, local
          seller, or a seasoned exporter, irrespective of your business size, you can export to global market and
          establish your business on the world map.
        </Typography>

        <Grid item md={12} xs={12} display={'flex'} flexDirection={{md: 'row', xs: 'column'}}
              alignItems={{md: 'center', xs: 'unset'}}
              justifyContent={{md: 'space-between', xs: 'unset'}} mt={4}>

          {landing_step?.map((item, index) => {
            return (
              <Grid item md={3.8} xs={12} className={styles.mainBoxView}
                    display={'flex'} flexDirection={'column'}
                    key={index} mt={{md: 0, xs: 2}}>

                <Box className={styles.stepView}/>
                <Typography className={styles.stepText}>{item?.step}</Typography>

                <Grid item md={12} xs={12} pt={3} display={'flex'} flexDirection={'column'} alignItems={'center'}
                      justifyContent={'center'}>
                  <Box className={styles.stepIconView} display={'flex'} alignItems={'center'}
                       justifyContent={'center'}>
                    {item?.icon}
                  </Box>
                  <Typography className={styles.stepContentText} mt={-1}>{item?.title}</Typography>
                  <Box className={styles.borderBottomLine} mt={3} mb={2}/>
                </Grid>

                <Grid item md={12} xs={12} pb={3} display={'flex'} flexDirection={'column'} alignItems={'center'}
                      justifyContent={'center'}>
                  <Box>
                    <Typography className={styles.stepContentText} mb={2}>{item?.subTitle}</Typography>
                    {item?.data?.map((item1, index1) => {
                      return (
                        <Box key={index1} display={'flex'} flexDirection={'row'} alignItems={'center'} mt={1}>
                          <LandingTickIcon height={22} width={22}/>
                          <Box pl={1}>
                            <Typography className={styles.stepContentDataText}>{item1}</Typography>
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>
                </Grid>
              </Grid>
            )
          })}

        </Grid>
      </Grid>

      <Grid item md={12} xs={12} mt={10}>
        <Grid item md={12} xs={12} display={'flex'} flexDirection={{md: 'row', xs: 'column'}}
              className={styles.doorRegisterMainView}>
          <Grid item md={6} xs={12} className={styles.doorStepView}
          >
            <Box p={5}>
              <Typography className={styles.doorstepText}>From Farm to Doorstep, </Typography>
              <Typography className={styles.freshStartText} mt={0.2}>Your Fresh Start.</Typography>

              <Typography className={styles.doorstepDescText} mt={2}>Ready to elevate your global trade experience?
                List your products now with Trebbs.com and enjoy
                the convenience of fresh, quality products shipped across globe. It's time to indulge in
                hassle-free, online global trade that puts you in control.</Typography>

              <Box mt={2}>
                <FormButton
                  title={'Export now'}
                  btnStyleView={style.btnStyleView}
                  hoverTextColor={THEME_COLOR6}
                  bgColorStyleView={'#fff'}
                  onSubmit={onExportNowClick}
                />
              </Box>
            </Box>

            <Box className={styles.curveLineView}>
              <Box sx={style.curveLineSubView}>
                <CurveLineIcon height={'100%'} width={'100%'}/>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}
                className={styles.registerBusinessView} p={3}>
            <Box sx={style.landingBusinessView}>
              <LandingBusinessManIcon height={'100%'} width={'100%'}/>
            </Box>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    tgsin: state.userData.tgsin,
  }
};

export default connect(
  mapStateToProps,
  null
)(LandingContent2);

const style = {
  cardMainView: {
    height: {md: 300, xs: 'unset'},
    minHeight: {md: 'unset', xs: 300}
  },
  landingTextColor: {
    color: THEME_COLOR11
  },
  btnStyleView: {
    backgroundColor: '#fff',
    color: THEME_COLOR6,
    fontSize: 14,
    fontFamily: 'InterMedium',
    width: {md: '30%', xs: '55%'},
    height: 40,
    borderRadius: 5
  },
  curveLineSubView: {
    height: {md: '100%', xs: 150},
    width: {md: '100%', xs: 150}
  },
  landingBusinessView: {
    height: {md: 450, xs: 'unset'},
    width: {md: 450, xs: 'unset'}
  },
};
