import * as actionTypes from './types';

//login
export const loginUser = data => ({
  type: actionTypes.LOGIN_USER,
  payload: data,
});

export const loginUserSuccess = data => ({
  type: actionTypes.LOGIN_USER_SUCCESS,
  payload: data,
});

export const loginUserFail = data => ({
  type: actionTypes.LOGIN_USER_FAIL,
  payload: data,
});

export const forgotUserName = data => ({
  type: actionTypes.FORGOT_USER_NAME,
  payload: data,
});

export const forgotUserNameSuccess = data => ({
  type: actionTypes.FORGOT_USER_NAME_SUCCESS,
  payload: data,
});

export const forgotUserNameFail = data => ({
  type: actionTypes.FORGOT_USER_NAME_FAIL,
  payload: data,
});

export const forgotUserPassword = data => ({
  type: actionTypes.FORGOT_USER_PASSWORD,
  payload: data,
});

export const forgotUserPasswordSuccess = data => ({
  type: actionTypes.FORGOT_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const forgotUserPasswordFail = data => ({
  type: actionTypes.FORGOT_USER_PASSWORD_FAIL,
  payload: data,
});

export const updateForgotUserPassword = data => ({
  type: actionTypes.UPDATE_FORGOT_USER_PASSWORD,
  payload: data,
});

export const updateForgotUserPasswordSuccess = data => ({
  type: actionTypes.UPDATE_FORGOT_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const updateForgotUserPasswordFail = data => ({
  type: actionTypes.UPDATE_FORGOT_USER_PASSWORD_FAIL,
  payload: data,
});

export const getForgotUserPassword = data => ({
  type: actionTypes.GET_FORGOT_USER_PASSWORD,
  payload: data,
});

export const getForgotUserPasswordSuccess = data => ({
  type: actionTypes.GET_FORGOT_USER_PASSWORD_SUCCESS,
  payload: data,
});

export const getForgotUserPasswordFail = data => ({
  type: actionTypes.GET_FORGOT_USER_PASSWORD_FAIL,
  payload: data,
});

//register
export const registerUser = data => ({
  type: actionTypes.REGISTER_USER,
  payload: data,
});

export const registerUserSuccess = data => ({
  type: actionTypes.REGISTER_USER_SUCCESS,
  payload: data,
});

export const registerUserFail = data => ({
  type: actionTypes.REGISTER_USER_FAIL,
  payload: data,
});

export const getRegisterData = () => ({
  type: actionTypes.GET_REGISTER_DATA,
});

export const getRegisterDataSuccess = data => ({
  type: actionTypes.GET_REGISTER_DATA_SUCCESS,
  payload: data,
});

export const getRegisterDataFail = data => ({
  type: actionTypes.GET_REGISTER_DATA_FAIL,
  payload: data,
});

//product
export const addProductData = () => ({
  type: actionTypes.ADD_PRODUCT_DATA,
});

export const addProductDataSuccess = data => ({
  type: actionTypes.ADD_PRODUCT_DATA_SUCCESS,
  payload: data,
});

export const addProductDataFail = data => ({
  type: actionTypes.ADD_PRODUCT_DATA_FAIL,
  payload: data,
});

export const getProductDetail = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL,
  payload: data,
});

export const getProductDetailSuccess = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL_SUCCESS,
  payload: data,
});

export const getProductDetailFail = data => ({
  type: actionTypes.GET_PRODUCT_DETAIL_FAIL,
  payload: data,
});

export const updateProductStatus = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS,
  payload: data,
});

export const updateProductStatusSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS_SUCCESS,
  payload: data,
});

export const updateProductStatusFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_STATUS_FAIL,
  payload: data,
});

export const addProductVariety = data => ({
  type: actionTypes.ADD_PRODUCT_VARIETY,
  payload: data,
});

export const addProductVarietySuccess = data => ({
  type: actionTypes.ADD_PRODUCT_VARIETY_SUCCESS,
  payload: data,
});

export const addProductVarietyFail = data => ({
  type: actionTypes.ADD_PRODUCT_VARIETY_FAIL,
  payload: data,
});

export const addProductImage = data => ({
  type: actionTypes.ADD_PRODUCT_IMAGE,
  payload: data,
});

export const addProductImageSuccess = data => ({
  type: actionTypes.ADD_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const addProductImageFail = data => ({
  type: actionTypes.ADD_PRODUCT_IMAGE_FAIL,
  payload: data,
});

export const updateProductImage = data => ({
  type: actionTypes.UPDATE_PRODUCT_IMAGE,
  payload: data,
});

export const updateProductImageSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const updateProductImageFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_IMAGE_FAIL,
  payload: data,
});

export const addProductPackingPrice = data => ({
  type: actionTypes.ADD_PRODUCT_PACKING_PRICE,
  payload: data,
});

export const addProductPackingPriceSuccess = data => ({
  type: actionTypes.ADD_PRODUCT_PACKING_PRICE_SUCCESS,
  payload: data,
});

export const addProductPackingPriceFail = data => ({
  type: actionTypes.ADD_PRODUCT_PACKING_PRICE_FAIL,
  payload: data,
});

export const addProductShipping = data => ({
  type: actionTypes.ADD_PRODUCT_SHIPPING,
  payload: data,
});

export const addProductShippingSuccess = data => ({
  type: actionTypes.ADD_PRODUCT_SHIPPING_SUCCESS,
  payload: data,
});

export const addProductShippingFail = data => ({
  type: actionTypes.ADD_PRODUCT_SHIPPING_FAIL,
  payload: data,
});

export const updateProductVariety = data => ({
  type: actionTypes.UPDATE_PRODUCT_VARIETY,
  payload: data,
});

export const updateProductVarietySuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_VARIETY_SUCCESS,
  payload: data,
});

export const updateProductVarietyFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_VARIETY_FAIL,
  payload: data,
});

export const updateProductPackingPrice = data => ({
  type: actionTypes.UPDATE_PRODUCT_PACKING_PRICE,
  payload: data,
});

export const updateProductPackingPriceSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_PACKING_PRICE_SUCCESS,
  payload: data,
});

export const updateProductPackingPriceFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_PACKING_PRICE_FAIL,
  payload: data,
});

export const updateProductPricePerPackage = data => ({
  type: actionTypes.UPDATE_PRODUCT_PRICE_PER_PACKAGE,
  payload: data,
});

export const updateProductPricePerPackageSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_PRICE_PER_PACKAGE_SUCCESS,
  payload: data,
});

export const updateProductPricePerPackageFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_PRICE_PER_PACKAGE_FAIL,
  payload: data,
});

export const updateProductShipping = data => ({
  type: actionTypes.UPDATE_PRODUCT_SHIPPING,
  payload: data,
});

export const updateProductShippingSuccess = data => ({
  type: actionTypes.UPDATE_PRODUCT_SHIPPING_SUCCESS,
  payload: data,
});

export const updateProductShippingFail = data => ({
  type: actionTypes.UPDATE_PRODUCT_SHIPPING_FAIL,
  payload: data,
});

export const listProduct = data => ({
  type: actionTypes.LIST_PRODUCT,
  payload: data,
});

export const listProductSuccess = data => ({
  type: actionTypes.LIST_PRODUCT_SUCCESS,
  payload: data,
});

export const listProductFail = data => ({
  type: actionTypes.LIST_PRODUCT_FAIL,
  payload: data,
});

export const deleteProduct = data => ({
  type: actionTypes.DELETE_PRODUCT,
  payload: data,
});

export const deleteProductSuccess = data => ({
  type: actionTypes.DELETE_PRODUCT_SUCCESS,
  payload: data,
});

export const deleteProductFail = data => ({
  type: actionTypes.DELETE_PRODUCT_FAIL,
  payload: data,
});

export const updateMainProductImage = data => ({
  type: actionTypes.UPDATE_MAIN_PRODUCT_IMAGE,
  payload: data,
});

export const updateMainProductImageSuccess = data => ({
  type: actionTypes.UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const updateMainProductImageFail = data => ({
  type: actionTypes.UPDATE_MAIN_PRODUCT_IMAGE_FAIL,
  payload: data,
});

export const deleteProductImage = data => ({
  type: actionTypes.DELETE_PRODUCT_IMAGE,
  payload: data,
});

export const deleteProductImageSuccess = data => ({
  type: actionTypes.DELETE_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const deleteProductImageFail = data => ({
  type: actionTypes.DELETE_PRODUCT_IMAGE_FAIL,
  payload: data,
});

export const addProductDocument = data => ({
  type: actionTypes.ADD_PRODUCT_DOCUMENT,
  payload: data,
});

export const addProductDocumentSuccess = data => ({
  type: actionTypes.ADD_PRODUCT_DOCUMENT_SUCCESS,
  payload: data,
});

export const addProductDocumentFail = data => ({
  type: actionTypes.ADD_PRODUCT_DOCUMENT_FAIL,
  payload: data,
});

export const deleteProductDocument = data => ({
  type: actionTypes.DELETE_PRODUCT_DOCUMENT,
  payload: data,
});

export const deleteProductDocumentSuccess = data => ({
  type: actionTypes.DELETE_PRODUCT_DOCUMENT_SUCCESS,
  payload: data,
});

export const deleteProductDocumentFail = data => ({
  type: actionTypes.DELETE_PRODUCT_DOCUMENT_FAIL,
  payload: data,
});

export const getSellerCategoryList = () => ({
  type: actionTypes.GET_SELLER_CATEGORY_LIST,
});

export const getSellerCategoryListSuccess = data => ({
  type: actionTypes.GET_SELLER_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const getSellerCategoryListFail = data => ({
  type: actionTypes.GET_SELLER_CATEGORY_LIST_FAIL,
  payload: data,
});

export const listCategory = () => ({
  type: actionTypes.LIST_CATEGORY,
});

export const listCategorySuccess = data => ({
  type: actionTypes.LIST_CATEGORY_SUCCESS,
  payload: data,
});

export const listCategoryFail = data => ({
  type: actionTypes.LIST_CATEGORY_FAIL,
  payload: data,
});


//my account
export const getSellerDetail = () => ({
  type: actionTypes.GET_SELLER_DETAIL,
});

export const getSellerDetailSuccess = data => ({
  type: actionTypes.GET_SELLER_DETAIL_SUCCESS,
  payload: data,
});

export const getSellerDetailFail = data => ({
  type: actionTypes.GET_SELLER_DETAIL_FAIL,
  payload: data,
});

export const addSellerContactDetail = data => ({
  type: actionTypes.ADD_SELLER_CONTACT_DETAILS,
  payload: data,
});

export const addSellerContactDetailSuccess = data => ({
  type: actionTypes.ADD_SELLER_CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const addSellerContactDetailFail = data => ({
  type: actionTypes.ADD_SELLER_CONTACT_DETAILS_FAIL,
  payload: data,
});

export const updateSellerContactDetail = data => ({
  type: actionTypes.UPDATE_SELLER_CONTACT_DETAILS,
  payload: data,
});

export const updateSellerContactDetailSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const updateSellerContactDetailFail = data => ({
  type: actionTypes.UPDATE_SELLER_CONTACT_DETAILS_FAIL,
  payload: data,
});

export const addSellerExportLicenseDetail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_LICENSE_DETAILS,
  payload: data,
});

export const addSellerExportLicenseDetailSuccess = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS,
  payload: data,
});

export const addSellerExportLicenseDetailFail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_LICENSE_DETAILS_FAIL,
  payload: data,
});

export const updateSellerExportLicenseDetail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_LICENSE_DETAILS,
  payload: data,
});

export const updateSellerExportLicenseDetailSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS,
  payload: data,
});

export const updateSellerExportLicenseDetailFail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_LICENSE_DETAILS_FAIL,
  payload: data,
});

export const addSellerExportRcmcDetail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_RCMC_DETAILS,
  payload: data,
});

export const addSellerExportRcmcDetailSuccess = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_RCMC_DETAILS_SUCCESS,
  payload: data,
});

export const addSellerExportRcmcDetailFail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_RCMC_DETAILS_FAIL,
  payload: data,
});

export const updateSellerExportRcmcDetail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_RCMC_DETAILS,
  payload: data,
});

export const updateSellerExportRcmcDetailSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS,
  payload: data,
});

export const updateSellerExportRcmcDetailFail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_RCMC_DETAILS_FAIL,
  payload: data,
});

export const deleteSellerExportRcmcDetail = data => ({
  type: actionTypes.DELETE_SELLER_EXPORT_RCMC_DETAILS,
  payload: data,
});

export const deleteSellerExportRcmcDetailSuccess = data => ({
  type: actionTypes.DELETE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS,
  payload: data,
});

export const deleteSellerExportRcmcDetailFail = data => ({
  type: actionTypes.DELETE_SELLER_EXPORT_RCMC_DETAILS_FAIL,
  payload: data,
});

export const addSellerExportFssaiDetail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_FSSAI_DETAILS,
  payload: data,
});

export const addSellerExportFssaiDetailSuccess = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS,
  payload: data,
});

export const addSellerExportFssaiDetailFail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_FSSAI_DETAILS_FAIL,
  payload: data,
});

export const updateSellerExportFssaiDetail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_FSSAI_DETAILS,
  payload: data,
});

export const updateSellerExportFssaiDetailSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS,
  payload: data,
});

export const updateSellerExportFssaiDetailFail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_FSSAI_DETAILS_FAIL,
  payload: data,
});

export const addSellerExportTaxDetail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_TAX_DETAILS,
  payload: data,
});

export const addSellerExportTaxDetailSuccess = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_TAX_DETAILS_SUCCESS,
  payload: data,
});

export const addSellerExportTaxDetailFail = data => ({
  type: actionTypes.ADD_SELLER_EXPORT_TAX_DETAILS_FAIL,
  payload: data,
});

export const updateSellerExportTaxDetail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_TAX_DETAILS,
  payload: data,
});

export const updateSellerExportTaxDetailSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_TAX_DETAILS_SUCCESS,
  payload: data,
});

export const updateSellerExportTaxDetailFail = data => ({
  type: actionTypes.UPDATE_SELLER_EXPORT_TAX_DETAILS_FAIL,
  payload: data,
});

export const addSellerBankDetail = data => ({
  type: actionTypes.ADD_SELLER_BANK_DETAILS,
  payload: data,
});

export const addSellerBankDetailSuccess = data => ({
  type: actionTypes.ADD_SELLER_BANK_DETAILS_SUCCESS,
  payload: data,
});

export const addSellerBankDetailFail = data => ({
  type: actionTypes.ADD_SELLER_BANK_DETAILS_FAIL,
  payload: data,
});

export const updateSellerBankDetail = data => ({
  type: actionTypes.UPDATE_SELLER_BANK_DETAILS,
  payload: data,
});

export const updateSellerBankDetailSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_BANK_DETAILS_SUCCESS,
  payload: data,
});

export const updateSellerBankDetailFail = data => ({
  type: actionTypes.UPDATE_SELLER_BANK_DETAILS_FAIL,
  payload: data,
});

export const addSellerDocuments = data => ({
  type: actionTypes.ADD_SELLER_DOCUMENTS,
  payload: data,
});

export const addSellerDocumentsSuccess = data => ({
  type: actionTypes.ADD_SELLER_DOCUMENTS_SUCCESS,
  payload: data,
});

export const addSellerDocumentsFail = data => ({
  type: actionTypes.ADD_SELLER_DOCUMENTS_FAIL,
  payload: data,
});

export const updateSellerDocuments = data => ({
  type: actionTypes.UPDATE_SELLER_DOCUMENTS,
  payload: data,
});

export const updateSellerDocumentsSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_DOCUMENTS_SUCCESS,
  payload: data,
});

export const updateSellerDocumentsFail = data => ({
  type: actionTypes.UPDATE_SELLER_DOCUMENTS_FAIL,
  payload: data,
});

export const addSellerProductCategory = data => ({
  type: actionTypes.ADD_SELLER_PRODUCT_CATEGORY,
  payload: data,
});

export const addSellerProductCategorySuccess = data => ({
  type: actionTypes.ADD_SELLER_PRODUCT_CATEGORY_SUCCESS,
  payload: data,
});

export const addSellerProductCategoryFail = data => ({
  type: actionTypes.ADD_SELLER_PRODUCT_CATEGORY_FAIL,
  payload: data,
});

// export const checkCartProduct = data => ({
//   type: actionTypes.CHECK_CART_PRODUCT,
//   payload: data,
// });
//
// export const checkCartProductSuccess = data => ({
//   type: actionTypes.CHECK_CART_PRODUCT_SUCCESS,
//   payload: data,
// });
//
// export const checkCartProductFail = data => ({
//   type: actionTypes.CHECK_CART_PRODUCT_FAIL,
//   payload: data,
// });

export const updateCartProduct = data => ({
  type: actionTypes.UPDATE_CART_PRODUCT,
  payload: data,
});

export const updateShowTgsin = data => ({
  type: actionTypes.UPDATE_SHOW_TGSIN,
  payload: data,
});

//orders
export const listOrder = data => ({
  type: actionTypes.LIST_ORDER,
  payload: data,
});

export const listOrderSuccess = data => ({
  type: actionTypes.LIST_ORDER_SUCCESS,
  payload: data,
});

export const listOrderFail = data => ({
  type: actionTypes.LIST_ORDER_FAIL,
  payload: data,
});

export const getOrderDetail = data => ({
  type: actionTypes.GET_ORDER_DETAIL,
  payload: data,
});

export const getOrderDetailSuccess = data => ({
  type: actionTypes.GET_ORDER_DETAIL_SUCCESS,
  payload: data,
});

export const getOrderDetailFail = data => ({
  type: actionTypes.GET_ORDER_DETAIL_FAIL,
  payload: data,
});

export const confirmShipment = data => ({
  type: actionTypes.CONFIRM_SHIPMENT,
  payload: data,
});

export const confirmShipmentSuccess = data => ({
  type: actionTypes.CONFIRM_SHIPMENT_SUCCESS,
  payload: data,
});

export const confirmShipmentFail = data => ({
  type: actionTypes.CONFIRM_SHIPMENT_FAIL,
  payload: data,
});

export const getOrderCount = () => ({
  type: actionTypes.GET_ORDER_COUNT,
});

export const getOrderCountSuccess = data => ({
  type: actionTypes.GET_ORDER_COUNT_SUCCESS,
  payload: data,
});

export const getOrderCountFail = data => ({
  type: actionTypes.GET_ORDER_COUNT_FAIL,
  payload: data,
});

export const getStates = data => ({
  type: actionTypes.GET_STATES,
  payload: data,
});

export const getStatesSuccess = data => ({
  type: actionTypes.GET_STATES_SUCCESS,
  payload: data,
});

export const getStatesFail = data => ({
  type: actionTypes.GET_STATES_FAIL,
  payload: data,
});

export const getCities = data => ({
  type: actionTypes.GET_CITIES,
  payload: data,
});

export const getCitiesSuccess = data => ({
  type: actionTypes.GET_CITIES_SUCCESS,
  payload: data,
});

export const getCitiesFail = data => ({
  type: actionTypes.GET_CITIES_FAIL,
  payload: data,
});

export const updateCities = data => ({
  type: actionTypes.UPDATE_CITIES,
  payload: data,
});

//dashboard
export const getDashboard = data => ({
  type: actionTypes.GET_DASHBOARD,
  payload: data,
});

export const getDashboardSuccess = data => ({
  type: actionTypes.GET_DASHBOARD_SUCCESS,
  payload: data,
});

export const getDashboardFail = data => ({
  type: actionTypes.GET_DASHBOARD_FAIL,
  payload: data,
});

export const getLoginSellerDetail = () => ({
  type: actionTypes.GET_LOGIN_SELLER_DETAIL,
});

export const getLoginSellerDetailSuccess = data => ({
  type: actionTypes.GET_LOGIN_SELLER_DETAIL_SUCCESS,
  payload: data,
});

export const getLoginSellerDetailFail = data => ({
  type: actionTypes.GET_LOGIN_SELLER_DETAIL_FAIL,
  payload: data,
});

export const updateSellerUsername = data => ({
  type: actionTypes.UPDATE_SELLER_USERNAME,
  payload: data,
});

export const updateSellerUsernameSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_USERNAME_SUCCESS,
  payload: data,
});

export const updateSellerUsernameFail = data => ({
  type: actionTypes.UPDATE_SELLER_USERNAME_FAIL,
  payload: data,
});

export const updateSellerPassword = data => ({
  type: actionTypes.UPDATE_SELLER_PASSWORD,
  payload: data,
});

export const updateSellerPasswordSuccess = data => ({
  type: actionTypes.UPDATE_SELLER_PASSWORD_SUCCESS,
  payload: data,
});

export const updateSellerPasswordFail = data => ({
  type: actionTypes.UPDATE_SELLER_PASSWORD_FAIL,
  payload: data,
});

export const updateListLoading = data => ({
  type: actionTypes.UPDATE_LIST_LOADING,
  payload: data,
});

export const updateProductDetail = data => ({
  type: actionTypes.UPDATE_PRODUCT_DETAIL,
  payload: data,
});

export const alertMsgEmpty = () => ({
  type: actionTypes.ALERT_MSG_EMPTY,
});

export const updateAlertMsg = data => ({
  type: actionTypes.UPDATE_ALERT_MSG,
  payload: data,
});

export const updateApiStatus = data => ({
  type: actionTypes.UPDATE_API_STATUS,
  payload: data,
});

export const logOut = () => ({
  type: actionTypes.LOG_OUT,
});
