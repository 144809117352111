import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import {useLocation} from 'react-router-dom'

import styles from './ProductDocumentView.module.css'

import FormButton from "./FormButton";
import AlertDialogBox from "./AlertDialogBox";
import UploadProductDocument from "./UploadProductDocument";
import UploadMainProductImageModal from "./UploadMainProductImageModal";

import {ReactComponent as DeleteIcon} from "../assets/img/delete.svg";
import {ReactComponent as DeclineIcon} from "../assets/img/decline.svg"

import {connect} from "react-redux";
import {updateApiStatus, addProductDocument, deleteProductDocument} from "../redux/actions/userDataActions";

const buttonList = {
  deleted: {
    icon: <DeclineIcon height={80} width={80}/>,
    content: 'Are you sure you would like to delete?',
    firstBtn: 'Yes, Delete!',
    secondBtn: 'No, cancel',
  }
};

const ProductDocumentView = (props) => {
  const [documentId, setDocumentId] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [documentImage, setDocumentImage] = useState(null);
  const [displayDocumentImage, setDisplayDocumentImage] = useState(null);
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (props.apiStatus == 'DELETE_PRODUCT_DOCUMENT_SUCCESS') {
      setDocumentId('')
      setConfirmBoxVisible(false);
      props.updateApiStatus('')
    } else if (props.apiStatus == 'ADD_PRODUCT_DOCUMENT_SUCCESS') {
      setDocumentName('')
      setDocumentImage(null)
      setDisplayDocumentImage('')
      props.updateApiStatus('')
    }
  }, [props.apiStatus])

  const onChangeImage = (fileList) => {
    setDocumentImage(fileList[0].file);
    setDisplayDocumentImage(fileList[0].image);
  };

  const onRemoveImage = () => {
    setDocumentImage(null);
    setDisplayDocumentImage('');
  };

  const onAddClick = () => {
    let errorText = {};
    if (documentName == '') {
      errorText = {documentName: 'Document Name is required'};
    }
    if (documentImage == null) {
      errorText = {...errorText, documentImage: 'Document is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('productUid', props?.productUid);
    formData.append('productDocumentName', documentName);
    formData.append('productDocument', documentImage);

    props.addProductDocument(formData)
  };

  const onDeleteDocument = (id) => {
    setDocumentId(id);
    setConfirmBoxVisible(true);
  };

  const onConfirmDeleteClick = () => {
    let data = {
      productUid: props?.productUid,
      documentId: documentId
    }
    props.deleteProductDocument(JSON.stringify(data))
  };

  const onCancelDeleteClick = () => {
    setDocumentId('')
    setConfirmBoxVisible(false);
  };

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Document Name</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Document ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Uploaded Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>View Document</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {props?.data?.productDocumentList?.length > 0 ?
              props?.data?.productDocumentList?.map((item, index) => {
                return (
                  <TableRow key={index} className={styles.tableRow}>
                    <TableCell>
                      {item?.documentName}
                    </TableCell>
                    <TableCell>
                      {item?.documentId?.split(".")?.[0]}
                    </TableCell>
                    <TableCell>
                      {item?.timestamp}
                    </TableCell>
                    <TableCell
                      style={{cursor: 'pointer'}}
                      onClick={() => window.open(item?.displayDocument, '_blank')}>
                      View
                    </TableCell>
                    <TableCell
                      style={{cursor: 'pointer'}}
                      onClick={() => onDeleteDocument(item?.documentUid)}>
                      <DeleteIcon width={20} height={20}/>
                    </TableCell>
                  </TableRow>
                );
              }) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <UploadProductDocument
        documentName={documentName}
        setDocumentName={setDocumentName}
        displayDocumentImage={displayDocumentImage}
        errorDocumentName={error?.documentName}
        errorDocumentImage={error?.documentImage}
        onRemoveImage={onRemoveImage}
        onChangeImage={onChangeImage}
      />

      <Grid item md={12} xs={12} mt={3}>
        <Typography className={styles.addMoreText} onClick={() => onAddClick()}>Add</Typography>
      </Grid>

      <AlertDialogBox
        confirmBox={confirmBoxVisible}
        type={'deleted'}
        icon={buttonList['deleted']['icon']}
        content={buttonList['deleted']['content']}
        firstBtn={buttonList['deleted']['firstBtn']}
        secondBtn={buttonList['deleted']['secondBtn']}
        btnLoading={props.btnLoading}
        onConfirmClick={onConfirmDeleteClick}
        onCancelClick={onCancelDeleteClick}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    btnLoading: state.userData.btnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    addProductDocument: data => dispatch(addProductDocument(data)),
    deleteProductDocument: data => dispatch(deleteProductDocument(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDocumentView);
