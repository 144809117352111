import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

import styles from './ProductShippingDetailView.module.css'

const ProductShippingDetailView = (props) => {

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>MOQ</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Processing Time</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Supply ability</Typography>
                <Typography className={styles.subTitleText} ml={1}>/Week</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Type of product</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Required Temperature</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Stuffing</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.moq ? item?.productShippingDetail?.moq + 'Kg' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.processingTime ? item?.productShippingDetail?.processingTime + ' Days' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.supplyAbilityPerWeek ? item?.productShippingDetail?.supplyAbilityPerWeek + 'Kg' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productTypeVal ? item?.productTypeVal : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.requiredTemperature ?
                      (item?.productShippingDetail?.requiredTemperature == 0 ? 'Not Required' : (item?.productShippingDetail?.requiredTemperature + '°C'))
                      : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.stuffingVal ? item?.stuffingVal : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default ProductShippingDetailView;
