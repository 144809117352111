import React, {useEffect, useState, useRef} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import dayjs from "dayjs";

import Container from "../../components/Container";
import OrderDetailView from "../../components/OrderDetailView";
import OrderPackingDetailView from "../../components/OrderPackingDetailView";
import OrderShippingDetailView from "../../components/OrderShippingDetailView";
import OrderPaymentDetailView from "../../components/OrderPaymentDetailView";
import FormDatePicker from "../../components/FormDatePicker";
import FormTimePicker from "../../components/FormTimePicker";
import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import FormTextArea from "../../components/FormTextArea";
import {showConfirmShipmentBtn} from "../../assets/functions/common";

import styles from "./OrderDetail.module.css"

import {connect} from "react-redux";
import {confirmShipment, getOrderDetail, updateApiStatus} from "../../redux/actions/userDataActions";

const OrderDetail = (props) => {
  const [orderUid, setOrderUid] = useState('');
  const [pageType, setPageType] = useState('detail');
  const [pickupScheduleDate, setPickupScheduleDate] = useState(dayjs());
  const [pickupScheduleTime, setPickupScheduleTime] = useState(dayjs());
  const [preshipmentInspectionScheduleDate, setPreshipmentInspectionScheduleDate] = useState(dayjs());
  const [preshipmentInspectionScheduleTime, setPreshipmentInspectionScheduleTime] = useState(dayjs());
  const [confirmShipment, setConfirmShipment] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const pickupScheduleRef = useRef(null);
  const preshipmentInspectionScheduleRef = useRef(null);

  useEffect(() => {
    var order_uid = location.pathname.split("/").pop();
    setOrderUid(order_uid);
    let data = {
      orderUid: order_uid,
    };
    props.getOrderDetail(JSON.stringify(data));
  }, []);

  useEffect(() => {
    let params = location?.hash;
    if (params) {
      if (params == '#pickup') {
        if (pickupScheduleRef?.current) {
          pickupScheduleRef?.current?.scrollIntoView({behavior: "smooth"});
        }
      } else if (params == '#PSI') {
        if (preshipmentInspectionScheduleRef?.current) {
          preshipmentInspectionScheduleRef?.current?.scrollIntoView({behavior: "smooth"});
        }
      }
    }
  }, [])

  useEffect(() => {
    if (props?.orderDetail != null) {
      let data = props?.orderDetail;
      let pickup_schedule_date = data?.pickup_schedule_date;
      let pickup_schedule_time = data?.pickup_schedule_time;
      if (pickup_schedule_date) {
        setPickupScheduleDate(dayjs(pickup_schedule_date));
      }
      if (pickup_schedule_time) {
        if (pickup_schedule_time == 0) {
          setPickupScheduleTime(dayjs('0000', 'HHmm'));
        } else {
          setPickupScheduleTime(dayjs(pickup_schedule_time, 'HHmm'));
        }
      }

      if (data?.preshipment_inspection_schedule_date) {
        let preshipment_inspection_schedule_date = data?.preshipment_inspection_schedule_date;
        let preshipment_inspection_schedule_time = data?.preshipment_inspection_schedule_time;
        if (preshipment_inspection_schedule_date) {
          setPreshipmentInspectionScheduleDate(dayjs(preshipment_inspection_schedule_date));
        }
        if (preshipment_inspection_schedule_time) {
          if (preshipment_inspection_schedule_time == 0) {
            setPreshipmentInspectionScheduleTime(dayjs('0000', 'HHmm'));
          } else {
            setPreshipmentInspectionScheduleTime(dayjs(preshipment_inspection_schedule_time, 'HHmm'));
          }
        }
      }

      if (pickup_schedule_date && pickup_schedule_time) {
        let confirm_shipment = showConfirmShipmentBtn(pickup_schedule_date, pickup_schedule_time, data?.payment_status)
        setConfirmShipment(confirm_shipment);
      }

      let page_type = 'detail'
      if (data?.cancelled_reason) {
        page_type = 'cancel'
      }
      setPageType(page_type)
    }
  }, [props?.orderDetail])

  useEffect(() => {
    if (props.apiStatus == 'CONFIRM_SHIPMENT_SUCCESS') {
      props.updateApiStatus('')
      navigate('/orders');
    } else if (props.apiStatus == 'GET_ORDER_DETAIL_FAIL') {
      props.updateApiStatus('');
      navigate('/orders')
    }
  }, [props.apiStatus])

  const onConfirmShipment = () => {
    let data = {
      orderUid: orderUid,
    }
    props.confirmShipment(JSON.stringify(data))
  };

  return (
    <Container page={'orders'}>

      <Grid container item md={12} xs={12} mt={4}>
        <Typography
          className={styles.mainLabelText}>{pageType == 'cancel' ? 'Cancelled Orders' : 'Order Details'}</Typography>
      </Grid>

      <Grid container item md={12} xs={12} mt={1}>
        <FormButton
          title={'Back to Orders'}
          btnStyleView={style.btnStyleView}
          bgColorStyleView={'#000'}
          onSubmit={() => navigate('/orders')}
        />
      </Grid>


      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Order Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderDetailView
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Packing Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderPackingDetailView
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Shipping Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderShippingDetailView
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12} mt={4}
            display={"flex"} flexDirection={'column'}
            justifyContent={'center'}
            className={styles.borderView}>

        <Grid item md={12} xs={12}
              display={'flex'} alignItems={'center'}
              className={styles.labelView}>
          <Typography className={styles.labelText} px={2}>Payment Details</Typography>
        </Grid>

        <Grid item md={12} xs={12} p={2}>
          <OrderPaymentDetailView
            data={props?.orderDetail}/>
        </Grid>
      </Grid>

      {props?.orderDetail?.order_status != 'cancelled' ?
        <>

          {props?.orderDetail?.pickup_schedule_date ?
            <Box ref={pickupScheduleRef}>
              <Typography className={styles.pickupLabelText} mt={4}>Pickup Schedule</Typography>

              <Grid item md={12} xs={12} container mt={3}
                    display={'flex'} flexDirection={'row'}
                    alignItems={'center'} justifyContent={'space-between'}
                    className={styles.pickUpBorderView}
                    p={5}>
                <Grid container item md={3.9} xs={12}>
                  <Typography className={styles.pickupSubLabelText}>Pickup Schedule</Typography>
                </Grid>

                <Grid container item md={3.9} xs={12}>
                  <FormDatePicker
                    disabled={true}
                    page={"OrderDetail"}
                    value={pickupScheduleDate}
                    label={'Pickup Date'}
                    inputFormat="DD-MM-YYYY"
                  />
                </Grid>

                <Grid container item md={3.9} xs={12}>
                  <FormTimePicker
                    disabled={true}
                    page={"OrderDetail"}
                    value={pickupScheduleTime}
                    label={'Pickup Time'}
                    inputFormat="hh:mm A"
                  />
                </Grid>
              </Grid>
            </Box>
            : null}


          {props?.orderDetail?.sgs_preshipment_inspection == 1 && (props?.orderDetail?.preshipment_inspection_schedule_date) ?
            <Box ref={preshipmentInspectionScheduleRef}>
              <Typography className={styles.preshipmentLabelText} mt={4}>Pre-Shipment Inspection Schedule</Typography>

              <Grid item md={12} xs={12} container mt={3}
                    display={'flex'} flexDirection={'row'}
                    alignItems={'center'} justifyContent={'space-between'}
                    className={styles.preshipmentBorderView}
                    p={5}>
                <Grid container item md={3.9} xs={12}>
                  <Typography className={styles.pickupSubLabelText}>Pre-Shipment Inspection</Typography>
                </Grid>

                <Grid container item md={8} xs={12}>
                  <FormTextInput
                    disabled={true}
                    page={'OrderDetail'}
                    value={props?.orderDetail?.preshipment_inspection_schedule_place}
                    label={'Place'}
                    placeholder={'Loading Location'}
                  />
                </Grid>

                <Grid container item md={3.9} xs={12}/>

                <Grid container item md={3.9} xs={12} mt={2}>
                  <FormDatePicker
                    disabled={true}
                    page={"OrderDetail"}
                    value={preshipmentInspectionScheduleDate}
                    label={'Date'}
                    inputFormat="DD-MM-YYYY"
                  />
                </Grid>

                <Grid container item md={3.9} xs={12} mt={2}>
                  <FormTimePicker
                    disabled={true}
                    page={"OrderDetail"}
                    value={preshipmentInspectionScheduleTime}
                    label={'Time'}
                    inputFormat="hh:mm A"
                  />
                </Grid>
              </Grid>

            </Box>
            : null}

        </>
        : null}

      {pageType == 'cancel' ?
        <>
          <Grid container item md={12} xs={12} mt={4}
                display={'flex'}
                flexDirection={'column'}
                className={styles.borderView} p={2}>
            <Grid display={"flex"}
                  flexDirection={"row"} item md={12} xs={12} mb={2}
                  container>
              <Typography className={styles.labelText}>Reason for cancellation</Typography>
            </Grid>

            <Grid container item md={12} xs={12}
                  display={'flex'}
                  alignItems={'center'} justifyContent={'flex-start'}>
              <Grid container item md={7} xs={7}>
                <FormTextArea
                  disabled={true}
                  page={'OrderDetail'}
                  placeholder={'Reason'}
                  value={props?.orderDetail?.cancelled_reason}
                  textInputViewStyle={style.textInputViewStyle}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
        : null}

      {confirmShipment && (pageType == 'detail') && (props?.orderDetail?.order_status == 'unshipped') ?
        <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}
              justifyContent={'center'}
              mt={3}>
          <Box className={styles.btnView}>
            <FormButton
              loading={props?.orderBtnLoading}
              title={'Confirm Shipment'}
              btnType={'btn8'}
              onSubmit={() => onConfirmShipment()}
            />
          </Box>
        </Grid>
        : null}

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    orderDetail: state.userData.orderDetail,
    orderBtnLoading: state.userData.orderBtnLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderDetail: data => dispatch(getOrderDetail(data)),
    confirmShipment: data => dispatch(confirmShipment(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);

const style = {
  btnStyleView: {
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 5,
    height: 30,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #d3d3d3',
  },
}
