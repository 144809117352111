import React, {useEffect, useRef} from 'react'
import {
  Grid, Typography, Box, Card, CardActions, CardContent, Checkbox, Divider,
} from '@mui/material';

import styles from './PaymentPolicy.module.css';

import LandingHeader from "../../components/LandingHeader";
import LandingFooter from "../../components/LandingFooter";

const payment_policy_list = [
  {
    number: 1,
    title: `Payment Methods`,
    content: `We support various payment methods, including
            credit card
            payments, bank transfers, and online payment gateways. Buyers and sellers can
            select the payment method that is most convenient for them.`
  },
  {
    number: 2,
    title: `Payment Terms`,
    content: `All payments must be made in USD agreed upon by
            both the
            buyer and the seller. The payment terms will be negotiated and agreed upon by
            both parties before the transaction takes place. The payment terms may include
            payment in advance and Letter of Credit (LC).`
  },
  {
    number: 3,
    title: `Payment Processing Time`,
    content: `The payment processing time will
            depend on the
            payment method selected. For credit card payments and online payment
            gateways, the payment will be processed immediately. Bank transfers may take
            several days to process, depending on the banks involved.`
  },
  {
    number: 4,
    title: `Currency Exchange`,
    content: `If the buyer and seller are in different
            countries, currency
            exchange may be necessary. We use the prevailing exchange rate at the time of
            the transaction to determine the payment amount. We do not charge any
            additional fees for currency exchange, but buyers and sellers should check with
            their banks for any currency conversion fees.`
  },
  {
    number: 5,
    title: `Dispute Resolution`,
    content: `In the event of any payment disputes, we
            will work with
            both the buyer and the seller to resolve the issue. If necessary, we may involve a
            third-party mediator to help resolve the dispute.`
  }
];

const PaymentPolicy = (props) => {
  const paymentPolicyRef = useRef(null);

  useEffect(() => {
    if (paymentPolicyRef?.current) {
      paymentPolicyRef?.current?.scrollIntoView({behavior: "smooth"});
    }
  }, []);

  return (
    <Grid container item md={12} xs={12} display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          ref={paymentPolicyRef}>

      <LandingHeader
        showHome={true}
      />

      <Grid container item md={8} xs={10} display={"flex"} mt={7} mb={7}
            alignItems={"center"}>

        <Grid item md={12} xs={12} mb={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography className={styles.mainTitleText}>International Payment Policy</Typography>
        </Grid>

        <Typography className={styles.contentText}>
          <span className={styles.titleText}>Introduction:</span> This payment policy outlines the payment terms and
          conditions for
          ‘Trebb.com’. As an international B2B platform, we facilitate business transactions
          between buyers and sellers located in different countries. Our payment policy is
          designed to ensure smooth and secure payment transactions for all parties involved.
        </Typography>


        <Box pl={3}>
          {payment_policy_list.map((item, index) => {
            return (
              <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} mt={3} key={index}>
                <Typography className={styles.contentText}>{item?.number}.</Typography>
                <Typography pl={1} className={styles.contentText}><span
                  className={styles.titleText}>{item?.title}:</span> {item?.content}
                </Typography>
              </Box>
            )
          })}
        </Box>

        <Typography className={styles.contentText} mt={3}>
          <span className={styles.titleText}>Conclusion:</span> Our payment policy is designed to provide a secure and
          efficient payment
          process for all parties involved in B2B transactions. We strive to ensure that payments
          are made promptly and that both buyers and sellers are satisfied with the transaction. If
          you have any questions about our payment policy, please do not hesitate to contact us.
        </Typography>
      </Grid>

      <LandingFooter/>
    </Grid>
  )
}

export default PaymentPolicy
