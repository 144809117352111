import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import {v4 as uuidv4} from "uuid";

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import FormTextArea from "./FormTextArea";
import AddProductVarietySizeDetail from "./AddProductVarietySizeDetail";

import './../assets/css/custom.css';

import {ReactComponent as EditIcon} from "../assets/img/pencil.svg";
import {ReactComponent as CloseIcon} from "../assets/img/close.svg";

const AddProductVarietyDetail = (props) => {
  // const [variety, setVariety] = useState('')
  // const [sizeList, setSizeList] = useState([])
  // const [description, setDescription] = useState('')
  // const [selectedSizeData, setSelectedSizeData] = useState(null)
  // const [error, setError] = useState(null);

  useEffect(() => {
    if (props.item != null) {
      props?.setVariety(props?.item?.variety)
      props?.setSizeList(props?.item?.sizeList)
      props?.setDescription(props?.item?.description)
    } else {
      props?.setVariety('')
      props?.setSizeList([])
      props?.setDescription('')
    }
  }, [props.item]);

  const onAddSize = (data, type) => {
    props?.setSelectedSizeData(null);
    props?.setError(null);
    let list = [...props?.sizeList];
    if(type == 'edit') {
      let index = list.findIndex((x) => x?.id == data?.id);
      if(index >= 0) {
        list[index] = data;
      }
      props?.setSizeList(list)
    } else {
      props?.setSizeList([...list, data])
    }
  };

  // const onAddClick = () => {
  //   let errorText = {};
  //   if (variety == '') {
  //     errorText = {variety: 'Variety is required'};
  //   }
  //   if (sizeList.length == 0) {
  //     errorText = {...errorText, sizeList: 'Size is required'};
  //   }
  //   if (description == '') {
  //     errorText = {...errorText, description: 'Description is required'};
  //   }
  //   if (Object.keys(errorText).length != 0) {
  //     setError(errorText);
  //     return;
  //   }
  //
  //   let id_val = uuidv4();
  //   if (props?.item != null) {
  //     id_val = props?.item?.id;
  //   }
  //
  //   let data = {
  //     id: id_val,
  //     variety: variety,
  //     sizeList: sizeList,
  //     description: description,
  //   }
  //
  //   if (props.type == 'add') {
  //     setVariety('')
  //     setSizeList([])
  //     setDescription('')
  //   }
  //
  //   let type = 'add';
  //   if (props?.item != null) {
  //     type = 'edit';
  //   }
  //   props?.onAdd(data, type)
  //
  // };

  const onEditSize = (item) => {
    props?.setSelectedSizeData(item);
  };

  const onDeleteSize = (item) => {
    let list = [...props?.sizeList];
    let index = list.findIndex((x) => x?.id == item?.id);
    if(index >= 0) {
      list.splice(index, 1);
    }
    props?.setSizeList(list)
  };

  return (
    <Grid item container xs={12} md={12} mt={3}>


      <Grid item container xs={12} md={12} style={styles.boxView}
            p={2}>
        <Grid item md={12} xs={12} mt={3}
              container>
          <FormTextInput
            page={'AddProductVarietyDetail'}
            value={props?.variety}
            setValue={props?.setVariety}
            label={'Variety'}
            error={props?.error?.variety}
            required={true}
          />
        </Grid>

        <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12}
              mt={3}
              container>
          <Typography sx={styles.labelText}>Size</Typography>
          <Box pl={1}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
        </Grid>

        <AddProductVarietySizeDetail
          type={'add'}
          item={props?.selectedSizeData}
          onAdd={onAddSize}
        />

        {props?.error?.sizeList ?
          <Box mt={1} ml={0.5} styles={styles.errorView}>
            <Typography sx={styles.errorText}>{props?.error?.sizeList}</Typography>
          </Box>
          : null}

        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              mt={3}
              style={styles.cardDiv}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.sizeList?.length > 0 ?
                  props?.sizeList.map((item, index) => (
                    <TableRow
                      key={index}>
                      <TableCell>{item?.size}</TableCell>
                      <TableCell>
                        <Box display={'flex'} flexDirection={'row'}>
                          <Box onClick={() => onEditSize(item)}>
                            <EditIcon height={15} width={15}/>
                          </Box>
                          <Box ml={2}
                               onClick={() => onDeleteSize(item)}>
                            <CloseIcon height={15} width={15}/>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item md={12} xs={12} mt={3}
              container>
          <FormTextArea
            page={'AddProductVarietyDetail'}
            value={props?.description}
            setValue={props?.setDescription}
            label={'Description'}
            error={props?.error?.description}
            required={true}
          />
        </Grid>
      </Grid>

      {/*{props?.type == 'add' ?*/}
      {/*  <Grid item md={12} xs={12} container*/}
      {/*        display="flex" flexDirection="row" justifyContent="right" mt={4}>*/}
      {/*    <Box>*/}
      {/*      <FormButton*/}
      {/*        title={props?.item != null ? 'Update' : 'Add'}*/}
      {/*        btnType={'btn6'}*/}
      {/*        onSubmit={onAddClick}*/}
      {/*      />*/}
      {/*    </Box>*/}
      {/*  </Grid>*/}
      {/*  : null}*/}

    </Grid>
  );
}

export default AddProductVarietyDetail;

const styles = {
  boxView: {
    border: '1px solid #aaa'
  },
  labelText: {
    color: '#000',
    fontFamily: 'InterMedium',
    fontSize: 15,
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  cardDiv: {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFF',
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  errorView: {},
  errorText: {
    color: 'red',
    fontSize: 12,
    fontFamily: 'InterMedium'
  }
};

