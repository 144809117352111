import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import moment from 'moment';
import {useNavigate} from "react-router-dom";

import FormButton from "./FormButton";
import FormSelectBox from "./FormSelectBox";
import {
  unshippedOrderStatus,
  showConfirmShipmentBtn,
  getCurrencyConversionCalc
} from '../assets/functions/common'

import {connect} from "react-redux";

import styles from "./OrderTabList.module.css"

import {THEME_COLOR9} from "../constants";
import {COUNTRIES} from "../constants/countries";

const OrderTabList = (props) => {
  const [orderStatus, setOrderStatus] = useState('');
  const [document, setDocument] = useState('');
  const [confirmShipment, setConfirmShipment] = useState(false);
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceWithoutReferralFee, setTotalPriceWithoutReferralFee] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [pricePerKgWithoutReferralFee, setPricePerKgWithoutReferralFee] = useState(0);
  const [pricePerKgReferralFee, setPricePerKgReferralFee] = useState(0);
  const [pricePerPackageWithoutReferralFee, setPricePerPackageWithoutReferralFee] = useState(0);
  const [pricePerPackageReferralFee, setPricePerPackageReferralFee] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [userPrice, setUserPrice] = useState(0);
  const [userPricePerKg, setUserPricePerKg] = useState(0);
  const [userTotalPrice, setUserTotalPrice] = useState(0);
  const [UserTotalPriceWithoutReferralFee, setUserTotalPriceWithoutReferralFee] = useState(0);
  const [userShippingCost, setUserShippingCost] = useState('')
  const [userCargoPrice, setUserCargoPrice] = useState(0);
  const [userPricePerKgWithoutReferralFee, setUserPricePerKgWithoutReferralFee] = useState(0);
  const [userPricePerKgReferralFee, setUserPricePerKgReferralFee] = useState(0);
  const [userPricePerPackageWithoutReferralFee, setUserPricePerPackageWithoutReferralFee] = useState(0);
  const [userPricePerPackageReferralFee, setUserPricePerPackageReferralFee] = useState(0);
  const [userCurrencySymbol, setUserCurrencySymbol] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let currency_symbol = '';
        let price = 0;
        let price_per_kg = 0;
        let total_price = 0;
        let total_price_without_referral_fee = 0;
        let shipping_cost = 0;
        let cargo_price = 0;
        let price_per_kg_without_referral_fee = 0;
        let price_per_kg_referral_fee = 0;
        let price_per_package_without_referral_fee = 0;
        let price_per_package_referral_fee = 0;
        let user_currency_symbol = '';
        let converted_price = 0;
        let converted_price_per_kg = 0;
        let converted_total_price = 0;
        let converted_total_price_without_referral_fee = 0;
        let converted_shipping_cost = 0;
        let converted_cargo_price = 0;
        let converted_price_per_kg_without_referral_fee = 0;
        let converted_price_per_kg_referral_fee = 0;
        let converted_price_per_package_without_referral_fee = 0;
        let converted_price_per_package_referral_fee = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          package_gross_weight: props?.data?.package_gross_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props?.data?.shipping_cost ? props?.data?.shipping_cost : 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
          rate: props?.data?.rate,
          usd_rate: props?.data?.usd_rate,
          admin_rate: props?.data?.admin_rate,
        }

        let data = getCurrencyConversionCalc(data_list)
        if (data != null) {
          price = data?.seller_price;
          price_per_kg = data?.seller_price_per_kg;
          total_price = data?.seller_total_price;
          shipping_cost = data?.seller_shipping_cost;
          cargo_price = data?.seller_cargo_price;
          price_per_kg_without_referral_fee = data?.seller_price_per_kg_without_referral_fee;
          price_per_kg_referral_fee = data?.seller_price_per_kg_referral_fee;
          price_per_package_without_referral_fee = data?.seller_price_per_package_without_referral_fee;
          price_per_package_referral_fee = data?.seller_price_per_package_referral_fee;
          total_price_without_referral_fee = data?.seller_total_price_without_referral_fee;
          currency_symbol = data?.seller_currency_symbol;
          converted_price = data?.converted_price;
          converted_price_per_kg = data?.converted_price_per_kg;
          converted_total_price = data?.converted_total_price;
          converted_shipping_cost = data?.converted_shipping_cost;
          converted_cargo_price = data?.converted_cargo_price;
          converted_price_per_kg_without_referral_fee = data?.converted_price_per_kg_without_referral_fee;
          converted_price_per_kg_referral_fee = data?.converted_price_per_kg_referral_fee;
          converted_price_per_package_without_referral_fee = data?.converted_price_per_package_without_referral_fee;
          converted_price_per_package_referral_fee = data?.converted_price_per_package_referral_fee;
          converted_total_price_without_referral_fee = data?.converted_total_price_without_referral_fee;
          user_currency_symbol = data?.user_currency_symbol;
        }

        setPrice(price);
        setTotalPrice(total_price);
        setTotalPriceWithoutReferralFee(total_price_without_referral_fee);
        setPricePerKg(price_per_kg);
        setCargoPrice(cargo_price)
        setPricePerKgWithoutReferralFee(price_per_kg_without_referral_fee)
        setPricePerKgReferralFee(price_per_kg_referral_fee)
        setPricePerPackageWithoutReferralFee(price_per_package_without_referral_fee)
        setPricePerPackageReferralFee(price_per_package_referral_fee)
        setShippingCost(shipping_cost)
        setCurrencySymbol(currency_symbol)
        setUserPrice(converted_price);
        setUserTotalPrice(converted_total_price)
        setUserTotalPriceWithoutReferralFee(converted_total_price_without_referral_fee)
        setUserPricePerKg(converted_price_per_kg);
        setUserCargoPrice(converted_cargo_price)
        setUserPricePerKgWithoutReferralFee(converted_price_per_kg_without_referral_fee)
        setUserPricePerKgReferralFee(converted_price_per_kg_referral_fee)
        setUserPricePerPackageWithoutReferralFee(converted_price_per_package_without_referral_fee)
        setUserPricePerPackageReferralFee(converted_price_per_package_referral_fee)
        setUserShippingCost(converted_shipping_cost)
        setUserCurrencySymbol(user_currency_symbol)
      }

      if (props?.tab == 'unshipped') {
        // if (props?.data?.unshipped_timestamp) {
        //   let order_status = unshippedOrderStatus(props?.data?.unshipped_timestamp);
        //   setOrderStatus(order_status);
        // }
        if (props?.data?.payment_status == 'paid') {
          setOrderStatus('Pickup Scheduled');
        } else {
          setOrderStatus('Confirmed')
        }

        if (props?.data?.pickup_schedule_date && props?.data?.pickup_schedule_time) {
          let confirm_shipment = showConfirmShipmentBtn(props?.data?.pickup_schedule_date, props?.data?.pickup_schedule_time, props?.data?.payment_status)
          setConfirmShipment(confirm_shipment);
        }
      } else {
        setOrderStatus(props?.data?.order_status)
      }
    }
  }, [props.data])

  let item = props.data;

  const onOrderDetail = (item, type) => {
    props.onOrderDetail(item?.order_uid, type)
  };

  const onTrackOrder = (item) => {
    window.open(item?.track_order, '_blank')
  };

  const onConfirmShipment = (item) => {
    props.onConfirmShipment(item?.order_uid)
  };

  const onDocument = (val) => {
    setDocument(val);
    let list = [...props?.data?.document_list];
    let index = list.findIndex((x) => x.value == val);
    if (index >= 0) {
      window.open(list[index]['document_file'], '_blank')
    }
  }

  return (
    <>
      <TableCell>
        <Typography className={styles.skuText}>{item.sku}</Typography>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView}>
          {item?.product_image ?
            <Grid item md={4} xs={12} className={styles.imgView}>
              <img src={item.product_image} className={styles.imgStyle}/>
            </Grid>
            : null}
          <Typography className={styles.productNameTitleText}>
            {(item?.variety ? item.variety : 'NIL Variety')
            + ', ' + (item?.name ? item.name : 'NIL')
            + ', ' + (item?.variety_size ? item?.variety_size : 'NIL Size')
            + ', ' + (item?.package_net_weight ?
              (item?.package_net_weight + item?.unit + ' ' + item?.packing_material)
              : 'NIL Packing')
            + ', ' + (item?.pickup_location ? item?.pickup_location : 'NIL Location')}
          </Typography>
          <Typography className={styles.pricePerKgPackageText} mt={1}>({currencySymbol}{pricePerKg?.toFixed(2)} / Kg)
            ({currencySymbol}{price?.toFixed(2)} /
            Package)</Typography>
          <Typography className={styles.hsCodeText} mt={1}>HS
            Code: {item?.hs_code ? (item.hs_code + ' - ' + item.hs_code_title) : ''}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography
            className={styles.sellerText}>{item?.user_name_entity ? item?.user_name_entity : ''}
            {item?.user_country ? '(' + COUNTRIES.filter(
              (x) => x.code == item?.user_country
            )?.[0]?.label + ')' : ''}</Typography>
          <Typography
            className={styles.tgsinText} mt={1}>TGBIN: {item?.tgbin ? item?.tgbin : '-'}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView2}>
          <Typography
            className={styles.orderUidText}>{item?.order_uid ? '#' + item.order_uid : ''}</Typography>
          <Typography
            className={styles.OrderTimestampText}
            mt={1}>{item?.order_timestamp ? item.order_timestamp : ''}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.orderQuantityText}>
            {item?.quantity ? (item?.package_net_weight * item?.quantity) + item?.unit : 0}
          </Typography>
          <Typography className={styles.quantityText} mt={1}>
            No. of Packages: {item?.quantity ? item?.quantity : 0}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.pricePerKgText}>{currencySymbol}{pricePerKgWithoutReferralFee?.toFixed(7)} / Kg</Typography>
          <Typography className={styles.pricePerPackageText}
                      mt={1}>{currencySymbol}{pricePerPackageWithoutReferralFee?.toFixed(6)} /
            Package</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.totalPriceText}>
            {currencySymbol}{totalPrice ? totalPrice?.toFixed(2) : 0}
          </Typography>
          <Typography className={styles.shippingCostText} mt={1}>
            Shipping Cost: {currencySymbol}{shippingCost ? shippingCost?.toFixed(2) : 0}
          </Typography>
          <Typography className={styles.shippingCostText} mt={1}>
            {/*Referral Fee: {item?.referral_fee ? item.referral_fee + '%' : 0}*/}
            Referral Fee: {currencySymbol}{(pricePerPackageReferralFee * item?.quantity)?.toFixed(2)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.userCargoPriceText}>
            {userCurrencySymbol}{userCargoPrice ? userCargoPrice?.toFixed(2) : 0}
          </Typography>
          <Typography className={styles.cargoPriceText} pl={3}>
            ({currencySymbol}{cargoPrice ? cargoPrice?.toFixed(2) : 0})
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.shippingModeText}>
            Mode of Shipping: {item?.mode_of_shipping ? item.mode_of_shipping : ''}
          </Typography>
          <Typography className={styles.incotermText} mt={1}>
            Incoterm: {item?.incoterm ? item.incoterm : ''}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          {item?.delivery_date ?
            <>
              <Typography className={styles.deliveryDateText}>
                Delivery Date:
              </Typography>
              <Typography className={styles.deliveryDateText}>
                {item?.delivery_date ? moment(item?.delivery_date).format('DD-MM-YYYY') : '-'}
              </Typography>
            </>
            : null}
          <>
            <Typography className={styles.shippingDateText} mt={1}>
              Ship by Date:
            </Typography>
            <Typography className={styles.shippingDateText}>
              {item?.shipping_date ? moment(item?.shipping_date).format('DD-MM-YYYY') : '-'}
            </Typography>
          </>
        </Box>
      </TableCell>
      <TableCell>
        <Box className={styles.dataView1}>
          <Typography className={styles.orderStatusText}>
            Order Status: {orderStatus ? orderStatus?.replaceAll("_", " ") : ''}
          </Typography>
          <Typography className={styles.paymentStatusText} mt={1}>
            Payment Status: {item?.payment_status ?
            (item.payment_status == 'half_paid' ? '50% Paid' : item.payment_status)
            : ''}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        {props?.tab == 'cancelled' ?
          <Box className={styles.btnView}>
            <FormButton
              title={'View'}
              btnType={'btn7'}
              width={'100%'}
              onSubmit={() => onOrderDetail(item, 'view')}
            />
          </Box>
          :
          <Box className={styles.btnView}>
            <FormButton
              title={'View Details'}
              btnType={'btn7'}
              width={'100%'}
              onSubmit={() => onOrderDetail(item, 'view')}
            />
          </Box>}
        {props?.tab == 'unshipped' ?
          <>
            <Box className={styles.btnView} mt={1}>
              <FormButton
                title={'Pickup Schedule'}
                btnType={'btn10'}
                width={'100%'}
                onSubmit={() => onOrderDetail(item, 'pickup')}
              />
            </Box>
            {item?.sgs_preshipment_inspection == 1 ?
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  title={'P.S.I Schedule'}
                  btnType={'btn9'}
                  width={'100%'}
                  onSubmit={() => onOrderDetail(item, 'PSI')}
                />
              </Box>
              : null}
            {confirmShipment ?
              <Box className={styles.btnView} mt={1}>
                <FormButton
                  loading={props?.orderBtnLoading}
                  title={'Confirm Shipment'}
                  btnType={'btn8'}
                  width={'100%'}
                  onSubmit={() => onConfirmShipment(item)}
                />
              </Box>
              : null}
          </>
          : null}

        {props?.tab == 'shipped' && (item?.track_order) ?
          <Box className={styles.btnView} mt={1}>
            <FormButton
              title={'Track Order'}
              btnType={'btn11'}
              width={'100%'}
              onSubmit={() => onTrackOrder(item)}
            />
          </Box>
          : null}

        {(props?.tab == 'unshipped') || (props?.tab == 'shipped') || (props?.tab == 'delivered') ?
          <Box className={styles.btnView} mt={1}>
            <FormSelectBox
              page={'OrderTabList'}
              placeholder={'Documents'}
              value={document}
              setValue={(val) => onDocument(val)}
              placeholderColor={'#000'}
              placeholderColorOpacity={'1'}
              textInputViewStyle={{
                backgroundColor: THEME_COLOR9,
                borderRadius: 1
              }}
              selectedItemViewStyle={{
                backgroundColor: THEME_COLOR9,
                fontFamily: 'InterBold'
              }}
              textInputValue={{
                color: '#000',
                fontSize: 13,
                px: 1.5,
                fontFamily: 'InterBold'
              }}
              data={item?.document_list}
            />
          </Box>
          : null}
      </TableCell>

    </>
  )
};

const mapStateToProps = (state) => {
  return {
    orderBtnLoading: state.userData.orderBtnLoading,
  };
};

export default connect(mapStateToProps, null)(OrderTabList);

