import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

import FormButton from "./FormButton";
import FormMultiSelectBox from "./FormMultiSelectBox";

import {connect} from "react-redux";
import {listCategory, addSellerProductCategory, updateApiStatus} from "../redux/actions/userDataActions";

import './../assets/css/custom.css';
import {THEME_COLOR2} from "../constants";

const AddProductDetails = (props) => {
  const [categoryList, setCategoryList] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if(props?.type == 'edit') {

    } else {
      props.listCategory();
    }
  }, []);

  useEffect(() => {
    if (props.apiStatus == "ADD_SELLER_PRODUCT_CATEGORY_SUCCESS") {
      props.updateApiStatus("");
      // navigate('/');
      if (props?.showTgsin) {
      } else {
        navigate('/')
      }
    }
  }, [props.apiStatus]);

  useEffect(() => {
    if (props?.data != null) {
      if (props.data?.categoryList?.length > 0) {
        setCategoryList(props.data?.categoryList);
      }
    }
  }, [props.data]);

  const onLaunchClick = () => {
    let errorText = {};
    if (categoryList.length == 0) {
      errorText = {categoryList: 'Please choose category'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      categoryList: JSON.stringify(categoryList),
    };
    props.addSellerProductCategory(JSON.stringify(data))
  };

  const onDashboardClick = () => {
    navigate('/');
  }

  return (
    <Grid item container xs={12} md={12}
          alignItems={props?.showTgsin ? "unset" : "flex-start"}
          flexDirection={props?.showTgsin ? 'column' : 'unset'}
          justifyContent={props?.showTgsin ? "center" : "space-between"}>

      <Grid item md={12} xs={12} mt={3}>
        <FormMultiSelectBox
          page={'AddProductsDetails'}
          multiple={true}
          value={categoryList}
          setValue={setCategoryList}
          loading={props.listLoading}
          data={props?.categoryList ? props?.categoryList : []}
          label={"Select Product Category"}
          error={error?.categoryList}
          textInputViewStyle={styles.textInputViewStyle}
          selectedItemViewStyle={styles.selectedItemViewStyle}
          required={true}
        />
      </Grid>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="center" mt={4}>
        <Box>
          <FormButton
            title={props?.userStatus == 'pending' ? 'Launch your business' : 'Submit'}
            onSubmit={onLaunchClick}
          />
        </Box>
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    categoryList: state.userData.categoryList,
    listLoading: state.userData.listLoading,
    apiStatus: state.userData.apiStatus,
    tgsin: state.userData.tgsin,
    showTgsin: state.userData.showTgsin,
    userStatus: state.userData.userStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listCategory: data => dispatch(listCategory(data)),
    addSellerProductCategory: data => dispatch(addSellerProductCategory(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProductDetails);

const styles = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  selectedCategoryView: {
    border: '1px solid #ddd'
  },
  sellerTitleText: {
    color: THEME_COLOR2,
    fontFamily: 'InterBold',
    fontSize: 20
  },
  borderBottomView: {
    border: '1px solid #ccc'
  },
  congratulationTitleText: {
    color: THEME_COLOR2,
    fontFamily: 'InterBold',
    fontSize: 17
  },
  tgsinTitleText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  btnStyleView: {
    backgroundColor: THEME_COLOR2,
    color: '#fff',
    fontSize: 14,
    width: '100%',
    height: 40,
  }
};

