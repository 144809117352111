import {v4 as uuidv4} from "uuid";

const initialState = {
  userName: '',
  userToken: '',
  userContactName: '',
  userEmail: '',
  userCountry: '',
  userStatus: 'pending',
  loginLoading: false,
  getRegisterDataList: null,
  registerLoading: false,
  tgsin: '',
  showTgsin: false,
  addProductDataList: null,
  productDetail: null,
  productLoading: false,
  productActionLoading: false,
  productList: [],
  categoryList: null,
  sellerCategoryList: [],
  productTotalPages: 0,
  productTotalCount: 0,
  sellerDetail: null,
  sellerLoading: false,
  statesList: [],
  citiesList: [],
  stateLoading: false,
  cityLoading: false,
  cartProduct: '',
  order: [],
  orderTotalPages: 0,
  orderDetail: null,
  dashboardDetail: null,
  pendingOrderCount: 0,
  unshippedOrderCount: 0,
  shippedOrderCount: 0,
  cancelledOrderCount: 0,
  deliveredOrderCount: 0,
  productMessage: null,
  orderMessage: null,
  orderBtnLoading: false,
  listLoading: false,
  subLoading: false,
  btnLoading: false,
  msg: {open: false, message: '', msgType: ''},
  apiStatus: '',
};

const userDataReducer = (state = initialState, action) => {

  switch (action.type) {

    //login
    case 'LOGIN_USER':
      return {
        ...state,
        loginLoading: true,
      };

    case 'LOGIN_USER_SUCCESS':
      return {
        ...state,
        userName: action.payload.userName,
        userToken: action.payload.userToken,
        userEmail: action.payload.userData.userEmail,
        userContactName: action.payload.userData.userContactName,
        userCountry: action.payload.userData.userCountry,
        userStatus: action.payload.userData.userStatus,
        tgsin: action.payload.userData.tgsin,
        sellerUid: action.payload.userData?.sellerUid,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        loginLoading: false,
      };

    case 'LOGIN_USER_FAIL':
      return {
        ...state,
        loginLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'FORGOT_USER_NAME':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'FORGOT_USER_NAME'
      };

    case 'FORGOT_USER_NAME_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        apiStatus: 'FORGOT_USER_NAME_SUCCESS'
      };

    case 'FORGOT_USER_NAME_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'FORGOT_USER_NAME_FAIL'
      };

    case 'FORGOT_USER_PASSWORD':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'FORGOT_USER_PASSWORD'
      };

    case 'FORGOT_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        apiStatus: 'FORGOT_USER_PASSWORD_SUCCESS'
      };

    case 'FORGOT_USER_PASSWORD_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'FORGOT_USER_PASSWORD_FAIL'
      };

    case 'UPDATE_FORGOT_USER_PASSWORD':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPDATE_FORGOT_USER_PASSWORD'
      };

    case 'UPDATE_FORGOT_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        btnLoading: false,
        apiStatus: 'UPDATE_FORGOT_USER_PASSWORD_SUCCESS'
      };

    case 'UPDATE_FORGOT_USER_PASSWORD_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'UPDATE_FORGOT_USER_PASSWORD_FAIL'
      };

    case 'GET_FORGOT_USER_PASSWORD':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'GET_FORGOT_USER_PASSWORD'
      };

    case 'GET_FORGOT_USER_PASSWORD_SUCCESS':
      return {
        ...state,
        listLoading: false,
        apiStatus: 'GET_FORGOT_USER_PASSWORD_SUCCESS'
      };

    case 'GET_FORGOT_USER_PASSWORD_FAIL':
      return {
        ...state,
        listLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_FORGOT_USER_PASSWORD_FAIL'
      };

    //register
    case 'REGISTER_USER':
      return {
        ...state,
        registerLoading: true,
        apiStatus: 'REGISTER_USER',
      };

    case 'REGISTER_USER_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        userName: action.payload.data.userName,
        userToken: action.payload.data.userToken,
        userStatus: action.payload.data.userStatus,
        registerLoading: false,
        apiStatus: 'REGISTER_USER_SUCCESS',
      };

    case 'REGISTER_USER_FAIL':
      return {
        ...state,
        registerLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'REGISTER_USER_FAIL',
      };

    case 'GET_REGISTER_DATA':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_REGISTER_DATA_SUCCESS':
      return {
        ...state,
        getRegisterDataList: action.payload.data,
        listLoading: false,
      };

    case 'GET_REGISTER_DATA_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    //product
    case 'ADD_PRODUCT_DATA':
      return {
        ...state,
        listLoading: true,
      };

    case 'ADD_PRODUCT_DATA_SUCCESS':
      return {
        ...state,
        addProductDataList: action.payload.data,
        listLoading: false,
      };

    case 'ADD_PRODUCT_DATA_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'ADD_PRODUCT_VARIETY':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'ADD_PRODUCT_VARIETY',
      };

    case 'ADD_PRODUCT_VARIETY_SUCCESS':
      let add_product_variety_data = {...state.productDetail}

      add_product_variety_data = {
        ...add_product_variety_data,
        productVarietyDetail: action.payload.data,
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: add_product_variety_data,
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_VARIETY_SUCCESS',
      };

    case 'ADD_PRODUCT_VARIETY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_VARIETY_FAIL',
      };

    case 'ADD_PRODUCT_IMAGE':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'ADD_PRODUCT_IMAGE',
      };

    case 'ADD_PRODUCT_IMAGE_SUCCESS':
      let add_product_image_data = {...state.productDetail}

      add_product_image_data = {
        ...add_product_image_data,
        productImageDetail: action.payload.data,
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: add_product_image_data,
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_IMAGE_SUCCESS',
      };

    case 'ADD_PRODUCT_IMAGE_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_IMAGE_FAIL',
      };

    case 'ADD_PRODUCT_PACKING_PRICE':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'ADD_PRODUCT_PACKING_PRICE',
      };

    case 'ADD_PRODUCT_PACKING_PRICE_SUCCESS':
      let add_product_packing_price_data = {...state.productDetail}

      add_product_packing_price_data = {
        ...add_product_packing_price_data,
        productPackingPriceDetail: action.payload.data,
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: add_product_packing_price_data,
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_PACKING_PRICE_SUCCESS',
      };

    case 'ADD_PRODUCT_PACKING_PRICE_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_PACKING_PRICE_FAIL',
      };

    case 'ADD_PRODUCT_SHIPPING':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'ADD_PRODUCT_SHIPPING',
      };

    case 'ADD_PRODUCT_SHIPPING_SUCCESS':
      let add_product_shipping_data = {...state.productDetail}

      add_product_shipping_data = {
        ...add_product_shipping_data,
        productShippingDetail: action.payload.data,
      };

      let productMessage = {
        type: 'product_added',
        sku: action.payload.data?.sku ? action.payload.data?.sku : ''
      };

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: add_product_shipping_data,
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_SHIPPING_SUCCESS',
        productMessage: productMessage
      };

    case 'ADD_PRODUCT_SHIPPING_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'ADD_PRODUCT_SHIPPING_FAIL',
      };

    case 'UPDATE_PRODUCT_VARIETY':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'UPDATE_PRODUCT_VARIETY',
      };

    case 'UPDATE_PRODUCT_VARIETY_SUCCESS':
      let update_product_variety_data = {...state.productDetail}

      update_product_variety_data = {
        ...update_product_variety_data,
        productVarietyDetail: action.payload.data,
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: update_product_variety_data,
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_VARIETY_SUCCESS',
      };

    case 'UPDATE_PRODUCT_VARIETY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_VARIETY_FAIL',
      };

    case 'UPDATE_PRODUCT_IMAGE':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'UPDATE_PRODUCT_IMAGE',
      };

    case 'UPDATE_PRODUCT_IMAGE_SUCCESS':
      let update_product_image_data = {...state.productDetail}

      // update_product_image_data = {
      //   ...update_product_image_data,
      //   productImageDetail: action.payload.data,
      // }

      let detail = state?.productDetail?.productImageDetail
      if (detail != null) {
        let detail1 = {...detail}
        detail1 = {
          ...detail1,
          ...action.payload.data,
        }
        update_product_image_data = {
          ...update_product_image_data,
          productImageDetail: detail1,
        }
      } else {
        update_product_image_data = {
          ...update_product_image_data,
          productImageDetail: action.payload.data,
        }
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: update_product_image_data,
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_IMAGE_SUCCESS',
      };

    case 'UPDATE_PRODUCT_IMAGE_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_IMAGE_FAIL',
      };

    case 'UPDATE_PRODUCT_PACKING_PRICE':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'UPDATE_PRODUCT_PACKING_PRICE',
      };

    case 'UPDATE_PRODUCT_PACKING_PRICE_SUCCESS':
      let update_product_packing_price_data = {...state.productDetail}

      update_product_packing_price_data = {
        ...update_product_packing_price_data,
        productPackingPriceDetail: action.payload.data,
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: update_product_packing_price_data,
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_PACKING_PRICE_SUCCESS',
      };

    case 'UPDATE_PRODUCT_PACKING_PRICE_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_PACKING_PRICE_FAIL',
      };

    case 'UPDATE_PRODUCT_SHIPPING':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'UPDATE_PRODUCT_SHIPPING',
      };

    case 'UPDATE_PRODUCT_SHIPPING_SUCCESS':
      let update_product_shipping_data = {...state.productDetail}

      update_product_shipping_data = {
        ...update_product_shipping_data,
        productShippingDetail: action.payload.data,
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: update_product_shipping_data,
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_SHIPPING_SUCCESS',
      };

    case 'UPDATE_PRODUCT_SHIPPING_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_SHIPPING_FAIL',
      };

    case 'GET_PRODUCT_DETAIL':
      return {
        ...state,
        listLoading: true,
        subLoading: false,
        productLoading: false,
        apiStatus: 'GET_PRODUCT_DETAIL'
      };

    case 'GET_PRODUCT_DETAIL_SUCCESS':
      return {
        ...state,
        productDetail: action.payload.data,
        listLoading: false,
        apiStatus: 'GET_PRODUCT_DETAIL_SUCCESS'
      };

    case 'GET_PRODUCT_DETAIL_FAIL':
      return {
        ...state,
        listLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_PRODUCT_DETAIL_FAIL'
      };

    case 'UPDATE_PRODUCT_STATUS':
      return {
        ...state,
        productActionLoading: true,
        apiStatus: 'UPDATE_PRODUCT_STATUS'
      };

    case 'UPDATE_PRODUCT_STATUS_SUCCESS':
      let product_data = [...state.productList];
      let productDataIndex = product_data.findIndex((x) => (x.uid == action.payload.productUid));
      let product_total_count = state?.productTotalCount
      if (productDataIndex >= 0) {
        if (action.payload.updatedStatus == 'removed') {
          product_data.splice(productDataIndex, 1);
          product_total_count = (product_total_count > 0) ? parseInt(product_total_count) - 1 : 0
        } else {
          if (action.payload?.tabStatus == 'inactive' || action.payload?.tabStatus == 'active') {
            product_data.splice(productDataIndex, 1);
            product_total_count = (product_total_count > 0) ? parseInt(product_total_count) - 1 : 0
          } else if (action.payload?.tabStatus == 'all') {
            product_data[productDataIndex].status = action.payload.updatedStatus == 'approved' ? 'active' : action.payload.updatedStatus;
            product_data[productDataIndex].timestamp = action.payload.timestamp;
          }
        }
      }
      let product_detail_data = {...state.productDetail};
      product_detail_data = {
        ...product_detail_data,
        productStatus: action.payload.updatedStatus == 'approved' ? 'active' : action.payload.updatedStatus,
        timestamp: action.payload.timestamp,
      }
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productActionLoading: false,
        productList: product_data,
        productDetail: product_detail_data,
        productTotalCount: product_total_count,
        apiStatus: 'UPDATE_PRODUCT_STATUS_SUCCESS'
      };

    case 'UPDATE_PRODUCT_STATUS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        productActionLoading: false,
        apiStatus: 'UPDATE_PRODUCT_STATUS_FAIL'
      };

    case 'UPDATE_PRODUCT_PRICE_PER_PACKAGE':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'UPDATE_PRODUCT_PRICE_PER_PACKAGE',
      };

    case 'UPDATE_PRODUCT_PRICE_PER_PACKAGE_SUCCESS':
      let update_product_price_per_package = [...state.productList];
      let update_product_price_per_package_index = update_product_price_per_package.findIndex((x) => (x.uid == action.payload.data.productUid));

      if (update_product_price_per_package_index >= 0) {
        update_product_price_per_package[update_product_price_per_package_index]['pricePerPackage'] = action.payload.data.pricePerPackage
      }

      let update_product_detail_price_per_package = {...state.productDetail}

      update_product_detail_price_per_package = {
        ...update_product_detail_price_per_package,
        productPackingPriceDetail: {
          ...update_product_detail_price_per_package?.productPackingPriceDetail,
          ...action.payload.data,
        },
      };

      let productPriceMessage = {
        type: 'product_price_updated',
        sku: action.payload?.sku ? action.payload?.sku : '',
      };

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: update_product_detail_price_per_package,
        productList: update_product_price_per_package,
        listLoading: false,
        productMessage: productPriceMessage,
        apiStatus: 'UPDATE_PRODUCT_PRICE_PER_PACKAGE_SUCCESS',
      };

    case 'UPDATE_PRODUCT_PRICE_PER_PACKAGE_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'UPDATE_PRODUCT_PRICE_PER_PACKAGE_FAIL',
      };

    case 'LIST_PRODUCT':
      return {
        ...state,
        productLoading: true,
      };

    case 'LIST_PRODUCT_SUCCESS':
      return {
        ...state,
        productList: action.payload.data,
        productTotalPages: action.payload.total_pages,
        productTotalCount: action.payload.total_count,
        addProductDataList: null,
        productDetail: null,
        productLoading: false,
        productMessage: null
      };

    case 'LIST_PRODUCT_FAIL':
      return {
        ...state,
        addProductDataList: null,
        productDetail: null,
        productLoading: false,
      };

    case 'DELETE_PRODUCT':
      return {
        ...state,
        productLoading: true,
        apiStatus: 'DELETE_PRODUCT',
      };

    case 'DELETE_PRODUCT_SUCCESS':
      let data = [...state.productList];
      let productIndex = data.findIndex((x) => (x.uid == action.payload.productUid));
      if (productIndex >= 0) {
        data.splice(productIndex, 1);
      }
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productLoading: false,
        productList: data,
        apiStatus: 'DELETE_PRODUCT_SUCCESS',
      };

    case 'DELETE_PRODUCT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        productLoading: false,
        apiStatus: 'DELETE_PRODUCT_FAIL',
      };


    case 'UPDATE_MAIN_PRODUCT_IMAGE':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'UPDATE_MAIN_PRODUCT_IMAGE'
      };

    case 'UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS':
      let product_detail_main_img_data = {...state.productDetail};

      product_detail_main_img_data = {
        ...product_detail_main_img_data,
        mainImageList: action.payload.data,
      };
      return {
        ...state,
        btnLoading: false,
        productDetail: product_detail_main_img_data,
        apiStatus: 'UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS',
        msg: {open: true, message: action.payload.msg, msgType: 'success'}
      };

    case 'UPDATE_MAIN_PRODUCT_IMAGE_FAIL':
      return {
        ...state,
        btnLoading: false,
        apiStatus: 'UPDATE_MAIN_PRODUCT_IMAGE_FAIL',
        msg: {open: true, message: action.payload.msg, msgType: 'error'}
      };

    case 'DELETE_PRODUCT_IMAGE':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'DELETE_PRODUCT_IMAGE'
      };

    case 'DELETE_PRODUCT_IMAGE_SUCCESS':
      let product_detail_img_data = {...state.productDetail};

      let img_list = [...product_detail_img_data?.imageList];
      let img_index = img_list.findIndex((x) => x.id == action.payload.data?.productImageId);
      if (img_index >= 0) {
        img_list.splice(img_index, 1)
      }
      product_detail_img_data = {
        ...product_detail_img_data,
        imageList: img_list,
      }
      return {
        ...state,
        btnLoading: false,
        productDetail: product_detail_img_data,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'DELETE_PRODUCT_IMAGE_SUCCESS'
      };

    case 'DELETE_PRODUCT_IMAGE_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'DELETE_PRODUCT_IMAGE_FAIL'
      };

    case 'ADD_PRODUCT_DOCUMENT':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'ADD_PRODUCT_DOCUMENT'
      };

    case 'ADD_PRODUCT_DOCUMENT_SUCCESS':
      let product_doc_detail = {...state.productDetail};
      product_doc_detail = {
        ...product_doc_detail,
        productDocumentList: [...product_doc_detail?.productDocumentList, action.payload.data]
      }
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        productDetail: product_doc_detail,
        apiStatus: 'ADD_PRODUCT_DOCUMENT_SUCCESS'
      };

    case 'ADD_PRODUCT_DOCUMENT_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'ADD_PRODUCT_DOCUMENT_FAIL'
      };

    case 'DELETE_PRODUCT_DOCUMENT':
      return {
        ...state,
        btnLoading: true,
        apiStatus: 'DELETE_PRODUCT_DOCUMENT'
      };

    case 'DELETE_PRODUCT_DOCUMENT_SUCCESS':
      let product_delete_doc_detail = {...state.productDetail};
      let product_delete_doc_list = [...product_delete_doc_detail?.productDocumentList]
      let productDocIndex = product_delete_doc_list.findIndex((x) => x.documentUid == action.payload?.data?.documentUid);
      if (productDocIndex >= 0) {
        product_delete_doc_list.splice(productDocIndex, 1)
      }
      product_delete_doc_detail = {
        ...product_delete_doc_detail,
        productDocumentList: product_delete_doc_list
      }
      return {
        ...state,
        btnLoading: false,
        productDetail: product_delete_doc_detail,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        apiStatus: 'DELETE_PRODUCT_DOCUMENT_SUCCESS'
      };

    case 'DELETE_PRODUCT_DOCUMENT_FAIL':
      return {
        ...state,
        btnLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'DELETE_PRODUCT_DOCUMENT_FAIL'
      };

    case 'GET_SELLER_CATEGORY_LIST':
      return {
        ...state,
        listLoading: true,
      };

    case 'GET_SELLER_CATEGORY_LIST_SUCCESS':
      return {
        ...state,
        listLoading: false,
        sellerCategoryList: action.payload.data,
      };

    case 'GET_SELLER_CATEGORY_LIST_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'LIST_CATEGORY':
      return {
        ...state,
        listLoading: true,
      };

    case 'LIST_CATEGORY_SUCCESS':
      return {
        ...state,
        listLoading: false,
        categoryList: action.payload.data,
      };

    case 'LIST_CATEGORY_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    //my account
    case 'GET_SELLER_DETAIL':
      return {
        ...state,
        sellerDetail: null,
        listLoading: true,
      };

    case 'GET_SELLER_DETAIL_SUCCESS':
      return {
        ...state,
        sellerDetail: action.payload.data,
        userStatus: action.payload.data?.sellerStatus,
        listLoading: false,
      };

    case 'GET_SELLER_DETAIL_FAIL':
      return {
        ...state,
        sellerDetail: null,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
      };

    case 'ADD_SELLER_CONTACT_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_CONTACT_DETAILS',
      };

    case 'ADD_SELLER_CONTACT_DETAILS_SUCCESS':
      let add_seller_contact_data = {...state.sellerDetail}
      let add_contact_data = add_seller_contact_data?.contactDetails;

      add_contact_data['sellerId'] = action.payload.data?.sellerId;
      add_contact_data['firstName'] = action.payload.contact_data.first_name;
      add_contact_data['lastName'] = action.payload.contact_data.last_name;
      add_contact_data['designation'] = action.payload.contact_data.designation;
      add_contact_data['businessAddress'] = action.payload.contact_data.business_address;
      add_contact_data['city'] = action.payload.contact_data.city;
      add_contact_data['stateProvince'] = action.payload.contact_data.state_province;
      add_contact_data['zipCode'] = action.payload.contact_data.zip_code;
      add_contact_data['email'] = action.payload.contact_data.email;
      add_contact_data['mobileNo'] = action.payload.contact_data.mobile_no;
      // add_contact_data['mobileCountry'] = action.payload.contact_data.mobile_no_country;
      // add_contact_data['mobileCode'] = action.payload.contact_data.mobile_no_code;
      add_contact_data['mobileNoCountry'] = action.payload.contact_data.mobile_no_country;
      add_contact_data['mobileNoCode'] = action.payload.contact_data.mobile_no_code;
      add_contact_data['whatsAppNo'] = action.payload.contact_data.whatsapp_no;
      add_contact_data['whatsAppNoCountry'] = action.payload.contact_data.whatsapp_no_country;
      add_contact_data['whatsAppNoCode'] = action.payload.contact_data.whatsapp_no_code;
      // add_contact_data['whatsAppCountry'] = action.payload.contact_data.whatsapp_no_country;
      // add_contact_data['whatsAppCode'] = action.payload.contact_data.whatsapp_no_code;

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: add_seller_contact_data,
        userContactName: action.payload.contact_data.first_name + ' ' + action.payload.contact_data.last_name,
        userEmail: action.payload.contact_data.email,
        apiStatus: 'ADD_SELLER_CONTACT_DETAILS_SUCCESS',
      };

    case 'ADD_SELLER_CONTACT_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_CONTACT_DETAILS_FAIL',
      };

    case 'UPDATE_SELLER_CONTACT_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'UPDATE_SELLER_CONTACT_DETAILS',
      };

    case 'UPDATE_SELLER_CONTACT_DETAILS_SUCCESS':
      let seller_contact_data = {...state.sellerDetail}
      let contact_data = seller_contact_data?.contactDetails;

      contact_data['sellerId'] = action.payload.data?.sellerId;
      contact_data['firstName'] = action.payload.contact_data.first_name;
      contact_data['lastName'] = action.payload.contact_data.last_name;
      contact_data['designation'] = action.payload.contact_data.designation;
      contact_data['businessAddress'] = action.payload.contact_data.business_address;
      contact_data['city'] = action.payload.contact_data.city;
      contact_data['stateProvince'] = action.payload.contact_data.state_province;
      contact_data['zipCode'] = action.payload.contact_data.zip_code;
      contact_data['email'] = action.payload.contact_data.email;
      // contact_data['mobileNo'] = action.payload.contact_data.mobile_no;
      // contact_data['mobileCountry'] = action.payload.contact_data.mobile_no_country;
      // contact_data['mobileCode'] = action.payload.contact_data.mobile_no_code;
      // contact_data['whatsAppNo'] = action.payload.contact_data.whatsapp_no;
      // contact_data['whatsAppCountry'] = action.payload.contact_data.whatsapp_no_country;
      // contact_data['whatsAppCode'] = action.payload.contact_data.whatsapp_no_code;
      contact_data['mobileNo'] = action.payload.contact_data.mobile_no;
      contact_data['mobileNoCountry'] = action.payload.contact_data.mobile_no_country;
      contact_data['mobileNoCode'] = action.payload.contact_data.mobile_no_code;
      contact_data['whatsAppNo'] = action.payload.contact_data.whatsapp_no;
      contact_data['whatsAppNoCountry'] = action.payload.contact_data.whatsapp_no_country;
      contact_data['whatsAppNoCode'] = action.payload.contact_data.whatsapp_no_code;

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: seller_contact_data,
        userContactName: action.payload.contact_data.first_name + ' ' + action.payload.contact_data.last_name,
        userEmail: action.payload.contact_data.email,
        apiStatus: 'UPDATE_SELLER_CONTACT_DETAILS_SUCCESS',
      };

    case 'UPDATE_SELLER_CONTACT_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'UPDATE_SELLER_CONTACT_DETAILS_FAIL',
      };

    case 'ADD_SELLER_EXPORT_LICENSE_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_EXPORT_LICENSE_DETAILS',
      };

    case 'ADD_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS':
      let add_seller_export_license_data = {...state.sellerDetail}
      let add_export_license_data = add_seller_export_license_data?.exportLicenseDetails;

      add_export_license_data['sellerId'] = action.payload.data.sellerId;
      add_export_license_data['exporterName'] = action.payload.data.exporterName;
      add_export_license_data['exportLicenseNo'] = action.payload.data.exportLicenseNo;
      if (action.payload.data?.displayExportLicenseDocument) {
        add_export_license_data['displayExportLicenseDocument'] = action.payload.data.displayExportLicenseDocument;
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerDetail: add_seller_export_license_data,
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS',
      };

    case 'ADD_SELLER_EXPORT_LICENSE_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_EXPORT_LICENSE_DETAILS_FAIL',
      };

    case 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS',
      };

    case 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS':
      let update_seller_export_license_data = {...state.sellerDetail}
      let update_export_license_data = update_seller_export_license_data?.exportLicenseDetails;

      update_export_license_data['sellerId'] = action.payload.data.sellerId;
      update_export_license_data['exporterName'] = action.payload.data.exporterName;
      update_export_license_data['exportLicenseNo'] = action.payload.data.exportLicenseNo;
      if (action.payload.data?.displayExportLicenseDocument) {
        update_export_license_data['displayExportLicenseDocument'] = action.payload.data.displayExportLicenseDocument;
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: update_seller_export_license_data,
        apiStatus: 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS',
      };

    case 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS_FAIL',
      };

    case 'ADD_SELLER_EXPORT_RCMC_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_EXPORT_RCMC_DETAILS',
      };

    case 'ADD_SELLER_EXPORT_RCMC_DETAILS_SUCCESS':
      let add_seller_export_rcmc_data = {...state.sellerDetail}
      let rcmc_list = [...add_seller_export_rcmc_data?.exportRcmcDetails?.rcmcDetails];


      if (action.payload.data?.displayRcmcRegistrationDocument) {
        rcmc_list = [...rcmc_list, {
          rcmcUid: action.payload.data.rcmcUid,
          boardName: action.payload.data.boardName,
          rcmcNo: action.payload.data.rcmcNo,
          displayRcmcRegistrationDocument: action.payload.data.displayRcmcRegistrationDocument
        }]
      } else {
        rcmc_list = [...rcmc_list, {
          rcmcUid: action.payload.data.rcmcUid,
          boardName: action.payload.data.boardName,
          rcmcNo: action.payload.data.rcmcNo,
        }]
      }

      add_seller_export_rcmc_data['exportRcmcDetails']['rcmcDetails'] = rcmc_list;

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: add_seller_export_rcmc_data,
        apiStatus: 'ADD_SELLER_EXPORT_RCMC_DETAILS_SUCCESS',
      };

    case 'ADD_SELLER_EXPORT_RCMC_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_EXPORT_RCMC_DETAILS_FAIL',
      };

    case 'UPDATE_SELLER_EXPORT_RCMC_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'UPDATE_SELLER_EXPORT_RCMC_DETAILS',
      };

    case 'UPDATE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS':
      let update_seller_export_rcmc_data = {...state.sellerDetail}
      let rcmc_update_list = [...update_seller_export_rcmc_data?.exportRcmcDetails?.rcmcDetails];

      let rcmc_update_index = rcmc_update_list.findIndex((x) => x.rcmcUid == action.payload.data?.rcmcUid);
      if(rcmc_update_index >= 0) {
        if (action.payload.data?.displayRcmcRegistrationDocument) {
          rcmc_update_list[rcmc_update_index]['rcmcUid'] = action.payload.data?.rcmcUid;
          rcmc_update_list[rcmc_update_index]['boardName'] = action.payload.data?.boardName;
          rcmc_update_list[rcmc_update_index]['rcmcNo'] = action.payload.data?.rcmcNo;
          rcmc_update_list[rcmc_update_index]['displayRcmcRegistrationDocument'] = action.payload.data?.displayRcmcRegistrationDocument;
        } else {
          rcmc_update_list[rcmc_update_index]['rcmcUid'] = action.payload.data?.rcmcUid;
          rcmc_update_list[rcmc_update_index]['boardName'] = action.payload.data?.boardName;
          rcmc_update_list[rcmc_update_index]['rcmcNo'] = action.payload.data?.rcmcNo;
        }
        update_seller_export_rcmc_data['exportRcmcDetails']['rcmcDetails'] = rcmc_update_list;
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: update_seller_export_rcmc_data,
        apiStatus: 'UPDATE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS',
      };

    case 'UPDATE_SELLER_EXPORT_RCMC_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'UPDATE_SELLER_EXPORT_RCMC_DETAILS_FAIL',
      };

    case 'DELETE_SELLER_EXPORT_RCMC_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'DELETE_SELLER_EXPORT_RCMC_DETAILS',
      };

    case 'DELETE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS':
      let delete_seller_export_rcmc_data = {...state.sellerDetail}
      let rcmc_delete_list = [...delete_seller_export_rcmc_data?.exportRcmcDetails?.rcmcDetails];
      let rcmc_delete_index = rcmc_delete_list.findIndex((x) => x.rcmcUid == action.payload?.rcmcUid);

      if(rcmc_delete_index >= 0) {
        rcmc_delete_list.splice(rcmc_delete_index, 1)
        delete_seller_export_rcmc_data['exportRcmcDetails']['rcmcDetails'] = rcmc_delete_list;
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: delete_seller_export_rcmc_data,
        apiStatus: 'DELETE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS',
      };

    case 'DELETE_SELLER_EXPORT_RCMC_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'DELETE_SELLER_EXPORT_RCMC_DETAILS_FAIL',
      };

    case 'ADD_SELLER_EXPORT_FSSAI_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_EXPORT_FSSAI_DETAILS',
      };

    case 'ADD_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS':
      let add_seller_export_fssai_data = {...state.sellerDetail}
      let add_export_fssai_data = add_seller_export_fssai_data?.exportFssaiDetails;

      add_export_fssai_data['sellerId'] = action.payload.data.sellerId;
      add_export_fssai_data['fssaiRegistrationNo'] = action.payload.data.fssaiRegistrationNo;
      add_export_fssai_data['rcmcNo'] = action.payload.data.rcmcNo;
      if (action.payload.data?.displayFssaiRegistrationDocument) {
        add_export_fssai_data['displayFssaiRegistrationDocument'] = action.payload.data.displayFssaiRegistrationDocument;
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: add_seller_export_fssai_data,
        apiStatus: 'ADD_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS',
      };

    case 'ADD_SELLER_EXPORT_FSSAI_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_EXPORT_FSSAI_DETAILS_FAIL',
      };

    case 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS',
      };

    case 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS':
      let update_seller_export_fssai_data = {...state.sellerDetail}
      let update_export_fssai_data = update_seller_export_fssai_data?.exportFssaiDetails;

      update_export_fssai_data['sellerId'] = action.payload.data.sellerId;
      update_export_fssai_data['fssaiRegistrationNo'] = action.payload.data.fssaiRegistrationNo;
      update_export_fssai_data['rcmcNo'] = action.payload.data.rcmcNo;
      if (action.payload.data?.displayFssaiRegistrationDocument) {
        update_export_fssai_data['displayFssaiRegistrationDocument'] = action.payload.data.displayFssaiRegistrationDocument;
      }

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: update_seller_export_fssai_data,
        apiStatus: 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS',
      };

    case 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS_FAIL',
      };

    case 'ADD_SELLER_EXPORT_TAX_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_EXPORT_TAX_DETAILS',
      };

    case 'ADD_SELLER_EXPORT_TAX_DETAILS_SUCCESS':
      let add_seller_export_tax_data = {...state.sellerDetail}
      let add_export_tax_data = add_seller_export_tax_data?.exportTaxDetails;
      add_export_tax_data['sellerId'] = action.payload.data.sellerId;
      add_export_tax_data['businessName'] = action.payload.data.businessName;
      add_export_tax_data['taxRegistrationNo'] = action.payload.data.taxRegistrationNo;
      if (action.payload.data?.displayTaxRegistrationDocument) {
        add_export_tax_data['displayTaxRegistrationDocument'] = action.payload.data.displayTaxRegistrationDocument;
      }

      let add_tax_bank_data = add_seller_export_tax_data?.bankDetails;
      add_tax_bank_data['accountHolderName'] = action.payload.data.businessName;

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: add_seller_export_tax_data,
        apiStatus: 'ADD_SELLER_EXPORT_TAX_DETAILS_SUCCESS',
      };

    case 'ADD_SELLER_EXPORT_TAX_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_EXPORT_TAX_DETAILS_FAIL',
      };

    case 'UPDATE_SELLER_EXPORT_TAX_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'UPDATE_SELLER_EXPORT_TAX_DETAILS',
      };

    case 'UPDATE_SELLER_EXPORT_TAX_DETAILS_SUCCESS':
      let update_seller_export_tax_data = {...state.sellerDetail}
      let update_export_tax_data = update_seller_export_tax_data?.exportTaxDetails;

      update_export_tax_data['sellerId'] = action.payload.data.sellerId;
      update_export_tax_data['businessName'] = action.payload.data.businessName;
      update_export_tax_data['taxRegistrationNo'] = action.payload.data.taxRegistrationNo;
      if (action.payload.data?.displayTaxRegistrationDocument) {
        update_export_tax_data['displayTaxRegistrationDocument'] = action.payload.data.displayTaxRegistrationDocument;
      }

      let update_tax_bank_data = update_seller_export_tax_data?.bankDetails;
      update_tax_bank_data['accountHolderName'] = action.payload.data.businessName;

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: update_seller_export_tax_data,
        apiStatus: 'UPDATE_SELLER_EXPORT_TAX_DETAILS_SUCCESS',
      };

    case 'UPDATE_SELLER_EXPORT_TAX_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'UPDATE_SELLER_EXPORT_TAX_DETAILS_FAIL',
      };

    case 'ADD_SELLER_BANK_DETAILS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_BANK_DETAILS',
      };

    case 'ADD_SELLER_BANK_DETAILS_SUCCESS':
      let add_seller_bank_data = {...state.sellerDetail}
      let add_bank_data = add_seller_bank_data?.bankDetails;
      add_bank_data['sellerId'] = action.payload.data.sellerId;
      add_bank_data['accountHolderName'] = action.payload.data.accountHolderName;
      add_bank_data['bankName'] = action.payload.data.bankName;
      add_bank_data['accountNo'] = action.payload.data.accountNo;
      add_bank_data['swiftCode'] = action.payload.data.swiftCode;
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: add_seller_bank_data,
        apiStatus: 'ADD_SELLER_BANK_DETAILS_SUCCESS',
      };

    case 'ADD_SELLER_BANK_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_BANK_DETAILS_FAIL',
      };

    case 'UPDATE_SELLER_BANK_DETAILS':
      return {
        ...state,
        sellerLoading: true,
      };

    case 'UPDATE_SELLER_BANK_DETAILS_SUCCESS':
      let update_seller_bank_data = {...state.sellerDetail}
      let update_bank_data = update_seller_bank_data?.bankDetails;
      update_bank_data['sellerId'] = action.payload.data.sellerId;
      update_bank_data['accountHolderName'] = action.payload.data.accountHolderName;
      update_bank_data['bankName'] = action.payload.data.bankName;
      update_bank_data['accountNo'] = action.payload.data.accountNo;
      update_bank_data['swiftCode'] = action.payload.data.swiftCode;
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: update_seller_bank_data
      };

    case 'UPDATE_SELLER_BANK_DETAILS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
      };

    case 'ADD_SELLER_DOCUMENTS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_DOCUMENTS',
      };

    case 'ADD_SELLER_DOCUMENTS_SUCCESS':
      let add_seller_document_data = {...state.sellerDetail}
      let add_document_data = add_seller_document_data?.documentDetails;
      add_document_data['sellerId'] = action.payload.data.sellerId;
      add_document_data['displaySignatureImage'] = action.payload.data.displaySignatureImage;
      add_document_data['displayBusinessLogoImage'] = action.payload.data.displayBusinessLogoImage;
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: add_seller_document_data,
        apiStatus: 'ADD_SELLER_DOCUMENTS_SUCCESS',
      };

    case 'ADD_SELLER_DOCUMENTS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_DOCUMENTS_FAIL',
      };

    case 'UPDATE_SELLER_DOCUMENTS':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'UPDATE_SELLER_DOCUMENTS',
      };

    case 'UPDATE_SELLER_DOCUMENTS_SUCCESS':
      let update_seller_document_data = {...state.sellerDetail}
      let update_document_data = update_seller_document_data?.documentDetails;
      update_document_data['sellerId'] = action.payload.data.sellerId;
      update_document_data['displaySignatureImage'] = action.payload.data.displaySignatureImage;
      update_document_data['displayBusinessLogoImage'] = action.payload.data.displayBusinessLogoImage;
      return {
        ...state,
        sellerLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerDetail: update_seller_document_data,
        apiStatus: 'UPDATE_SELLER_DOCUMENTS_SUCCESS',
      };

    case 'UPDATE_SELLER_DOCUMENTS_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'UPDATE_SELLER_DOCUMENTS_FAIL',
      };

    case 'ADD_SELLER_PRODUCT_CATEGORY':
      return {
        ...state,
        sellerLoading: true,
        apiStatus: 'ADD_SELLER_PRODUCT_CATEGORY',
      };

    case 'ADD_SELLER_PRODUCT_CATEGORY_SUCCESS':
      let add_seller_product_category_data = {...state.sellerDetail}
      let add_product_category_data = add_seller_product_category_data?.productDetails;
      add_product_category_data['categoryList'] = action.payload.data.categoryList;
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        sellerDetail: add_seller_product_category_data,
        // tgsin: action.payload?.tgsin,
        // showTgsin: action.payload?.showTgsin,
        userStatus: action.payload?.data?.status,
        apiStatus: 'ADD_SELLER_PRODUCT_CATEGORY_SUCCESS',
      };

    case 'ADD_SELLER_PRODUCT_CATEGORY_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
        apiStatus: 'ADD_SELLER_PRODUCT_CATEGORY_FAIL',
      };

    // case 'CHECK_CART_PRODUCT':
    //   return {
    //     ...state,
    //     sellerLoading: true,
    //   };
    //
    // case 'CHECK_CART_PRODUCT_SUCCESS':
    //   return {
    //     ...state,
    //     cartProduct: action.payload.cart,
    //     sellerLoading: false,
    //   };
    //
    // case 'CHECK_CART_PRODUCT_FAIL':
    //   return {
    //     ...state,
    //     msg: {open: true, message: action.payload.msg, msgType: 'error'},
    //     sellerLoading: false,
    //   };

    case 'UPDATE_CART_PRODUCT':
      return {
        ...state,
        cartProduct: action.payload,
      };

    //orders
    case 'LIST_ORDER':
      return {
        ...state,
        orderLoading: true,
      };

    case 'LIST_ORDER_SUCCESS':
      let pending_order_count = state?.pendingOrderCount;
      let cancelled_order_count = state?.cancelledOrderCount;
      let auto_cancelled_order_count = action?.payload?.auto_cancelled_order_count;
      if (auto_cancelled_order_count > 0) {
        pending_order_count = pending_order_count > 0 ? (parseInt(pending_order_count) - parseInt(auto_cancelled_order_count)) : 0
        cancelled_order_count = parseInt(cancelled_order_count) + parseInt(auto_cancelled_order_count)
      }

      return {
        ...state,
        orderList: action.payload.data,
        orderTotalPages: action.payload?.total_pages,
        pendingOrderCount: pending_order_count,
        cancelledOrderCount: cancelled_order_count,
        orderLoading: false,
        orderMessage: null
      };

    case 'LIST_ORDER_FAIL':
      return {
        ...state,
        orderLoading: false,
      };

    case 'GET_ORDER_DETAIL':
      return {
        ...state,
        listLoading: true,
        apiStatus: 'GET_ORDER_DETAIL'
      };

    case 'GET_ORDER_DETAIL_SUCCESS':
      return {
        ...state,
        orderDetail: action.payload.data,
        listLoading: false,
        apiStatus: 'GET_ORDER_DETAIL_SUCCESS'
      };

    case 'GET_ORDER_DETAIL_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        listLoading: false,
        apiStatus: 'GET_ORDER_DETAIL_FAIL'
      };

    case 'CONFIRM_SHIPMENT':
      return {
        ...state,
        orderBtnLoading: true,
        apiStatus: 'CONFIRM_SHIPMENT'
      };

    case 'CONFIRM_SHIPMENT_SUCCESS':
      let order_list = [...state.orderList];
      let orderConfirmIndex = order_list.findIndex((x) => x.order_uid == action.payload?.data?.orderUid);
      if (orderConfirmIndex >= 0) {
        order_list.splice(orderConfirmIndex, 1)
      }

      let orderMessage = {
        type: 'order_shipped',
        orderUid: action.payload?.data?.orderUid
      };

      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        orderList: order_list,
        orderBtnLoading: false,
        unshippedOrderCount: state.unshippedOrderCount > 0 ? parseInt(state.unshippedOrderCount) - 1 : 0,
        shippedOrderCount: parseInt(state.shippedOrderCount) + 1,
        orderMessage: orderMessage,
        apiStatus: 'CONFIRM_SHIPMENT_SUCCESS'
      };

    case 'CONFIRM_SHIPMENT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        orderBtnLoading: false,
        apiStatus: 'CONFIRM_SHIPMENT_FAIL'
      };

    case 'GET_ORDER_COUNT':
      return {
        ...state,
        apiStatus: 'GET_ORDER_COUNT'
      };

    case 'GET_ORDER_COUNT_SUCCESS':
      return {
        ...state,
        apiStatus: 'GET_ORDER_COUNT_SUCCESS',
        pendingOrderCount: action.payload.data?.pending_order_count,
        unshippedOrderCount: action.payload.data?.unshipped_order_count,
        shippedOrderCount: action.payload.data?.shipped_order_count,
        deliveredOrderCount: action.payload.data?.delivered_order_count,
        cancelledOrderCount: action.payload.data?.cancelled_order_count,
      };

    case 'GET_ORDER_COUNT_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        apiStatus: 'GET_ORDER_COUNT_FAIL'
      };

    case 'GET_STATES':
      return {
        ...state,
        statesList: [],
        stateLoading: true,
      };

    case 'GET_STATES_SUCCESS':
      return {
        ...state,
        statesList: action.payload.data,
        stateLoading: false,
      };

    case 'GET_STATES_FAIL':
      return {
        ...state,
        stateLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'GET_CITIES':
      return {
        ...state,
        citiesList: [],
        cityLoading: true,
      };

    case 'GET_CITIES_SUCCESS':
      return {
        ...state,
        citiesList: action.payload.data,
        cityLoading: false,
      };

    case 'GET_CITIES_FAIL':
      return {
        ...state,
        cityLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'UPDATE_CITIES':
      return {
        ...state,
        citiesList: action.payload,
      };

    case 'GET_DASHBOARD':
      return {
        ...state,
        dashboardDetail: null,
        listLoading: true,
      };

    case 'GET_DASHBOARD_SUCCESS':
      return {
        ...state,
        dashboardDetail: action.payload.data,
        listLoading: false,
      };

    case 'GET_DASHBOARD_FAIL':
      return {
        ...state,
        listLoading: false,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
      };

    case 'GET_LOGIN_SELLER_DETAIL':
      return {
        ...state,
        listLoading: true
      };

    case 'GET_LOGIN_SELLER_DETAIL_SUCCESS':
      return {
        ...state,
        userStatus: action.payload.data?.sellerStatus,
        tgsin: action.payload.data?.tgsin,
        userNameEntity: action.payload.data?.nameEntity,
        userCountry: action.payload.data?.country,
        sellerUid: action.payload.data?.sellerUid,
        listLoading: false,
      };

    case 'GET_LOGIN_SELLER_DETAIL_FAIL':
      return {
        ...state,
        listLoading: false,
      };

    case 'UPDATE_SELLER_USERNAME':
      return {
        ...state,
        sellerLoading: true,
      };

    case 'UPDATE_SELLER_USERNAME_SUCCESS':
      let update_seller_login_data = {...state.sellerDetail}
      let update_login_data = update_seller_login_data?.loginDetails;
      update_login_data['userName'] = action.payload.userName;
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
        userName: action.payload.userName,
        sellerDetail: update_seller_login_data
      };

    case 'UPDATE_SELLER_USERNAME_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
      };

    case 'UPDATE_SELLER_PASSWORD':
      return {
        ...state,
        sellerLoading: true,
      };

    case 'UPDATE_SELLER_PASSWORD_SUCCESS':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'success'},
        sellerLoading: false,
      };

    case 'UPDATE_SELLER_PASSWORD_FAIL':
      return {
        ...state,
        msg: {open: true, message: action.payload.msg, msgType: 'error'},
        sellerLoading: false,
      };

    case 'UPDATE_PRODUCT_DETAIL':
      return {
        ...state,
        productDetail: action.payload,
      };

    case 'UPDATE_LIST_LOADING':
      return {
        ...state,
        listLoading: action.payload,
      };

    case 'ALERT_MSG_EMPTY':
      return {
        ...state,
        msg: {open: false, message: '', msgType: ''},
      };

    case 'UPDATE_SHOW_TGSIN':
      return {
        ...state,
        showTgsin: action.payload,
      };

    case 'UPDATE_ALERT_MSG':
      return {
        ...state,
        msg: {open: action.payload.open, message: action.payload.message, msgType: action.payload.msgType},
      };

    case 'UPDATE_API_STATUS':
      return {
        ...state,
        apiStatus: action.payload,
      };

    case 'LOG_OUT':
      return {
        ...state,
        userName: '',
        userToken: '',
        userContactName: '',
        userEmail: '',
        userStatus: 'pending',
        userCountry: '',
        loginLoading: false,
        getRegisterDataList: null,
        registerLoading: false,
        tgsin: '',
        showTgsin: false,
        addProductDataList: null,
        productDetail: null,
        productLoading: false,
        productActionLoading: false,
        productList: [],
        categoryList: null,
        sellerCategoryList: [],
        productTotalPages: 0,
        productTotalCount: 0,
        sellerDetail: null,
        sellerLoading: false,
        statesList: [],
        citiesList: [],
        stateLoading: false,
        cartProduct: '',
        order: [],
        orderTotalPages: 0,
        orderDetail: null,
        dashboardDetail: null,
        pendingOrderCount: 0,
        unshippedOrderCount: 0,
        shippedOrderCount: 0,
        deliveredOrderCount: 0,
        cancelledOrderCount: 0,
        productMessage: null,
        orderMessage: null,
        cityLoading: false,
        orderBtnLoading: false,
        listLoading: false,
        subLoading: false,
        btnLoading: false,
        msg: {open: false, message: '', msgType: ''},
        apiStatus: "",
      };
  }
  return state;
};

export default userDataReducer;
