import moment from "moment";
import getSymbolFromCurrency from 'currency-symbol-map'

export const createRandomString = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
};

export const getCurrencyConversionCalc = (data_list) => {
  let data = null;
  if (data_list?.price_per_package != '' && data_list?.package_net_weight != ''
    && data_list?.seller_currency != '' && data_list?.user_currency != '') {
    let price_val = (parseFloat((((data_list?.price_per_package / data_list?.package_net_weight) * data_list?.referral_fee) / 100) + (data_list?.price_per_package / data_list?.package_net_weight)) * data_list?.package_net_weight)
    let currency_val = data_list?.seller_currency;
    let main_currency_val = data_list?.seller_currency;
    let price_per_kg_without_referral_fee = parseFloat(data_list?.price_per_package / data_list?.package_net_weight);
    let price_per_kg_referral_fee = parseFloat((price_per_kg_without_referral_fee * data_list?.referral_fee) / 100);
    let price_per_package_without_referral_fee = data_list?.price_per_package;
    let price_per_package_referral_fee = price_per_kg_referral_fee * data_list?.package_net_weight;
    let seller_price = price_val;
    let seller_price_per_kg = price_val / data_list?.package_net_weight;
    let seller_total_price = data_list?.quantity * price_val;
    let seller_total_price_without_referral_fee = data_list?.quantity * price_per_package_without_referral_fee;
    let seller_shipping_cost = 0;
    let seller_shipping_rate = 0;
    let seller_cargo_price = seller_total_price;
    if (data_list?.shipping_cost != 0) {
      seller_shipping_rate = 1 / data_list?.admin_rate;
      seller_shipping_cost = seller_shipping_rate * data_list?.shipping_cost;
      seller_cargo_price = (parseFloat(seller_total_price) + parseFloat(seller_shipping_cost));
    }

    let converted_price = price_val;
    let converted_price_per_kg_without_referral_fee = price_per_kg_without_referral_fee;
    let converted_price_per_kg_referral_fee = price_per_kg_referral_fee;
    let converted_price_per_package_without_referral_fee = price_per_package_without_referral_fee;
    let converted_price_per_package_referral_fee = price_per_package_referral_fee;
    let converted_shipping_cost = seller_shipping_cost;
    let converted_price_per_kg = seller_price_per_kg;
    let converted_cargo_price = seller_cargo_price;
    let converted_total_price = seller_total_price;
    let converted_total_price_without_referral_fee = seller_total_price_without_referral_fee;

    if (data_list?.rate != 0 && data_list?.rate != '') {
      let usd_rate = data_list?.usd_rate ? data_list?.usd_rate : 0;
      let total = usd_rate * price_val;
      converted_price_per_kg_without_referral_fee = usd_rate * price_per_kg_without_referral_fee;
      converted_price_per_kg_referral_fee = usd_rate * price_per_kg_referral_fee;
      converted_price_per_package_without_referral_fee = usd_rate * price_per_package_without_referral_fee;
      converted_price_per_package_referral_fee = usd_rate * price_per_package_referral_fee;
      converted_total_price_without_referral_fee = usd_rate * seller_total_price_without_referral_fee;
      converted_price = total;
      let converted_shipping_rate = 0;
      if (data_list?.shipping_cost != 0) {
        converted_shipping_cost = usd_rate * seller_shipping_cost
      }
      main_currency_val = data_list?.main_currency;

      converted_price_per_kg = converted_price / data_list?.package_net_weight;
      converted_total_price = data_list?.quantity * converted_price;
      converted_cargo_price = converted_total_price;
      if (data_list?.shipping_cost != 0) {
        converted_cargo_price = (parseFloat(converted_total_price) + parseFloat(converted_shipping_cost));
      }
    }

    let seller_currency_symbol = getSymbolFromCurrency(currency_val)
    let main_currency_symbol = getSymbolFromCurrency(main_currency_val)

    data = {
      user_currency_symbol: main_currency_symbol,
      converted_price: converted_price,
      converted_price_per_kg: converted_price_per_kg,
      converted_total_price: converted_total_price,
      converted_shipping_cost: converted_shipping_cost,
      converted_cargo_price: converted_cargo_price,
      converted_price_per_kg_without_referral_fee: converted_price_per_kg_without_referral_fee,
      converted_price_per_kg_referral_fee: converted_price_per_kg_referral_fee,
      converted_price_per_package_without_referral_fee: converted_price_per_package_without_referral_fee,
      converted_price_per_package_referral_fee: converted_price_per_package_referral_fee,
      converted_total_price_without_referral_fee: converted_total_price_without_referral_fee,
      seller_currency_symbol: seller_currency_symbol,
      seller_price: seller_price,
      seller_price_per_kg: seller_price_per_kg,
      seller_total_price: seller_total_price,
      seller_shipping_cost: seller_shipping_cost,
      seller_cargo_price: seller_cargo_price,
      seller_price_per_kg_without_referral_fee: price_per_kg_without_referral_fee,
      seller_price_per_kg_referral_fee: price_per_kg_referral_fee,
      seller_price_per_package_without_referral_fee: price_per_package_without_referral_fee,
      seller_price_per_package_referral_fee: price_per_package_referral_fee,
      seller_total_price_without_referral_fee: seller_total_price_without_referral_fee,
    }

  }
  return data;
};

export const unshippedOrderStatus = (unshipped_timestamp) => {
  let a = moment(unshipped_timestamp, "hh:mm:ss A DD-MM-Y");
  let b = moment();
  let hours = b.diff(a, "hours");
  if (hours >= 24) {
    return 'Pickup Scheduled'
  } else {
    return 'Confirmed'
  }
};

export const showConfirmShipmentBtn = (pickup_schedule_date, pickup_schedule_time, payment_status) => {
  let pickup_date = moment(pickup_schedule_date).format('DD-MM-YYYY');
  let pickup_time = moment('0000', 'HHmm').format('HH:mm');
  if (pickup_schedule_time != 0) {
    pickup_time = moment(pickup_schedule_time, 'HHmm').format('HH:mm');
  }
  let date_time = pickup_date + ' ' + pickup_time;
  let now = moment().format('DD-MM-YYYY HH:mm');

  let date_time1 = moment(date_time, 'DD-MM-YYYY HH:mm');
  let now1 = moment(now, 'DD-MM-YYYY HH:mm');
  let difference = date_time1.diff(now1);

  if ((difference < 0) || (payment_status != 'paid')) {
    return false
  } else {
    return true
  }
};
