import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormButton from "./FormButton";
import ImageUpload from "./ImageUpload";

import {connect} from "react-redux";
import {addSellerDocuments, updateSellerDocuments, updateApiStatus} from "../redux/actions/userDataActions";

import './../assets/css/custom.css';

const UploadDocuments = (props) => {
  const [signatureImage, setSignatureImage] = useState(null);
  const [businessLogoImage, setBusinessLogoImage] = useState(null);
  const [displaySignatureImage, setDisplaySignatureImage] = useState('');
  const [displayBusinessLogoImage, setDisplayBusinessLogoImage] = useState('');

  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      setSignatureImage(null);
      setBusinessLogoImage(null);
      setDisplaySignatureImage(props.data.displaySignatureImage);
      setDisplayBusinessLogoImage(props.data.displayBusinessLogoImage);
    }
  }, [props.data]);

  useEffect(() => {
    if (props?.apiStatus == 'ADD_SELLER_DOCUMENTS_SUCCESS'
      || props?.apiStatus == 'UPDATE_SELLER_DOCUMENTS_SUCCESS') {
      setSignatureImage(null);
      setBusinessLogoImage(null);
      props?.updateApiStatus('')
    }
  }, [props?.apiStatus])

  const onSaveClick = () => {
    let errorText = {};
    if (signatureImage == null && displaySignatureImage == '') {
      errorText = {signatureImage: 'Signature Image is required'};
    }
    if (businessLogoImage == null && displayBusinessLogoImage == '') {
      errorText = {...errorText, businessLogoImage: 'Business Logo Image is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('sellerId', props?.data?.sellerId);

    if (props.data?.sellerId) {
      if (signatureImage != null) {
        formData.append('signatureImage', signatureImage);
      }
      if (businessLogoImage != null) {
        formData.append('businessLogoImage', businessLogoImage);
      }
    } else {
      formData.append('signatureImage', signatureImage);
      formData.append('businessLogoImage', businessLogoImage);
    }

    if (props.data?.sellerId) {
      if (signatureImage != null || businessLogoImage != null) {
        props.updateSellerDocuments(formData)
      }
    } else {
      props.addSellerDocuments(formData)
    }
  };

  const onRemoveImage = (type) => {
    if (type == 'signature') {
      setSignatureImage(null);
      setDisplaySignatureImage('');
    } else if (type == 'businessLogo') {
      setBusinessLogoImage(null);
      setDisplayBusinessLogoImage('');
    }
  };

  const onChangeImage = (fileList, type) => {
    if (type == 'signature') {
      setSignatureImage(fileList[0].file);
      setDisplaySignatureImage(fileList[0].image);
    } else if (type == 'businessLogo') {
      setBusinessLogoImage(fileList[0].file);
      setDisplayBusinessLogoImage(fileList[0].image);
    }
  };

  return (
    <Grid
      item container
      xs={12} md={12} mt={3}>

      <Typography style={styles.titleText}>Upload Files</Typography>

      <Grid item md={12} xs={12}
            container>
        <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12}
              mt={{xs: 3}}
              container>
          <Typography sx={styles.labelText}>Signature of authorized person</Typography>
          <Box pl={0.5}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} mt={2} container>
          <ImageUpload
            type={'signature'}
            maxFiles={1}
            fileType={'image'}
            image={displaySignatureImage}
            error={error?.signatureImage}
            disabled={props?.type == 'edit'}
            closeIcon={props?.type == 'edit' ? false : true}
            onRemove={onRemoveImage}
            onChange={onChangeImage}/>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12}
            container>
        <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12}
              mt={{xs: 3}}
              container>
          <Typography sx={styles.labelText}>Business Logo</Typography>
          <Box pl={0.5}>
            <Typography sx={styles.requiredText}>*</Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} mt={2} container>
          <ImageUpload
            type={'businessLogo'}
            maxFiles={1}
            fileType={'image'}
            image={displayBusinessLogoImage}
            error={error?.businessLogoImage}
            disabled={props?.type == 'edit'}
            closeIcon={props?.type == 'edit' ? false : true}
            onRemove={onRemoveImage}
            onChange={onChangeImage}/>
        </Grid>
      </Grid>


      {props?.type == 'edit' ? null :
        <Grid item md={12} xs={12} container
              display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box>
            <FormButton
              title={'Save'}
              onSubmit={onSaveClick}
            />
          </Box>
        </Grid>
      }

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSellerDocuments: data => dispatch(addSellerDocuments(data)),
    updateSellerDocuments: data => dispatch(updateSellerDocuments(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);

const styles = {
  titleText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  btnStyleView: {
    height: 40,
  },
  labelText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'InterMedium',
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterMedium',
    fontSize: 14,
  },
};

