import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import OrderTabList from "./OrderTabList";
import Page from "./Page";

import {connect} from "react-redux";
import {listOrder, confirmShipment, updateApiStatus} from "../redux/actions/userDataActions";

import styles from "./OrderTab.module.css"

import {ReactComponent as InfoIcon} from "../assets/img/infoCircle.svg";
import {ReactComponent as TickIcon} from "../assets/img/tick_grey.svg";
import {ReactComponent as CloseIcon} from "../assets/img/close_black2.svg";
import moment from "moment";

const OrderTab = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [showPendingMessage, setShowPendingMessage] = useState(true);
  const [showUnshippedMessage, setShowUnshippedMessage] = useState(false);
  const [orderMessageVal, setOrderMessageVal] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getOrderList(1);
  }, []);

  useEffect(() => {
    if (props?.orderMessage != null) {
      setOrderMessageVal(props?.orderMessage)
    }
  }, [props?.orderMessage]);

  const getOrderList = (page) => {
    let data = {
      page: page,
      status: props?.tab
    };
    props.listOrder(JSON.stringify(data))
    setPageNum(page)
  };

  const onPageSelect = (page) => {
    getOrderList(page);
  };

  const onOrderDetail = (order_uid, type) => {
    if (type == 'pickup' || type == 'PSI') {
      navigate('/order_detail/' + order_uid + '#' + type)
    } else {
      navigate('/order_detail/' + order_uid)
    }
  };

  const onConfirmShipment = (order_uid) => {
    let data = {
      orderUid: order_uid,
    }
    props.confirmShipment(JSON.stringify(data))
  };

  if (props.orderLoading) {
    return (
      <Box style={{minHeight: window.innerHeight - 140}}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <>

      {props?.tab == 'pending' && props?.orderList?.length > 0 && showPendingMessage ?
        <Grid item md={12} xs={12}
              display={"flex"}
              flexDirection={'column'}
              className={styles.descView}
              alignItems={"flex-start"} justifyContent={"center"} p={2} mb={4}>
          <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}
                justifyContent={'space-between'}>
            <Grid item md={10} xs={10} display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <InfoIcon height={15} width={15}/>
              <Typography className={styles.descTitleText} ml={0.2}>
                Order Pending
              </Typography>
            </Grid>
            <Grid
              onClick={() => setShowPendingMessage(false)}
              item md={2} xs={2} display={'flex'} justifyContent={'flex-end'} className={styles.closeIconView}>
              <CloseIcon height={12} width={12}/>
            </Grid>
          </Grid>
          <Typography className={styles.descText} mt={1}>
            Pending status might indicate an issue with the Buyer's payment.
          </Typography>
        </Grid>
        :
        (props?.tab == 'unshipped' && props?.orderList?.length > 0 && showUnshippedMessage ?
          <Grid item md={12} xs={12}
                display={"flex"}
                flexDirection={'column'}
                className={styles.descView}
                alignItems={"flex-start"} justifyContent={"center"} p={2} mb={4}>
            <Grid container item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}
                  justifyContent={'space-between'}>
              <Grid item md={10} xs={10} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <InfoIcon height={15} width={15}/>
                <Typography className={styles.descTitleText} ml={0.2}>
                  Unshipped Orders
                </Typography>
              </Grid>
              <Grid
                onClick={() => setShowUnshippedMessage(false)}
                item md={2} xs={2} display={'flex'} justifyContent={'flex-end'} className={styles.closeIconView}>
                <CloseIcon height={12} width={12}/>
              </Grid>
            </Grid>
            <Typography className={styles.descItalicText} mt={1}>
              Orders which are unshipped are scheduled for pickup at the given time. Seller should process the product
              for pickup within the given time, otherwise it will be auto cancelled and declining rate will be debited
              from seller account.
            </Typography>
          </Grid>
          : (props?.tab == 'shipped' && props?.orderList?.length > 0 ?
            orderMessageVal?.type == 'order_shipped' ?
              <Grid item md={12} xs={12}
                    display={"flex"}
                    flexDirection={'column'}
                    className={styles.descView}
                    alignItems={"flex-start"} justifyContent={"center"} p={2} mb={4}>
                <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <Box className={styles.iconBoxView} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <TickIcon height={9} width={9}/>
                  </Box>
                  <Typography className={styles.shippedText} ml={0.3}>
                    Shipment confirmed
                  </Typography>
                </Grid>
                <Typography className={styles.shippedDescText} mt={1}>
                  Successfully confirmed shipment of order #{orderMessageVal?.orderUid} on {moment().format('DD/MM/YYYY HH:mm')} hrs.
                </Typography>
              </Grid>
              : null
            : null))
      }


      <TableContainer
        className={'tableContainer'}>
        <Table
          stickyHeader
          aria-label="sticky table">
          <TableHead
            className={'table'}>
            <TableRow className={styles.tableHeadRow}>
              <TableCell>
                <Typography className={styles.labelText}>SKU</Typography>
                <Typography className={styles.subLabelText} ml={1}>Product ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Product</Typography>
                <Typography className={styles.subLabelText} ml={1}>Hs Code</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Buyer</Typography>
                <Typography className={styles.subLabelText} ml={1}>TGBIN</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order ID</Typography>
                <Typography className={styles.subLabelText} ml={1}>Order Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order Quantity</Typography>
                <Typography className={styles.subLabelText} ml={1}>No of Packages</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Price/Kg</Typography>
                <Typography className={styles.subLabelText} ml={1}>Price/Package</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Total EXW Price</Typography>
                <Typography className={styles.subLabelText} ml={1}>Shipping Cost</Typography>
                <Typography className={styles.subLabelText} ml={1}>Referral Fee</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order Total</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>M. Shipping</Typography>
                <Typography className={styles.subLabelText} ml={1}>Incoterm</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Delivery Date</Typography>
                <Typography className={styles.subLabelText} ml={1}>Ship by Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.labelText}>Order Status</Typography>
                <Typography className={styles.subLabelText} ml={1}>Payment Status</Typography>
              </TableCell>
              {props?.tab == 'cancelled' ?
                <TableCell>
                  <Typography className={styles.labelText}>Reason</Typography>
                </TableCell>
                :
                <TableCell>
                  <Typography className={styles.labelText}>Action</Typography>
                </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.orderList?.length > 0 ?
              props?.orderList?.map((item, index) => {
                return (
                  <TableRow key={index} className={styles.tableRow}>
                    <OrderTabList
                      tab={props?.tab}
                      key={index}
                      index={index}
                      data={item}
                      onOrderDetail={onOrderDetail}
                      onConfirmShipment={onConfirmShipment}
                    />
                  </TableRow>
                )
              }) : null}
          </TableBody>
        </Table>
      </TableContainer>


      <Box display={"flex"} justifyContent={"flex-end"} flex={1} mt={4}>
        {props.orderTotalPages > 0 ?
          <Page
            totalPages={props.orderTotalPages}
            pageNum={pageNum}
            onSelectPage={onPageSelect}/>
          : null}
      </Box>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    orderTotalPages: state.userData.orderTotalPages,
    orderList: state.userData.orderList,
    orderLoading: state.userData.orderLoading,
    apiStatus: state.userData.apiStatus,
    orderMessage: state.userData.orderMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listOrder: data => dispatch(listOrder(data)),
    confirmShipment: data => dispatch(confirmShipment(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTab);
