import * as React from 'react';
import {useEffect} from "react";
import {Box, Grid, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

import AddProductDetailTab from './AddProductDetailTab';
import AddProductImageTab from './AddProductImageTab';
import AddProductPackingPriceTab from './AddProductPackingPriceTab';
import AddProductShippingDetailTab from './AddProductShippingDetailTab';

import {connect} from "react-redux";
import {
  addProductVariety, updateApiStatus, addProductImage, addProductPackingPrice, addProductShipping,
  updateProductVariety, updateProductPackingPrice, updateProductShipping, updateProductImage
} from "../redux/actions/userDataActions";

import styles from './BasicAddProductTab.module.css'

import {THEME_COLOR9} from '../constants'

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabList = [
  {
    value: 0,
    label: 'Product Details'
  },
  {
    value: 1,
    label: 'Images'
  },
  {
    value: 2,
    label: 'Packing & Price'
  },
  {
    value: 3,
    label: 'Shipping Details'
  }
]

const BasicAddProductTabs = (props) => {
  const [value, setValue] = React.useState(0);
  const [productUid, setProductUid] = React.useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (props.apiStatus == "ADD_PRODUCT_VARIETY_SUCCESS"
      || props.apiStatus == "UPDATE_PRODUCT_VARIETY_SUCCESS"
      || props.apiStatus == "ADD_PRODUCT_IMAGE_FAIL"
      || props.apiStatus == "UPDATE_PRODUCT_IMAGE_FAIL") {
      setValue(1);
      props.updateApiStatus("");
    } else if (props.apiStatus == "ADD_PRODUCT_IMAGE_SUCCESS"
      || props.apiStatus == "UPDATE_PRODUCT_IMAGE_SUCCESS") {
      setValue(2);
      props.updateApiStatus("");
    } else if (props.apiStatus == "ADD_PRODUCT_PACKING_PRICE_SUCCESS"
      || props.apiStatus == "UPDATE_PRODUCT_PACKING_PRICE_SUCCESS") {
      setValue(3);
      props.updateApiStatus("");
    } else if (props.apiStatus == "ADD_PRODUCT_SHIPPING_SUCCESS"
      || props.apiStatus == "UPDATE_PRODUCT_SHIPPING_SUCCESS") {
      setValue(3);
      props.updateApiStatus("");
      navigate('/product')
    }
  }, [props.apiStatus]);


  useEffect(() => {
    let id = props?.productDetail?.productVarietyDetail?.productUid;
    if (props?.productDetail?.productVarietyDetail?.productUid) {
      setProductUid(id);
    }
  }, [props.productDetail]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAddProductVariety = (data) => {
    props.addProductVariety(JSON.stringify(data))
  };

  const onUpdateProductVariety = (data) => {
    props.updateProductVariety(JSON.stringify(data))
  };

  const onAddProductImage = (data) => {
    props.addProductImage(data)
  };

  const onUpdateProductImage = (data) => {
    props.updateProductImage(data)
  };

  const onAddProductPackingPrice = (data) => {
    props.addProductPackingPrice(JSON.stringify(data))
  };

  const onUpdateProductPackingPrice = (data) => {
    props.updateProductPackingPrice(JSON.stringify(data))
  };

  const onAddProductShipping = (data) => {
    props.addProductShipping(JSON.stringify(data))
  };

  const onUpdateProductShipping = (data) => {
    props.updateProductShipping(JSON.stringify(data))
  };

  return (
    <Grid item md={12} xs={12} className={styles.mainView}
      // display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
    >
      <Grid item md={12} xs={12}
            display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}
            sx={{backgroundColor: THEME_COLOR9, borderBottom: '1px solid #aaa'}} className={styles.labelMainView}
      >
        {tabList.map((item, index) => {
          return (
            <Box key={index} className={styles.labelView}
                 sx={index == value ? {
                     backgroundColor: '#fff',
                     borderRight: '1px solid #aaa', borderLeft: index == 0 ? 'unset' : '1px solid #aaa',
                     marginBottom: -0.2
                   }
                   : {backgroundColor: THEME_COLOR9}}
                 onClick={() => setValue(index)}
                 py={2} px={3}
            >
              <Typography className={styles.labelText}
              sx={index == value ? {fontFamily: 'InterBold'} : {fontFamily: 'InterMedium'}}
              >{item.label}</Typography>
            </Box>
          )
        })}
      </Grid>

      <Grid container item md={12} xs={12} className={styles.borderBottomLine}/>

      <Grid item md={12} xs={12} mt={3}
            display={'flex'} flexDirection={'column'} alignItems={'center'}
      >
        {value == 0 ?
          <AddProductDetailTab
            list={props?.list}
            data={props?.productDetail?.productVarietyDetail ? props.productDetail.productVarietyDetail : null}
            onAddProductVariety={onAddProductVariety}
            onUpdateProductVariety={onUpdateProductVariety}
          />
          : null}

        {value == 1 ?
          <AddProductImageTab
            data={props?.productDetail?.productImageDetail ? props.productDetail?.productImageDetail : null}
            productUid={productUid}
            onAddProductImage={onAddProductImage}
            onUpdateProductImage={onUpdateProductImage}
          />
          : null}

        {value == 2 ?
          <AddProductPackingPriceTab
            data={props?.productDetail?.productPackingPriceDetail ? props.productDetail.productPackingPriceDetail : null}
            productUid={productUid}
            list={props?.list}
            onAddProductPackingPrice={onAddProductPackingPrice}
            onUpdateProductPackingPrice={onUpdateProductPackingPrice}
          />
          : null}

        {value == 3 ?
          <AddProductShippingDetailTab
            productUid={productUid}
            packingPriceDetail={props?.productDetail?.productPackingPriceDetail ? props.productDetail.productPackingPriceDetail : null}
            data={props?.productDetail?.productShippingDetail}
            list={props?.list}
            onAddProductShipping={onAddProductShipping}
            onUpdateProductShipping={onUpdateProductShipping}
          />
          : null}
      </Grid>

    </Grid>
  );
}


const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
    // productDetail: state.userData.productDetail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProductVariety: data => dispatch(addProductVariety(data)),
    addProductImage: data => dispatch(addProductImage(data)),
    addProductPackingPrice: data => dispatch(addProductPackingPrice(data)),
    addProductShipping: data => dispatch(addProductShipping(data)),
    updateProductVariety: data => dispatch(updateProductVariety(data)),
    updateProductImage: data => dispatch(updateProductImage(data)),
    updateProductPackingPrice: data => dispatch(updateProductPackingPrice(data)),
    updateProductShipping: data => dispatch(updateProductShipping(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicAddProductTabs);
