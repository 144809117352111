import React, {useEffect, useState} from 'react'
import {Grid, Typography, Box} from "@mui/material";
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";

import styles from './Index.module.css'

import {ReactComponent as BoxIcon} from "../../assets/img/box.svg";

import {connect} from "react-redux";
import {getDashboard} from "../../redux/actions/userDataActions";
import FormButton from "../../components/FormButton";

const Index = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    props.getDashboard();
  }, []);

  return (
    <Container page={"index"}>
      {props?.dashboardDetail?.totalProducts == 0 || props?.dashboardDetail == null ?
        <Grid item md={12} xs={12}
              container
              display={"flex"}
              flexDirection={{md: "column", xs: "column"}}
        >
          {props?.tgsin ?
            <>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={1}>
                <Box>
                  <Typography className={styles.sellerTitleText}>Dear Seller,</Typography>
                </Box>
                <Box mt={0.6} pl={0.5}>
                  <Typography className={styles.welcomeAboardText}>Welcome aboard !!!!!</Typography>
                </Box>
              </Box>
              <Box className={styles.borderBottomView}/>
              <Typography className={styles.congratulationTitleText} mt={2}>Congratulations !</Typography>
              <Typography className={styles.tgsinTitleText} mt={1}>Your Trebbs Global Seller account has been
                activated.</Typography>
              <Typography className={styles.tgsinTitleText} mt={1}>
                Your Trebbs
                Global
                Seller identification number
                (TGSIN) is {props?.tgsin}, which is also sent to your email.
              </Typography>

              <Box mt={4}>
                <Typography className={styles.viewDashboardText}>Add Products to view your Dashboard.</Typography>

                <Grid item md={12} xs={12} mt={3}>
                  <Box className={styles.boxView}
                       display={'flex'}
                       flexDirection={'column'}
                       alignItems={'center'} justifyContent={'center'}
                       pt={2} pb={1}>
                    <BoxIcon height={100} width={100}/>
                    <Box className={styles.borderBottomView} mt={3} mb={1}/>
                    <FormButton
                      btnType={'btn11'}
                      title={'Add Product'}
                      width={'60%'}
                      height={'20%'}
                      onSubmit={() => navigate('/add_product')}
                    />
                  </Box>
                </Grid>


              </Box>


            </>
            : null}
        </Grid>
        :
        <>
          <Typography className={styles.dashboardText}>Dashboard</Typography>

          <Grid item md={12} xs={12} className={styles.countBorderBottomView} mt={1} mb={3}/>

          <Grid container item md={12} xs={12}>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/product')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalProducts} Products</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/product?status=active')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalActiveProducts} Active
                  Products</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/product?status=inactive')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalInactiveProducts} Inactive
                  Products</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/product?status=removed')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalRemovedProducts} Removed
                  Products</Typography>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} className={styles.countBorderBottomView} mb={2}/>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/orders')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalOrders} Orders</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/orders?status=pending')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalPendingOrders} Pending
                  Orders</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/orders?status=unshipped')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalUnshippedOrders} Unshipped
                  Orders</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/orders?status=shipped')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalShippedOrders} Shipped
                  Orders</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/orders?status=delivered')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalDeliveredOrders} Delivered
                  Orders</Typography>
              </Grid>
            </Grid>

            <Grid item md={3} xs={12} mb={2}>
              <Grid item md={11} xs={12}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    className={styles.cardDiv}
                    onClick={() => navigate('/orders?status=cancelled')}>
                <Typography className={styles.titleText}>{props?.dashboardDetail?.totalCancelledOrders} Cancelled
                  Orders</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      }

    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    dashboardDetail: state.userData.dashboardDetail,
    tgsin: state.userData.tgsin,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDashboard: data => dispatch(getDashboard(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

