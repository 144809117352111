import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormButton from "./FormButton";

import './../assets/css/custom.css';

import {THEME_COLOR2} from "../constants";

import {connect} from "react-redux";

const AccountCreated = (props) => {

  const onUpdateBusinessDetailBtnClick = () => {
    props.onUpdateBusinessDetailBtnClick()
  };

  return (
    <Grid item container xs={12} md={12}
     display={'flex'} flexDirection={'column'}>

      <Grid item md={12} xs={12}>
        <Typography sx={styles.successText}>Dear Seller your account has been created successfully !!!</Typography>
      </Grid>

      <Grid item md={12} xs={12} mt={2}>
        <Typography sx={styles.loginText}>Your login credentials are :</Typography>
      </Grid>

      <Grid item md={4} xs={12} mt={2} display={'flex'} justifyContent={'flex-end'}>
        <Typography sx={styles.usernameText}>Username : {props?.userName ? props.userName : '-'}</Typography>
      </Grid>

      <Grid item md={12} xs={12} mt={2}>
        <Typography sx={styles.emailText}>Your login credentials are sent to the given email address.</Typography>
      </Grid>

      <Grid item md={12} xs={12} mt={2}>
        <Typography sx={styles.enableText}>Please complete the registration process to enable your Trebbs Global Seller
          Account
          (TGSA).</Typography>
      </Grid>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4}>
        <Box>
          <FormButton
            title={'Update Business Details'}
            onSubmit={onUpdateBusinessDetailBtnClick}
          />
        </Box>
      </Grid>

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    userName: state.userData.userName,
  };
};

export default connect(mapStateToProps, null)(AccountCreated);

const styles = {
  successText: {
    color: THEME_COLOR2,
    fontFamily: 'InterBold',
    fontSize: 17
  },
  loginText: {
    color: '#000',
    fontSize: 13,
    fontFamily: 'InterMedium',
  },
  usernameText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 13
  },
  emailText: {
    color: '#000',
    fontSize: 13,
    fontFamily: 'InterMedium',
  },
  enableText: {
    color: '#000',
    fontSize: 13,
    fontFamily: 'InterMedium',
  }
};

