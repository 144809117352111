import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Grid, Box, Card, CardContent, CardMedia, Typography, CardActionArea} from '@mui/material';

import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";
import AlertDialogBox from "../../components/AlertDialogBox";
import SendMailModal from "../../components/SendMailModal";

import {connect} from "react-redux";
import {loginUser, forgotUserName, forgotUserPassword, updateApiStatus} from "../../redux/actions/userDataActions";

import Logo from "../../assets/img/logo.png";
import LoginBgImg from "../../assets/img/login_bg_image.jpg"

import {THEME_COLOR2} from "../../constants";

import {ReactComponent as EyeVisibleIcon} from "../../assets/img/eye.svg";
import {ReactComponent as EyeHiddenIcon} from "../../assets/img/eye_slash.svg";

const SignIn = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showMailModalVisible, setShowMailModalVisible] = useState(false);
  const [showPopupVisible, setShowPopupVisible] = useState(false);
  const [forgotType, setForgotType] = useState('');
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == 'FORGOT_USER_NAME_SUCCESS'
      || props.apiStatus == 'FORGOT_USER_PASSWORD_SUCCESS') {
      setShowMailModalVisible(false)
      setShowPopupVisible(true)
      props.updateApiStatus('')
    }
  }, [props.apiStatus]);

  const onSignIn = () => {
    let errorText = {};
    if (username == '') {
      errorText = {username: 'Username is required'};
    }
    if (password == '') {
      errorText = {...errorText, password: 'Password is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      username: username,
      password: password,
    };
    props.loginUser(JSON.stringify(data))
  };

  const onSignUpClick = () => {
    navigate('/sign_up')
  };

  const onForgotUsername = () => {
    setForgotType('username')
    setShowMailModalVisible(true)
  };

  const onSendMail = (data) => {
    if (forgotType == 'username') {
      props?.forgotUserName(JSON.stringify(data));
    } else if (forgotType == 'password') {
      props?.forgotUserPassword(JSON.stringify(data))
    }
  };

  const onCancelMail = () => {
    setForgotType('')
    setShowMailModalVisible(false)
  };

  const onForgotPassword = () => {
    setForgotType('password')
    setShowMailModalVisible(true)
  };

  const onPopupConfirmClick = () => {
    setShowPopupVisible(false)
    setForgotType('')
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false);
    } else {
      setPasswordEyeVisible(true);
    }
  };

  return (
    <Grid container item md={12} xs={12}>
      <Box sx={styles.bgImgView}>
        <img alt="" src={LoginBgImg} style={styles.imgStyle}/>
      </Box>
      <Grid sx={styles.contentView}
            container item md={12} xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
        <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          {/*<Box sx={styles.imgView} mr={2}>*/}
          {/*  <img alt="" src={Logo} style={styles.imgStyle}/>*/}
          {/*</Box>*/}
          <Box>
            <h2 style={styles.mainTitleText}>Trebbs</h2>
          </Box>
        </Grid>
        <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Card sx={[styles.cardView, {
            width: {md: '70%', sm: '90%', xs: '90%'},
            minHeight: {md: '40vw', sm: '100vw', xs: '100vw'}
          }]}>
            <Typography style={styles.titleText}>Sign In</Typography>
            <Typography style={styles.subTitleText} mt={1}>Trebbs</Typography>
            <CardContent style={styles.cardContentView}>

              <Grid container item md={12} xs={12}
                    sx={styles.textInputView} mt={4}>

                <Grid item md={12} xs={12} mt={3}
                      container display={'flex'} flexDirection={'row'} alignItems={'center'}
                      justifyContent={'space-between'}>
                  <Grid display={"flex"}
                        flexDirection={"row"} item md={6} xs={6}>
                    <Typography sx={styles.labelText}>Username</Typography>
                    <Box pl={0.2}>
                      <Typography sx={styles.requiredText}>*</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={6} display={'flex'} justifyContent={'flex-end'}
                    onClick={() => onForgotUsername()}>
                    <Typography style={styles.forgotText}>Forgot Username</Typography>
                  </Grid>
                </Grid>

                <FormTextInput
                  page={'signIn'}
                  value={username}
                  setValue={setUsername}
                  error={error?.username}
                  textInputViewStyle={styles.textInputViewStyle}
                  required={true}
                />
              </Grid>

              <Grid container item md={12} xs={12} mt={1}
                    sx={styles.textInputView}>

                <Grid item md={12} xs={12} mt={3}
                      container display={'flex'} flexDirection={'row'} alignItems={'center'}
                      justifyContent={'space-between'}>
                  <Grid display={"flex"}
                        flexDirection={"row"} item md={6} xs={6}>
                    <Typography sx={styles.labelText}>Password</Typography>
                    <Box pl={0.2}>
                      <Typography sx={styles.requiredText}>*</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={6} display={'flex'} justifyContent={'flex-end'}
                        onClick={() => onForgotPassword()}>
                    <Typography style={styles.forgotText}>Forgot Password</Typography>
                  </Grid>
                </Grid>

                <FormTextInput
                  page={'signIn'}
                  value={password}
                  setValue={setPassword}
                  error={error?.password}
                  textInputViewStyle={styles.textInputViewStyle}
                  required={true}
                  type={passwordEyeVisible ? 'text' : 'password'}
                  icon={true}
                  rightIcon={passwordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> : <EyeHiddenIcon height={15} width={15}/>}
                  onIconClick={onPasswordEyeIconClick}
                />
              </Grid>

              <Grid container
                    item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mt={4}>
                <FormButton
                  loading={props.loginLoading}
                  title={'Sign In'}
                  btnStyleView={styles.btnStyleView}
                  onSubmit={onSignIn}
                />
              </Grid>

              <Box
                mt={3}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}>
                <Typography style={styles.notMemberText}>Not a Member yet?</Typography>
                <Typography ml={0.3} style={styles.signUpText} onClick={() => onSignUpClick()}>Sign up</Typography>
              </Box>

            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AlertDialogBox
        confirmBox={showPopupVisible}
        title={forgotType == 'username' ? 'Trebbs Seller - Forgot Username' : (forgotType == 'password' ? 'Trebbs Seller - Forgot Password' : '')}
        content={'Mail Sent. Please check your mail'}
        firstBtn={'Ok'}
        padding={3}
        height={'30%'}
        width={'30%'}
        mt={1}
        alignItems={'unset'}
        onConfirmClick={onPopupConfirmClick}
        onCancelClick={onPopupConfirmClick}
      />

      <SendMailModal
        type={forgotType}
        btnLoading={props?.btnLoading}
        open={showMailModalVisible}
        onCancel={onCancelMail}
        onSubmit={onSendMail}/>

      <AlertMsg/>

    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    loginLoading: state.userData.loginLoading,
    apiStatus: state.userData.apiStatus,
    btnLoading: state.userData.btnLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: data => dispatch(loginUser(data)),
    forgotUserName: data => dispatch(forgotUserName(data)),
    forgotUserPassword: data => dispatch(forgotUserPassword(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

const styles = {
  bgImgView: {
    position: 'fixed',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  contentView: {
    zIndex: 10,
    minHeight: window.innerHeight
  },
  imgView: {
    height: 100,
    width: 100,
  },
  mainTitleText: {
    color: '#FFF',
    fontFamily: 'InterBold',
    fontSize: 45
  },
  cardView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 2,
  },
  titleText: {
    textAlign: 'center',
    fontFamily: 'InterBold',
    fontSize: 30
  },
  subTitleText: {
    textAlign: 'center',
    color: '#a1a5b7',
    fontFamily: 'InterBold',
    fontSize: 17
  },
  cardContentView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textInputView: {
    width: '80%'
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  labelText: {
    fontSize: 13,
    fontFamily: 'InterMedium',
    color: '#000'
  },
  requiredText: {
    color: 'red',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  forgotText: {
    fontSize: 13,
    fontFamily: 'InterMedium',
    color: THEME_COLOR2,
    cursor: 'pointer'
  },
  btnStyleView: {
    fontSize: 14,
    height: 40,
    width: '80%'
  },
  notMemberText: {
    color: '#a1a5b7',
    fontSize: 13.5,
    fontFamily: 'InterMedium',
  },
  signUpText: {
    cursor:'pointer',
    color: THEME_COLOR2,
    fontSize: 13.5,
    fontFamily: 'InterMedium',
  }
};
