import {useEffect} from "react";

import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import countryToCurrency from "country-to-currency";

import {connect} from 'react-redux';
import {getLoginSellerDetail} from "./redux/actions/userDataActions";

import SignIn from './containers/Auth/SignIn';
import SignUp from './containers/Auth/SignUp';
import Landing from './containers/Auth/Landing';
import CreateAccount from './containers/Auth/CreateAccount';
import SignOut from "./containers/Auth/SignOut";
import Verification from "./containers/Auth/Verification";
import TermsAndConditions from './containers/Auth/TermsAndConditions';
import Index from './containers/Dashboard';
import Product from './containers/Product/Product';
import ProductDetail from './containers/Product/ProductDetail';
import AddProduct from './containers/Product/AddProduct';
import Orders from './containers/Order/Orders';
import OrderDetail from './containers/Order/OrderDetail';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import MyAccount from './containers/MyAccount/MyAccount';
import About from "./containers/About/About";
import UserTermsAndConditions from "./containers/UserTermsAndConditions/UserTermsAndConditions";
import PaymentPolicy from "./containers/PaymentPolicy/PaymentPolicy";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";

import './assets/css/custom.css';

const App = props => {
  useEffect(() => {
    if (props.userToken) {
      props.getLoginSellerDetail()
    }
  }, [])

  let routes = (
    <Routes>
      <Route exact path="/sign_in" element={<SignIn {...props} />}/>
      <Route exact path="/sign_up" element={<SignUp {...props} />}/>
      <Route exact path="/" element={<Landing {...props} />}/>
      <Route path="*" element={<Navigate replace to="/"/>}/>
      <Route exact path="/" element={<Navigate replace to="/"/>}/>
      {/*<Route exact path="/" element={<Navigate replace to="/sign_in"/>}/>*/}
      <Route exact path="/forgot_password/:id" element={<ForgotPassword {...props} />}/>
      <Route exact path="/seller_agreement" element={<TermsAndConditions {...props} />}/>
      <Route exact path="/about" element={<About {...props} />}/>
      <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
      <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
      <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
    </Routes>
  );

  if (props.userToken && (props.userStatus == 'pending')) {
    routes = (
      <Routes>
        <Route exact path="/create_account" element={<CreateAccount {...props} />}/>
        <Route exact path="/sign_out" element={<SignOut {...props} />}/>
        <Route exact path="/" element={<Landing {...props} />}/>
        <Route path="*" element={<Navigate replace to="/create_account"/>}/>
        <Route exact path="/seller_agreement" element={<TermsAndConditions {...props} />}/>
        <Route exact path="/about" element={<About {...props} />}/>
        <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
        <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
        <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
      </Routes>
    );
  }

  if (props.userToken && (props.userStatus == 'pending_verification')) {
    routes = (
      <Routes>
        <Route exact path="/verification" element={<Verification {...props} />}/>
        <Route exact path="/sign_out" element={<SignOut {...props} />}/>
        <Route exact path="/" element={<Landing {...props} />}/>
        <Route path="*" element={<Navigate replace to="/verification"/>}/>
        <Route exact path="/about" element={<About {...props} />}/>
        <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
        <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
        <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
      </Routes>
    );
  }

  if (props.userToken && (props.userStatus == 'active')) {
    routes = (
      <Routes>
        <Route exact path="/" element={<Landing {...props} />}/>
        <Route exact path="/sign_out" element={<SignOut {...props} />}/>
        <Route exact path="/index" element={<Index {...props} />}/>
        <Route exact path="/product" element={<Product {...props} />}/>
        <Route exact path="/product/:id" element={<ProductDetail {...props} />}/>
        <Route exact path="/add_product" element={<AddProduct {...props} />}/>
        <Route exact path="/orders" element={<Orders {...props} />}/>
        <Route exact path="/order_detail/:id" element={<OrderDetail {...props} />}/>
        <Route exact path="/order_detail/:id/cancel" element={<OrderDetail {...props} />}/>
        {/*<Route exact path="/update_account" element={<CreateAccount {...props} />}/>*/}
        <Route exact path="/my_account" element={<MyAccount {...props} />}/>
        <Route exact path="/seller_agreement" element={<TermsAndConditions {...props} />}/>
        <Route exact path="/about" element={<About {...props} />}/>
        <Route exact path="/user_terms_and_conditions" element={<UserTermsAndConditions {...props} />}/>
        <Route exact path="/privacy_policy" element={<PrivacyPolicy {...props} />}/>
        <Route exact path="/payment_policy" element={<PaymentPolicy {...props} />}/>
        <Route path="*" element={<Navigate replace to="/index"/>}/>
        <Route path="/" element={<Navigate replace to="/"/>}/>
      </Routes>
    );
  }

  return (
    <BrowserRouter>
      {routes}
    </BrowserRouter>
  );
}


const mapStateToProps = state => {
  return {
    userToken: state.userData.userToken,
    userCountry: state.userData.userCountry,
    userStatus: state.userData.userStatus,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getLoginSellerDetail: data => dispatch(getLoginSellerDetail(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
