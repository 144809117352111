import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

import styles from './OrderDetailView.module.css'

import {unshippedOrderStatus, getCurrencyConversionCalc} from "../assets/functions/common";

import {COUNTRIES} from "../constants/countries";

const OrderDetailView = (props) => {
  const [price, setPrice] = useState(0);
  const [pricePerKg, setPricePerKg] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceWithoutReferralFee, setTotalPriceWithoutReferralFee] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [cargoPrice, setCargoPrice] = useState(0);
  const [pricePerKgWithoutReferralFee, setPricePerKgWithoutReferralFee] = useState(0);
  const [pricePerKgReferralFee, setPricePerKgReferralFee] = useState(0);
  const [pricePerPackageWithoutReferralFee, setPricePerPackageWithoutReferralFee] = useState(0);
  const [pricePerPackageReferralFee, setPricePerPackageReferralFee] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [userPrice, setUserPrice] = useState(0);
  const [userPricePerKg, setUserPricePerKg] = useState(0);
  const [userTotalPrice, setUserTotalPrice] = useState(0);
  const [UserTotalPriceWithoutReferralFee, setUserTotalPriceWithoutReferralFee] = useState(0);
  const [userShippingCost, setUserShippingCost] = useState('')
  const [userCargoPrice, setUserCargoPrice] = useState(0);
  const [userPricePerKgWithoutReferralFee, setUserPricePerKgWithoutReferralFee] = useState(0);
  const [userPricePerKgReferralFee, setUserPricePerKgReferralFee] = useState(0);
  const [userPricePerPackageWithoutReferralFee, setUserPricePerPackageWithoutReferralFee] = useState(0);
  const [userPricePerPackageReferralFee, setUserPricePerPackageReferralFee] = useState(0);
  const [userCurrencySymbol, setUserCurrencySymbol] = useState('')
  const [orderStatus, setOrderStatus] = useState('');

  useEffect(() => {
    if (props.data) {
      if (props.data.quantity == '') {
        setTotalPrice(0);
      } else {

        let currency_symbol = '';
        let price = 0;
        let price_per_kg = 0;
        let total_price = 0;
        let total_price_without_referral_fee = 0;
        let shipping_cost = 0;
        let price_per_kg_without_referral_fee = 0;
        let price_per_kg_referral_fee = 0;
        let price_per_package_without_referral_fee = 0;
        let price_per_package_referral_fee = 0;
        let cargo_price = 0;
        let user_currency_symbol = '';
        let converted_price = 0;
        let converted_price_per_kg = 0;
        let converted_total_price = 0;
        let converted_total_price_without_referral_fee = 0;
        let converted_shipping_cost = 0;
        let converted_price_per_kg_without_referral_fee = 0;
        let converted_price_per_kg_referral_fee = 0;
        let converted_price_per_package_without_referral_fee = 0;
        let converted_price_per_package_referral_fee = 0;
        let converted_cargo_price = 0;

        let data_list = {
          price_per_package: props?.data?.price_per_package,
          package_net_weight: props?.data?.package_net_weight,
          package_gross_weight: props?.data?.package_gross_weight,
          referral_fee: props?.data?.referral_fee,
          quantity: props?.data?.quantity,
          shipping_cost: props?.data?.shipping_cost ? props?.data?.shipping_cost : 0,
          seller_currency: props?.data?.seller_currency,
          user_currency: props?.data?.user_currency,
          main_currency: props?.data?.main_currency,
          rate: props?.data?.rate,
          usd_rate: props?.data?.usd_rate,
          admin_rate: props?.data?.admin_rate,
        }

        let data = getCurrencyConversionCalc(data_list)
        if (data != null) {
          price = data?.seller_price;
          price_per_kg = data?.seller_price_per_kg;
          total_price = data?.seller_total_price;
          shipping_cost = data?.seller_shipping_cost;
          cargo_price = data?.seller_cargo_price;
          price_per_kg_without_referral_fee = data?.seller_price_per_kg_without_referral_fee;
          price_per_kg_referral_fee = data?.seller_price_per_kg_referral_fee;
          price_per_package_without_referral_fee = data?.seller_price_per_package_without_referral_fee;
          price_per_package_referral_fee = data?.seller_price_per_package_referral_fee;
          total_price_without_referral_fee = data?.seller_total_price_without_referral_fee;
          currency_symbol = data?.seller_currency_symbol;
          converted_price = data?.converted_price;
          converted_price_per_kg = data?.converted_price_per_kg;
          converted_total_price = data?.converted_total_price;
          converted_shipping_cost = data?.converted_shipping_cost;
          converted_cargo_price = data?.converted_cargo_price;
          converted_price_per_kg_without_referral_fee = data?.converted_price_per_kg_without_referral_fee;
          converted_price_per_kg_referral_fee = data?.converted_price_per_kg_referral_fee;
          converted_price_per_package_without_referral_fee = data?.converted_price_per_package_without_referral_fee;
          converted_price_per_package_referral_fee = data?.converted_price_per_package_referral_fee;
          converted_total_price_without_referral_fee = data?.converted_total_price_without_referral_fee;
          user_currency_symbol = data?.user_currency_symbol;
        }

        setPrice(price);
        setTotalPrice(total_price);
        setTotalPriceWithoutReferralFee(total_price_without_referral_fee);
        setPricePerKg(price_per_kg);
        setCargoPrice(cargo_price)
        setPricePerKgWithoutReferralFee(price_per_kg_without_referral_fee)
        setPricePerKgReferralFee(price_per_kg_referral_fee)
        setPricePerPackageWithoutReferralFee(price_per_package_without_referral_fee)
        setPricePerPackageReferralFee(price_per_package_referral_fee)
        setShippingCost(shipping_cost)
        setCurrencySymbol(currency_symbol)
        setUserPrice(converted_price);
        setUserTotalPrice(converted_total_price)
        setUserTotalPriceWithoutReferralFee(converted_total_price_without_referral_fee)
        setUserPricePerKg(converted_price_per_kg);
        setUserCargoPrice(converted_cargo_price)
        setUserPricePerKgWithoutReferralFee(converted_price_per_kg_without_referral_fee)
        setUserPricePerKgReferralFee(converted_price_per_kg_referral_fee)
        setUserPricePerPackageWithoutReferralFee(converted_price_per_package_without_referral_fee)
        setUserPricePerPackageReferralFee(converted_price_per_package_referral_fee)
        setUserShippingCost(converted_shipping_cost)
        setUserCurrencySymbol(user_currency_symbol)
      }

      if (props?.data?.order_status == 'unshipped') {
        // if (props?.data?.unshipped_timestamp) {
        //   let order_status = unshippedOrderStatus(props?.data?.unshipped_timestamp);
        //   setOrderStatus(order_status);
        // }
        if(props?.data?.payment_status == 'paid') {
          setOrderStatus('Pickup Scheduled');
        } else {
          setOrderStatus('Confirmed')
        }
      } else {
        setOrderStatus(props?.data?.order_status)
      }
    }
  }, [props.data])

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>SKU</Typography>
                <Typography className={styles.subTitleText} ml={1}>Product ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Product</Typography>
                <Typography className={styles.subTitleText} ml={1}>HS Code</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Seller</Typography>
                <Typography className={styles.subTitleText} ml={1}>TGSIN</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order ID</Typography>
                <Typography className={styles.subTitleText} ml={1}>Order Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Quantity</Typography>
                <Typography className={styles.subTitleText} ml={1}>No. of Packages</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Price/Kg</Typography>
                <Typography className={styles.subTitleText} ml={1}>Price/Package</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Total EXW Price</Typography>
                <Typography className={styles.subTitleText} ml={1}>Shipping Cost</Typography>
                <Typography className={styles.subTitleText} ml={1}>Referral Fee</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Total</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Order Status</Typography>
                <Typography className={styles.subTitleText} ml={1}>Payment Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.sku ? item.sku : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.name ? item.name : '-'}</Typography>
                <Typography className={styles.subValueText} mt={1}>
                  HS code: {item?.hs_code ? (item.hs_code + ' - ' + item.hs_code_title) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.seller_name_entity ? item?.seller_name_entity : ''}
                  {item?.seller_country ? '(' + COUNTRIES.filter(
                    (x) => x.code == item?.seller_country
                  )?.[0]?.label + ')' : '-'}</Typography>
                <Typography className={styles.subValueText} mt={1}>
                  {item?.tgsin ? item.tgsin : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.order_uid ? '#' + item.order_uid : '-'}</Typography>
                <Typography className={styles.subValueText} mt={1}>
                  {item?.order_timestamp ? item.order_timestamp : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.quantity ? (item?.package_net_weight * item?.quantity) + item?.unit : 0}
                </Typography>
                <Typography className={styles.valueText} mt={1}>
                  No. of Packages: {item?.quantity ? item?.quantity : 0}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>Price/Kg: {currencySymbol}{pricePerKgWithoutReferralFee?.toFixed(7)} / Kg</Typography>
                <Typography className={styles.subValueText} mt={1}>Price/Package: {currencySymbol}{pricePerPackageWithoutReferralFee?.toFixed(6)} / Package</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {currencySymbol}{totalPrice ? totalPrice?.toFixed(2) : 0}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  Shipping Cost: {currencySymbol}{shippingCost ? shippingCost?.toFixed(2) : 0}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  {/*Referral Fee: {item?.referral_fee ? item.referral_fee + '%' : 0}*/}
                  Referral Fee: {currencySymbol}{(pricePerPackageReferralFee * item?.quantity)?.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {userCurrencySymbol}{userCargoPrice ? userCargoPrice?.toFixed(2) : 0}
                </Typography>
                <Typography className={styles.valueText} pl={3}>
                  ({currencySymbol}{cargoPrice ? cargoPrice?.toFixed(2) : 0})
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  Order Status: {orderStatus ? orderStatus?.replaceAll("_", " ") : '-'}
                </Typography>
                <Typography className={styles.subPayValueText} mt={1}>
                  Payment
                  Status: {item?.payment_status ? (item.payment_status == 'half_paid' ? '50% Paid' : item.payment_status) : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default OrderDetailView;
