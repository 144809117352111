import React, {useState, useEffect} from 'react'
import {
  Grid, Typography, Box,
  Stepper,
  Step,
  StepLabel, Card, CardContent
} from '@mui/material';
import {useNavigate, useLocation} from "react-router-dom";

import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";
import AlertDialogBox from "../../components/AlertDialogBox";

import {connect} from "react-redux";
import {updateForgotUserPassword, updateApiStatus, getForgotUserPassword} from "../../redux/actions/userDataActions";

import {THEME_COLOR2} from "../../constants";

import {ReactComponent as EyeVisibleIcon} from "../../assets/img/eye.svg";
import {ReactComponent as EyeHiddenIcon} from "../../assets/img/eye_slash.svg";
import LoginBgImg from "../../assets/img/login_bg_image.jpg";

const password_req = "1. Minimum Length: Passwords should typically be a minimum of 8 to 12 characters. Longer passwords are generally more secure. \n2. Complexity: Passwords should include a combination of: \nUppercase letters (A-Z) \nLowercase letters (a-z) \nNumbers (0-9) \nSpecial characters (e.g., !, @, #, $, %, etc.)";

const ForgotPassword = (props) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [linkAvailable, setLinkAvailable] = useState(false);
  const [error, setError] = useState(null);
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
  const [confirmPasswordEyeVisible, setConfirmPasswordEyeVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let forgot_password_uid = location.pathname.split("/").pop();

    let data = {
      uid: forgot_password_uid,
    };

    props.getForgotUserPassword(JSON.stringify(data))
  }, [])

  useEffect(() => {
    if (props?.apiStatus == 'UPDATE_FORGOT_USER_PASSWORD_SUCCESS') {
      setError(null);
      setPassword('')
      setConfirmPassword('')
      props.updateApiStatus('')
    } else if (props?.apiStatus == 'GET_FORGOT_USER_PASSWORD_SUCCESS') {
      setLinkAvailable(true)
      props.updateApiStatus('')
    } else if (props?.apiStatus == 'GET_FORGOT_USER_PASSWORD_FAIL') {
      setLinkAvailable(false)
      props.updateApiStatus('')
    }
  }, [props?.apiStatus])

  const onSubmitClick = () => {
    let errorText = {};
    if (password == '') {
      errorText = {errorText, password: 'Password is required'};
    } else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_ "])[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,12}$/.test(password)) {
      errorText = {...errorText, password: 'Password not secure'};
    }
    if (confirmPassword == '') {
      errorText = {...errorText, confirmPassword: 'Confirm Password is required'};
    }
    if (password != confirmPassword) {
      errorText = {...errorText, confirmPassword: 'Password not match'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    setError(null);

    let forgot_password_uid = location.pathname.split("/").pop();

    let data = {
      uid: forgot_password_uid,
      password: password,
    };

    props.updateForgotUserPassword(JSON.stringify(data))
  };

  const onPasswordRequirement = () => {
    setPopUpVisible(true);
  };

  const onPopupCloseClick = () => {
    setPopUpVisible(false);
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false)
    } else {
      setPasswordEyeVisible(true)
    }
  };

  const onConfirmPasswordEyeIconClick = () => {
    if (confirmPasswordEyeVisible) {
      setConfirmPasswordEyeVisible(false)
    } else {
      setConfirmPasswordEyeVisible(true)
    }
  };

  return (

    <Grid container item md={12} xs={12}>
      <Box sx={styles.bgImgView}>
        <img alt="" src={LoginBgImg} style={styles.imgStyle}/>
      </Box>
      {linkAvailable ?
        <Grid sx={styles.contentView}
              container item md={12} xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}>
          <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {/*<Box sx={styles.imgView} mr={2}>*/}
            {/*  <img alt="" src={Logo} style={styles.imgStyle}/>*/}
            {/*</Box>*/}
            <Box>
              <h2 style={styles.mainTitleText}>Trebbs</h2>
            </Box>
          </Grid>
          <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Card sx={[styles.cardView, {
              width: {md: '70%', sm: '90%', xs: '90%'},
              minHeight: {md: '40vw', sm: '100vw', xs: '100vw'}
            }]}>
              <Typography style={styles.titleText}>Forgot Password</Typography>
              <Typography style={styles.subTitleText} mt={1}>Trebbs</Typography>
              <CardContent style={styles.cardContentView}>

                <Grid container item md={12} xs={12}
                      sx={styles.textInputView} mt={4}>

                  <Grid
                    onClick={() => onPasswordRequirement()}
                    container item md={12} xs={12} display={'flex'} justifyContent={'flex-end'}>
                    <Typography sx={styles.passwordReqText}>Password Requirements</Typography>
                  </Grid>

                  <FormTextInput
                    page={'ForgotPassword'}
                    value={password}
                    setValue={setPassword}
                    placeholder={'Password'}
                    error={error?.password}
                    textInputViewStyle={styles.textInputViewStyle}
                    required={true}
                    type={passwordEyeVisible ? 'text' : 'password'}
                    icon={true}
                    rightIcon={passwordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> :
                      <EyeHiddenIcon height={15} width={15}/>}
                    onIconClick={onPasswordEyeIconClick}
                  />
                </Grid>

                <Grid container item md={12} xs={12} mt={1}
                      sx={styles.textInputView}>
                  <FormTextInput
                    page={'ForgotPassword'}
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    placeholder={'Re-enter Password'}
                    error={error?.confirmPassword}
                    textInputViewStyle={styles.textInputViewStyle}
                    required={true}
                    type={confirmPasswordEyeVisible ? 'text' : 'password'}
                    icon={true}
                    rightIcon={confirmPasswordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> :
                      <EyeHiddenIcon height={15} width={15}/>}
                    onIconClick={onConfirmPasswordEyeIconClick}
                  />
                </Grid>

                <Grid container
                      item md={12} xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      mt={4}>
                  <FormButton
                    title={'Submit'}
                    btnStyleView={styles.btnStyleView}
                    onSubmit={onSubmitClick}
                  />
                </Grid>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
        :
        <Grid sx={styles.contentView}
              container item md={12} xs={12}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}>
          <Typography sx={styles.forgotPswdText}>Link Not Available</Typography>
        </Grid>
      }

      <AlertDialogBox
        confirmBox={popUpVisible}
        title={'Password Requirements'}
        content={password_req}
        closeIcon={true}
        type={'passwordReq'}
        onCancelClick={onPopupCloseClick}
      />

      <AlertMsg/>

    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getForgotUserPassword: data => dispatch(getForgotUserPassword(data)),
    updateForgotUserPassword: data => dispatch(updateForgotUserPassword(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

const styles = {
  bgImgView: {
    position: 'fixed',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  contentView: {
    zIndex: 10,
    minHeight: window.innerHeight
  },
  imgView: {
    height: 100,
    width: 100,
  },
  mainTitleText: {
    color: '#FFF',
    fontFamily: 'InterBold',
    fontSize: 45
  },
  cardView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 2,
  },
  titleText: {
    textAlign: 'center',
    fontFamily: 'InterBold',
    fontSize: 30
  },
  subTitleText: {
    textAlign: 'center',
    color: '#a1a5b7',
    fontFamily: 'InterBold',
    fontSize: 17
  },
  cardContentView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textInputView: {
    width: '80%'
  },
  forgotPswdText: {
    textAlign: 'center',
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 35
  },
  passwordReqText: {
    fontSize: 12,
    fontFamily: 'InterMedium',
    color: THEME_COLOR2,
    cursor: 'pointer'
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
};

