import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import FormButton from "./FormButton";

import styles from './LandingHeader.module.css';

import {ReactComponent as LockIcon} from "../assets/img/lock.svg";
import {ReactComponent as HomeIcon} from "../assets/img/home.svg";

import {THEME_COLOR11, THEME_COLOR9} from "../constants";

import {connect} from "react-redux";

const LandingHeader = (props) => {
  const navigate = useNavigate();

  const onSellerLogin = () => {
    navigate('/sign_in')
  };

  const onSellerRegister = () => {
    navigate('/sign_up')
  };

  const onHomeClick = () => {
    if (props?.tgsin) {
      navigate('/index')
    } else {
      navigate('/')
    }
  }

  return (
    <Grid container item md={12} xs={12} className={styles.headerView}
          style={style.headerBgColor}
          alignItems={'center'} justifyContent={'center'}>
      <Grid container item md={10} xs={10} alignItems={'center'}>
        <Grid item xs={8}
              display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Typography className={styles.trebbsText} style={style.textColor}>Trebbs</Typography>
          <Typography className={styles.tradeText}>Trade beyond <span
            style={style.textColor}>borders</span></Typography>
        </Grid>
        <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-end'}>
          {props?.showHome || props?.tgsin ?
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}
                 className={styles.sellerLoginView}
                 onClick={() => onHomeClick()}>
              <HomeIcon height={15} width={15}/>
              <Typography className={styles.sellerLoginText} pl={0.3}>Home</Typography>
            </Box>
            :
            <>
              <Box display={{md:'flex',xs:'none'}}>
                <FormButton
                  btnType={'btn8'}
                  hoverTextColor={'#000'}
                  btnStyleView={style.buttonView8}
                  title={'Register now to export'}
                  onSubmit={() => onSellerRegister()}
                />
              </Box>
              <Box pl={3} display={'flex'} flexDirection={'row'} alignItems={'center'}
                   className={styles.sellerLoginView}
                   onClick={() => onSellerLogin()}>
                <LockIcon height={15} width={15}/>
                <Typography className={styles.sellerLoginText} pl={0.3}>Seller login</Typography>
              </Box>
            </>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    userToken: state.userData.userToken,
    tgsin: state.userData.tgsin,
  }
};

export default connect(
  mapStateToProps,
  null
)(LandingHeader);

const style = {
  buttonView8: {
    color: '#000',
    border: '1px solid #000',
  },
  headerBgColor: {
    backgroundColor: THEME_COLOR9
  },
  textColor: {
    color: THEME_COLOR11
  }
}
