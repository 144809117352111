import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {v4 as uuidv4} from "uuid";

import FormButton from "./FormButton";
import MultiImageUpload from "./MultiImageUpload";
import ImageUpload from "./ImageUpload";

import './../assets/css/custom.css';

const AddProductImageTab = (props) => {
  const [productUid, setProductUid] = useState('');
  const [productImageList, setProductImageList] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [displayMainImage, setDisplayMainImage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      let detail = props?.data;
      let img = [];
      if (detail?.image) {
        const imgArray = detail?.image.split(',');
        imgArray.map((x) => {
          img = [...img, {
            id: uuidv4(),
            file: '',
            image: x
          }]
        });
        setProductImageList(img);
      }
      if (detail?.mainImage) {
        setDisplayMainImage(detail?.mainImage)
      }
    }
  }, [props.data]);


  useEffect(() => {
    setProductUid(props?.productUid);
  }, [props?.productUid]);


  const onContinueClick = () => {
    let prdt_image_list = [...productImageList];

    let errorText = {};
    if (mainImage == null && displayMainImage == '') {
      errorText = {...errorText, mainImage: 'Main Image is required'};
    } else if(prdt_image_list?.length > 5) {
      errorText = {...errorText, image: 'Maximum 5 images are allowed to upload'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let formData = new FormData();
    formData.append('productUid', productUid);
    if (mainImage != null) {
      formData.append('main_product_image', mainImage);
    }

    let newImg = prdt_image_list.filter((x) => x.file != '');
    let oldImg = prdt_image_list.filter((x) => x.file == '');
    let final_old_img = [];
    oldImg.forEach(y => {
      final_old_img = [...final_old_img, y.image.substring(y.image.lastIndexOf('/') + 1)]
    });
    if (final_old_img?.length > 0) {
      formData.append("old_product_image", JSON.stringify(final_old_img));
    }

    if (newImg.length > 0) {
      newImg.forEach(y => {
        formData.append("product_image", y.file);
      });
    }

    if (productUid) {
      props.onUpdateProductImage(formData)
    } else {
      props.onAddProductImage(formData)
    }
  };


  const onRemoveMainImage = (type) => {
    setMainImage(null);
    setDisplayMainImage('');
  };

  const onChangeMainImage = (fileList, type) => {
    setMainImage(fileList[0].file);
    setDisplayMainImage(fileList[0].image);
  };

  const onRemoveImage = (data) => {
    let img_list = [...productImageList];
    let imgIndex = img_list.findIndex((x) => x.id == data.id);
    if (imgIndex >= 0) {
      img_list.splice(imgIndex, 1);
    }
    setProductImageList(img_list)
  };

  const onChangeImage = (fileList) => {
    let prdt_image_list = [...productImageList, ...fileList];
    setProductImageList(prdt_image_list);
  };


  return (
    <Grid container item xs={11.6} md={11.6} mt={2}
    >

      <Grid item xs={12} md={12}
            display={'flex'}
            flexDirection={'row'}
      >

        <Grid item md={2} xs={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
          <Typography style={styles.mainText} mb={2}>Main</Typography>
          <ImageUpload
            page={'AddProductImageTab'}
            type={'product'}
            maxFiles={1}
            image={displayMainImage}
            error={error?.mainImage}
            onRemove={onRemoveMainImage}
            onChange={onChangeMainImage}
          />
        </Grid>

        <Grid item md={10} xs={10} mt={4}>
          <MultiImageUpload
            imageLengthCheck={true}
            imageUpload={true}
            maxFiles={5}
            image={productImageList}
            error={error?.image}
            onRemove={onRemoveImage}
            onChange={onChangeImage}
          />
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4} mb={4}>
        <FormButton
          btnType={'btn11'}
          title={'Continue'}
          onSubmit={onContinueClick}
        />
      </Grid>

    </Grid>
  );
}

export default AddProductImageTab;

const styles = {
  mainText: {
    fontSize: 13,
    fontFamily: 'InterBold'
  }
}
