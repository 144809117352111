import React, {useEffect, useState, useRef} from 'react';
import {Grid, Typography, Box, TextField, TableCell, Snackbar} from '@mui/material';
import {useNavigate} from "react-router-dom";

import FormButton from "./FormButton";
import AlertDialogBox from "./AlertDialogBox";

import {THEME_COLOR2, THEME_COLOR7, THEME_COLOR9, USER_URL} from "../constants";

import {connect} from "react-redux";
import {
  deleteProduct,
  updateApiStatus,
  updateProductStatus
} from "../redux/actions/userDataActions";

import {ReactComponent as ExclaimIcon} from "../assets/img/exclamation.svg";

const ProductActionRow = (props) => {
  const [confirmBox, setConfirmBox] = useState(false);
  const [productUid, setProductUid] = useState('');
  const [copyOpen, setCopyOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == "UPDATE_PRODUCT_STATUS_SUCCESS") {
      setConfirmBox(false);
      setProductUid('')
      props.updateApiStatus("");
      if (props?.page == 'detail') {
        navigate('/product')
      }
    }
  }, [props.apiStatus]);

  const onInactivateList = () => {
    let data = {
      productUid: props?.uid,
      status: 'inactive',
      tabStatus: props?.tabStatus
    };
    props.updateProductStatus(JSON.stringify(data));
  };

  const onActivateList = () => {
    let data = {
      productUid: props?.uid,
      status: 'active',
      tabStatus: props?.tabStatus
    };
    props.updateProductStatus(JSON.stringify(data));
  };

  const onDeleteList = () => {
    setProductUid(props?.uid);
    setConfirmBox(true);
  };

  const onConfirmClick = () => {
    let data = {
      productUid: productUid,
      status: 'removed',
    };
    props.updateProductStatus(JSON.stringify(data));
  };

  const onCancelClick = () => {
    setProductUid('')
    setConfirmBox(false);
  };

  const onViewDetail = () => {
    navigate("/product/" + props.uid);
  };

  const onEdit = () => {
    navigate("/add_product?id=" + props.uid)
  };

  const onShareProduct = () => {
    let url = USER_URL + 'product_detail?id=' + props?.uid;
    setCopyOpen(true);
    navigator.clipboard.writeText(url)
  };

  return (
    <>
      <TableCell>
        {props.status == 'pending' ?
          <Box sx={style.btnView} mb={1}>
            <FormButton
              btnType={'btn11'}
              title={'Edit'}
              buttonView={style.editBtnView}
              width={'100%'}
              onSubmit={onEdit}
            />
          </Box>
          : null}
        {props.status == 'active' ?
          <Box sx={style.btnView}>
            <FormButton
              loading={props?.productActionLoading}
              btnType={'btn11'}
              title={'Inactivate listing'}
              buttonView={style.inactivateBtnView}
              textColorStyleView={'red'}
              width={'100%'}
              onSubmit={onInactivateList}
            />
          </Box>
          : null}
        {props.status == 'inactive' ?
          <Box sx={style.btnView}>
            <FormButton
              loading={props?.productActionLoading}
              btnType={'btn11'}
              title={'Activate listing'}
              buttonView={style.activateBtnView}
              textColorStyleView={THEME_COLOR7}
              width={'100%'}
              onSubmit={onActivateList}
            />
          </Box>
          : null}
        <Box sx={style.btnView} mt={1}>
          <FormButton
            btnType={'btn11'}
            title={'Delete listing'}
            bgColorStyleView={'#fff'}
            buttonView={style.deleteBtnView}
            width={'100%'}
            onSubmit={onDeleteList}
          />
        </Box>
        {((props.status == 'pending' || props.status == 'active') && (props?.page == 'list')) ?
          <Box sx={style.btnView} mt={1}>
            <FormButton
              btnType={'btn11'}
              title={'View Details'}
              buttonView={style.viewBtnView}
              width={'100%'}
              onSubmit={onViewDetail}
            />
          </Box>
          : null}
        {(props.status == 'active') ?
          <Box sx={style.btnView} mt={1}>
            <FormButton
              btnType={'btn9'}
              title={'Share Product'}
              width={'100%'}
              onSubmit={onShareProduct}
            />
          </Box>
          : null}
      </TableCell>

      <Snackbar
        message="Copied"
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={2000}
        onClose={() => setCopyOpen(false)}
        open={copyOpen}
        ContentProps={{
          sx: {
            width: "100%",
            "& .MuiSnackbarContent-message": {
              width: "inherit",
              textAlign: "center",
            }
          }
        }}
      />

      <AlertDialogBox
        confirmBox={confirmBox}
        type={'delete'}
        icon={<ExclaimIcon height={70} width={70}/>}
        content={"Are you sure you would like to delete?"}
        btnLoading={props?.productActionLoading}
        firstBtn={"Yes, delete!"}
        secondBtn={"No, cancel"}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    productActionLoading: state.userData.productActionLoading,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteProduct: data => dispatch(deleteProduct(data)),
    updateProductStatus: data => dispatch(updateProductStatus(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionRow);


const style = {
  editBtnView: {
    backgroundColor: THEME_COLOR9,
    color: '#000',
    border: '1px solid #aaa',
    width: '100%'
  },
  inactivateBtnView: {
    backgroundColor: THEME_COLOR9,
    color: 'red',
    border: '1px solid #aaa',
    width: '100%'
  },
  activateBtnView: {
    backgroundColor: THEME_COLOR9,
    color: THEME_COLOR7,
    border: '1px solid #aaa',
    width: '100%'
  },
  deleteBtnView: {
    backgroundColor: '#fff',
    color: '#000',
    border: '1px solid #aaa',
    width: '100%'
  },
  viewBtnView: {
    backgroundColor: THEME_COLOR9,
    color: '#000',
    border: '1px solid #aaa',
    width: '100%'
  },
  btnView: {
    cursor: 'pointer'
  }
};
