import React, {useEffect, useState} from 'react'
import {
  Typography,
  Grid,
  Box,
  Tabs,
  Tab, Snackbar
} from "@mui/material";
import {useNavigate} from "react-router-dom";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import AddCorporateDetails from "../../components/AddCorporateDetails";
import AddContactDetails from "../../components/AddContactDetails";
import AddExporterDetails from "../../components/AddExporterDetails";
import AddProductDetails from "../../components/AddProductDetails";
import LoginDetails from "../../components/LoginDetails";

import {connect} from "react-redux";
import {
  getRegisterData,
  getSellerDetail,
  updateSellerContactDetail,
  listCategory
} from "../../redux/actions/userDataActions";

import '../../assets/css/custom.css';

import {THEME_COLOR7, THEME_COLOR8, USER_URL} from "../../constants";


import styles from './MyAccount.module.css'

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MyAccount = (props) => {
  const [value, setValue] = useState(0);
  const [sellerUid, setSellerUid] = useState('');
  const [corporateDetail, setCorporateDetail] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);
  const [exporterDetail, setExporterDetail] = useState(null);
  const [productDetail, setProductDetail] = useState([]);
  const [loginDetails, setLoginDetails] = useState(null);
  const [agreeBox, setAgreeBox] = useState(false);
  const [error, setError] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    props.getRegisterData();
    props.getSellerDetail();
    props.listCategory();
  }, []);

  useEffect(() => {
    if (props?.sellerDetail != null) {
      setCorporateDetail(props?.sellerDetail?.corporateDetails ? props?.sellerDetail.corporateDetails : null);
      setContactDetail(props?.sellerDetail?.contactDetails ? props?.sellerDetail.contactDetails : null)

      setExporterDetail({
        exportLicenseDetails: props?.sellerDetail?.exportLicenseDetails ? props?.sellerDetail.exportLicenseDetails : null,
        exportFssaiDetails: props?.sellerDetail?.exportFssaiDetails ? props?.sellerDetail.exportFssaiDetails : null,
        exportRcmcDetails: props?.sellerDetail?.exportRcmcDetails ? props?.sellerDetail.exportRcmcDetails : null,
        exportTaxDetails: props?.sellerDetail?.exportTaxDetails ? props?.sellerDetail.exportTaxDetails : null,
        bankDetails: props?.sellerDetail?.bankDetails ? props?.sellerDetail.bankDetails : null,
        documentDetails: props?.sellerDetail?.documentDetails ? props?.sellerDetail.documentDetails : null
      })

      setProductDetail(props?.sellerDetail?.productDetails ? props?.sellerDetail.productDetails : null)
      setSellerUid(props?.sellerDetail?.sellerUid ? props?.sellerDetail.sellerUid : '')
      if (props?.sellerDetail?.contactDetails) {
        if (props?.sellerDetail?.contactDetails?.sellerId == '') {
          setAgreeBox(false)
        } else {
          setAgreeBox(true)
        }
      }

      setLoginDetails(props?.sellerDetail?.loginDetails ? props?.sellerDetail?.loginDetails : null)
    }
  }, [props?.sellerDetail])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAddContactDetailClick = (data) => {

    setContactDetail(data)
    let data1 = {
      seller_id: data.sellerId,
      first_name: data.firstName,
      last_name: data.lastName,
      designation: data.designation,
      business_address: data.businessAddress,
      city: data.city,
      state_province: data.stateProvince.name,
      zip_code: data.zipCode,
      email: data.email,
      mobile_no: data.mobileNo,
      mobile_no_code: data.mobileNoCode.phone,
      mobile_no_country: data.mobileNoCode.code,
      whatsapp_no: data.whatsAppNo,
      whatsapp_no_code: data.whatsAppNoCode.phone,
      whatsapp_no_country: data.whatsAppNoCode.code,
    }

    if (data?.sellerId) {
      props.updateSellerContactDetail(JSON.stringify(data1))
    }
  };


  return (
    <Container page={"MyAccount"}>

      <Grid item md={12} xs={12} px={2} pt={2}>
        <Grid item md={12} xs={12}
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
          <Grid item md={6} xs={12}>
            <Typography className={styles.myAccountText} mb={2}>My Account</Typography>
          </Grid>
        </Grid>

        <Tabs scrollButtons="on" variant="scrollable" value={value} onChange={handleChange}
              sx={{
                borderBottom: '1px solid #ddd',
                "& .MuiTab-root": {
                  color: THEME_COLOR7,
                  fontSize: 15,
                  fontFamily: 'InterMedium',
                  textTransform: 'capitalize',
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: THEME_COLOR8,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: THEME_COLOR8,
                  fontSize: 15,
                  fontFamily: 'InterMedium',
                  textTransform: 'capitalize'
                }
              }}
              aria-label="basic tabs example">
          <Tab label={'Corporate Details'} {...a11yProps(0)} />
          <Tab label={'Contact Details'} {...a11yProps(1)} />
          <Tab label={'Exporter Details'} {...a11yProps(2)} />
          <Tab label={'Product Details'} {...a11yProps(3)} />
          <Tab label={'Login Details'} {...a11yProps(4)} />
        </Tabs>
      </Grid>

      <Grid item md={12} xs={12}>
        <TabPanel value={value} index={0}>
          <AddCorporateDetails
            data={corporateDetail}
            agreeBox={agreeBox}
            setAgreeBox={setAgreeBox}
            error={error}
            setError={setError}
            entityTypeList={props.getRegisterDataList?.entityTypeList ? props.getRegisterDataList.entityTypeList : []}
            type={'edit'}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <AddContactDetails
            data={contactDetail}
            sellerUid={sellerUid}
            type={'edit'}
            onAddContactDetailClick={onAddContactDetailClick}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <AddExporterDetails
            data={exporterDetail}
            type={'edit'}
          />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <AddProductDetails
            data={productDetail}
            categoryList={props?.categoryList}
            type={'edit'}
          />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <LoginDetails
            data={loginDetails}
            type={'edit'}
          />
        </TabPanel>
      </Grid>
      <AlertMsg/>

    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    getRegisterDataList: state.userData.getRegisterDataList,
    sellerDetail: state.userData.sellerDetail,
    categoryList: state.userData.categoryList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRegisterData: data => dispatch(getRegisterData(data)),
    getSellerDetail: data => dispatch(getSellerDetail(data)),
    updateSellerContactDetail: data => dispatch(updateSellerContactDetail(data)),
    listCategory: data => dispatch(listCategory(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
