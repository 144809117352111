import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";

import styles from './ProductPackingDetailView.module.css'

const ProductPackingDetailView = (props) => {

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Packing Material</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Package</Typography>
                <Typography className={styles.subTitleText} ml={1}>Net Weight</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Package</Typography>
                <Typography className={styles.subTitleText} ml={1}>Gross Weight</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Package</Typography>
                <Typography className={styles.subTitleText} ml={1}>Length</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Package</Typography>
                <Typography className={styles.subTitleText} ml={1}>Width</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Package</Typography>
                <Typography className={styles.subTitleText} ml={1}>Height</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Package</Typography>
                <Typography className={styles.volumeTitleText} ml={1}>Volume (m<sup>3</sup>)
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productPackingPriceDetail != null ?
                    (item?.productPackingPriceDetail?.packingMaterial ? item?.productPackingPriceDetail?.packingMaterial : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productPackingPriceDetail != null ?
                    (item?.productPackingPriceDetail?.packageNetWeight ? item?.productPackingPriceDetail?.packageNetWeight + 'Kg' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productPackingPriceDetail != null ?
                    (item?.productPackingPriceDetail?.packageGrossWeight ? item?.productPackingPriceDetail?.packageGrossWeight + 'Kg' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.length ? item?.productShippingDetail?.length + 'cm' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.width ? item?.productShippingDetail?.width + 'cm' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.height ? item?.productShippingDetail?.height + 'cm' : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productShippingDetail != null ?
                    (item?.productShippingDetail?.height ?
                      (((item?.productShippingDetail?.length * item?.productShippingDetail?.width * item?.productShippingDetail?.height) / 1000000)) : '0')
                    : '0'} m<sup>3</sup>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default ProductPackingDetailView;
