import React from 'react';
import {Box, Grid, Typography} from "@mui/material";

import FormTextInput from "./FormTextInput";
import ImageUpload from "./ImageUpload";

import {ReactComponent as CloseIcon} from "../assets/img/close_black.svg";

const UploadProductDocument = (props) => {

  return (
    <Grid container item md={12} xs={12}
          display={'flex'} flexDirection={'row'}
          alignItems={'center'}
          mt={4}>

      <Grid item md={4} xs={4}>
        <FormTextInput
          page={'UploadProductDocument'}
          value={props?.documentName}
          setValue={(val) => props?.setDocumentName(val)}
          error={props?.errorDocumentName}
          textInputViewStyle={style.textInputViewStyle}
          required={true}
        />
      </Grid>

      <Grid item md={4} xs={4} ml={2}>
        <ImageUpload
          fileType={'pdf'}
          fileSize={false}
          maxFiles={1}
          image={props?.displayDocumentImage}
          error={props?.errorDocumentImage}
          onRemove={props?.onRemoveImage}
          onChange={props?.onChangeImage}/>
      </Grid>

    </Grid>
  )
};

export default UploadProductDocument;


const style = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
};


