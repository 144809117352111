import React, {useEffect, useState} from 'react';
import {Grid, Box, Stepper, Step, StepLabel, Card} from '@mui/material';

import AddCorporateDetails from "../components/AddCorporateDetails";
import AddContactDetails from "../components/AddContactDetails";
import AddExporterDetails from "../components/AddExporterDetails";
import AddProductDetails from "../components/AddProductDetails";

import {connect} from "react-redux";
import {
  getRegisterData,
  getSellerDetail,
  addSellerContactDetail,
  updateSellerContactDetail,
  updateApiStatus,
  updateShowTgsin
} from "../redux/actions/userDataActions";

import './../assets/css/custom.css';

import {THEME_COLOR2} from "../constants";

const BusinessDetails = (props) => {
  const [sellerUid, setSellerUid] = useState('');
  const [corporateDetail, setCorporateDetail] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);
  const [exporterDetail, setExporterDetail] = useState(null);
  const [productDetail, setProductDetail] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [agreeBox, setAgreeBox] = useState(false);
  const [error, setError] = useState(null);

  const steps = [
    'Corporate details',
    'Contact details',
    'Exporter details',
    'Product details',
  ];

  useEffect(() => {
    props.getRegisterData();
    props.getSellerDetail();
  }, []);

  useEffect(() => {
    if (props?.sellerDetail != null) {
      setCorporateDetail(props?.sellerDetail?.corporateDetails ? props?.sellerDetail.corporateDetails : null);
      setContactDetail(props?.sellerDetail?.contactDetails ? props?.sellerDetail.contactDetails : null)

      setExporterDetail({
        exportLicenseDetails: props?.sellerDetail?.exportLicenseDetails ? props?.sellerDetail.exportLicenseDetails : null,
        exportFssaiDetails: props?.sellerDetail?.exportFssaiDetails ? props?.sellerDetail.exportFssaiDetails : null,
        exportRcmcDetails: props?.sellerDetail?.exportRcmcDetails ? props?.sellerDetail.exportRcmcDetails : null,
        exportTaxDetails: props?.sellerDetail?.exportTaxDetails ? props?.sellerDetail.exportTaxDetails : null,
        bankDetails: props?.sellerDetail?.bankDetails ? props?.sellerDetail.bankDetails : null,
        documentDetails: props?.sellerDetail?.documentDetails ? props?.sellerDetail.documentDetails : null
      })

      setProductDetail(props?.sellerDetail?.productDetails ? props?.sellerDetail.productDetails : null)
      setSellerUid(props?.sellerDetail?.sellerUid ? props?.sellerDetail.sellerUid : '')
      if (props?.sellerDetail?.contactDetails) {
        if (props?.sellerDetail?.contactDetails?.sellerId == '') {
          setAgreeBox(false)
        } else {
          setAgreeBox(true)
        }
      }
    }
  }, [props?.sellerDetail])

  useEffect(() => {
    if(activeTab == 3) {
      props.updateShowTgsin(false)
    }
  },[activeTab])

  useEffect(() => {
    if (props.apiStatus == "ADD_SELLER_CONTACT_DETAILS_SUCCESS"
      || props.apiStatus == "UPDATE_SELLER_CONTACT_DETAILS_SUCCESS") {
      setActiveTab(2);
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  const onAddCorporateDetailClick = (data) => {
    // setCorporateDetail(data);
    setActiveTab(1);
  };

  const onAddContactDetailClick = (data) => {

    setContactDetail(data)
    let data1 = {
      seller_id: data.sellerId,
      first_name: data.firstName,
      last_name: data.lastName,
      designation: data.designation,
      business_address: data.businessAddress,
      city: data.city,
      state_province: data.stateProvince.name,
      zip_code: data.zipCode,
      email: data.email,
      mobile_no: data.mobileNo,
      mobile_no_code: data.mobileNoCode.phone,
      mobile_no_country: data.mobileNoCode.code,
      whatsapp_no: data.whatsAppNo,
      whatsapp_no_code: data.whatsAppNoCode.phone,
      whatsapp_no_country: data.whatsAppNoCode.code,
    }

    if (data?.sellerId) {
      props.updateSellerContactDetail(JSON.stringify(data1))
    } else {
      props.addSellerContactDetail(JSON.stringify(data1))
    }
  };

  const onContinueExporterDetailClick = () => {
    if(exporterDetail?.exportLicenseDetails?.sellerId == ''
      || exporterDetail?.exportRcmcDetails?.rcmcDetails?.length == 0
      || exporterDetail?.exportTaxDetails?.sellerId == ''
      || exporterDetail?.bankDetails?.sellerId == ''
      || exporterDetail?.documentDetails?.sellerId == '') {
      return;
    }
    setActiveTab(3);
  };

  const onTabClick = (index) => {
    if((index == 1 || index == 2 || index == 3) && corporateDetail?.corporateCountry == '' || !agreeBox) {
      let errorText = {};
      if (!agreeBox) {
        errorText = {agreeTerms: 'Please Agree the terms & conditions'};
        if (Object.keys(errorText).length != 0) {
          setError(errorText);
          return;
        }
      } else {
        setError(null)
      }
      return;
    } else if((index == 2 || index == 3) && contactDetail?.sellerId == '') {
      return;
    } else if((index == 3) && (exporterDetail?.exportLicenseDetails?.sellerId == ''
      || exporterDetail?.exportRcmcDetails?.rcmcDetails?.length == 0
      || exporterDetail?.exportTaxDetails?.sellerId == ''
      || exporterDetail?.bankDetails?.sellerId == ''
      || exporterDetail?.documentDetails?.sellerId == '')) {
      return;
    }
    setError(null)
    setActiveTab(index)
  }

  return (
    <Grid item container xs={12} md={12}
          display={"flex"} alignItems={"center"} justifyContent={"center"}>

      <Box sx={{width: {md: '90%', xs: '100%'}}}>
        <Stepper activeStep={activeTab} alternativeLabel>
          {steps?.map((label, index) => (
            <Step key={label}
                  onClick={() => onTabClick(index)}
                  sx={styles.mainStep}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {(activeTab == 0 ?
        (<Grid container
               direction="column"
               style={{width: '75%'}} mt={4}>
          <AddCorporateDetails
            data={corporateDetail}
            agreeBox={agreeBox}
            setAgreeBox={setAgreeBox}
            error={error}
            setError={setError}
            entityTypeList={props.getRegisterDataList?.entityTypeList ? props.getRegisterDataList.entityTypeList : []}
            onAddCorporateDetailClick={onAddCorporateDetailClick}/>
        </Grid>)
        : (activeTab == 1 ?
          (<Grid container
                 direction="column"
                 style={{width: '75%'}} mt={4}>
            <AddContactDetails
              data={contactDetail}
              sellerUid={sellerUid}
              onAddContactDetailClick={onAddContactDetailClick}
            />
          </Grid>) : (activeTab == 2 ?
              (<Grid container
                     direction="column"
                     style={{width: '75%'}} mt={4}>
                <AddExporterDetails
                  data={exporterDetail}
                  onContinue={onContinueExporterDetailClick}
                />
              </Grid>) :
              (<Grid container
                     direction="column"
                     style={{width: '75%'}} mt={4}>
                <AddProductDetails
                  data={productDetail}
                />
              </Grid>)
          )))}

    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    getRegisterDataList: state.userData.getRegisterDataList,
    sellerDetail: state.userData.sellerDetail,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRegisterData: data => dispatch(getRegisterData(data)),
    getSellerDetail: data => dispatch(getSellerDetail(data)),
    addSellerContactDetail: data => dispatch(addSellerContactDetail(data)),
    updateSellerContactDetail: data => dispatch(updateSellerContactDetail(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
    updateShowTgsin: data => dispatch(updateShowTgsin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails);

const styles = {
  mainStep: {
    '& .MuiStepLabel-label': {
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 8}
    },
    '& .MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-root': {
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: THEME_COLOR2, // circle color (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (ACTIVE)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-completed': {
      color: THEME_COLOR2, // circle color (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (COMPLETED)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
  },
};

