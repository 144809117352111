import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Grid, Box, Card, CardContent, CardMedia, Typography, CardActionArea} from '@mui/material';

import FormButton from "../../components/FormButton";
import LandingContent from "../../components/LandingContent";
import LandingContent2 from "../../components/LandingContent2";
import LandingHeader from "../../components/LandingHeader";
import LandingFooter from "../../components/LandingFooter";

import {THEME_COLOR11, THEME_COLOR9} from "../../constants";

import styles from './Landing.module.css';

import LandingImage from "../../assets/img/landing.png"

const Landing = (props) => {

  const navigate = useNavigate();

  const onSellerRegister = () => {
    navigate('/sign_up')
  };

  return (
    <Grid container item md={12} xs={12} justifyContent={'center'} className={styles.mainView}
          style={{minHeight: window.innerHeight}}>

      <LandingHeader
        showHome={false}
      />

      <Grid item md={10} xs={10} mt={{md: 0, xs: 8}}>

        <Grid container item md={12} xs={12} justifyContent={'space-between'}>
          <Grid item md={3.6} xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Typography className={styles.titleText}>Open your door to the global <span style={style.textColor}>agri market</span></Typography>
            <Typography className={styles.descText} mt={2}>Ditch the middleman and harvest a world of opportunity. Our
              global
              B2B marketplace connects
              agri-exporters and importers for fast, secure, and transparent trading. Streamline your operations, find
              the
              perfect match for your needs, and unlock a world of agricultural commerce – all in one place</Typography>
            <Box mt={2} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
              <FormButton
                btnType={'btn8'}
                hoverTextColor={'#000'}
                btnStyleView={style.buttonView8}
                title={'Register now to export'}
                onSubmit={() => onSellerRegister()}
              />
            </Box>
          </Grid>
          <Grid item md={7.6} xs={12} display={{md: 'flex', xs: 'none'}} justifyContent={'flex-end'}>
            <Box className={styles.landingImageView}>
              <img alt="" src={LandingImage} className={styles.landingImageStyle}/>
            </Box>
          </Grid>
          <LandingContent/>
        </Grid>

        <LandingContent2/>

      </Grid>

      <LandingFooter/>
    </Grid>
  )
};


export default Landing;

const style = {
  buttonView8: {
    color: '#000',
    border: '1px solid #000',
  },
  headerBgColor: {
    backgroundColor: THEME_COLOR9
  },
  textColor: {
    color: THEME_COLOR11
  }
}
