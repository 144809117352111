import {put, takeLatest, call} from "redux-saga/effects";

import {
  LOGIN_USER,
  GET_REGISTER_DATA,
  REGISTER_USER,
  ADD_PRODUCT_DATA,
  GET_PRODUCT_DETAIL,
  ADD_PRODUCT_VARIETY,
  ADD_PRODUCT_IMAGE,
  ADD_PRODUCT_PACKING_PRICE,
  ADD_PRODUCT_SHIPPING,
  UPDATE_PRODUCT_VARIETY,
  UPDATE_PRODUCT_IMAGE,
  UPDATE_PRODUCT_PACKING_PRICE,
  UPDATE_PRODUCT_SHIPPING,
  LIST_PRODUCT,
  DELETE_PRODUCT,
  LIST_CATEGORY,
  GET_SELLER_DETAIL,
  UPDATE_SELLER_CONTACT_DETAILS,
  ADD_SELLER_CONTACT_DETAILS,
  ADD_SELLER_EXPORT_LICENSE_DETAILS,
  UPDATE_SELLER_EXPORT_LICENSE_DETAILS,
  ADD_SELLER_EXPORT_RCMC_DETAILS,
  UPDATE_SELLER_EXPORT_RCMC_DETAILS,
  DELETE_SELLER_EXPORT_RCMC_DETAILS,
  ADD_SELLER_EXPORT_FSSAI_DETAILS,
  UPDATE_SELLER_EXPORT_FSSAI_DETAILS,
  ADD_SELLER_EXPORT_TAX_DETAILS,
  UPDATE_SELLER_EXPORT_TAX_DETAILS,
  ADD_SELLER_BANK_DETAILS,
  UPDATE_SELLER_BANK_DETAILS,
  ADD_SELLER_DOCUMENTS,
  UPDATE_SELLER_DOCUMENTS,
  ADD_SELLER_PRODUCT_CATEGORY,
  // CHECK_CART_PRODUCT,
  GET_STATES,
  GET_CITIES,
  GET_ORDER_DETAIL,
  GET_DASHBOARD,
  UPDATE_PRODUCT_PRICE_PER_PACKAGE,
  UPDATE_PRODUCT_STATUS,
  GET_LOGIN_SELLER_DETAIL,
  LIST_ORDER,
  CONFIRM_SHIPMENT,
  GET_ORDER_COUNT,
  UPDATE_MAIN_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  ADD_PRODUCT_DOCUMENT,
  DELETE_PRODUCT_DOCUMENT,
  GET_SELLER_CATEGORY_LIST,
  FORGOT_USER_NAME,
  FORGOT_USER_PASSWORD,
  UPDATE_FORGOT_USER_PASSWORD,
  GET_FORGOT_USER_PASSWORD,
  UPDATE_SELLER_USERNAME,
  UPDATE_SELLER_PASSWORD
} from "../actions/types";
import {
  loginUserSuccess,
  loginUserFail,
  getRegisterDataSuccess,
  getRegisterDataFail,
  registerUserSuccess,
  registerUserFail,
  addProductDataSuccess,
  addProductDataFail,
  addProductVarietySuccess,
  addProductVarietyFail,
  addProductImageSuccess,
  addProductImageFail,
  addProductPackingPriceSuccess,
  addProductPackingPriceFail,
  addProductShippingSuccess,
  addProductShippingFail,
  updateProductVarietySuccess,
  updateProductVarietyFail,
  updateProductImageSuccess,
  updateProductImageFail,
  updateProductPackingPriceSuccess,
  updateProductPackingPriceFail,
  updateProductShippingSuccess,
  updateProductShippingFail,
  getProductDetailSuccess,
  getProductDetailFail,
  listProductSuccess,
  listProductFail,
  deleteProductSuccess,
  deleteProductFail,
  listCategorySuccess,
  listCategoryFail,
  getSellerDetailSuccess,
  getSellerDetailFail,
  addSellerContactDetailSuccess,
  addSellerContactDetailFail,
  updateSellerContactDetailSuccess,
  updateSellerContactDetailFail,
  addSellerExportLicenseDetailSuccess,
  addSellerExportLicenseDetailFail,
  updateSellerExportLicenseDetailSuccess,
  updateSellerExportLicenseDetailFail,
  addSellerExportRcmcDetailSuccess,
  addSellerExportRcmcDetailFail,
  updateSellerExportRcmcDetailSuccess,
  updateSellerExportRcmcDetailFail,
  deleteSellerExportRcmcDetailSuccess,
  deleteSellerExportRcmcDetailFail,
  addSellerExportFssaiDetailSuccess,
  addSellerExportFssaiDetailFail,
  updateSellerExportFssaiDetailSuccess,
  updateSellerExportFssaiDetailFail,
  addSellerExportTaxDetailSuccess,
  addSellerExportTaxDetailFail,
  updateSellerExportTaxDetailSuccess,
  updateSellerExportTaxDetailFail,
  addSellerBankDetailSuccess,
  addSellerBankDetailFail,
  updateSellerBankDetailSuccess,
  updateSellerBankDetailFail,
  addSellerDocumentsSuccess,
  addSellerDocumentsFail,
  updateSellerDocumentsSuccess,
  updateSellerDocumentsFail,
  addSellerProductCategorySuccess,
  addSellerProductCategoryFail,
  // checkCartProductSuccess,
  // checkCartProductFail,
  getStatesSuccess,
  getStatesFail,
  getCitiesSuccess,
  getCitiesFail,
  getOrderDetailSuccess,
  getOrderDetailFail,
  getDashboardSuccess,
  getDashboardFail,
  updateProductPricePerPackageSuccess,
  updateProductPricePerPackageFail,
  updateProductStatusSuccess,
  updateProductStatusFail,
  getLoginSellerDetailSuccess,
  getLoginSellerDetailFail,
  listOrderSuccess,
  listOrderFail,
  confirmShipmentSuccess,
  confirmShipmentFail,
  getOrderCountSuccess,
  getOrderCountFail,
  updateMainProductImageSuccess,
  updateMainProductImageFail,
  deleteProductImageSuccess,
  deleteProductImageFail,
  addProductDocumentSuccess,
  addProductDocumentFail,
  deleteProductDocumentSuccess,
  deleteProductDocumentFail,
  getSellerCategoryListSuccess,
  getSellerCategoryListFail,
  forgotUserNameSuccess,
  forgotUserNameFail,
  forgotUserPasswordSuccess,
  forgotUserPasswordFail,
  updateForgotUserPasswordSuccess,
  updateForgotUserPasswordFail,
  getForgotUserPasswordSuccess,
  getForgotUserPasswordFail,
  updateSellerUsernameSuccess,
  updateSellerUsernameFail,
  updateSellerPasswordSuccess,
  updateSellerPasswordFail
} from "../actions/userDataActions";

import * as Api from "../../api";

function* onLoginUser(data) {
  try {
    const response = yield call(Api.sellerLogin, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(loginUserSuccess({...response, userName: payload_data.username}));
    } else {
      yield put(loginUserFail(response));
    }
  } catch (error) {
    yield put(loginUserFail({msg: "Failed"}));
  }
}

function* onGetRegisterData() {
  try {
    const response = yield call(Api.getRegisterData);
    if (response.status == "Success") {
      yield put(getRegisterDataSuccess(response));
    } else {
      yield put(getRegisterDataFail(response));
    }
  } catch (error) {
    yield put(getRegisterDataFail("Failed"));
  }
}

function* onRegisterUser(data) {
  try {
    const response = yield call(Api.sellerRegister, data.payload);
    if (response.status == "Success") {
      yield put(registerUserSuccess(response));
    } else {
      yield put(registerUserFail(response));
      // window.location.href = 'sign_in';
    }
  } catch (error) {
    yield put(registerUserFail({msg: "Failed"}));
    // window.location.href = 'sign_in';
  }
}

function* onAddProductData() {
  try {
    const response = yield call(Api.getAddProductData);
    if (response.status == "Success") {
      yield put(addProductDataSuccess(response));
    } else {
      yield put(addProductDataFail(response));
    }
  } catch (error) {
    yield put(addProductDataFail("Failed"));
  }
}

function* onGetProductDetail(data) {
  try {
    const response = yield call(Api.getProductDetail, data.payload);
    if (response.status == "Success") {
      yield put(getProductDetailSuccess(response));
      const response1 = yield call(Api.getAddProductData);
      if (response1.status == "Success") {
        yield put(addProductDataSuccess(response1));
      } else {
        yield put(addProductDataFail(response1));
      }
    } else {
      yield put(getProductDetailFail(response));
    }
  } catch (error) {
    yield put(getProductDetailFail("Failed"));
  }
}

function* onUpdateProductStatus(data) {
  try {
    const response = yield call(Api.updateProductStatus, data.payload);
    if (response.status == "Success") {
      let payload_data = JSON.parse(data.payload);
      yield put(updateProductStatusSuccess({...response, tabStatus: payload_data?.tabStatus}));
    } else {
      yield put(updateProductStatusFail(response));
    }
  } catch (error) {
    yield put(updateProductStatusFail("Failed"));
  }
}

function* onAddProductVariety(data) {
  try {
    const response = yield call(Api.addProductVariety, data.payload);
    if (response.status == "Success") {
      yield put(addProductVarietySuccess(response));
    } else {
      yield put(addProductVarietyFail(response));
    }
  } catch (error) {
    yield put(addProductVarietyFail("Failed"));
  }
}

function* onAddProductImage(data) {
  try {
    const response = yield call(Api.addProductImage, data.payload);
    if (response.status == "Success") {
      yield put(addProductImageSuccess(response));
    } else {
      yield put(addProductImageFail(response));
    }
  } catch (error) {
    yield put(addProductImageFail("Failed"));
  }
}

function* onAddProductPackingPrice(data) {
  try {
    const response = yield call(Api.addProductPackingPrice, data.payload);
    if (response.status == "Success") {
      yield put(addProductPackingPriceSuccess(response));
    } else {
      yield put(addProductPackingPriceFail(response));
    }
  } catch (error) {
    yield put(addProductPackingPriceFail("Failed"));
  }
}

function* onAddProductShipping(data) {
  try {
    const response = yield call(Api.addProductShipping, data.payload);
    if (response.status == "Success") {
      yield put(addProductShippingSuccess(response));
    } else {
      yield put(addProductShippingFail(response));
    }
  } catch (error) {
    yield put(addProductShippingFail("Failed"));
  }
}

function* onUpdateProductVariety(data) {
  try {
    const response = yield call(Api.updateProductVariety, data.payload);
    if (response.status == "Success") {
      yield put(updateProductVarietySuccess(response));
    } else {
      yield put(updateProductVarietyFail(response));
    }
  } catch (error) {
    yield put(updateProductVarietyFail("Failed"));
  }
}

function* onUpdateProductImage(data) {
  try {
    const response = yield call(Api.updateProductImage, data.payload);
    if (response.status == "Success") {
      yield put(updateProductImageSuccess(response));
    } else {
      yield put(updateProductImageFail(response));
    }
  } catch (error) {
    yield put(updateProductImageFail("Failed"));
  }
}

function* onUpdateProductPackingPrice(data) {
  try {
    const response = yield call(Api.updateProductPackingPrice, data.payload);
    if (response.status == "Success") {
      yield put(updateProductPackingPriceSuccess(response));
    } else {
      yield put(updateProductPackingPriceFail(response));
    }
  } catch (error) {
    yield put(updateProductPackingPriceFail("Failed"));
  }
}

function* onUpdateProductPricePerPackage(data) {
  try {
    const response = yield call(Api.updateProductPricePerPackage, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(updateProductPricePerPackageSuccess({...response, sku: payload_data?.sku}));
    } else {
      yield put(updateProductPricePerPackageFail(response));
    }
  } catch (error) {
    yield put(updateProductPricePerPackageFail("Failed"));
  }
}

function* onUpdateProductShipping(data) {
  try {
    const response = yield call(Api.updateProductShipping, data.payload);
    if (response.status == "Success") {
      yield put(updateProductShippingSuccess(response));
    } else {
      yield put(updateProductShippingFail(response));
    }
  } catch (error) {
    yield put(updateProductShippingFail("Failed"));
  }
}

function* onDeleteProduct(data) {
  try {
    const response = yield call(Api.deleteProduct, data.payload);
    if (response.status == "Success") {
      yield put(deleteProductSuccess(response));
    } else {
      yield put(deleteProductFail(response));
    }
  } catch (error) {
    yield put(deleteProductFail({msg: "Failed"}));
  }
}

function* onListProduct(data) {
  try {
    const response = yield call(Api.listProduct, data.payload);
    if (response.status == "Success") {
      yield put(listProductSuccess(response));
    } else {
      yield put(listProductFail(response));
    }
  } catch (error) {
    yield put(listProductFail("Failed"));
  }
}

function* onListCategory(data) {
  try {
    const response = yield call(Api.listCategory, data.payload);
    if (response.status == "Success") {
      yield put(listCategorySuccess(response));
    } else {
      yield put(listCategoryFail(response));
    }
  } catch (error) {
    yield put(listCategoryFail("Failed"));
  }
}

function* onGetSellerDetail(data) {
  try {
    const response = yield call(Api.getSellerDetail, data.payload);
    if (response.status == "Success") {
      yield put(getSellerDetailSuccess(response));
    } else {
      yield put(getSellerDetailFail(response));
    }
  } catch (error) {
    yield put(getSellerDetailFail("Failed"));
  }
}

function* onAddSellerContactDetail(data) {
  try {
    const response = yield call(Api.addSellerContactDetail, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(addSellerContactDetailSuccess({...response, contact_data: payload_data}));
    } else {
      yield put(addSellerContactDetailFail(response));
    }
  } catch (error) {
    yield put(addSellerContactDetailFail("Failed"));
  }
}

function* onUpdateSellerContactDetail(data) {
  try {
    const response = yield call(Api.updateSellerContactDetail, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(updateSellerContactDetailSuccess({...response, contact_data: payload_data}));
    } else {
      yield put(updateSellerContactDetailFail(response));
    }
  } catch (error) {
    yield put(updateSellerContactDetailFail("Failed"));
  }
}

function* onAddSellerExportLicenseDetail(data) {
  try {
    const response = yield call(Api.addSellerExportLicenseDetail, data.payload);
    if (response.status == "Success") {
      yield put(addSellerExportLicenseDetailSuccess(response));
    } else {
      yield put(addSellerExportLicenseDetailFail(response));
    }
  } catch (error) {
    yield put(addSellerExportLicenseDetailFail("Failed"));
  }
}

function* onUpdateSellerExportLicenseDetail(data) {
  try {
    const response = yield call(Api.updateSellerExportLicenseDetail, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerExportLicenseDetailSuccess(response));
    } else {
      yield put(updateSellerExportLicenseDetailFail(response));
    }
  } catch (error) {
    yield put(updateSellerExportLicenseDetailFail("Failed"));
  }
}

function* onAddSellerExportRcmcDetail(data) {
  try {
    const response = yield call(Api.addSellerExportRcmcDetail, data.payload);
    if (response.status == "Success") {
      yield put(addSellerExportRcmcDetailSuccess(response));
    } else {
      yield put(addSellerExportRcmcDetailFail(response));
    }
  } catch (error) {
    yield put(addSellerExportRcmcDetailFail("Failed"));
  }
}

function* onUpdateSellerExportRcmcDetail(data) {
  try {
    const response = yield call(Api.updateSellerExportRcmcDetail, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerExportRcmcDetailSuccess(response));
    } else {
      yield put(updateSellerExportRcmcDetailFail(response));
    }
  } catch (error) {
    yield put(updateSellerExportRcmcDetailFail("Failed"));
  }
}

function* onDeleteSellerExportRcmcDetail(data) {
  try {
    const response = yield call(Api.deleteSellerExportRcmcDetail, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(deleteSellerExportRcmcDetailSuccess({...response, rcmcUid: payload_data?.rcmcUid}));
    } else {
      yield put(deleteSellerExportRcmcDetailFail(response));
    }
  } catch (error) {
    yield put(deleteSellerExportRcmcDetailFail("Failed"));
  }
}

function* onAddSellerExportFssaiDetail(data) {
  try {
    const response = yield call(Api.addSellerExportFssaiDetail, data.payload);
    if (response.status == "Success") {
      yield put(addSellerExportFssaiDetailSuccess(response));
    } else {
      yield put(addSellerExportFssaiDetailFail(response));
    }
  } catch (error) {
    yield put(addSellerExportFssaiDetailFail("Failed"));
  }
}

function* onUpdateSellerExportFssaiDetail(data) {
  try {
    const response = yield call(Api.updateSellerExportFssaiDetail, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerExportFssaiDetailSuccess(response));
    } else {
      yield put(updateSellerExportFssaiDetailFail(response));
    }
  } catch (error) {
    yield put(updateSellerExportFssaiDetailFail("Failed"));
  }
}

function* onAddSellerExportTaxDetail(data) {
  try {
    const response = yield call(Api.addSellerExportTaxDetail, data.payload);
    if (response.status == "Success") {
      yield put(addSellerExportTaxDetailSuccess(response));
    } else {
      yield put(addSellerExportTaxDetailFail(response));
    }
  } catch (error) {
    yield put(addSellerExportTaxDetailFail("Failed"));
  }
}

function* onUpdateSellerExportTaxDetail(data) {
  try {
    const response = yield call(Api.updateSellerExportTaxDetail, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerExportTaxDetailSuccess(response));
    } else {
      yield put(updateSellerExportTaxDetailFail(response));
    }
  } catch (error) {
    yield put(updateSellerExportTaxDetailFail("Failed"));
  }
}

function* onAddSellerBankDetail(data) {
  try {
    const response = yield call(Api.addSellerBankDetail, data.payload);
    if (response.status == "Success") {
      yield put(addSellerBankDetailSuccess(response));
    } else {
      yield put(addSellerBankDetailFail(response));
    }
  } catch (error) {
    yield put(addSellerBankDetailFail("Failed"));
  }
}

function* onUpdateSellerBankDetail(data) {
  let payload_data = JSON.parse(data.payload);
  try {
    const response = yield call(Api.updateSellerBankDetail, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerBankDetailSuccess({...response, bank_data: payload_data}));
    } else {
      yield put(updateSellerBankDetailFail(response));
    }
  } catch (error) {
    yield put(updateSellerBankDetailFail("Failed"));
  }
}

function* onAddSellerDocuments(data) {
  try {
    const response = yield call(Api.addSellerDocument, data.payload);
    if (response.status == "Success") {
      yield put(addSellerDocumentsSuccess(response));
    } else {
      yield put(addSellerDocumentsFail(response));
    }
  } catch (error) {
    yield put(addSellerDocumentsFail("Failed"));
  }
}

function* onUpdateDocuments(data) {
  try {
    const response = yield call(Api.updateSellerDocument, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerDocumentsSuccess(response));
    } else {
      yield put(updateSellerDocumentsFail(response));
    }
  } catch (error) {
    yield put(updateSellerDocumentsFail("Failed"));
  }
}

function* onAddSellerProductCategory(data) {
  try {
    const response = yield call(Api.addSellerProductCategory, data.payload);
    if (response.status == "Success") {
      yield put(addSellerProductCategorySuccess(response));
    } else {
      yield put(addSellerProductCategoryFail(response));
    }
  } catch (error) {
    yield put(addSellerProductCategoryFail("Failed"));
  }
}

// function* onCheckCartProduct(data) {
//   try {
//     const response = yield call(Api.checkCartProduct, data.payload);
//     if (response.status == "Success") {
//       yield put(checkCartProductSuccess(response));
//     } else {
//       yield put(checkCartProductFail(response));
//     }
//   } catch (error) {
//     yield put(checkCartProductFail("Failed"));
//   }
// }

function* onGetStatesList(data) {
  try {
    const response = yield call(Api.getStatesList, data.payload);
    if (response.status == "Success") {
      yield put(getStatesSuccess(response));
    } else {
      yield put(getStatesFail(response));
    }
  } catch (error) {
    yield put(getStatesFail({msg: "Failed"}));
  }
}

function* onGetCitiesList(data) {
  try {
    const response = yield call(Api.getCitiesList, data.payload);
    if (response.status == "Success") {
      yield put(getCitiesSuccess(response));
    } else {
      yield put(getCitiesFail(response));
    }
  } catch (error) {
    yield put(getCitiesFail({msg: "Failed"}));
  }
}

function* onGetLoginSellerDetail() {
  try {
    const response = yield call(Api.getLoginSellerDetail);
    if (response.status == "Success") {
      yield put(getLoginSellerDetailSuccess(response));
    } else {
      yield put(getLoginSellerDetailFail(response));
    }
  } catch (error) {
    yield put(getLoginSellerDetailFail({msg: "Failed"}));
  }
}

function* ongetDashboard() {
  try {
    const response = yield call(Api.getDashboard);
    if (response.status == "Success") {
      yield put(getDashboardSuccess(response));
    } else {
      yield put(getDashboardFail(response));
    }
  } catch (error) {
    yield put(getDashboardFail({msg: "Failed"}));
  }
}


function* onListOrder(data) {
  try {
    const response = yield call(Api.listOrder, data.payload);
    if (response.status == "Success") {
      yield put(listOrderSuccess(response));
    } else {
      yield put(listOrderFail(response));
    }
  } catch (error) {
    yield put(listOrderFail("Failed"));
  }
}

function* onGetOrderDetail(data) {
  try {
    const response = yield call(Api.getOrderDetail, data.payload);
    if (response.status == "Success") {
      yield put(getOrderDetailSuccess(response));
    } else {
      yield put(getOrderDetailFail(response));
    }
  } catch (error) {
    yield put(getOrderDetailFail({msg: "Failed"}));
  }
}

function* onConfirmShipment(data) {
  try {
    const response = yield call(Api.confirmShipment, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(confirmShipmentSuccess({...response, data: payload_data}));
    } else {
      yield put(confirmShipmentFail(response));
    }
  } catch (error) {
    yield put(confirmShipmentFail({msg: "Failed"}));
  }
}

function* onGetOrderCount() {
  try {
    const response = yield call(Api.getOrderCount);
    if (response.status == "Success") {
      yield put(getOrderCountSuccess(response));
    } else {
      yield put(getOrderCountFail(response));
    }
  } catch (error) {
    yield put(getOrderCountFail("Failed"));
  }
}

function* onUpdateMainProductImage(data) {
  try {
    const response = yield call(Api.updateMainProductImage, data.payload);
    if (response.status == "Success") {
      yield put(updateMainProductImageSuccess(response));
    } else {
      yield put(updateMainProductImageFail(response));
    }
  } catch (error) {
    yield put(updateMainProductImageFail({msg: "Failed"}));
  }
}

function* onDeleteProductImage(data) {
  try {
    const response = yield call(Api.deleteProductImage, data.payload);
    if (response.status == "Success") {
      yield put(deleteProductImageSuccess(response));
    } else {
      yield put(deleteProductImageFail(response));
    }
  } catch (error) {
    yield put(deleteProductImageFail({msg: "Failed"}));
  }
}

function* onAddProductDocument(data) {
  try {
    const response = yield call(Api.addProductDocument, data.payload);
    if (response.status == "Success") {
      yield put(addProductDocumentSuccess(response));
    } else {
      yield put(addProductDocumentFail(response));
    }
  } catch (error) {
    yield put(addProductDocumentFail({msg: "Failed"}));
  }
}

function* onDeleteProductDocument(data) {
  try {
    const response = yield call(Api.deleteProductDocument, data.payload);
    if (response.status == "Success") {
      yield put(deleteProductDocumentSuccess(response));
    } else {
      yield put(deleteProductDocumentFail(response));
    }
  } catch (error) {
    yield put(deleteProductDocumentFail({msg: "Failed"}));
  }
}

function* onGetSellerCategoryList() {
  try {
    const response = yield call(Api.getSellerCategoryList);
    if (response.status == "Success") {
      yield put(getSellerCategoryListSuccess(response));
    } else {
      yield put(getSellerCategoryListFail(response));
    }
  } catch (error) {
    yield put(getSellerCategoryListFail({msg: "Failed"}));
  }
}

function* onForgotUsername(data) {
  try {
    const response = yield call(Api.forgotUserName, data.payload);
    if (response.status == "Success") {
      yield put(forgotUserNameSuccess(response));
    } else {
      yield put(forgotUserNameFail(response));
    }
  } catch (error) {
    yield put(forgotUserNameFail("Failed"));
  }
}

function* onForgotUserPassword(data) {
  try {
    const response = yield call(Api.forgotUserPassword, data.payload);
    if (response.status == "Success") {
      yield put(forgotUserPasswordSuccess(response));
    } else {
      yield put(forgotUserPasswordFail(response));
    }
  } catch (error) {
    yield put(forgotUserPasswordFail("Failed"));
  }
}

function* onUpdateForgotUserPassword(data) {
  try {
    const response = yield call(Api.updateForgotUserPassword, data.payload);
    if (response.status == "Success") {
      yield put(updateForgotUserPasswordSuccess(response));
    } else {
      yield put(updateForgotUserPasswordFail(response));
    }
  } catch (error) {
    yield put(updateForgotUserPasswordFail("Failed"));
  }
}

function* onGetForgotUserPassword(data) {
  try {
    const response = yield call(Api.getForgotUserPassword, data.payload);
    if (response.status == "Success") {
      yield put(getForgotUserPasswordSuccess(response));
    } else {
      yield put(getForgotUserPasswordFail(response));
    }
  } catch (error) {
    yield put(getForgotUserPasswordFail("Failed"));
  }
}

function* onUpdateSellerUsername(data) {
  try {
    const response = yield call(Api.updateSellerUsername, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(updateSellerUsernameSuccess({...response, userName: payload_data.username}));
    } else {
      yield put(updateSellerUsernameFail(response));
    }
  } catch (error) {
    yield put(updateSellerUsernameFail("Failed"));
  }
}

function* onUpdateSellerPassword(data) {
  try {
    const response = yield call(Api.updateSellerPassword, data.payload);
    if (response.status == "Success") {
      yield put(updateSellerPasswordSuccess(response));
    } else {
      yield put(updateSellerPasswordFail(response));
    }
  } catch (error) {
    yield put(updateSellerPasswordFail("Failed"));
  }
}

function* userDataSaga() {
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(GET_REGISTER_DATA, onGetRegisterData);
  yield takeLatest(REGISTER_USER, onRegisterUser);
  yield takeLatest(ADD_PRODUCT_DATA, onAddProductData);
  yield takeLatest(GET_PRODUCT_DETAIL, onGetProductDetail);
  yield takeLatest(UPDATE_PRODUCT_STATUS, onUpdateProductStatus);
  yield takeLatest(ADD_PRODUCT_VARIETY, onAddProductVariety);
  yield takeLatest(ADD_PRODUCT_IMAGE, onAddProductImage);
  yield takeLatest(ADD_PRODUCT_PACKING_PRICE, onAddProductPackingPrice);
  yield takeLatest(ADD_PRODUCT_SHIPPING, onAddProductShipping);
  yield takeLatest(UPDATE_PRODUCT_VARIETY, onUpdateProductVariety);
  yield takeLatest(UPDATE_PRODUCT_IMAGE, onUpdateProductImage);
  yield takeLatest(UPDATE_PRODUCT_PACKING_PRICE, onUpdateProductPackingPrice);
  yield takeLatest(UPDATE_PRODUCT_PRICE_PER_PACKAGE, onUpdateProductPricePerPackage);
  yield takeLatest(UPDATE_PRODUCT_SHIPPING, onUpdateProductShipping);
  yield takeLatest(DELETE_PRODUCT, onDeleteProduct);
  yield takeLatest(LIST_PRODUCT, onListProduct);
  yield takeLatest(LIST_CATEGORY, onListCategory);
  yield takeLatest(GET_SELLER_DETAIL, onGetSellerDetail);
  yield takeLatest(ADD_SELLER_CONTACT_DETAILS, onAddSellerContactDetail);
  yield takeLatest(UPDATE_SELLER_CONTACT_DETAILS, onUpdateSellerContactDetail);
  yield takeLatest(ADD_SELLER_EXPORT_LICENSE_DETAILS, onAddSellerExportLicenseDetail);
  yield takeLatest(UPDATE_SELLER_EXPORT_LICENSE_DETAILS, onUpdateSellerExportLicenseDetail);
  yield takeLatest(ADD_SELLER_EXPORT_RCMC_DETAILS, onAddSellerExportRcmcDetail);
  yield takeLatest(UPDATE_SELLER_EXPORT_RCMC_DETAILS, onUpdateSellerExportRcmcDetail);
  yield takeLatest(DELETE_SELLER_EXPORT_RCMC_DETAILS, onDeleteSellerExportRcmcDetail);
  yield takeLatest(ADD_SELLER_EXPORT_FSSAI_DETAILS, onAddSellerExportFssaiDetail);
  yield takeLatest(UPDATE_SELLER_EXPORT_FSSAI_DETAILS, onUpdateSellerExportFssaiDetail);
  yield takeLatest(ADD_SELLER_EXPORT_TAX_DETAILS, onAddSellerExportTaxDetail);
  yield takeLatest(UPDATE_SELLER_EXPORT_TAX_DETAILS, onUpdateSellerExportTaxDetail);
  yield takeLatest(ADD_SELLER_BANK_DETAILS, onAddSellerBankDetail);
  yield takeLatest(UPDATE_SELLER_BANK_DETAILS, onUpdateSellerBankDetail);
  yield takeLatest(ADD_SELLER_DOCUMENTS, onAddSellerDocuments);
  yield takeLatest(UPDATE_SELLER_DOCUMENTS, onUpdateDocuments);
  yield takeLatest(ADD_SELLER_PRODUCT_CATEGORY, onAddSellerProductCategory);
  // yield takeLatest(CHECK_CART_PRODUCT, onCheckCartProduct);
  yield takeLatest(GET_STATES, onGetStatesList);
  yield takeLatest(GET_CITIES, onGetCitiesList);
  yield takeLatest(GET_LOGIN_SELLER_DETAIL, onGetLoginSellerDetail);
  yield takeLatest(GET_DASHBOARD, ongetDashboard);
  yield takeLatest(LIST_ORDER, onListOrder);
  yield takeLatest(GET_ORDER_DETAIL, onGetOrderDetail);
  yield takeLatest(CONFIRM_SHIPMENT, onConfirmShipment);
  yield takeLatest(GET_ORDER_COUNT, onGetOrderCount);
  yield takeLatest(UPDATE_MAIN_PRODUCT_IMAGE, onUpdateMainProductImage);
  yield takeLatest(DELETE_PRODUCT_IMAGE, onDeleteProductImage);
  yield takeLatest(ADD_PRODUCT_DOCUMENT, onAddProductDocument);
  yield takeLatest(DELETE_PRODUCT_DOCUMENT, onDeleteProductDocument);
  yield takeLatest(GET_SELLER_CATEGORY_LIST, onGetSellerCategoryList);
  yield takeLatest(FORGOT_USER_NAME, onForgotUsername);
  yield takeLatest(FORGOT_USER_PASSWORD, onForgotUserPassword);
  yield takeLatest(UPDATE_FORGOT_USER_PASSWORD, onUpdateForgotUserPassword);
  yield takeLatest(GET_FORGOT_USER_PASSWORD, onGetForgotUserPassword);
  yield takeLatest(UPDATE_SELLER_USERNAME, onUpdateSellerUsername);
  yield takeLatest(UPDATE_SELLER_PASSWORD, onUpdateSellerPassword);
}

export default userDataSaga;
