import React, {useState, useEffect} from 'react';
import {Typography, TableCell} from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map'

import ProductActionRow from "./ProductActionRow";
import ExwPricePerPackageRow from "./ExwPricePerPackageRow";

import styles from "./ProductTabList.module.css"

import {THEME_COLOR7} from "../constants";

const status_list = {
  pending: {
    color: 'red',
  },
  active: {
    color: THEME_COLOR7,
  },
  inactive: {
    color: 'red',
  },
  removed: {
    color: 'red',
  }
}

const ProductTabList = (props) => {
  const [sellerCurrency, setSellerCurrency] = useState('');
  const [pricePerkg, setPricePerKg] = useState(0);
  const [referralFeePerkg, setReferralFeePerkg] = useState(0);
  const [referralFeePerPackage, setReferralFeePerPackage] = useState(0);

  useEffect(() => {
    if (props.data != null) {
      let currency = getSymbolFromCurrency(props?.data?.sellerCurrency);
      setSellerCurrency(currency)

      let data = props?.data;
      let seller_price_per_kg = 0;
      let seller_referral_fee_per_kg = 0;
      let seller_referral_fee_per_package = 0;
      if (data?.pricePerPackage != '' && data?.packageNetWeight != '') {
        seller_price_per_kg = parseFloat(data?.pricePerPackage / data?.packageNetWeight);
        setPricePerKg(seller_price_per_kg);

        seller_referral_fee_per_kg = parseFloat((seller_price_per_kg * data?.referralFee) / 100)
        setReferralFeePerkg(seller_referral_fee_per_kg);

        seller_referral_fee_per_package = parseFloat((data?.pricePerPackage * data?.referralFee) / 100)
        setReferralFeePerPackage(seller_referral_fee_per_package);
      }
    }
  }, [props?.data])

  let item = props.data;

  return (
    <>
      <TableCell>
        <Typography className={styles.statusText} sx={{...status_list[item?.status]}}>
          {item?.status ? item.status : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.valueText}>{item?.sku ? item.sku : '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography className={styles.valueText}>
          {item?.product ? item.product : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.valueSubText}>{item?.hsCode ? (item.hsCode + ' - ' + item.hsCodeTitle) : '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.valueText}>{item?.pickupLocation ? item.pickupLocation : '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.valueText}>
          price/Kg: {pricePerkg ? sellerCurrency + pricePerkg?.toFixed(2) : '-'}
        </Typography>
        <Typography
          className={styles.valueText}>
          Referral Fee: {referralFeePerkg ?  sellerCurrency + referralFeePerkg?.toFixed(2)  : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          className={styles.valueText}>{item?.packageNetWeight ?
          (item.packageNetWeight + ' kg ' + item.packingMaterial)
          : '-'}</Typography>
      </TableCell>
      <TableCell>
        <ExwPricePerPackageRow
          uid={item?.uid}
          status={item?.status}
          packingPriceId={item?.packingPriceId}
          pricePerPackage={item?.pricePerPackage}
          packageNetWeight={item?.packageNetWeight}
          referralFeePerPackage={referralFeePerPackage}
          sku={item?.sku}
          referralFee={item?.referralFee}
          sellerCurrency={sellerCurrency}
        />
      </TableCell>
      <TableCell>
        <Typography className={styles.valueText}>
          {item?.createdTimestamp && item?.timestamp ? ('Date Created: ' + item.createdTimestamp) : item?.createdTimestamp}
        </Typography>
        <Typography className={styles.valueText}>
          {item?.timestamp ? 'Status Changed Date: ' + item.timestamp : ''}
        </Typography>
      </TableCell>
      {props?.tabStatus != 'removed' ?
        <ProductActionRow
          page={'list'}
          status={item?.status}
          uid={item?.uid}
          tabStatus={props?.tabStatus}
        />
        : null}
    </>
  )
};

export default ProductTabList;
