import React, {useEffect, useState} from 'react';
import {Grid, Typography, Box, TextField, TableCell, TableRow} from '@mui/material';
import {useNavigate} from "react-router-dom";

import FormTextInput from "./FormTextInput";

import styles from "./ExwPricePerPackageRow.module.css"

import {ReactComponent as CloseIcon} from "../assets/img/close_black.svg";

import {THEME_COLOR2, THEME_COLOR7, THEME_COLOR9} from "../constants";

import {connect} from "react-redux";
import {
  updateProductPricePerPackage,
  updateApiStatus,
} from "../redux/actions/userDataActions";

const ExwPricePerPackageRow = (props) => {
  const [editProductUid, setEditProductUid] = useState('');
  const [exwPricePerPackage, setExwPricePerPackage] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == "UPDATE_PRODUCT_PRICE_PER_PACKAGE_SUCCESS") {
      setEditProductUid('')
      setExwPricePerPackage('')
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  const onEditClick = () => {
    setEditProductUid(props?.uid)
    setExwPricePerPackage(props?.pricePerPackage)
  };

  const onCloseClick = () => {
    setEditProductUid('')
    setExwPricePerPackage('')
  };

  const onSaveClick = () => {
    let errorText = {};
    if (exwPricePerPackage == '') {
      errorText = {exwPricePerPackage: 'EXW Price/Package is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(exwPricePerPackage)) {
      errorText = {...errorText, exwPricePerPackage: 'Invalid EXW Price/Package'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      productUid: props?.uid,
      pricePerPackage: exwPricePerPackage,
      sku: props?.sku
    }

    props.updateProductPricePerPackage(JSON.stringify(data))
  };

  return (
    <>
      <TableCell>
        {props?.packingPriceId && (props.status != 'removed') ?
          <Box>
            <Box sx={style.exwPricePerPackageFormField}>
              <FormTextInput
                page={'Product'}
                disabled={editProductUid != props?.uid}
                value={editProductUid == props?.uid ? exwPricePerPackage : props?.pricePerPackage}
                setValue={setExwPricePerPackage}
                error={editProductUid == props?.uid ? error?.exwPricePerPackage : null}
                textInputViewStyle={style.textInputViewStyle}
                required={"true"}
              />
              {editProductUid == props?.uid ?
                <Box
                  onClick={() => onCloseClick()}
                  sx={style.closeIconView}>
                  <CloseIcon height={13} width={13}/>
                </Box>
                : null}
            </Box>
            <Box sx={style.editView} mt={0.5}>
              <Typography
                onClick={() => editProductUid == props?.uid ? onSaveClick() : onEditClick()}
                sx={style.editText}>
                {editProductUid == props?.uid ? 'save' : 'edit'}
              </Typography>
            </Box>
          </Box>
          : null}
        <Typography className={styles.valueText}>
          {/*{props?.sellerCurrency}{(props?.pricePerPackage != '' && props?.packageNetWeight != '') ?*/}
          {/*(parseFloat((((props.pricePerPackage / props.packageNetWeight) * props.referralFee) / 100) + (props.pricePerPackage / props.packageNetWeight)) * props.packageNetWeight)?.toFixed(2)*/}
          {/*: 0}*/}
          {props?.referralFeePerPackage ? props?.sellerCurrency + props?.referralFeePerPackage : 0}
        </Typography>
      </TableCell>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProductPricePerPackage: data => dispatch(updateProductPricePerPackage(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExwPricePerPackageRow);


const style = {
  exwPricePerPackageFormField: {
    position: 'relative'
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  closeIconView: {
    cursor: 'pointer',
    backgroundColor: '#aaa',
    height: 18,
    width: 18,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: -6
  },
  editView: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editText: {
    cursor: 'pointer',
    color: THEME_COLOR2,
    fontFamily: 'InterBold',
    fontSize: 13
  },
  exwPricePerPackageWithRf: {
    color: '#b5b5c3',
    fontSize: 13,
    fontFamily: 'InterBold',
    textAlign: 'center'
  },
};
