import React, {useEffect, useState} from 'react'
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import OrderTab from "../../components/OrderTab";

import styles from "./Orders.module.css";

import {connect} from "react-redux";
import {
  getOrderCount,
  updateApiStatus
} from "../../redux/actions/userDataActions";

import {THEME_COLOR7, THEME_COLOR8} from '../../constants'

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Orders = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    props.getOrderCount()
  }, [])

  useEffect(() => {
    if (props.apiStatus == 'CONFIRM_SHIPMENT_SUCCESS') {
      setValue(2)
      props.updateApiStatus('')
    }
  }, [props.apiStatus])

  useEffect(() => {
    let windowUrl = window.location.search;
    let params = new URLSearchParams(windowUrl);
    let order_status = params.get('status');
    if (order_status) {
      if(order_status == 'pending') {
        setValue(0)
      } else if(order_status == 'unshipped') {
        setValue(1)
      } else if(order_status == 'shipped') {
        setValue(2)
      } else if(order_status == 'delivered') {
        setValue(3)
      } else if(order_status == 'cancelled') {
        setValue(4)
      }
    }
  },[])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container page={"orders"}>

      <Grid item md={12} xs={12} className={styles.borderView} px={2} pt={2}>
        <Typography className={styles.manageOrderText} mb={2}>Manage Orders</Typography>
        <Tabs scrollButtons="on" variant="scrollable" value={value} onChange={handleChange}
              sx={{
                "& .MuiTab-root": {
                  color: THEME_COLOR7,
                  fontSize: 15,
                  fontFamily: 'InterBold',
                  textTransform: 'capitalize',
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: THEME_COLOR8,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: THEME_COLOR8,
                  fontSize: 15,
                  fontFamily: 'InterBold',
                  textTransform: 'capitalize'
                }
              }}
              aria-label="basic tabs example">
          <Tab label={props?.pendingOrderCount + ' Pending'} {...a11yProps(0)} />
          <Tab label={props?.unshippedOrderCount + ' Unshipped'} {...a11yProps(1)} />
          <Tab label={props?.shippedOrderCount + ' Shipped'} {...a11yProps(2)} />
          <Tab label={props?.deliveredOrderCount + ' Delivered'} {...a11yProps(3)} />
          <Tab label={props?.cancelledOrderCount + ' Cancelled'} {...a11yProps(4)} />
        </Tabs>
      </Grid>

      <Grid item md={12} xs={12}>
        <TabPanel value={value} index={0}>
          <OrderTab
            tab={'pending'}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <OrderTab
            tab={'unshipped'}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <OrderTab
            tab={'shipped'}
          />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <OrderTab
            tab={'delivered'}
          />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <OrderTab
            tab={'cancelled'}
          />
        </TabPanel>
      </Grid>
      <AlertMsg/>

    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    pendingOrderCount: state.userData.pendingOrderCount,
    unshippedOrderCount: state.userData.unshippedOrderCount,
    shippedOrderCount: state.userData.shippedOrderCount,
    deliveredOrderCount: state.userData.deliveredOrderCount,
    cancelledOrderCount: state.userData.cancelledOrderCount,
    apiStatus: state.userData.apiStatus
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderCount: data => dispatch(getOrderCount(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
