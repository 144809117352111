import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";

import './../assets/css/custom.css';

import {ReactComponent as EyeVisibleIcon} from "../assets/img/eye.svg";
import {ReactComponent as EyeHiddenIcon} from "../assets/img/eye_slash.svg";

import {connect} from "react-redux";
import {updateSellerPassword, updateSellerUsername} from "../redux/actions/userDataActions";

import {THEME_COLOR7} from "../constants";

const LoginDetails = (props) => {
  const [username, setUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPasswordEyeVisible, setCurrentPasswordEyeVisible] = useState(false);
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
  const [confirmPasswordEyeVisible, setConfirmPasswordEyeVisible] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.data != null) {
      let user_name = props.data.userName;
      setUsername(user_name);
    }
  }, [props.data]);

  const onUpdateUserName = () => {
    let errorText = {};
    if (username == '') {
      errorText = {username: 'Username is required'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    setError(null)

    let data = {
      username: username,
    };

    props.updateSellerUsername(JSON.stringify(data))
  };

  const onUpdateUserPassword = () => {
    let errorText = {};
    if (currentPassword == '') {
      errorText = {currentPassword: 'Current Password is required'};
    }
    if (password == '') {
      errorText = {...errorText, password: 'Password is required'};
    } else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_ "])[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,12}$/.test(password)) {
      errorText = {...errorText, password: 'Password not secure'};
    }
    if (confirmPassword == '') {
      errorText = {...errorText, confirmPassword: 'Confirm Password is required'};
    } else if (password != confirmPassword) {
      errorText = {...errorText, confirmPassword: 'Password not match'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }
    setError(null)

    let data = {
      currentPassword: currentPassword,
      password: password,
    };

    props.updateSellerPassword(JSON.stringify(data))
  };

  const onCurrentPasswordEyeIconClick = () => {
    if (currentPasswordEyeVisible) {
      setCurrentPasswordEyeVisible(false)
    } else {
      setCurrentPasswordEyeVisible(true)
    }
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false)
    } else {
      setPasswordEyeVisible(true)
    }
  };

  const onConfirmPasswordEyeIconClick = () => {
    if (confirmPasswordEyeVisible) {
      setConfirmPasswordEyeVisible(false)
    } else {
      setConfirmPasswordEyeVisible(true)
    }
  };

  return (
    <Grid item container xs={12} md={12}>

      <Typography sx={styles.labelText} mt={1}>Change Username</Typography>

      <Grid item md={12} xs={12} sx={styles.boxView} mt={1} p={2}>
        <Grid item md={12} xs={12}
              container>
          <FormTextInput
            page={'LoginDetails'}
            value={username}
            setValue={setUsername}
            label={'Username'}
            error={error?.username}
            textInputViewStyle={styles.textInputViewStyle}
            required={"true"}
          />
        </Grid>

        <Grid item md={12} xs={12} container
              display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box>
            <FormButton
              title={'Update'}
              onSubmit={onUpdateUserName}
            />
          </Box>
        </Grid>
      </Grid>


      <Typography sx={styles.labelText} mt={3}>Change Password</Typography>

      <Grid item md={12} xs={12} mt={1} sx={styles.boxView} p={2}>

        <Grid item md={12} xs={12}
              container mb={3}>
          <FormTextInput
            page={'LoginDetails'}
            value={currentPassword}
            setValue={setCurrentPassword}
            label={'Current Password'}
            error={error?.currentPassword}
            textInputViewStyle={styles.textInputViewStyle}
            required={"true"}
            type={currentPasswordEyeVisible ? 'text' : 'password'}
            icon={true}
            rightIcon={currentPasswordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> :
              <EyeHiddenIcon height={15} width={15}/>}
            onIconClick={onCurrentPasswordEyeIconClick}
          />
        </Grid>

        <Grid item md={12} xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}
              alignItems={'flex-start'}>
          <Grid item md={5.8} xs={12}
                container>
            <FormTextInput
              page={'LoginDetails'}
              value={password}
              setValue={setPassword}
              label={'Password'}
              error={error?.password}
              textInputViewStyle={styles.textInputViewStyle}
              required={"true"}
              type={passwordEyeVisible ? 'text' : 'password'}
              icon={true}
              rightIcon={passwordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> :
                <EyeHiddenIcon height={15} width={15}/>}
              onIconClick={onPasswordEyeIconClick}
            />
          </Grid>

          <Grid item md={5.8} xs={12}
                container>
            <FormTextInput
              page={'LoginDetails'}
              value={confirmPassword}
              setValue={setConfirmPassword}
              label={'Confirm Password'}
              error={error?.confirmPassword}
              textInputViewStyle={styles.textInputViewStyle}
              required={"true"}
              type={confirmPasswordEyeVisible ? 'text' : 'password'}
              icon={true}
              rightIcon={confirmPasswordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> :
                <EyeHiddenIcon height={15} width={15}/>}
              onIconClick={onConfirmPasswordEyeIconClick}
            />
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} container
              display="flex" flexDirection="row" justifyContent="right" mt={4}>
          <Box>
            <FormButton
              title={'Update'}
              onSubmit={onUpdateUserPassword}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateSellerUsername: data => dispatch(updateSellerUsername(data)),
    updateSellerPassword: data => dispatch(updateSellerPassword(data)),
  };
};

export default connect(null, mapDispatchToProps)(LoginDetails);

const styles = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  boxView: {
    border: '1px solid #aaa'
  },
  labelText: {
    color: THEME_COLOR7,
    fontSize: 15,
    fontFamily: 'InterBold',
  }
};

