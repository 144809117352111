import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';

import AccountCreated from "../../components/AccountCreated";
import BusinessDetails from "../../components/BusinessDetails";
import AlertMsg from "../../components/AlertMsg";

import LoginBgImg from "../../assets/img/login_bg_image.jpg"

import {ReactComponent as LogOutIcon} from "../../assets/img/logout.svg"

import {THEME_COLOR2} from "../../constants";

const CreateAccount = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();

  const steps = [
    'Account creation',
    'Business details',
    // 'Dashboard'
  ];

  const onUpdateBusinessDetailBtnClick = () => {
    setActiveTab(1);
  };

  const onTabClick = (index) => {
    if (index == 2) {
      navigate('/')
    } else {
      setActiveTab(index)
    }
  }

  return (
    <Grid container item md={12} xs={12} style={{position: 'relative'}}>
      <Box sx={styles.bgImgView}>
        <img alt="" src={LoginBgImg} style={styles.imgStyle}/>
      </Box>

      <Box
        onClick={() => navigate('/sign_out')}
        style={{position: 'absolute', top: 10, right: 10, zIndex: 10, cursor: 'pointer'}}>
        <LogOutIcon height={30} width={30}/>
      </Box>

      <Grid sx={styles.contentView}
            container item md={12} xs={12}
            mt={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
        <Grid container item md={12} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Card sx={[styles.cardView, {
            width: {md: '70%', sm: '90%', xs: '90%'},
            minHeight: {md: '30vw', sm: '100vw', xs: '100vw'}
          }]}>
            <CardContent style={styles.cardContentView}>

              <Box sx={{width: {md: '90%', xs: '100%'}}} mt={5}>
                <Stepper activeStep={activeTab} alternativeLabel>
                  {steps?.map((label, index) => (
                    <Step key={label}
                          onClick={() => onTabClick(index)}
                          sx={styles.mainStep}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              {(activeTab == 0 ?
                  (<Grid container
                         direction="column"
                         style={{width: '75%'}} mt={4}>
                    <AccountCreated
                      onUpdateBusinessDetailBtnClick={onUpdateBusinessDetailBtnClick}
                    />
                  </Grid>)
                  :
                  // (activeTab == 1 ?
                  (<Grid container
                         direction="column"
                         style={{width: '75%'}} mt={4}>
                    <BusinessDetails
                    />
                  </Grid>)
                // :
                //   (<Grid container
                //        direction="column"
                //        style={{width: '75%'}} mt={4}>
                // </Grid>)
                // )
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AlertMsg/>

    </Grid>
  )
};

export default CreateAccount;

const styles = {
  bgImgView: {
    position: 'fixed',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  contentView: {
    zIndex: 10,
    minHeight: window.innerHeight
  },
  cardView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 2,
    marginBottom: 10
  },
  cardContentView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainStep: {
    '& .MuiStepLabel-label': {
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 8}
    },
    '& .MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-root': {
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: THEME_COLOR2, // circle color (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (ACTIVE)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (ACTIVE)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
    '& .MuiStepLabel-root .Mui-completed': {
      color: THEME_COLOR2, // circle color (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 20, xs: 18}
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
      {
        color: '#000', // Just text label (COMPLETED)
        fontFamily: 'InterBold',
        fontSize: {md: 13, xs: 8}
      },
    '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
      fill: '#FFF', // circle's number (COMPLETED)
      fontFamily: 'InterBold',
      fontSize: {md: 13, xs: 18}
    },
  },
};
