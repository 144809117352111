import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Grid, Box, Card, CardContent, CardMedia, Typography, CardActionArea} from '@mui/material';
import countryToCurrency from "country-to-currency";

import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";
import FormCountrySelectBox from "../../components/FormCountrySelectBox";
import FormSelectBox from "../../components/FormSelectBox";
import AlertDialogBox from "../../components/AlertDialogBox";

import {connect} from "react-redux";
import {getRegisterData, registerUser, updateApiStatus} from "../../redux/actions/userDataActions";

import Logo from "../../assets/img/logo.png";
import LoginBgImg from "../../assets/img/login_bg_image.jpg"

import {THEME_COLOR2} from "../../constants";
import {ReactComponent as EyeVisibleIcon} from "../../assets/img/eye.svg";
import {ReactComponent as EyeHiddenIcon} from "../../assets/img/eye_slash.svg";

const password_req = "1. Minimum Length: Passwords should typically be a minimum of 8 to 12 characters. Longer passwords are generally more secure. \n2. Complexity: Passwords should include a combination of: \nUppercase letters (A-Z) \nLowercase letters (a-z) \nNumbers (0-9) \nSpecial characters (e.g., !, @, #, $, %, etc.)";

const SignUp = (props) => {
  const [country, setCountry] = useState(null);
  const [typeOfEntity, setTypeOfEntity] = useState('');
  const [nameOfEntity, setNameOfEntity] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [error, setError] = useState(null);
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
  const [confirmPasswordEyeVisible, setConfirmPasswordEyeVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.apiStatus == "REGISTER_USER_SUCCESS") {
      props.updateApiStatus("");
    }
  }, [props.apiStatus]);

  useEffect(() => {
    props.getRegisterData();
  }, []);

  const onSignUp = () => {
    let errorText = {};
    let currency = '';
    if (country == null) {
      errorText = {country: 'Country is required'};
    } else {
      currency = countryToCurrency[country?.code];
      if(currency == '') {
        errorText = {...errorText, country: 'Currency not fetched'};
      }
    }
    if (typeOfEntity == '') {
      errorText = {...errorText, typeOfEntity: 'Type of Entity is required'};
    }
    if (nameOfEntity == '') {
      errorText = {...errorText, nameOfEntity: 'Name of Entity is required'};
    }
    if (emailAddress == '') {
      errorText = {...errorText, emailAddress: 'Email is required'};
    } else if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$/.test(emailAddress)) {
      errorText = {...errorText, emailAddress: 'Invalid Email'};
    }
    if (password == '') {
      errorText = {...errorText, password: 'Password is required'};
    } else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_ "])[a-zA-Z0-9!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]{8,12}$/.test(password)) {
      errorText = {...errorText, password: 'Password not secure'};
    }
    if (confirmPassword == '') {
      errorText = {...errorText, confirmPassword: 'Confirm Password is required'};
    }
    if (password != confirmPassword) {
      errorText = {...errorText, confirmPassword: 'Password not match'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      country: country?.code,
      currency: currency,
      typeOfEntity: typeOfEntity,
      nameOfEntity: nameOfEntity,
      emailAddress: emailAddress,
      password: password,
    };

    props.registerUser(JSON.stringify(data))
  };

  const onSignInClick = () => {
    navigate('/sign_in')
  };

  const onPasswordRequirement = () => {
    setPopUpVisible(true);
  };

  const onPopupCloseClick = () => {
    setPopUpVisible(false);
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false)
    } else {
      setPasswordEyeVisible(true)
    }
  };

  const onConfirmPasswordEyeIconClick = () => {
    if (confirmPasswordEyeVisible) {
      setConfirmPasswordEyeVisible(false)
    } else {
      setConfirmPasswordEyeVisible(true)
    }
  };

  return (
    <Grid container item md={12} xs={12}>
      <Box sx={styles.bgImgView}>
        <img alt="" src={LoginBgImg} style={styles.imgStyle}/>
      </Box>
      <Grid sx={styles.contentView}
            container item md={12} xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
        <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          {/*<Box sx={styles.imgView} mr={2}>*/}
          {/*  <img alt="" src={Logo} style={styles.imgStyle}/>*/}
          {/*</Box>*/}
          <Box>
            <h2 style={styles.mainTitleText}>Trebbs</h2>
          </Box>
        </Grid>
        <Grid container item md={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Card sx={[styles.cardView, {
            width: {md: '90%', sm: '90%', xs: '90%'},
            minHeight: {md: '40vw', sm: '100vw', xs: '100vw'}
          }]}>
            <Typography style={styles.titleText}>Sign Up</Typography>
            <Typography style={styles.subTitleText} mt={1}>Trebbs</Typography>
            <CardContent style={styles.cardContentView}>

              <Grid item md={12} xs={12} container display={'flex'}
                    flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1}>
                <Grid item md={5.8} xs={12} container
                      sx={styles.textInputView}>
                  <FormCountrySelectBox
                    page={'SignUp'}
                    type={"code"}
                    value={country}
                    setValue={setCountry}
                    placeholder={'Select a Country'}
                    textInputViewStyle={styles.textInputViewStyle}
                    error={error?.country}
                    required={"true"}
                  />
                </Grid>

                <Grid item md={5.8} xs={12}
                      sx={styles.textInputView} container>
                  <FormSelectBox
                    page={'SignUp'}
                    value={typeOfEntity}
                    setValue={setTypeOfEntity}
                    data={props.getRegisterDataList?.entityTypeList ? props.getRegisterDataList.entityTypeList : []}
                    placeholder={"Type of Entity"}
                    error={error?.typeOfEntity}
                    textInputViewStyle={styles.textInputViewStyle}
                    selectedItemViewStyle={styles.selectedItemViewStyle}
                    placeholderColorOpacity={1}
                    required={true}
                  />
                </Grid>
              </Grid>

              <Grid item md={12} xs={12} container display={'flex'}
                    flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1}>
                <Grid item md={5.8} xs={12}
                      sx={styles.textInputView} container>
                  <FormTextInput
                    page={'SignUp'}
                    value={nameOfEntity}
                    setValue={setNameOfEntity}
                    placeholder={'Name of Entity'}
                    error={error?.nameOfEntity}
                    textInputViewStyle={styles.textInputViewStyle}
                    required={true}
                  />
                </Grid>

                <Grid container item md={5.8} xs={12}
                      sx={styles.textInputView}>
                  <FormTextInput
                    page={'SignUp'}
                    value={emailAddress}
                    setValue={setEmailAddress}
                    type={"email"}
                    placeholder={'E-mail Address'}
                    error={error?.emailAddress}
                    textInputViewStyle={styles.textInputViewStyle}
                    required={true}
                  />
                </Grid>
              </Grid>

              <Grid item md={12} xs={12} container display={'flex'}
                    flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1}>
                <Grid
                  onClick={() => onPasswordRequirement()}
                  container item md={5.8} xs={12} display={'flex'} justifyContent={'flex-end'}>
                  <Typography sx={styles.passwordReqText}>Password Requirements</Typography>
                </Grid>
              </Grid>

              <Grid item md={12} xs={12} container display={'flex'}
                    flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid container item md={5.8} xs={12}
                      sx={styles.textInputView}>
                  <FormTextInput
                    page={'SignUp'}
                    value={password}
                    setValue={setPassword}
                    placeholder={'Password'}
                    error={error?.password}
                    textInputViewStyle={styles.textInputViewStyle}
                    required={true}
                    type={passwordEyeVisible ? 'text' : 'password'}
                    icon={true}
                    rightIcon={passwordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> :
                      <EyeHiddenIcon height={15} width={15}/>}
                    onIconClick={onPasswordEyeIconClick}
                  />
                </Grid>

                <Grid container item md={5.8} xs={12}
                      sx={styles.textInputView}>
                  <FormTextInput
                    page={'SignUp'}
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    placeholder={'Re-enter Password'}
                    error={error?.confirmPassword}
                    textInputViewStyle={styles.textInputViewStyle}
                    required={true}
                    type={confirmPasswordEyeVisible ? 'text' : 'password'}
                    icon={true}
                    rightIcon={confirmPasswordEyeVisible ? <EyeVisibleIcon height={15} width={15}/> :
                      <EyeHiddenIcon height={15} width={15}/>}
                    onIconClick={onConfirmPasswordEyeIconClick}
                  />
                </Grid>
              </Grid>

              <Grid container
                    item md={12} xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mt={4}>
                <FormButton
                  loading={props.loginLoading}
                  title={'Create your Trebbs Seller Account'}
                  btnStyleView={styles.btnStyleView}
                  onSubmit={onSignUp}
                />
              </Grid>

              <Box
                mt={3}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}>
                <Typography style={styles.notMemberText}>Already have an Account?</Typography>
                <Typography ml={0.3} style={styles.signUpText} onClick={() => onSignInClick()}>Sign in</Typography>
              </Box>

            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AlertDialogBox
        confirmBox={popUpVisible}
        title={'Password Requirements'}
        content={password_req}
        closeIcon={true}
        type={'passwordReq'}
        onCancelClick={onPopupCloseClick}
      />

      <AlertMsg/>

    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    registerLoading: state.userData.registerLoading,
    getRegisterDataList: state.userData.getRegisterDataList,
    apiStatus: state.userData.apiStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerUser: data => dispatch(registerUser(data)),
    getRegisterData: data => dispatch(getRegisterData(data)),
    updateApiStatus: data => dispatch(updateApiStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

const styles = {
  bgImgView: {
    position: 'fixed',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  contentView: {
    zIndex: 10,
    minHeight: window.innerHeight
  },
  imgView: {
    height: 100,
    width: 100,
  },
  mainTitleText: {
    color: '#FFF',
    fontFamily: 'InterBold',
    fontSize: 45
  },
  cardView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 2,
  },
  titleText: {
    textAlign: 'center',
    fontFamily: 'InterBold',
    fontSize: 30
  },
  subTitleText: {
    textAlign: 'center',
    color: '#a1a5b7',
    fontFamily: 'InterBold',
    fontSize: 17
  },
  cardContentView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textInputView: {
    // width: '80%'
  },
  passwordReqText: {
    fontSize: 12,
    color: THEME_COLOR2,
    cursor: 'pointer',
    fontFamily: 'InterMedium',
  },
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  btnStyleView: {
    fontSize: 14,
    height: 40,
    width: '50%'
  },
  notMemberText: {
    color: '#a1a5b7',
    fontSize: 13.5,
    fontFamily: 'InterMedium',
  },
  signUpText: {
    cursor: 'pointer',
    color: THEME_COLOR2,
    fontSize: 13.5,
    fontFamily: 'InterMedium',
  }
};
