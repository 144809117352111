import React from 'react';
import Button from '@mui/material/Button';

import {THEME_COLOR2, THEME_COLOR3, THEME_COLOR4, THEME_COLOR5, THEME_COLOR6, THEME_COLOR7, THEME_COLOR8, THEME_COLOR9, THEME_COLOR10} from "../constants";

const FormButton = (props) => {

  const onAdd = () => {
    props.onSubmit();
  };

  let btnStyle = styles.buttonView;
  let bgColor = props?.bgColorStyleView ? props?.bgColorStyleView : THEME_COLOR2;
  let textColor = '#FFF';
  if (props?.btnType === 'btn2') {
    btnStyle = styles.buttonView2;
    bgColor = '#f0efef';
    textColor = THEME_COLOR4;
  } else if (props?.btnType === 'btn3') {
    btnStyle = styles.buttonView3;
    bgColor = '#f0efef';
    textColor = THEME_COLOR4;
  } else if (props?.btnType === 'btn4') {
    btnStyle = styles.buttonView4;
    bgColor = '#f1416c';
    textColor = '#FFF';
  } else if (props?.btnType === 'btn5') {
    btnStyle = styles.buttonView5;
    bgColor = '#f2f6fb';
    textColor = THEME_COLOR2;
  } else if (props?.btnType === 'btn6') {
    btnStyle = styles.buttonView6;
    bgColor = THEME_COLOR2;
    textColor = '#FFF';
  } else if (props?.btnType === 'btn7') {
    btnStyle = styles.buttonView7;
    bgColor = THEME_COLOR5;
    textColor = '#FFF';
  } else if (props?.btnType === 'btn8') {
    btnStyle = styles.buttonView8;
    bgColor = THEME_COLOR6;
    textColor = '#FFF';
  } else if (props?.btnType === 'btn9') {
    btnStyle = styles.buttonView9;
    bgColor = THEME_COLOR7;
    textColor = '#FFF';
  } else if (props?.btnType === 'btn10') {
    btnStyle = styles.buttonView10;
    bgColor = THEME_COLOR8;
    textColor = '#FFF';
  } else if (props?.btnType === 'btn11') {
    btnStyle = props?.buttonView ? props?.buttonView : styles.buttonView11;
    bgColor = props?.bgColorStyleView ? props?.bgColorStyleView : THEME_COLOR9;
    textColor = props?.textColorStyleView ? props?.textColorStyleView : '#000';
  } else if (props?.btnType === 'btn12') {
    btnStyle = styles.buttonView12;
    bgColor = THEME_COLOR10;
    textColor = '#FFF';
  } else if (props?.btnType === 'btn13') {
    btnStyle = styles.buttonView13;
    bgColor = '#000';
    textColor = '#FFF';
  }

  return (
    <Button
      disableElevation={true}
      disabled={props?.loading}
      sx={[btnStyle, styles.btnView, props?.btnStyleView, {
        ':hover': {
          color: props?.hoverTextColor ? props?.hoverTextColor : textColor,
          backgroundColor: bgColor,
        },
        width: props?.width ? props?.width : 'unset',
        height: props?.height ? props?.height : 40,
      }]}
      onClick={() => onAdd()}
      variant="contained"
      // variant={props?.variant}
      size="medium">
      {props?.loading ? 'Please Wait' : props.title}
    </Button>
  )
};

export default FormButton;

const styles = {
  buttonView: {
    backgroundColor: THEME_COLOR2,
    color: '#FFF',
  },
  buttonView2: {
    backgroundColor: THEME_COLOR3,
    color: THEME_COLOR4,
  },
  buttonView3: {
    backgroundColor: '#f0efef',
    color: THEME_COLOR4,
  },
  buttonView4: {
    backgroundColor: '#f1416c',
    color: '#FFF',
  },
  buttonView5: {
    backgroundColor: '#FFF',
    color: '#000',
  },
  buttonView6: {
    backgroundColor: '#f2f6fb',
    color: THEME_COLOR2,
  },
  buttonView7: {
    backgroundColor: THEME_COLOR5,
    color: '#fff',
  },
  buttonView8: {
    backgroundColor: THEME_COLOR6,
    color: '#fff',
    border: '1px solid #aaa',
  },
  buttonView9: {
    backgroundColor: THEME_COLOR7,
    color: '#fff',
    border: '1px solid #aaa',
  },
  buttonView10: {
    backgroundColor: THEME_COLOR8,
    color: '#fff',
    border: '1px solid #aaa',
  },
  buttonView11: {
    backgroundColor: THEME_COLOR9,
    color: '#000',
    border: '1px solid #aaa',
  },
  buttonView12: {
    backgroundColor: THEME_COLOR10,
    color: '#fff',
  },
  buttonView13: {
    backgroundColor: '#000',
    color: '#fff',
  },
  btnView: {
    fontFamily: 'InterBold',
    fontSize: 12,
    textTransform: 'capitalize',
    height: 40,
  }
};
