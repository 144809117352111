import React from 'react';
import Pagination from '@mui/material/Pagination';
import {createTheme, ThemeProvider} from "@mui/material/styles";

import {THEME_COLOR9} from "../constants";

const Page = (props) => {

  const theme = createTheme({
    palette: {
      theme_color_9: {
        main: THEME_COLOR9,
      },
    },
  });

  const handleChange = (event, value) => {
    props.onSelectPage(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Pagination
        sx={{
          '& .MuiPaginationItem-root.Mui-selected': {
            color: '#000',
            fontFamily: 'InterMedium',
            ":hover": {
              backgroundColor: THEME_COLOR9,
              color: "#000"
            }
          },
          '& .MuiPaginationItem-root': {
            fontFamily: 'InterMedium',
            ":hover": {
              color: THEME_COLOR9
            }
          }
        }}
        count={props.totalPages}
        page={props.pageNum}
        onChange={handleChange}
        color="theme_color_9"
        shape={"rounded"}/>
    </ThemeProvider>
  );
};

export default Page;
