//login
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const FORGOT_USER_NAME = 'FORGOT_USER_NAME';
export const FORGOT_USER_NAME_SUCCESS = 'FORGOT_USER_NAME_SUCCESS';
export const FORGOT_USER_NAME_FAIL = 'FORGOT_USER_NAME_FAIL';
export const FORGOT_USER_PASSWORD = 'FORGOT_USER_PASSWORD';
export const FORGOT_USER_PASSWORD_SUCCESS = 'FORGOT_USER_PASSWORD_SUCCESS';
export const FORGOT_USER_PASSWORD_FAIL = 'FORGOT_USER_PASSWORD_FAIL';
export const UPDATE_FORGOT_USER_PASSWORD = 'UPDATE_FORGOT_USER_PASSWORD';
export const UPDATE_FORGOT_USER_PASSWORD_SUCCESS = 'UPDATE_FORGOT_USER_PASSWORD_SUCCESS';
export const UPDATE_FORGOT_USER_PASSWORD_FAIL = 'UPDATE_FORGOT_USER_PASSWORD_FAIL';
export const GET_FORGOT_USER_PASSWORD = 'GET_FORGOT_USER_PASSWORD';
export const GET_FORGOT_USER_PASSWORD_SUCCESS = 'GET_FORGOT_USER_PASSWORD_SUCCESS';
export const GET_FORGOT_USER_PASSWORD_FAIL = 'GET_FORGOT_USER_PASSWORD_FAIL';

//register
export const GET_REGISTER_DATA = 'GET_REGISTER_DATA';
export const GET_REGISTER_DATA_SUCCESS = 'GET_REGISTER_DATA_SUCCESS';
export const GET_REGISTER_DATA_FAIL = 'GET_REGISTER_DATA_FAIL';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const ADD_PRODUCT_DATA = 'ADD_PRODUCT_DATA';
export const ADD_PRODUCT_DATA_SUCCESS = 'ADD_PRODUCT_DATA_SUCCESS';
export const ADD_PRODUCT_DATA_FAIL = 'ADD_PRODUCT_DATA_FAIL';
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAIL = 'GET_PRODUCT_DETAIL_FAIL';
export const LIST_PRODUCT = 'LIST_PRODUCT';
export const LIST_PRODUCT_SUCCESS = 'LIST_PRODUCT_SUCCESS';
export const LIST_PRODUCT_FAIL = 'LIST_PRODUCT_FAIL';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const LIST_CATEGORY = 'LIST_CATEGORY';
export const LIST_CATEGORY_SUCCESS = 'LIST_CATEGORY_SUCCESS';
export const LIST_CATEGORY_FAIL = 'LIST_CATEGORY_FAIL';
export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS';
export const UPDATE_PRODUCT_STATUS_SUCCESS = 'UPDATE_PRODUCT_STATUS_SUCCESS';
export const UPDATE_PRODUCT_STATUS_FAIL = 'UPDATE_PRODUCT_STATUS_FAIL';

export const ADD_PRODUCT_VARIETY = 'ADD_PRODUCT_VARIETY';
export const ADD_PRODUCT_VARIETY_SUCCESS = 'ADD_PRODUCT_VARIETY_SUCCESS';
export const ADD_PRODUCT_VARIETY_FAIL = 'ADD_PRODUCT_VARIETY_FAIL';
export const ADD_PRODUCT_IMAGE = 'ADD_PRODUCT_IMAGE';
export const ADD_PRODUCT_IMAGE_SUCCESS = 'ADD_PRODUCT_IMAGE_SUCCESS';
export const ADD_PRODUCT_IMAGE_FAIL = 'ADD_PRODUCT_IMAGE_FAIL';
export const ADD_PRODUCT_PACKING_PRICE = 'ADD_PRODUCT_PACKING_PRICE';
export const ADD_PRODUCT_PACKING_PRICE_SUCCESS = 'ADD_PRODUCT_PACKING_PRICE_SUCCESS';
export const ADD_PRODUCT_PACKING_PRICE_FAIL = 'ADD_PRODUCT_PACKING_PRICE_FAIL';
export const ADD_PRODUCT_SHIPPING = 'ADD_PRODUCT_SHIPPING';
export const ADD_PRODUCT_SHIPPING_SUCCESS = 'ADD_PRODUCT_SHIPPING_SUCCESS';
export const ADD_PRODUCT_SHIPPING_FAIL = 'ADD_PRODUCT_SHIPPING_FAIL';
export const UPDATE_PRODUCT_VARIETY = 'UPDATE_PRODUCT_VARIETY';
export const UPDATE_PRODUCT_VARIETY_SUCCESS = 'UPDATE_PRODUCT_VARIETY_SUCCESS';
export const UPDATE_PRODUCT_VARIETY_FAIL = 'UPDATE_PRODUCT_VARIETY_FAIL';
export const UPDATE_PRODUCT_IMAGE = 'UPDATE_PRODUCT_IMAGE';
export const UPDATE_PRODUCT_IMAGE_SUCCESS = 'UPDATE_PRODUCT_IMAGE_SUCCESS';
export const UPDATE_PRODUCT_IMAGE_FAIL = 'UPDATE_PRODUCT_IMAGE_FAIL';
export const UPDATE_PRODUCT_PACKING_PRICE = 'UPDATE_PRODUCT_PACKING_PRICE';
export const UPDATE_PRODUCT_PACKING_PRICE_SUCCESS = 'UPDATE_PRODUCT_PACKING_PRICE_SUCCESS';
export const UPDATE_PRODUCT_PACKING_PRICE_FAIL = 'UPDATE_PRODUCT_PACKING_PRICE_FAIL';
export const UPDATE_PRODUCT_SHIPPING = 'UPDATE_PRODUCT_SHIPPING';
export const UPDATE_PRODUCT_SHIPPING_SUCCESS = 'UPDATE_PRODUCT_SHIPPING_SUCCESS';
export const UPDATE_PRODUCT_SHIPPING_FAIL = 'UPDATE_PRODUCT_SHIPPING_FAIL';
export const UPDATE_PRODUCT_PRICE_PER_PACKAGE = 'UPDATE_PRODUCT_PRICE_PER_PACKAGE';
export const UPDATE_PRODUCT_PRICE_PER_PACKAGE_SUCCESS = 'UPDATE_PRODUCT_PRICE_PER_PACKAGE_SUCCESS';
export const UPDATE_PRODUCT_PRICE_PER_PACKAGE_FAIL = 'UPDATE_PRODUCT_PRICE_PER_PACKAGE_FAIL';
export const UPDATE_MAIN_PRODUCT_IMAGE = 'UPDATE_MAIN_PRODUCT_IMAGE';
export const UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS = 'UPDATE_MAIN_PRODUCT_IMAGE_SUCCESS';
export const UPDATE_MAIN_PRODUCT_IMAGE_FAIL = 'UPDATE_MAIN_PRODUCT_IMAGE_FAIL';
export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE';
export const DELETE_PRODUCT_IMAGE_SUCCESS = 'DELETE_PRODUCT_IMAGE_SUCCESS';
export const DELETE_PRODUCT_IMAGE_FAIL = 'DELETE_PRODUCT_IMAGE_FAIL';
export const ADD_PRODUCT_DOCUMENT = 'ADD_PRODUCT_DOCUMENT';
export const ADD_PRODUCT_DOCUMENT_SUCCESS = 'ADD_PRODUCT_DOCUMENT_SUCCESS';
export const ADD_PRODUCT_DOCUMENT_FAIL = 'ADD_PRODUCT_DOCUMENT_FAIL';
export const DELETE_PRODUCT_DOCUMENT = 'DELETE_PRODUCT_DOCUMENT';
export const DELETE_PRODUCT_DOCUMENT_SUCCESS = 'DELETE_PRODUCT_DOCUMENT_SUCCESS';
export const DELETE_PRODUCT_DOCUMENT_FAIL = 'DELETE_PRODUCT_DOCUMENT_FAIL';
export const GET_SELLER_CATEGORY_LIST = 'GET_SELLER_CATEGORY_LIST';
export const GET_SELLER_CATEGORY_LIST_SUCCESS = 'GET_SELLER_CATEGORY_LIST_SUCCESS';
export const GET_SELLER_CATEGORY_LIST_FAIL = 'GET_SELLER_CATEGORY_LIST_FAIL';

//my account
export const GET_SELLER_DETAIL = 'GET_SELLER_DETAIL';
export const GET_SELLER_DETAIL_SUCCESS = 'GET_SELLER_DETAIL_SUCCESS';
export const GET_SELLER_DETAIL_FAIL = 'GET_SELLER_DETAIL_FAIL';
export const ADD_SELLER_CONTACT_DETAILS = 'ADD_SELLER_CONTACT_DETAILS';
export const ADD_SELLER_CONTACT_DETAILS_SUCCESS = 'ADD_SELLER_CONTACT_DETAILS_SUCCESS';
export const ADD_SELLER_CONTACT_DETAILS_FAIL = 'ADD_SELLER_CONTACT_DETAILS_FAIL';
export const UPDATE_SELLER_CONTACT_DETAILS = 'UPDATE_SELLER_CONTACT_DETAILS';
export const UPDATE_SELLER_CONTACT_DETAILS_SUCCESS = 'UPDATE_SELLER_CONTACT_DETAILS_SUCCESS';
export const UPDATE_SELLER_CONTACT_DETAILS_FAIL = 'UPDATE_SELLER_CONTACT_DETAILS_FAIL';
export const ADD_SELLER_EXPORT_LICENSE_DETAILS = 'ADD_SELLER_EXPORT_LICENSE_DETAILS';
export const ADD_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS = 'ADD_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS';
export const ADD_SELLER_EXPORT_LICENSE_DETAILS_FAIL = 'ADD_SELLER_EXPORT_LICENSE_DETAILS_FAIL';
export const UPDATE_SELLER_EXPORT_LICENSE_DETAILS = 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS';
export const UPDATE_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS = 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS_SUCCESS';
export const UPDATE_SELLER_EXPORT_LICENSE_DETAILS_FAIL = 'UPDATE_SELLER_EXPORT_LICENSE_DETAILS_FAIL';
export const ADD_SELLER_EXPORT_RCMC_DETAILS = 'ADD_SELLER_EXPORT_RCMC_DETAILS';
export const ADD_SELLER_EXPORT_RCMC_DETAILS_SUCCESS = 'ADD_SELLER_EXPORT_RCMC_DETAILS_SUCCESS';
export const ADD_SELLER_EXPORT_RCMC_DETAILS_FAIL = 'ADD_SELLER_EXPORT_RCMC_DETAILS_FAIL';
export const UPDATE_SELLER_EXPORT_RCMC_DETAILS = 'UPDATE_SELLER_EXPORT_RCMC_DETAILS';
export const UPDATE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS = 'UPDATE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS';
export const UPDATE_SELLER_EXPORT_RCMC_DETAILS_FAIL = 'UPDATE_SELLER_EXPORT_RCMC_DETAILS_FAIL';
export const DELETE_SELLER_EXPORT_RCMC_DETAILS = 'DELETE_SELLER_EXPORT_RCMC_DETAILS';
export const DELETE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS = 'DELETE_SELLER_EXPORT_RCMC_DETAILS_SUCCESS';
export const DELETE_SELLER_EXPORT_RCMC_DETAILS_FAIL = 'DELETE_SELLER_EXPORT_RCMC_DETAILS_FAIL';
export const ADD_SELLER_EXPORT_FSSAI_DETAILS = 'ADD_SELLER_EXPORT_FSSAI_DETAILS';
export const ADD_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS = 'ADD_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS';
export const ADD_SELLER_EXPORT_FSSAI_DETAILS_FAIL = 'ADD_SELLER_EXPORT_FSSAI_DETAILS_FAIL';
export const UPDATE_SELLER_EXPORT_FSSAI_DETAILS = 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS';
export const UPDATE_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS = 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS_SUCCESS';
export const UPDATE_SELLER_EXPORT_FSSAI_DETAILS_FAIL = 'UPDATE_SELLER_EXPORT_FSSAI_DETAILS_FAIL';
export const ADD_SELLER_EXPORT_TAX_DETAILS = 'ADD_SELLER_EXPORT_TAX_DETAILS';
export const ADD_SELLER_EXPORT_TAX_DETAILS_SUCCESS = 'ADD_SELLER_EXPORT_TAX_DETAILS_SUCCESS';
export const ADD_SELLER_EXPORT_TAX_DETAILS_FAIL = 'ADD_SELLER_EXPORT_TAX_DETAILS_FAIL';
export const UPDATE_SELLER_EXPORT_TAX_DETAILS = 'UPDATE_SELLER_EXPORT_TAX_DETAILS';
export const UPDATE_SELLER_EXPORT_TAX_DETAILS_SUCCESS = 'UPDATE_SELLER_EXPORT_TAX_DETAILS_SUCCESS';
export const UPDATE_SELLER_EXPORT_TAX_DETAILS_FAIL = 'UPDATE_SELLER_EXPORT_TAX_DETAILS_FAIL';
export const ADD_SELLER_BANK_DETAILS = 'ADD_SELLER_BANK_DETAILS';
export const ADD_SELLER_BANK_DETAILS_SUCCESS = 'ADD_SELLER_BANK_DETAILS_SUCCESS';
export const ADD_SELLER_BANK_DETAILS_FAIL = 'ADD_SELLER_BANK_DETAILS_FAIL';
export const UPDATE_SELLER_BANK_DETAILS = 'UPDATE_SELLER_BANK_DETAILS';
export const UPDATE_SELLER_BANK_DETAILS_SUCCESS = 'UPDATE_SELLER_BANK_DETAILS_SUCCESS';
export const UPDATE_SELLER_BANK_DETAILS_FAIL = 'UPDATE_SELLER_BANK_DETAILS_FAIL';
export const ADD_SELLER_DOCUMENTS = 'ADD_SELLER_DOCUMENTS';
export const ADD_SELLER_DOCUMENTS_SUCCESS = 'ADD_SELLER_DOCUMENTS_SUCCESS';
export const ADD_SELLER_DOCUMENTS_FAIL = 'ADD_SELLER_DOCUMENTS_FAIL';
export const UPDATE_SELLER_DOCUMENTS = 'UPDATE_SELLER_DOCUMENTS';
export const UPDATE_SELLER_DOCUMENTS_SUCCESS = 'UPDATE_SELLER_DOCUMENTS_SUCCESS';
export const UPDATE_SELLER_DOCUMENTS_FAIL = 'UPDATE_SELLER_DOCUMENTS_FAIL';
export const ADD_SELLER_PRODUCT_CATEGORY = 'ADD_SELLER_PRODUCT_CATEGORY';
export const ADD_SELLER_PRODUCT_CATEGORY_SUCCESS = 'ADD_SELLER_PRODUCT_CATEGORY_SUCCESS';
export const ADD_SELLER_PRODUCT_CATEGORY_FAIL = 'ADD_SELLER_PRODUCT_CATEGORY_FAIL';
// export const CHECK_CART_PRODUCT = 'CHECK_CART_PRODUCT';
// export const CHECK_CART_PRODUCT_SUCCESS = 'CHECK_CART_PRODUCT_SUCCESS';
// export const CHECK_CART_PRODUCT_FAIL = 'CHECK_CART_PRODUCT_FAIL';
export const UPDATE_CART_PRODUCT = 'UPDATE_CART_PRODUCT';
export const UPDATE_SHOW_TGSIN = 'UPDATE_SHOW_TGSIN';
export const GET_LOGIN_SELLER_DETAIL = 'GET_LOGIN_SELLER_DETAIL';
export const GET_LOGIN_SELLER_DETAIL_SUCCESS = 'GET_LOGIN_SELLER_DETAIL_SUCCESS';
export const GET_LOGIN_SELLER_DETAIL_FAIL = 'GET_LOGIN_SELLER_DETAIL_FAIL';

//order
export const LIST_ORDER = 'LIST_ORDER';
export const LIST_ORDER_SUCCESS = 'LIST_ORDER_SUCCESS';
export const LIST_ORDER_FAIL = 'LIST_ORDER_FAIL';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAIL = 'GET_ORDER_DETAIL_FAIL';
export const CONFIRM_SHIPMENT = 'CONFIRM_SHIPMENT';
export const CONFIRM_SHIPMENT_SUCCESS = 'CONFIRM_SHIPMENT_SUCCESS';
export const CONFIRM_SHIPMENT_FAIL = 'CONFIRM_SHIPMENT_FAIL';
export const GET_ORDER_COUNT = 'GET_ORDER_COUNT';
export const GET_ORDER_COUNT_SUCCESS = 'GET_ORDER_COUNT_SUCCESS';
export const GET_ORDER_COUNT_FAIL = 'GET_ORDER_COUNT_FAIL';

//
export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAIL = 'GET_STATES_FAIL';
export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL';
export const UPDATE_CITIES = 'UPDATE_CITIES';

//dashboard
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';

//my account
export const UPDATE_SELLER_USERNAME = 'UPDATE_SELLER_USERNAME';
export const UPDATE_SELLER_USERNAME_SUCCESS = 'UPDATE_SELLER_USERNAME_SUCCESS';
export const UPDATE_SELLER_USERNAME_FAIL = 'UPDATE_SELLER_USERNAME_FAIL';
export const UPDATE_SELLER_PASSWORD = 'UPDATE_SELLER_PASSWORD';
export const UPDATE_SELLER_PASSWORD_SUCCESS = 'UPDATE_SELLER_PASSWORD_SUCCESS';
export const UPDATE_SELLER_PASSWORD_FAIL = 'UPDATE_SELLER_PASSWORD_FAIL';

export const UPDATE_PRODUCT_DETAIL = 'UPDATE_PRODUCT_DETAIL';
export const UPDATE_LIST_LOADING = 'UPDATE_LIST_LOADING';
export const ALERT_MSG_EMPTY = 'ALERT_MSG_EMPTY';
export const UPDATE_ALERT_MSG = 'UPDATE_ALERT_MSG';
export const UPDATE_API_STATUS = 'UPDATE_API_STATUS';
export const LOG_OUT = 'LOG_OUT';
