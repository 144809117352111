import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';

import FormButton from "./FormButton";

import styles from "./LandingContent.module.css";

import {ReactComponent as LandingComputerIcon} from "../assets/img/landing_computer.svg";
import {ReactComponent as Boxes1Icon} from "../assets/img/boxes1.svg";
import {ReactComponent as Boxes2Icon} from "../assets/img/boxes2.svg";
import {ReactComponent as LandingMan1Icon} from "../assets/img/landing_man1.svg";
import {ReactComponent as LandingMan2Icon} from "../assets/img/landing_man2.svg";
import {ReactComponent as LandingTickIcon} from "../assets/img/landing_tick.svg";

import {THEME_COLOR2, THEME_COLOR11} from "../constants";

const connect_partner_data_list = [
  {
    id: 1,
    label: 'Unlimited listing',
  },
  {
    id: 2,
    label: 'Inventory manager tool',
  },
  {
    id: 3,
    label: 'Local currency support',
  },
  {
    id: 4,
    label: 'Inbuilt shipping solution',
  },
  {
    id: 5,
    label: 'E-documents',
  },
  {
    id: 6,
    label: 'Order tracking',
  },
  {
    id: 7,
    label: 'Payment protection',
  },
]

const LandingContent = (props) => {

  return (
    <Grid item md={12} xs={12}>
      <Grid container item md={12} xs={12} mt={10}
            alignItems={{md: 'center', xs: 'unset'}}
            justifyContent={{md: 'space-between', xs: 'unset'}}>
        <Grid item md={5.6} xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'}>

          <Typography className={styles.landingTitleText}>Expand your business beyond borders with <span
            style={style.landingTextColor}>Trebbs.com</span></Typography>

          <Typography className={styles.landingDescText} mt={2}>
            Unleash the full potential of global agriculture. Our B2B marketplace is your gateway to seamless
            cross-border
            trade. Connect with a vast network of verified agri exporters, discover new markets, and optimize your
            supply
            chain. From farm to table, we empower businesses to thrive in the dynamic world of agriculture.
          </Typography>
        </Grid>
        <Grid item md={5.6} xs={12} display={{md: 'flex', xs: 'none'}} justifyContent={'center'}
              className={styles.computerView}>
          <LandingComputerIcon height={'70%'} width={'70%'}/>
          <Box className={styles.box1View}>
            <Boxes1Icon height={'35%'} width={window.innerWidth / 15}/>
          </Box>
          <Box className={styles.box2View}>
            <Boxes2Icon height={'60%'} width={window.innerWidth / 8}/>
          </Box>
        </Grid>
      </Grid>


      <Grid container item md={12} xs={12} mt={10}
            sx={style.partnerView}>

        <Grid item md={3} xs={12} display={{md: 'flex', xs: 'none'}}  alignItems={'center'} justifyContent={'center'}>
          <LandingMan1Icon height={'70%'} width={'100%'}/>
        </Grid>

        <Grid item md={6} xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Typography mb={2} className={styles.landingPartnerTitleText}>Cut out the middleman and connect directly with
            agri
            partners</Typography>

          <Grid item md={5.6} xs={12} display={'flex'} flexDirection={'column'} alignItems='center'
                justifyContent={'center'}>
            <Box>
              {connect_partner_data_list?.map((item, index) => {
                return (
                  <Box key={index} display={'flex'} flexDirection={'row'} alignItems={'center'} mt={2}>
                    <LandingTickIcon height={23} width={23}/>
                    <Box pl={2}>
                      <Typography className={styles.connectPartnerDescText}>{item?.label}</Typography>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Grid>
        </Grid>

        <Grid item md={3} xs={12} display={{md: 'flex', xs: 'none'}}  alignItems={'center'} justifyContent={'center'}>
          <LandingMan2Icon height={'70%'} width={'100%'}/>
        </Grid>

      </Grid>

    </Grid>
  );
}

export default LandingContent;

const style = {
  landingTextColor: {
    color: THEME_COLOR11
  },
  partnerView: {
    height: {md: 500, xs: 'unset'},
    minHeight: {md: 'unset', xs: 500}
  },
  iconView: {
    height: {md: 30, xs: 50},
    width: {md: 30, xs: 50},
  }
};
