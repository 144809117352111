import { AUTHENTICATION_FAILED, ENV } from '../constants';
import axios from 'axios';

import { store } from '../redux/store';
import { logOut } from "../redux/actions/userDataActions";

let apiUrl = '';

if (ENV === 'PROD') {
  apiUrl = 'https://api.trebbs.com/seller/';
} else {
  apiUrl = 'http://localhost:8080/seller/';
}

function userToken() {
  const state = store.getState();
  let token = state.userData.userToken;
  return token;
}

export async function sellerLogin(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('login', config);
}

export async function getRegisterData() {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_register_data', config);
}

export async function sellerRegister(data) {
  let config = {
    method: 'POST',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('register', config);
}

export async function getAddProductData() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_add_product_data', config);
}

export async function getProductDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_product_detail', config);
}

export async function updateProductStatus(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_product_status', config);
}


export async function addProductVariety(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_product_variety', config);
}

export async function addProductImage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_product_image', config);
}

export async function addProductPackingPrice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_product_packing_price', config);
}

export async function addProductShipping(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_product_shipping', config);
}

export async function updateProductVariety(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_product_variety', config);
}

export async function updateProductImage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_product_image', config);
}

export async function updateProductPackingPrice(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_product_packing_price', config);
}

export async function updateProductPricePerPackage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('update_product_price_per_package', config);
}

export async function updateProductShipping(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_product_shipping', config);
}

export async function listProduct(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('list_product', config);
}

export async function deleteProduct(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_product', config);
}

export async function listCategory() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('list_category', config);
}

export async function getSellerDetail() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_seller_detail', config);
}

export async function addSellerContactDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_seller_contact_detail', config);
}

export async function updateSellerContactDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_seller_contact_detail', config);
}

export async function addSellerExportLicenseDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_seller_export_license_detail', config);
}

export async function updateSellerExportLicenseDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_seller_export_license_detail', config);
}

export async function addSellerExportRcmcDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_seller_export_rcmc_detail', config);
}

export async function updateSellerExportRcmcDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_seller_export_rcmc_detail', config);
}

export async function deleteSellerExportRcmcDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_seller_export_rcmc_detail', config);
}

export async function addSellerExportFssaiDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_seller_export_fssai_detail', config);
}

export async function updateSellerExportFssaiDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_seller_export_fssai_detail', config);
}

export async function addSellerExportTaxDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_seller_export_tax_detail', config);
}

export async function updateSellerExportTaxDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_seller_export_tax_detail', config);
}

export async function addSellerBankDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_seller_bank_detail', config);
}

export async function updateSellerBankDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_seller_bank_detail', config);
}

export async function addSellerDocument(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_seller_document', config);
}

export async function updateSellerDocument(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_seller_document', config);
}

export async function addSellerProductCategory(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('add_seller_product_category', config);
}

// export async function checkCartProduct(data) {
//   let token = userToken();
//   let config = {
//     method: 'POST',
//     headers: {
//       Authorization: 'Bearer ' + token,
//       'Content-Type': 'application/json',
//     },
//     data: data,
//   };
//
//   return callApi('check_cart_product', config);
// }

export async function getStatesList(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_states_list', config);
}


export async function getCitiesList(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_cities_list', config);
}

export async function getLoginSellerDetail() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_login_seller_detail', config);
}

//dashboard
export async function getDashboard() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_dashboard_page', config);
}

export async function listOrder(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return callApi('get_orders_list ', config);
}

export async function getOrderDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_order_detail', config);
}

export async function confirmShipment(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('confirm_shipment', config);
}

export async function getOrderCount() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_order_count', config);
}

export async function updateMainProductImage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('update_main_product_image', config);
}

export async function deleteProductImage(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_product_image', config);
}

export async function addProductDocument(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  };

  return callApi('add_product_document', config);
}

export async function deleteProductDocument(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('delete_product_document', config);
}

export async function getSellerCategoryList() {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('get_seller_category_list', config);
}

export async function forgotUserName(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('forgot_user_name', config);
}

export async function forgotUserPassword(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('forgot_user_password', config);
}

export async function updateForgotUserPassword(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_forgot_user_password', config);
}

export async function getForgotUserPassword(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('get_forgot_user_password', config);
}


export async function updateSellerUsername(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_seller_username', config);
}

export async function updateSellerPassword(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('update_seller_password', config);
}
/**
 * Private functions
 */
async function callApi(endpoint, config) {
  const url = `${apiUrl}${endpoint}`;

  console.log('Url: ', url);
  console.log('Config: ', config);

  return axios({
    ...config,
    url: url,
  })
    .then(async (response) => {
      console.log('-----API RESPONSE-----', response.data);
      if (response.data.msg === AUTHENTICATION_FAILED) {
        await store.dispatch(logOut(''));
        return { status: 'Failed', msg: response.data.msg };
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
      console.log(
        'There has been a problem with your fetch operation: ' + error.message,
      );
      if (error?.message) {
        return {
          status: 'Failed',
          msg: error.message,
        };
      }
      return {
        status: 'Failed',
        msg: 'Api Error',
      };
    });
}
