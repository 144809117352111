import React, {useEffect, useState} from 'react';
import {
  Grid,
  Box,
  Typography,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody
} from '@mui/material';

import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import FormSelectBox from "./FormSelectBox";

import './../assets/css/custom.css';

import {THEME_COLOR9} from "../constants";

const AddProductShippingDetailTab = (props) => {
  const [moq, setMoq] = useState('');
  const [productUid, setProductUid] = useState('');
  const [supplyAbilityPerWeek, setSupplyAbilityPerWeek] = useState('');
  const [processingTime, setProcessingTime] = useState('');
  const [productType, setProductType] = useState('');
  const [requiredTemperature, setRequiredTemperature] = useState('');
  const [stuffing, setStuffing] = useState('');
  const [sku, setSku] = useState('');
  const [pickupLocation, setPickupLocation] = useState('');
  const [packingMaterial, setPackingMaterial] = useState('');
  const [grossWeight, setGrossWeight] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [unitLabel, setUnitLabel] = useState('');
  const [error, setError] = useState(null);


  useEffect(() => {
    setProductUid(props?.productUid);
  }, [props.productUid]);

  useEffect(() => {
    if (props?.packingPriceDetail && (props?.packingPriceDetail != null)) {
      setSku(props?.packingPriceDetail?.sku);
      setPickupLocation(props?.packingPriceDetail?.pickupLocation);
      setPackingMaterial(props?.packingPriceDetail?.packingMaterial);
      setGrossWeight(props?.packingPriceDetail?.packageGrossWeight);
      if (props?.packingPriceDetail?.unit) {
        let unit_list = [...props.list?.unitList];
        let index = unit_list.findIndex((x) => x.value == props?.packingPriceDetail?.unit)
        if (index >= 0) {
          setUnitLabel(unit_list[index]?.label)
        }
      }
    } else {
      setUnitLabel(props.list?.unitList[0]?.label)
    }
  }, [props?.packingPriceDetail]);

  useEffect(() => {
    if (props.data != null) {
      setMoq(props.data.moq);
      setSupplyAbilityPerWeek(props.data.supplyAbilityPerWeek);
      setProcessingTime(props.data.processingTime);
      setRequiredTemperature(props.data.requiredTemperature);
      setProductType(props.data.productType);
      setStuffing(props.data.stuffing);
      setLength(props.data.length);
      setWidth(props.data.width);
      setHeight(props.data.height);
    }
  }, [props.data]);


  const onSaveClick = () => {
    let errorText = {};
    if (moq == '') {
      errorText = {moq: 'MOQ is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(moq)) {
      errorText = {...errorText, moq: 'Invalid MOQ'};
    }
    if (supplyAbilityPerWeek == '') {
      errorText = {...errorText, supplyAbilityPerWeek: 'Supply Ability Per Week is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(supplyAbilityPerWeek)) {
      errorText = {...errorText, supplyAbilityPerWeek: 'Invalid Supply Ability Per Week'};
    }
    if (processingTime == '') {
      errorText = {...errorText, processingTime: 'Processing Time is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(processingTime)) {
      errorText = {...errorText, processingTime: 'Invalid Processing Time'};
    }
    if (productType == '') {
      errorText = {...errorText, productType: 'Type of Product is required'};
    }
    if (requiredTemperature == '' && requiredTemperature != 0) {
      errorText = {...errorText, requiredTemperature: 'Required Temperature is required'};
    }
    if (stuffing == '') {
      errorText = {...errorText, stuffing: 'Stuffing is required'};
    }
    if (sku == '') {
      errorText = {...errorText, sku: 'SKU is required'};
    }
    if (pickupLocation == '') {
      errorText = {...errorText, pickupLocation: 'Pickup Location is required'};
    }
    if (packingMaterial == '') {
      errorText = {...errorText, packingMaterial: 'Packing Material is required'};
    }
    if (grossWeight == '') {
      errorText = {...errorText, grossWeight: 'Gross Weight is required'};
    }
    if (length == '') {
      errorText = {...errorText, length: 'Length is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(length)) {
      errorText = {...errorText, length: 'Invalid Length'};
    }
    if (width == '') {
      errorText = {...errorText, width: 'Width is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(width)) {
      errorText = {...errorText, width: 'Invalid Width'};
    }
    if (height == '') {
      errorText = {...errorText, height: 'Height is required'};
    } else if (!/^[0-9]*\.?[0-9]*$/.test(height)) {
      errorText = {...errorText, height: 'Invalid Height'};
    }
    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      productUid: productUid,
      moq: moq,
      supplyAbilityPerWeek: supplyAbilityPerWeek,
      processingTime: processingTime,
      productType: productType,
      requiredTemperature: requiredTemperature,
      stuffing: stuffing,
      length: length,
      width: width,
      height: height,
      sku: sku,
    }

    if (props?.data != null) {
      props.onUpdateProductShipping(data)
    } else {
      props.onAddProductShipping(data)
    }
  };


  return (
    <Grid item container xs={11.6} md={11.6}>

      <Grid item md={12} xs={12} mt={2}
            container>
        <FormTextInput
          page={'AddProductShippingDetailTab'}
          value={moq}
          setValue={setMoq}
          label={'Minimum Order Quantity'}
          placeholder={unitLabel}
          error={error?.moq}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddProductShippingDetailTab'}
          value={supplyAbilityPerWeek}
          setValue={setSupplyAbilityPerWeek}
          label={'Supply ability per Week'}
          placeholder={unitLabel}
          error={error?.supplyAbilityPerWeek}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormTextInput
          page={'AddProductShippingDetailTab'}
          value={processingTime}
          setValue={setProcessingTime}
          label={'Processing Time'}
          placeholder={'Days'}
          error={error?.processingTime}
          textInputViewStyle={styles.textInputViewStyle}
          required={"true"}
        />
      </Grid>

      <Grid item md={12} xs={12} style={styles.contentView}
            display={'flex'} flexDirection={'column'} justifyContent={'center'}
            p={2} mt={3}>
        <Box>
          <Typography style={styles.contentText}>
            Processing time will be the time taken to Procure, Pack and Pre cool the product for Pickup by the logistics
            service provider.
          </Typography>
        </Box>
        <Box pt={2}>
          <Typography style={styles.contentText}>
            Processing time will be calculated from the date of confirmation of order and will be shared with Buyer.
            Seller
            is responsible to schedule pickup within the given time. Otherwise the order will be automatically cancelled
          </Typography>
        </Box>
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormSelectBox
          page={'AddProductShippingDetailTab'}
          value={productType}
          setValue={setProductType}
          label={'Type of Product'}
          data={props.list?.productTypeList ? props.list.productTypeList : []}
          error={error?.productType}
          required={true}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormSelectBox
          page={'AddProductShippingDetailTab'}
          value={requiredTemperature}
          setValue={setRequiredTemperature}
          label={'Required Temperature'}
          placeholder={'°C'}
          data={props.list?.requiredTemperatureList ? props.list.requiredTemperatureList : []}
          error={error?.requiredTemperature}
          required={true}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <FormSelectBox
          page={'AddProductShippingDetailTab'}
          value={stuffing}
          setValue={setStuffing}
          label={'Stuffing'}
          data={props.list?.stuffingList ? props.list.stuffingList : []}
          error={error?.stuffing}
          required={true}
        />
      </Grid>

      <Grid item md={12} xs={12} mt={3}
            container>
        <Typography style={styles.packageDimensionText}>
          Package dimensions
        </Typography>
      </Grid>

      <Grid item md={12} xs={12} mt={1} mb={2}
            style={styles.contentView}
            display={'flex'} flexDirection={'row'} alignItems={'center'}
            p={2}>
        <Typography style={styles.packageDimensionContentText}>
          Your packings are listed below. Please enter their package dimensions in the respective boxes
        </Typography>
      </Grid>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={styles.tableHeadRow}>
              <TableCell>
                <Typography style={styles.tableLabelText}>SKU</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Pickup Location</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Packing Material</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Gross Weight</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Length</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Width</Typography>
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Height</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={styles.tableRow}>
              <TableCell>
                <FormTextInput
                  page={'AddProductShippingDetailTab'}
                  disabled={true}
                  value={sku}
                  setValue={setSku}
                  error={error?.sku}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductDetailTab'}
                  disabled={true}
                  value={pickupLocation}
                  setValue={setPickupLocation}
                  placeholder={'Location'}
                  error={error?.pickupLocation}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductShippingDetailTab'}
                  disabled={true}
                  value={packingMaterial}
                  setValue={setPackingMaterial}
                  placeholder={'material'}
                  error={error?.packingMaterial}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductShippingDetailTab'}
                  disabled={true}
                  value={grossWeight}
                  setValue={setGrossWeight}
                  placeholder={unitLabel}
                  error={error?.grossWeight}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <Typography style={styles.tableLabelText}>Package Dimensions</Typography>
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductShippingDetailTab'}
                  value={length}
                  setValue={setLength}
                  placeholder={'CM'}
                  error={error?.length}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductShippingDetailTab'}
                  value={width}
                  setValue={setWidth}
                  placeholder={'CM'}
                  error={error?.width}
                  required={true}
                />
              </TableCell>
              <TableCell>
                <FormTextInput
                  page={'AddProductShippingDetailTab'}
                  value={height}
                  setValue={setHeight}
                  placeholder={'CM'}
                  error={error?.height}
                  required={true}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item md={12} xs={12} container
            display="flex" flexDirection="row" justifyContent="right" mt={4} mb={4}>
        <FormButton
          btnType={'btn8'}
          title={'Save and Finish'}
          onSubmit={onSaveClick}
        />
      </Grid>

    </Grid>
  );
}

export default AddProductShippingDetailTab;

const styles = {
  textInputViewStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e3ea',
  },
  selectedItemViewStyle: {
    backgroundColor: '#fff',
  },
  packageDimensionText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  contentText: {
    fontSize: 13,
    color: '#000',
    fontFamily: 'InterItalic',
  },
  packageDimensionContentText: {
    fontSize: 13,
    color: '#000',
    fontFamily: 'InterItalic',
  },
  contentView: {
    backgroundColor: THEME_COLOR9,
    borderRadius: 4,
    boxShadow: '0px 2px 3px #aaa',
  },
  tableHeadRow: {
    backgroundColor: THEME_COLOR9,
    border: '1px solid #000'
  },
  tableRow: {
    backgroundColor: '#fff',
    border: '1px solid #000'
  },
  tableLabelText: {
    color: '#000',
    fontFamily: 'InterBold',
    fontSize: 12,
  },
};

