import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import getSymbolFromCurrency from 'currency-symbol-map'

import styles from './ProductDetailView.module.css'

import ProductActionRow from "./ProductActionRow";
import ExwPricePerPackageRow from "./ExwPricePerPackageRow";

import {THEME_COLOR7} from "../constants";
import {COUNTRIES} from "../constants/countries";

const status_list = {
  pending: {
    color: 'red',
  },
  active: {
    color: THEME_COLOR7,
  },
  inactive: {
    color: 'red',
  },
  removed: {
    color: 'red',
  }
}

const ProductDetailView = (props) => {
  const [sellerCurrency, setSellerCurrency] = useState('');
  const [pricePerkg, setPricePerKg] = useState(0);
  const [referralFeePerkg, setReferralFeePerkg] = useState(0);
  const [referralFeePerPackage, setReferralFeePerPackage] = useState(0);

  useEffect(() => {
    if(props.data != null) {
      let currency = getSymbolFromCurrency(props?.data?.sellerCurrency);
      setSellerCurrency(currency)

      let data = props?.data;
      let seller_price_per_kg = 0;
      let seller_referral_fee_per_kg = 0;
      let seller_referral_fee_per_package = 0;
      if (data?.pricePerPackage != '' && data?.packageNetWeight != '') {
        seller_price_per_kg = parseFloat(data?.productPackingPriceDetail?.pricePerPackage / data?.productPackingPriceDetail?.packageNetWeight);
        setPricePerKg(seller_price_per_kg);

        seller_referral_fee_per_kg = parseFloat((seller_price_per_kg * data?.referralFee) / 100)
        setReferralFeePerkg(seller_referral_fee_per_kg);

        seller_referral_fee_per_package = parseFloat((data?.productPackingPriceDetail?.pricePerPackage * data?.referralFee) / 100)
        setReferralFeePerPackage(seller_referral_fee_per_package);
      }

    }
  },[props?.data])

  let item = props?.data;

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography className={styles.titleText}>Country</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Seller</Typography>
                <Typography className={styles.subTitleText} ml={1}>TGSIN</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>SKU</Typography>
                <Typography className={styles.subTitleText} ml={1}>Product ID</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Product</Typography>
                <Typography className={styles.subTitleText} ml={1}>HS Code</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Pickup Location</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Packing</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Price/Kg</Typography>
                <Typography className={styles.subTitleText} ml={1}>Referral Fee</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>EXW Price/Package</Typography>
                <Typography className={styles.subTitleText} ml={1}>Referral Fee/Package</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Date Created</Typography>
                <Typography className={styles.subTitleText} ml={1}>Status Changed Date</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Listing Status</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.titleText}>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.tableRow}>
              <TableCell>
                <Typography
                  className={styles.valueText}>{item?.sellerCountry ? COUNTRIES.filter(
                  (x) => x.code == item?.sellerCountry
                )?.[0]?.label : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>Seller: {item?.sellerNameEntity ? item.sellerNameEntity : '-'}</Typography>
                <Typography className={styles.subValueText} mt={1}>
                  TGSIN: {item?.tgsin ? item.tgsin : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>SKU: {
                  item?.productPackingPriceDetail != null ?
                    (item?.productPackingPriceDetail?.sku ? item?.productPackingPriceDetail?.sku : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  Product: {item?.productVarietyDetail?.product ? item.productVarietyDetail.product : '-'}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  HS code: {item?.hsCodeVal ? (item.hsCodeVal + ' - ' + item.hsCodeTitle) : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.valueText}>
                  {item?.productPackingPriceDetail != null ?
                    (item?.productPackingPriceDetail?.pickupLocation ? item?.productPackingPriceDetail?.pickupLocation : '-')
                    : '-'}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.productPackingPriceDetail != null ?
                    (item?.productPackingPriceDetail?.packageNetWeight ?
                      (item?.productPackingPriceDetail?.packageNetWeight + ' kg ' + item?.productPackingPriceDetail?.packingMaterial)
                      : '-')
                    : '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  Price/Kg: {pricePerkg ? sellerCurrency + pricePerkg : '-'}
                  {/*{sellerCurrency}{item?.productPackingPriceDetail != null ? ((item?.productPackingPriceDetail?.pricePerPackage != '' && item?.productPackingPriceDetail?.packageNetWeight != '') ?*/}
                  {/*(((item?.productPackingPriceDetail?.pricePerPackage / item?.productPackingPriceDetail?.packageNetWeight) * item?.referralFee) / 100)?.toFixed(2)*/}
                  {/*: 0) : 0}*/}
                </Typography>
                <Typography className={styles.subValueText} mt={1}>
                  Referral Fee: {referralFeePerkg ?  sellerCurrency + referralFeePerkg  : '-'}
                  {/*Referral Fee: {item?.referralFee ? item.referralFee + '%' : '-'}*/}
                </Typography>
              </TableCell>
              <TableCell>
                <ExwPricePerPackageRow
                  uid={item?.productVarietyDetail?.productUid}
                  status={item?.productStatus}
                  packingPriceId={item?.packingPriceId}
                  pricePerPackage={item?.productPackingPriceDetail != null ? item?.productPackingPriceDetail?.pricePerPackage : ''}
                  packageNetWeight={item?.productPackingPriceDetail != null ? item?.productPackingPriceDetail?.packageNetWeight : ''}
                  referralFee={item?.referralFee}
                  sku={item?.productPackingPriceDetail?.sku}
                  referralFeePerPackage={referralFeePerPackage}
                  sellerCurrency={sellerCurrency}
                />
              </TableCell>
              <TableCell>
                <Typography className={styles.valueText}>
                  {item?.createdTimestamp && item?.timestamp ? ('Date Created: ' + item.createdTimestamp) : item?.createdTimestamp}
                </Typography>
                <Typography className={styles.subValueText}>
                  {item?.timestamp ? 'Status Changed Date: ' + item.timestamp : ''}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  className={styles.statusText}  sx={{...status_list[item?.productStatus]}}>
                  {item?.productStatus ? item.productStatus : '-'}</Typography>
              </TableCell>
              <TableCell>
                {props?.tabStatus != 'removed' ?
                  <ProductActionRow
                    page={'detail'}
                    status={item?.productStatus}
                    uid={item?.productVarietyDetail?.productUid}
                    tabStatus={item?.productStatus}
                  />
                  : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </>
  )
}


export default ProductDetailView;
