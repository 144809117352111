import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem, {listItemClasses} from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Navbar from "./Navbar";

import {connect} from "react-redux";
import {alertMsgEmpty, updateListLoading} from "../redux/actions/userDataActions";

import {ReactComponent as LeftArrow1} from "../assets/img/grey_left_arrow.svg";
import {ReactComponent as LeftArrow2} from "../assets/img/light_blue_left_arrow.svg";
import {ReactComponent as RightArrow1} from "../assets/img/grey_right_arrow.svg";
import {ReactComponent as RightArrow2} from "../assets/img/light_blue_right_arrow.svg";
import {ReactComponent as Dashboard} from "../assets/img/dashboard.svg";
import {ReactComponent as Product} from "../assets/img/product.svg";
import {ReactComponent as Order} from "../assets/img/order.svg"

import Logo from '../assets/img/logo.png';

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const menu_list = [
  {page: 'index', label: 'Dashboard', icon: <Dashboard height={20} width={20}/>, href: '/index'},
  {page: 'product', label: 'Product', icon: <Product height={20} width={20}/>, href: '/product'},
  {page: 'orders', label: 'Orders', icon: <Order height={20} width={20}/>, href: '/orders'},
];

const Sidebar = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState((window.innerWidth < 600) ? false : true);
  const [selectedTab, setSelectedTab] = useState(true);
  const [mobileView, setMobileView] = useState(window.innerWidth < 600);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setMobileView(window.innerWidth <= 990);
    });
  }, [])

  const handleDrawerToggle = () => {
    setSelectedTab(!open);
    setOpen(!open);
  };

  const onSelectTab = (item) => {
    props.alertMsgEmpty();
    props.updateListLoading(false);
    if (mobileView) {
      setOpen(false);
    }
    setSelectedTab(true);
    navigate(item.href)
  };

  const handleDrawerOpen = () => {
    if (!selectedTab) {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (!selectedTab) {
      setOpen(false);
    }
  };

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline/>

      <Navbar/>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={styles.headerView}>
          <ListItem
            disablePadding sx={{display: 'block'}}>
            <ListItemButton
              sx={{
                justifyContent: 'initial',
                ml: -1
              }}>
              {/*<ListItemIcon*/}
              {/*  sx={{*/}
              {/*    minWidth: 0,*/}
              {/*    mr: open ? 1 : 'auto',*/}
              {/*    justifyContent: 'center',*/}
              {/*    height: open ? 40 : 35,*/}
              {/*    width: open ? 40 : 35,*/}
              {/*  }}>*/}
              {/*  <img alt="" src={Logo} style={styles.imgStyle}/>*/}
              {/*</ListItemIcon>*/}
              <ListItemText primary={'Trebbs'}
                            sx={{
                              '& .MuiTypography-root': {
                                fontSize: 18,
                                color: '#FFF',
                                fontFamily: 'InterMedium'
                              }, opacity: open ? 1 : 0,
                            }}/>
            </ListItemButton>
          </ListItem>

          <Box sx={{height: 30, width: 5}}>
            <Box sx={styles.arrowIconView}>
              <IconButton onClick={handleDrawerToggle}>
                {open ?
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                    <LeftArrow1 height={13} width={13} style={{marginLeft: -4}}/>
                    <LeftArrow2 height={10} width={10} style={{marginLeft: -8}}/>
                  </Box> :
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                    <RightArrow2 height={10} width={10} style={{marginRight: -8}}/>
                    <RightArrow1 height={13} width={13} style={{marginRight: -4}}/>
                  </Box>}
              </IconButton>
            </Box>
          </Box>
        </DrawerHeader>

        <Divider style={{backgroundColor: '#555'}}/>
        <List
          style={styles.listView}>
          {menu_list.map((item, index) => (
            <ListItem
              key={item.label}
              disablePadding
              sx={{
                display: 'block',
                width: '90%',
                "&.MuiListItem-root.Mui-selected": {
                  backgroundColor: '#2a2a3c',
                  borderRadius: 2,
                },
                path: {
                  opacity: props.page === item.page ? 1 : 0.4,
                },
                ":hover": {
                  path: {
                    opacity: 1,
                  }
                }
              }}
              selected={props.page === item.page}
              onMouseOver={() => handleDrawerOpen()}
              onMouseLeave={() => handleDrawerClose()}
              onClick={() => onSelectTab(item)}>
              <ListItemButton
                sx={{
                  minHeight: 45,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}>
                <ListItemIcon
                  sx={{
                    // color: props.page === item.page ? '#FFF' : '#9d9da6',
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label}
                              sx={{
                                '& .MuiTypography-root': {
                                  fontFamily: 'InterMedium',
                                  fontSize: 14,
                                  color: props.page === item.page ? '#FFF' : '#9d9da6',
                                  ":hover": {
                                    color: "#FFF"
                                  }
                                },
                                opacity: open ? 1 : 0,
                              }}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    alertMsgEmpty: data => dispatch(alertMsgEmpty(data)),
    updateListLoading: data => dispatch(updateListLoading(data)),
  };
};

export default connect(null, mapDispatchToProps)(Sidebar);

const styles = {
  headerView: {
    backgroundColor: '#181c32',
  },
  imgStyle: {
    height: '100%',
    width: '100%'
  },
  arrowIconView: {
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderColor: '#FFF',
    borderWidth: 1,
    borderRadius: 1.5,
  },
  listView: {
    backgroundColor: '#181c32',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
};
